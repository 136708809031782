export const validateStoriesStructure = (stories) => {
  const newStoriesStructure = stories.map((storie) => {
    const { channelId } = storie;
    if (typeof channelId === 'number') {
      storie.channelId = [channelId];
    }
    return storie;
  });
  return newStoriesStructure;
};
