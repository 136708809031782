import { Model } from 'vue-mc';

/**
 * Product model
 */
class Product extends Model {
  // Default attributes that define the "empty" state.
  defaults() {
    return {
      availability: null,
      deliveryDiscountAmount: null,
      department: null,
      description: '',
      details: [],
      discountPercentage: null,
      firstOrderSpecialPrice: null,
      hasWarning: null,
      href: null,
      id: null,
      imageAppUrl: null,
      imageLargeUrl: null,
      imageMediumUrl: null,
      imageSmallUrl: null,
      images: [],
      isBestPrice: null,
      isMarketplace: null,
      marketplaceDeliveryDays: null,
      name: null,
      nutritionFacts: '',
      price: null,
      quantity: null,
      quantitySpecialPrice: null,
      related: [],
      tag: {},
      typeProduct: '',
      shelf: {},
      slug: null,
      specialPrice: null,
      storeId: null,
      unit: null,
      specialPriceOrderId: null,
    };
  }

  // Route configuration
  routes() {
    return {
      fetch: '/product/{id}',
      save: '/product',
    };
  }
}

export default Product;
