import { camelize } from '~/helpers/camelize';
import normalize from 'json-api-normalize';

export function getOrderDetailTransform(response) {
  const serializeOrderDetail = camelize(
    normalize(response).get([
      'id',

      'date',
      'reference',
      'status',

      'zone_id',
      'store_id',
      'warehouse_id',
      'total_products',
      'total_amount',
      'user_address_latitude',
      'user_address_longitude',
      'delivery_date',
      'delivery_time',
      'shifts',
      'subtotal',
      'delivery_amount',
      'discount_amount',
      'payment_method',
      'management_date',
      'source',
      'user_address',
      'store_name',
      'is_express',
      'credit_card_info',
      'hash_code',
      'model',

      // relationships
      'store.id',
      'store.title',
      'store.app_background_color',
      'store.address_required',
      'store.delivery_time',
      'store.description',
      'store.free_delivery_first_order',
      'store.logo_small_url',
      'store.logo_url',
      'store.merqueo_invoice',
      'store.minimum_order_amount',
      'store.minimum_order_amount_free_delivery',
      'store.show_without_coverage',
      'store.slug',

      'products.id',
      'products.total_price',
      'products.unit_price',
      'products.quantity_cart_original',
      'products.quantity_cart',
      'products.status',
      'products.id_store_product',
      'products.detail_product',

      'products.storeProduct.id',
      'products.storeProduct.slug',
      'products.storeProduct.special_price',
      'products.storeProduct.price',
      'products.storeProduct.public_price',
      'products.storeProduct.store_id',
      'products.storeProduct.discount_percentage',
      'products.storeProduct.quantity_special_price',
      'products.storeProduct.delivery_discount_amount',
      'products.storeProduct.has_warning',
      'products.storeProduct.sponsored',
      'products.storeProduct.pum',
      'products.storeProduct.volume',
      'products.storeProduct.weight',

      'campaignPrizeDetail.id',
      'campaignPrizeDetail.campaign_prize_type',
      'campaignPrizeDetail.discount_amount',
      'campaignPrizeDetail.discount_type',
      'campaignPrizeDetail.maximum_discount_per_order',
      'campaignPrizeDetail.campaign_type',
      'campaignPrizeDetail.brand',
      'campaignPrizeDetail.tag',
      'campaignPrizeDetail.logo',
      'campaignPrizeDetail.url_terms_and_conditions',
      'campaignPrizeDetail.limit_to_apply',
      'campaignPrizeDetail.start_date',
      'campaignPrizeDetail.end_date',
      'campaignPrizeDetail.is_acquisition',
      'campaignPrizeDetail.minimum_order_amount',
      'campaignPrizeDetail.status',
    ]),
  );

  const {
    id,
    userAddress,
    userAddressLatitude,
    userAddressLongitude,
    deliveryDate,
    deliveryAmount,
    discountAmount,
    status,
    paymentMethod,
    reference,
    totalProducts,
    subtotal,
    totalAmount,
    deliveryTime,
    storeId,
    creditCardInfo,
    campaignPrizeDetail,
    products,
    store,
    isExpress,
  } = serializeOrderDetail;
  const orderMapped = {
    id,
    address: {
      address: userAddress,
      latitude: userAddressLatitude,
      longitude: userAddressLongitude,
    },
    date: formatDate(deliveryDate.date),
    deliveryAmount,
    discount: discountAmount,
    status,
    paymentMethod,
    creditCardInfo,
    reference,
    time: deliveryTime,
    totalProducts,
    subTotal: subtotal,
    total: totalAmount,
    products: products.reduce(
      (reducer, product) => {
        const { storeProduct } = product;
        if (!reducer.storeProducts.find((sp) => storeProduct.id === sp.id)) {
          reducer.storeProducts.push(storeProduct);
        }
        reducer.orderProducts.push(product);
        return reducer;
      },
      { storeProducts: [], orderProducts: [] },
    ),
    storeId,
    campaigns: campaignPrizeDetail,
    store,
    isExpress,
  };
  return orderMapped;
}
function formatDate(date) {
  if (typeof new Date(date).toLocaleDateString()?.replaceAll == 'function') {
    return new Date(date).toLocaleDateString().replaceAll('/', '-');
  }
  return new Date(date).toLocaleString().split(' ')[0];
}
