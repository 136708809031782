import { Store } from 'vuex';

export interface IQuery {
  query: {
    express: string;
    store_id: string;
    department_id: string;
    shelf_id: string;
    product_id: string;
    banner_id: string;
    story_id: string;
    channel_id: string;
    brandroom_id: string;
    q: string;
  };
}

export interface ICity {
  slug: string;
}

export async function getStoreSlug({ getters, dispatch }: Store<any>, { query }: IQuery) {
  const express = query?.express ? JSON.parse(query?.express) : false;
  const storeId = parseInt(query.store_id);
  const store = getters['home/getStoreById'](storeId);
  const slug = store?.slug;

  if (store?.addressRequired && !getters['address/isAddressValid']) {
    const setMarketplaceStoreRedirectPromise = dispatch('home/setMarketplaceStoreRedirect', store);
    const setStoreSelectedBeforeAddressPromise = dispatch('home/setStoreSelectedBeforeAddress', true);
    await Promise.all([setMarketplaceStoreRedirectPromise, setStoreSelectedBeforeAddressPromise]);
  }

  if (express) {
    return { slug: 'super-ahorro-express', store };
  }

  return { slug, store };
}
