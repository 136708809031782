import $axios from '~/services/global-axios';

export async function getValidateDniTypeEndpoint(dniTypeId, documentNumber) {
  try {
    const params = `?dniTypeId=${dniTypeId}&documentNumber=${documentNumber}`;
    const response = await $axios.get(`/3.1/dni-type/validate${params}`);
    return { response, err: null };
  } catch (err) {
    return { response: null, err };
  }
}
