import createPersistedState from 'vuex-persistedstate';
import * as Sentry from '@sentry/browser';
const regexMutationsPermit = [/user\//, /home\//, /address\//];

export function createCampaignsStorageState(store) {
  return createPersistedState({
    key: 'campaigns',
    paths: ['campaigns', 'campaigns', 'campaigns'],
    filter: (data) => {
      let isMutationPermit = false;
      if ('type' in data) {
        let currentMutation = data.type;
        regexMutationsPermit.forEach((element) => {
          if (element.exec(currentMutation)) {
            isMutationPermit = true;
          }
        });
      }
      return isMutationPermit;
    },
    getState(key) {
      const storageState = localStorage.getItem(key);
      try {
        const state = JSON.parse(storageState);
        return state;
      } catch (_e) {
        return {};
      }
    },
    setState(key, state) {
      try {
        const stateMappedString = JSON.stringify(state);
        localStorage.setItem(key, stateMappedString);
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    storage: window.localStorage,
  })(store);
}
