import $axios from '~/services/global-axios';

export async function getCitiesEndpoint(countryId) {
  try {
    const response = await $axios.get(`/3.2/cities/main-and-neighborhoods?countryId=${countryId}`);
    // Servicio de prueba en el caso que el 100 no funcione ya que estan con modificaciones aún
    // const response = await $axios.get(`https://run.mocky.io/v3/04de3413-81ed-4b1f-8a97-62a0488cdc98`);
    return { error: null, response };
  } catch (error) {
    return { error, response: null };
  }
}

