import createPersistedState from 'vuex-persistedstate';
import * as Sentry from '@sentry/browser';
import { validateStoriesStructure } from './migrations/stories-migrations';
const regexMutationsPermit = [/user\//, /home\//, /address\//];

export function createGlobalStorageState(store) {
  return createPersistedState({
    key: 'data',
    paths: [
      'user',
      'home.store',
      'home.hasSelectedCountry',
      'home.showToolTipCountry',
      'home.firstTimeExpress',
      'home.countTimeInExpress',
      'home.currentStore',
      'home.marketplaceStore',
      'home.marketplaceStoreRedirect',
      'home.beforeRouteLogin',
      'home.previousPath',
      'home.redirectPath',
      'home.typeStore',
      'stories.channels',
      'stories.stories',
      'address.address',
      'address.address_object',
      'address.zone',
      'address.geolocationPermission',
      'phone_validate.typeRequest',
      'phone_validate.fbId',
      'product.product',
      'events.typeDeepLink',
      'nequi'
    ],
    filter: (data) => {
      let isMutationPermit = false;
      if ('type' in data) {
        let currentMutation = data.type;
        regexMutationsPermit.forEach((element) => {
          if (element.exec(currentMutation)) {
            isMutationPermit = true;
          }
        });
      }
      return isMutationPermit;
    },
    getState(key) {
      const storageState = localStorage.getItem(key);
      try {
        const state = JSON.parse(storageState);
        state.stories.stories = validateStoriesStructure(state.stories.stories);
        return state;
      } catch (_e) {
        return {};
      }
    },
    setState(key, state) {
      try {
        const stateMappedString = JSON.stringify(state);
        localStorage.setItem(key, stateMappedString);
      } catch (error) {
        let currentStorage = '';
        let storageToSave = '';
        try {
          currentStorage = localStorage.getItem(key);
        } catch (error) {
          currentStorage = JSON.stringify(error);
        }
        try {
          storageToSave = JSON.stringify(state);
        } catch (error) {
          storageToSave = JSON.stringify(error);
        }
        error.currentStorageLength = currentStorage ? currentStorage.length : null;
        error.storageToSaveLength = storageToSave ? storageToSave.length : null;
        error.storageToSave = storageToSave;
        error.currentStorage = currentStorage;
        Sentry.captureException(error);
      }
    },
    storage: window.localStorage,
  })(store);
}
