export const Error = () => import('../../client/components/error/404.vue' /* webpackChunkName: "components/error" */).then(c => wrapFunctional(c.default || c))
export const ErrorNoProducts = () => import('../../client/components/error/no-products.vue' /* webpackChunkName: "components/error-no-products" */).then(c => wrapFunctional(c.default || c))
export const ErrorStoreClosed = () => import('../../client/components/error/store-closed.vue' /* webpackChunkName: "components/error-store-closed" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqAlertCloseModal = () => import('../../client/components/dialogs/mq-alert-close-modal.vue' /* webpackChunkName: "components/dialogs-mq-alert-close-modal" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqBasketFull = () => import('../../client/components/dialogs/mq-basket-full.vue' /* webpackChunkName: "components/dialogs-mq-basket-full" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqCampaignModal = () => import('../../client/components/dialogs/mq-campaign-modal.vue' /* webpackChunkName: "components/dialogs-mq-campaign-modal" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqCancelTransactionModal = () => import('../../client/components/dialogs/mq-cancel-transaction-modal.vue' /* webpackChunkName: "components/dialogs-mq-cancel-transaction-modal" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqChangePrivacyPoliciesModal = () => import('../../client/components/dialogs/mq-change-privacy-policies-modal.vue' /* webpackChunkName: "components/dialogs-mq-change-privacy-policies-modal" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqChangesHelpcenterProductsModal = () => import('../../client/components/dialogs/mq-changes-helpcenter-products-modal.vue' /* webpackChunkName: "components/dialogs-mq-changes-helpcenter-products-modal" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqContactModal = () => import('../../client/components/dialogs/mq-contact-modal.vue' /* webpackChunkName: "components/dialogs-mq-contact-modal" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqCoverageModal = () => import('../../client/components/dialogs/mq-coverage-modal.vue' /* webpackChunkName: "components/dialogs-mq-coverage-modal" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqDeleteAccountErrorModal = () => import('../../client/components/dialogs/mq-delete-account-error-modal.vue' /* webpackChunkName: "components/dialogs-mq-delete-account-error-modal" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqDeleteAccountInfoModal = () => import('../../client/components/dialogs/mq-delete-account-info-modal.vue' /* webpackChunkName: "components/dialogs-mq-delete-account-info-modal" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqDeleteAccountModal = () => import('../../client/components/dialogs/mq-delete-account-modal.vue' /* webpackChunkName: "components/dialogs-mq-delete-account-modal" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqDeleteDirection = () => import('../../client/components/dialogs/mq-delete-direction.vue' /* webpackChunkName: "components/dialogs-mq-delete-direction" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqDeleteMethod = () => import('../../client/components/dialogs/mq-delete-method.vue' /* webpackChunkName: "components/dialogs-mq-delete-method" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqDeletedAccountModal = () => import('../../client/components/dialogs/mq-deleted-account-modal.vue' /* webpackChunkName: "components/dialogs-mq-deleted-account-modal" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqDeliveryTimesModal = () => import('../../client/components/dialogs/mq-delivery-times-modal.vue' /* webpackChunkName: "components/dialogs-mq-delivery-times-modal" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqDetailVideoProducts = () => import('../../client/components/dialogs/mq-detail-video-products.vue' /* webpackChunkName: "components/dialogs-mq-detail-video-products" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqDownloadAppModal = () => import('../../client/components/dialogs/mq-download-app-modal.vue' /* webpackChunkName: "components/dialogs-mq-download-app-modal" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqHelpCenterModal = () => import('../../client/components/dialogs/mq-help-center-modal.vue' /* webpackChunkName: "components/dialogs-mq-help-center-modal" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqMaxAgeModal = () => import('../../client/components/dialogs/mq-max-age-modal.vue' /* webpackChunkName: "components/dialogs-mq-max-age-modal" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqMaxSpecialModal = () => import('../../client/components/dialogs/mq-max-special-modal.vue' /* webpackChunkName: "components/dialogs-mq-max-special-modal" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqModalDeliveryStatus = () => import('../../client/components/dialogs/mq-modal-delivery-status.vue' /* webpackChunkName: "components/dialogs-mq-modal-delivery-status" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqModalDeliveryStrip = () => import('../../client/components/dialogs/mq-modal-delivery-strip.vue' /* webpackChunkName: "components/dialogs-mq-modal-delivery-strip" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqModalVideoBanner = () => import('../../client/components/dialogs/mq-modal-video-banner.vue' /* webpackChunkName: "components/dialogs-mq-modal-video-banner" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqModalVideoProduct = () => import('../../client/components/dialogs/mq-modal-video-product.vue' /* webpackChunkName: "components/dialogs-mq-modal-video-product" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqNewCardModal = () => import('../../client/components/dialogs/mq-new-card-modal.vue' /* webpackChunkName: "components/dialogs-mq-new-card-modal" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqNoProducts = () => import('../../client/components/dialogs/mq-no-products.vue' /* webpackChunkName: "components/dialogs-mq-no-products" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqOnboardingExpressModal = () => import('../../client/components/dialogs/mq-onboarding-express-modal.vue' /* webpackChunkName: "components/dialogs-mq-onboarding-express-modal" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqOrderDetail = () => import('../../client/components/dialogs/mq-order-detail.vue' /* webpackChunkName: "components/dialogs-mq-order-detail" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqProductAlreadyBought = () => import('../../client/components/dialogs/mq-product-already-bought.vue' /* webpackChunkName: "components/dialogs-mq-product-already-bought" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqProductNoAvailable = () => import('../../client/components/dialogs/mq-product-no-available.vue' /* webpackChunkName: "components/dialogs-mq-product-no-available" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqProductsNotPromo = () => import('../../client/components/dialogs/mq-products-not-promo.vue' /* webpackChunkName: "components/dialogs-mq-products-not-promo" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqRetryTransactionModal = () => import('../../client/components/dialogs/mq-retry-transaction-modal.vue' /* webpackChunkName: "components/dialogs-mq-retry-transaction-modal" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqSelectCountryModal = () => import('../../client/components/dialogs/mq-select-country-modal.vue' /* webpackChunkName: "components/dialogs-mq-select-country-modal" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqSurvey = () => import('../../client/components/dialogs/mq-survey.vue' /* webpackChunkName: "components/dialogs-mq-survey" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqUpdateOrder = () => import('../../client/components/dialogs/mq-update-order.vue' /* webpackChunkName: "components/dialogs-mq-update-order" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqUserWithOrdersModal = () => import('../../client/components/dialogs/mq-user-with-orders-modal.vue' /* webpackChunkName: "components/dialogs-mq-user-with-orders-modal" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqValidateNumberModal = () => import('../../client/components/dialogs/mq-validate-number-modal.vue' /* webpackChunkName: "components/dialogs-mq-validate-number-modal" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqWidgetStoryProducts = () => import('../../client/components/dialogs/mq-widget-story-products.vue' /* webpackChunkName: "components/dialogs-mq-widget-story-products" */).then(c => wrapFunctional(c.default || c))
export const DialogsMqYouAreSavingModal = () => import('../../client/components/dialogs/mq-you-are-saving-modal.vue' /* webpackChunkName: "components/dialogs-mq-you-are-saving-modal" */).then(c => wrapFunctional(c.default || c))
export const CommonMqAddressSelected = () => import('../../client/components/common/mq-address-selected.vue' /* webpackChunkName: "components/common-mq-address-selected" */).then(c => wrapFunctional(c.default || c))
export const CommonMqAlertsRender = () => import('../../client/components/common/mq-alerts-render.vue' /* webpackChunkName: "components/common-mq-alerts-render" */).then(c => wrapFunctional(c.default || c))
export const CommonMqCheckoutTabs = () => import('../../client/components/common/mq-checkout-tabs.vue' /* webpackChunkName: "components/common-mq-checkout-tabs" */).then(c => wrapFunctional(c.default || c))
export const CommonMqDeliveryBar = () => import('../../client/components/common/mq-delivery-bar.vue' /* webpackChunkName: "components/common-mq-delivery-bar" */).then(c => wrapFunctional(c.default || c))
export const CommonMqDeliveryTimeAvailable = () => import('../../client/components/common/mq-delivery-time-available.vue' /* webpackChunkName: "components/common-mq-delivery-time-available" */).then(c => wrapFunctional(c.default || c))
export const CommonMqDialogs = () => import('../../client/components/common/mq-dialogs.vue' /* webpackChunkName: "components/common-mq-dialogs" */).then(c => wrapFunctional(c.default || c))
export const CommonMqFooter = () => import('../../client/components/common/mq-footer.vue' /* webpackChunkName: "components/common-mq-footer" */).then(c => wrapFunctional(c.default || c))
export const CommonMqInvitationDownloadApp = () => import('../../client/components/common/mq-invitation-download-app.vue' /* webpackChunkName: "components/common-mq-invitation-download-app" */).then(c => wrapFunctional(c.default || c))
export const CommonMqLowerTabs = () => import('../../client/components/common/mq-lower-tabs.vue' /* webpackChunkName: "components/common-mq-lower-tabs" */).then(c => wrapFunctional(c.default || c))
export const CommonMqNavhalls = () => import('../../client/components/common/mq-navhalls.vue' /* webpackChunkName: "components/common-mq-navhalls" */).then(c => wrapFunctional(c.default || c))
export const CommonMqNavtool = () => import('../../client/components/common/mq-navtool.vue' /* webpackChunkName: "components/common-mq-navtool" */).then(c => wrapFunctional(c.default || c))
export const CommonMqOrder = () => import('../../client/components/common/mq-order.vue' /* webpackChunkName: "components/common-mq-order" */).then(c => wrapFunctional(c.default || c))
export const CommonMqPareCompare = () => import('../../client/components/common/mq-pare-compare.vue' /* webpackChunkName: "components/common-mq-pare-compare" */).then(c => wrapFunctional(c.default || c))
export const CommonMqPlayControls = () => import('../../client/components/common/mq-play-controls.vue' /* webpackChunkName: "components/common-mq-play-controls" */).then(c => wrapFunctional(c.default || c))
export const CommonMqServiceProposal = () => import('../../client/components/common/mq-service-proposal.vue' /* webpackChunkName: "components/common-mq-service-proposal" */).then(c => wrapFunctional(c.default || c))
export const CommonMqStoreSelected = () => import('../../client/components/common/mq-store-selected.vue' /* webpackChunkName: "components/common-mq-store-selected" */).then(c => wrapFunctional(c.default || c))
export const NewComponentsMqActionProduct = () => import('../../client/components/new-components/mq-action-product.vue' /* webpackChunkName: "components/new-components-mq-action-product" */).then(c => wrapFunctional(c.default || c))
export const NewComponentsMqCardProduct = () => import('../../client/components/new-components/mq-card-product.vue' /* webpackChunkName: "components/new-components-mq-card-product" */).then(c => wrapFunctional(c.default || c))
export const NewComponentsMqProductCardTag = () => import('../../client/components/new-components/mq-product-card-tag.vue' /* webpackChunkName: "components/new-components-mq-product-card-tag" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqAddCart = () => import('../../client/components/elements/mq-add-cart.vue' /* webpackChunkName: "components/elements-mq-add-cart" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqArrowsVueCarousel = () => import('../../client/components/elements/mq-arrows-vue-carousel.vue' /* webpackChunkName: "components/elements-mq-arrows-vue-carousel" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqBannerHall = () => import('../../client/components/elements/mq-banner-hall.vue' /* webpackChunkName: "components/elements-mq-banner-hall" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqBannerMasterHome = () => import('../../client/components/elements/mq-banner-master-home.vue' /* webpackChunkName: "components/elements-mq-banner-master-home" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqBannerTopSearch = () => import('../../client/components/elements/mq-banner-top-search.vue' /* webpackChunkName: "components/elements-mq-banner-top-search" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqBanner = () => import('../../client/components/elements/mq-banner.vue' /* webpackChunkName: "components/elements-mq-banner" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqBrandRoomHall = () => import('../../client/components/elements/mq-brand-room-hall.vue' /* webpackChunkName: "components/elements-mq-brand-room-hall" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqBrandRoom = () => import('../../client/components/elements/mq-brand-room.vue' /* webpackChunkName: "components/elements-mq-brand-room" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqCampaign = () => import('../../client/components/elements/mq-campaign.vue' /* webpackChunkName: "components/elements-mq-campaign" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqCampaignsAlert = () => import('../../client/components/elements/mq-campaigns-alert.vue' /* webpackChunkName: "components/elements-mq-campaigns-alert" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqDeliverySchedule = () => import('../../client/components/elements/mq-delivery-schedule.vue' /* webpackChunkName: "components/elements-mq-delivery-schedule" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqDeliverySelect = () => import('../../client/components/elements/mq-delivery-select.vue' /* webpackChunkName: "components/elements-mq-delivery-select" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqDetailProductDescription = () => import('../../client/components/elements/mq-detail-product-description.vue' /* webpackChunkName: "components/elements-mq-detail-product-description" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqDetailProduct = () => import('../../client/components/elements/mq-detail-product.vue' /* webpackChunkName: "components/elements-mq-detail-product" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqDownloadApp = () => import('../../client/components/elements/mq-download-app.vue' /* webpackChunkName: "components/elements-mq-download-app" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqFilePlayer = () => import('../../client/components/elements/mq-file-player.vue' /* webpackChunkName: "components/elements-mq-file-player" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqFinderHelpCenter = () => import('../../client/components/elements/mq-finder-help-center.vue' /* webpackChunkName: "components/elements-mq-finder-help-center" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqFinderNotFound = () => import('../../client/components/elements/mq-finder-not-found.vue' /* webpackChunkName: "components/elements-mq-finder-not-found" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqFinderProductsResults = () => import('../../client/components/elements/mq-finder-products-results.vue' /* webpackChunkName: "components/elements-mq-finder-products-results" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqFinder = () => import('../../client/components/elements/mq-finder.vue' /* webpackChunkName: "components/elements-mq-finder" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqIconPlay = () => import('../../client/components/elements/mq-icon-play.vue' /* webpackChunkName: "components/elements-mq-icon-play" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqMediaPlayer = () => import('../../client/components/elements/mq-media-player.vue' /* webpackChunkName: "components/elements-mq-media-player" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqMessageShelf = () => import('../../client/components/elements/mq-message-shelf.vue' /* webpackChunkName: "components/elements-mq-message-shelf" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqNewDownloadApp = () => import('../../client/components/elements/mq-new-download-app.vue' /* webpackChunkName: "components/elements-mq-new-download-app" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqOrderListProduct = () => import('../../client/components/elements/mq-order-list-product.vue' /* webpackChunkName: "components/elements-mq-order-list-product" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqPrivacyPolicy = () => import('../../client/components/elements/mq-privacy-policy.vue' /* webpackChunkName: "components/elements-mq-privacy-policy" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqProductCardDetail = () => import('../../client/components/elements/mq-product-card-detail.vue' /* webpackChunkName: "components/elements-mq-product-card-detail" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqProductCardTag = () => import('../../client/components/elements/mq-product-card-tag.vue' /* webpackChunkName: "components/elements-mq-product-card-tag" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqProductCard = () => import('../../client/components/elements/mq-product-card.vue' /* webpackChunkName: "components/elements-mq-product-card" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqProductDeliveryLabel = () => import('../../client/components/elements/mq-product-delivery-label.vue' /* webpackChunkName: "components/elements-mq-product-delivery-label" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqProductFinder = () => import('../../client/components/elements/mq-product-finder.vue' /* webpackChunkName: "components/elements-mq-product-finder" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqProductFirstBuyLabel = () => import('../../client/components/elements/mq-product-first-buy-label.vue' /* webpackChunkName: "components/elements-mq-product-first-buy-label" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqProductHall = () => import('../../client/components/elements/mq-product-hall.vue' /* webpackChunkName: "components/elements-mq-product-hall" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqProductOfferCard = () => import('../../client/components/elements/mq-product-offer-card.vue' /* webpackChunkName: "components/elements-mq-product-offer-card" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqShelf = () => import('../../client/components/elements/mq-shelf.vue' /* webpackChunkName: "components/elements-mq-shelf" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqSortProducts = () => import('../../client/components/elements/mq-sort-products.vue' /* webpackChunkName: "components/elements-mq-sort-products" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqTabsFooter = () => import('../../client/components/elements/mq-tabs-footer.vue' /* webpackChunkName: "components/elements-mq-tabs-footer" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqTabsShelves = () => import('../../client/components/elements/mq-tabs-shelves.vue' /* webpackChunkName: "components/elements-mq-tabs-shelves" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqTypeProduct = () => import('../../client/components/elements/mq-type-product.vue' /* webpackChunkName: "components/elements-mq-type-product" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqVirtualScrollGrid = () => import('../../client/components/elements/mq-virtual-scroll-grid.vue' /* webpackChunkName: "components/elements-mq-virtual-scroll-grid" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqVueCarousel = () => import('../../client/components/elements/mq-vue-carousel.vue' /* webpackChunkName: "components/elements-mq-vue-carousel" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqWidgetProducts = () => import('../../client/components/elements/mq-widget-products.vue' /* webpackChunkName: "components/elements-mq-widget-products" */).then(c => wrapFunctional(c.default || c))
export const PrimeMqLoaderPrime = () => import('../../client/components/prime/mq-loader-prime.vue' /* webpackChunkName: "components/prime-mq-loader-prime" */).then(c => wrapFunctional(c.default || c))
export const PrimeMqTagPrime = () => import('../../client/components/prime/mq-tag-prime.vue' /* webpackChunkName: "components/prime-mq-tag-prime" */).then(c => wrapFunctional(c.default || c))
export const SkeletonSkBanners = () => import('../../client/components/skeleton/sk-banners.vue' /* webpackChunkName: "components/skeleton-sk-banners" */).then(c => wrapFunctional(c.default || c))
export const SkeletonSkBrandroomHeader = () => import('../../client/components/skeleton/sk-brandroom-header.vue' /* webpackChunkName: "components/skeleton-sk-brandroom-header" */).then(c => wrapFunctional(c.default || c))
export const SkeletonSkCampaignsList = () => import('../../client/components/skeleton/sk-campaigns-list.vue' /* webpackChunkName: "components/skeleton-sk-campaigns-list" */).then(c => wrapFunctional(c.default || c))
export const SkeletonSkCard = () => import('../../client/components/skeleton/sk-card.vue' /* webpackChunkName: "components/skeleton-sk-card" */).then(c => wrapFunctional(c.default || c))
export const SkeletonSkDeliveryTimes = () => import('../../client/components/skeleton/sk-delivery-times.vue' /* webpackChunkName: "components/skeleton-sk-delivery-times" */).then(c => wrapFunctional(c.default || c))
export const SkeletonSkFinderProduct = () => import('../../client/components/skeleton/sk-finder-product.vue' /* webpackChunkName: "components/skeleton-sk-finder-product" */).then(c => wrapFunctional(c.default || c))
export const SkeletonSkFinder = () => import('../../client/components/skeleton/sk-finder.vue' /* webpackChunkName: "components/skeleton-sk-finder" */).then(c => wrapFunctional(c.default || c))
export const SkeletonSkHelpCenterListProducts = () => import('../../client/components/skeleton/sk-help-center-list-products.vue' /* webpackChunkName: "components/skeleton-sk-help-center-list-products" */).then(c => wrapFunctional(c.default || c))
export const SkeletonSkHelpCenter = () => import('../../client/components/skeleton/sk-help-center.vue' /* webpackChunkName: "components/skeleton-sk-help-center" */).then(c => wrapFunctional(c.default || c))
export const SkeletonSkItemNavbar = () => import('../../client/components/skeleton/sk-item-navbar.vue' /* webpackChunkName: "components/skeleton-sk-item-navbar" */).then(c => wrapFunctional(c.default || c))
export const SkeletonSkLayout = () => import('../../client/components/skeleton/sk-layout.vue' /* webpackChunkName: "components/skeleton-sk-layout" */).then(c => wrapFunctional(c.default || c))
export const SkeletonSkMyAccount = () => import('../../client/components/skeleton/sk-my-account.vue' /* webpackChunkName: "components/skeleton-sk-my-account" */).then(c => wrapFunctional(c.default || c))
export const SkeletonSkProductDetail = () => import('../../client/components/skeleton/sk-product-detail.vue' /* webpackChunkName: "components/skeleton-sk-product-detail" */).then(c => wrapFunctional(c.default || c))
export const SkeletonSkRadio = () => import('../../client/components/skeleton/sk-radio.vue' /* webpackChunkName: "components/skeleton-sk-radio" */).then(c => wrapFunctional(c.default || c))
export const SkeletonSkShelf = () => import('../../client/components/skeleton/sk-shelf.vue' /* webpackChunkName: "components/skeleton-sk-shelf" */).then(c => wrapFunctional(c.default || c))
export const SkeletonSkShelfs = () => import('../../client/components/skeleton/sk-shelfs.vue' /* webpackChunkName: "components/skeleton-sk-shelfs" */).then(c => wrapFunctional(c.default || c))
export const SkeletonSkTitle = () => import('../../client/components/skeleton/sk-title.vue' /* webpackChunkName: "components/skeleton-sk-title" */).then(c => wrapFunctional(c.default || c))
export const SkeletonSkUiBox = () => import('../../client/components/skeleton/sk-ui-box.vue' /* webpackChunkName: "components/skeleton-sk-ui-box" */).then(c => wrapFunctional(c.default || c))
export const SkeletonSkUiText = () => import('../../client/components/skeleton/sk-ui-text.vue' /* webpackChunkName: "components/skeleton-sk-ui-text" */).then(c => wrapFunctional(c.default || c))
export const TransitionsAccordionTransition = () => import('../../client/components/transitions/accordion-transition.vue' /* webpackChunkName: "components/transitions-accordion-transition" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqAppsLinks = () => import('../../client/components/utils/mq-apps-links.vue' /* webpackChunkName: "components/utils-mq-apps-links" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqBacktotop = () => import('../../client/components/utils/mq-backtotop.vue' /* webpackChunkName: "components/utils-mq-backtotop" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqBreadcrumb = () => import('../../client/components/utils/mq-breadcrumb.vue' /* webpackChunkName: "components/utils-mq-breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqButtonSlideup = () => import('../../client/components/utils/mq-button-slideup.vue' /* webpackChunkName: "components/utils-mq-button-slideup" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqButton = () => import('../../client/components/utils/mq-button.vue' /* webpackChunkName: "components/utils-mq-button" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqCheckboxV2 = () => import('../../client/components/utils/mq-checkbox-v2.vue' /* webpackChunkName: "components/utils-mq-checkbox-v2" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqCheckbox = () => import('../../client/components/utils/mq-checkbox.vue' /* webpackChunkName: "components/utils-mq-checkbox" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqIconProgress = () => import('../../client/components/utils/mq-icon-progress.vue' /* webpackChunkName: "components/utils-mq-icon-progress" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqIcon = () => import('../../client/components/utils/mq-icon.vue' /* webpackChunkName: "components/utils-mq-icon" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqImage = () => import('../../client/components/utils/mq-image.vue' /* webpackChunkName: "components/utils-mq-image" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqImgLazy = () => import('../../client/components/utils/mq-img-lazy.vue' /* webpackChunkName: "components/utils-mq-img-lazy" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqInputFile = () => import('../../client/components/utils/mq-input-file.vue' /* webpackChunkName: "components/utils-mq-input-file" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqInput = () => import('../../client/components/utils/mq-input.vue' /* webpackChunkName: "components/utils-mq-input" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqLabels = () => import('../../client/components/utils/mq-labels.vue' /* webpackChunkName: "components/utils-mq-labels" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqListCountries = () => import('../../client/components/utils/mq-list-countries.vue' /* webpackChunkName: "components/utils-mq-list-countries" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqListLink = () => import('../../client/components/utils/mq-list-link.vue' /* webpackChunkName: "components/utils-mq-list-link" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqLoadCheckout = () => import('../../client/components/utils/mq-load-checkout.vue' /* webpackChunkName: "components/utils-mq-load-checkout" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqLogo = () => import('../../client/components/utils/mq-logo.vue' /* webpackChunkName: "components/utils-mq-logo" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqMap = () => import('../../client/components/utils/mq-map.vue' /* webpackChunkName: "components/utils-mq-map" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqMaskInput = () => import('../../client/components/utils/mq-mask-input.vue' /* webpackChunkName: "components/utils-mq-mask-input" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqMenu = () => import('../../client/components/utils/mq-menu.vue' /* webpackChunkName: "components/utils-mq-menu" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqModalCorner = () => import('../../client/components/utils/mq-modal-corner.vue' /* webpackChunkName: "components/utils-mq-modal-corner" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqModalManagment = () => import('../../client/components/utils/mq-modal-managment.vue' /* webpackChunkName: "components/utils-mq-modal-managment" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqModalTemplate = () => import('../../client/components/utils/mq-modal-template.vue' /* webpackChunkName: "components/utils-mq-modal-template" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqModal = () => import('../../client/components/utils/mq-modal.vue' /* webpackChunkName: "components/utils-mq-modal" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqNewDropdown = () => import('../../client/components/utils/mq-new-dropdown.vue' /* webpackChunkName: "components/utils-mq-new-dropdown" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqPaginator = () => import('../../client/components/utils/mq-paginator.vue' /* webpackChunkName: "components/utils-mq-paginator" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqPreloader = () => import('../../client/components/utils/mq-preloader.vue' /* webpackChunkName: "components/utils-mq-preloader" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqProgress = () => import('../../client/components/utils/mq-progress.vue' /* webpackChunkName: "components/utils-mq-progress" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqRadioWithoutText = () => import('../../client/components/utils/mq-radio-without-text.vue' /* webpackChunkName: "components/utils-mq-radio-without-text" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqRadio = () => import('../../client/components/utils/mq-radio.vue' /* webpackChunkName: "components/utils-mq-radio" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqSelect = () => import('../../client/components/utils/mq-select.vue' /* webpackChunkName: "components/utils-mq-select" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqSimpleCarousel = () => import('../../client/components/utils/mq-simple-carousel.vue' /* webpackChunkName: "components/utils-mq-simple-carousel" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqSocialButtons = () => import('../../client/components/utils/mq-social-buttons.vue' /* webpackChunkName: "components/utils-mq-social-buttons" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqSocialHead = () => import('../../client/components/utils/mq-social-head.vue' /* webpackChunkName: "components/utils-mq-social-head" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqTextarea = () => import('../../client/components/utils/mq-textarea.vue' /* webpackChunkName: "components/utils-mq-textarea" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqToggleButton = () => import('../../client/components/utils/mq-toggle-button.vue' /* webpackChunkName: "components/utils-mq-toggle-button" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqTooltipText = () => import('../../client/components/utils/mq-tooltip-text.vue' /* webpackChunkName: "components/utils-mq-tooltip-text" */).then(c => wrapFunctional(c.default || c))
export const UtilsMqTooltip = () => import('../../client/components/utils/mq-tooltip.vue' /* webpackChunkName: "components/utils-mq-tooltip" */).then(c => wrapFunctional(c.default || c))
export const CampaignBinesMqSlotDiscountCheckout = () => import('../../client/components/campaign/bines/mq-slot-discount-checkout.vue' /* webpackChunkName: "components/campaign-bines-mq-slot-discount-checkout" */).then(c => wrapFunctional(c.default || c))
export const CampaignBinesMqSlotNotificationCampaignCheckout = () => import('../../client/components/campaign/bines/mq-slot-notification-campaign-checkout.vue' /* webpackChunkName: "components/campaign-bines-mq-slot-notification-campaign-checkout" */).then(c => wrapFunctional(c.default || c))
export const CampaignUiMqCampaignCard = () => import('../../client/components/campaign/ui/mq-campaign-card.vue' /* webpackChunkName: "components/campaign-ui-mq-campaign-card" */).then(c => wrapFunctional(c.default || c))
export const CampaignUiMqCampaignsList = () => import('../../client/components/campaign/ui/mq-campaigns-list.vue' /* webpackChunkName: "components/campaign-ui-mq-campaigns-list" */).then(c => wrapFunctional(c.default || c))
export const CampaignUiMqGiftToggle = () => import('../../client/components/campaign/ui/mq-gift-toggle.vue' /* webpackChunkName: "components/campaign-ui-mq-gift-toggle" */).then(c => wrapFunctional(c.default || c))
export const CampaignUiMqProductsListByCampaign = () => import('../../client/components/campaign/ui/mq-products-list-by-campaign.vue' /* webpackChunkName: "components/campaign-ui-mq-products-list-by-campaign" */).then(c => wrapFunctional(c.default || c))
export const CampaignWidgetsMqGiftToggleWidget = () => import('../../client/components/campaign/widgets/mq-gift-toggle-widget.vue' /* webpackChunkName: "components/campaign-widgets-mq-gift-toggle-widget" */).then(c => wrapFunctional(c.default || c))
export const CampaignWidgetsMqLossCamnpaignAlert = () => import('../../client/components/campaign/widgets/mq-loss-camnpaign-alert.vue' /* webpackChunkName: "components/campaign-widgets-mq-loss-camnpaign-alert" */).then(c => wrapFunctional(c.default || c))
export const ErrorDeeplinkNoSection = () => import('../../client/components/error/deeplink/no-section.vue' /* webpackChunkName: "components/error-deeplink-no-section" */).then(c => wrapFunctional(c.default || c))
export const FragmentsHeaderMqHeaderDesktop = () => import('../../client/components/fragments/header/mq-header-desktop.vue' /* webpackChunkName: "components/fragments-header-mq-header-desktop" */).then(c => wrapFunctional(c.default || c))
export const FragmentsHeaderMqHeaderMobile = () => import('../../client/components/fragments/header/mq-header-mobile.vue' /* webpackChunkName: "components/fragments-header-mq-header-mobile" */).then(c => wrapFunctional(c.default || c))
export const FragmentsHomeMqMainStoreCard = () => import('../../client/components/fragments/home/mq-main-store-card.vue' /* webpackChunkName: "components/fragments-home-mq-main-store-card" */).then(c => wrapFunctional(c.default || c))
export const FragmentsHomeMqMainStoreTag = () => import('../../client/components/fragments/home/mq-main-store-tag.vue' /* webpackChunkName: "components/fragments-home-mq-main-store-tag" */).then(c => wrapFunctional(c.default || c))
export const FragmentsHomeMqMainStoresGrid = () => import('../../client/components/fragments/home/mq-main-stores-grid.vue' /* webpackChunkName: "components/fragments-home-mq-main-stores-grid" */).then(c => wrapFunctional(c.default || c))
export const FragmentsHomeMqReferredComponent = () => import('../../client/components/fragments/home/mq-referred-component.vue' /* webpackChunkName: "components/fragments-home-mq-referred-component" */).then(c => wrapFunctional(c.default || c))
export const FragmentsHomeMqSpecialStoreCard = () => import('../../client/components/fragments/home/mq-special-store-card.vue' /* webpackChunkName: "components/fragments-home-mq-special-store-card" */).then(c => wrapFunctional(c.default || c))
export const FragmentsHomeMqSpecialStoreTag = () => import('../../client/components/fragments/home/mq-special-store-tag.vue' /* webpackChunkName: "components/fragments-home-mq-special-store-tag" */).then(c => wrapFunctional(c.default || c))
export const FragmentsHomeMqSpecialStoresGrid = () => import('../../client/components/fragments/home/mq-special-stores-grid.vue' /* webpackChunkName: "components/fragments-home-mq-special-stores-grid" */).then(c => wrapFunctional(c.default || c))
export const DialogsCheckoutMqCreditCardConfirmCvv = () => import('../../client/components/dialogs/checkout/mq-credit-card-confirm-cvv.vue' /* webpackChunkName: "components/dialogs-checkout-mq-credit-card-confirm-cvv" */).then(c => wrapFunctional(c.default || c))
export const DialogsConfirmMqHcConfirmChangePrice = () => import('../../client/components/dialogs/confirm/mq-hc-confirm-change-price.vue' /* webpackChunkName: "components/dialogs-confirm-mq-hc-confirm-change-price" */).then(c => wrapFunctional(c.default || c))
export const CommonConstantsStoreTabs = () => import('../../client/components/common/constants/store-tabs.js' /* webpackChunkName: "components/common-constants-store-tabs" */).then(c => wrapFunctional(c.default || c))
export const CommonElementsFooterMqFooterLegal = () => import('../../client/components/common/elements-footer/mq-footer-legal.vue' /* webpackChunkName: "components/common-elements-footer-mq-footer-legal" */).then(c => wrapFunctional(c.default || c))
export const CommonElementsFooterMqFooterMore = () => import('../../client/components/common/elements-footer/mq-footer-more.vue' /* webpackChunkName: "components/common-elements-footer-mq-footer-more" */).then(c => wrapFunctional(c.default || c))
export const ElementsAlertsMqBasketFullAlert = () => import('../../client/components/elements/alerts/mq-basket-full-alert.vue' /* webpackChunkName: "components/elements-alerts-mq-basket-full-alert" */).then(c => wrapFunctional(c.default || c))
export const ElementsAlertsMqBasketProgressAlert = () => import('../../client/components/elements/alerts/mq-basket-progress-alert.vue' /* webpackChunkName: "components/elements-alerts-mq-basket-progress-alert" */).then(c => wrapFunctional(c.default || c))
export const ElementsAlertsMqFreeDeliveryFinishAlert = () => import('../../client/components/elements/alerts/mq-free-delivery-finish-alert.vue' /* webpackChunkName: "components/elements-alerts-mq-free-delivery-finish-alert" */).then(c => wrapFunctional(c.default || c))
export const ElementsAlertsMqFreeDeliveryProgressAlert = () => import('../../client/components/elements/alerts/mq-free-delivery-progress-alert.vue' /* webpackChunkName: "components/elements-alerts-mq-free-delivery-progress-alert" */).then(c => wrapFunctional(c.default || c))
export const ElementsAlertsMqWidgetProductsAdded = () => import('../../client/components/elements/alerts/mq-widget-products-added.vue' /* webpackChunkName: "components/elements-alerts-mq-widget-products-added" */).then(c => wrapFunctional(c.default || c))
export const ElementsBrandroomMqBrandroomBackground = () => import('../../client/components/elements/brandroom/mq-brandroom-background.vue' /* webpackChunkName: "components/elements-brandroom-mq-brandroom-background" */).then(c => wrapFunctional(c.default || c))
export const ElementsBrandroomMqBrandroomDetail = () => import('../../client/components/elements/brandroom/mq-brandroom-detail.vue' /* webpackChunkName: "components/elements-brandroom-mq-brandroom-detail" */).then(c => wrapFunctional(c.default || c))
export const ElementsBrandroomMqBrandroomHeader = () => import('../../client/components/elements/brandroom/mq-brandroom-header.vue' /* webpackChunkName: "components/elements-brandroom-mq-brandroom-header" */).then(c => wrapFunctional(c.default || c))
export const ElementsBrandroomMqBrandroomNavbar = () => import('../../client/components/elements/brandroom/mq-brandroom-navbar.vue' /* webpackChunkName: "components/elements-brandroom-mq-brandroom-navbar" */).then(c => wrapFunctional(c.default || c))
export const ElementsHallsMqFavoriteProductsHall = () => import('../../client/components/elements/halls/mq-favorite-products-hall.vue' /* webpackChunkName: "components/elements-halls-mq-favorite-products-hall" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqError = () => import('../../client/components/elements/mq-stories/mq-error.vue' /* webpackChunkName: "components/elements-mq-error" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqItem = () => import('../../client/components/elements/mq-stories/mq-item.vue' /* webpackChunkName: "components/elements-mq-item" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqList = () => import('../../client/components/elements/mq-stories/mq-list.vue' /* webpackChunkName: "components/elements-mq-list" */).then(c => wrapFunctional(c.default || c))
export const ElementsMqShare = () => import('../../client/components/elements/mq-stories/mq-share.vue' /* webpackChunkName: "components/elements-mq-share" */).then(c => wrapFunctional(c.default || c))
export const ElementsTooltipsMqTooltipGift = () => import('../../client/components/elements/tooltips/mq-tooltip-gift.vue' /* webpackChunkName: "components/elements-tooltips-mq-tooltip-gift" */).then(c => wrapFunctional(c.default || c))
export const ElementsVueCarousel = () => import('../../client/components/elements/vue-carousel/Carousel.vue' /* webpackChunkName: "components/elements-vue-carousel" */).then(c => wrapFunctional(c.default || c))
export const ElementsVueCarouselNavigation = () => import('../../client/components/elements/vue-carousel/Navigation.vue' /* webpackChunkName: "components/elements-vue-carousel-navigation" */).then(c => wrapFunctional(c.default || c))
export const ElementsVueCarouselPagination = () => import('../../client/components/elements/vue-carousel/Pagination.vue' /* webpackChunkName: "components/elements-vue-carousel-pagination" */).then(c => wrapFunctional(c.default || c))
export const ElementsVueCarouselSlide = () => import('../../client/components/elements/vue-carousel/Slide.vue' /* webpackChunkName: "components/elements-vue-carousel-slide" */).then(c => wrapFunctional(c.default || c))
export const PagesFaqByCountriesMqFaqCo = () => import('../../client/components/pages/faqByCountries/mq-faq-co.vue' /* webpackChunkName: "components/pages-faq-by-countries-mq-faq-co" */).then(c => wrapFunctional(c.default || c))
export const PagesFaqByCountriesMqFaqMx = () => import('../../client/components/pages/faqByCountries/mq-faq-mx.vue' /* webpackChunkName: "components/pages-faq-by-countries-mq-faq-mx" */).then(c => wrapFunctional(c.default || c))
export const PrimeMocksLogs = () => import('../../client/components/prime/__Mocks__/logs.js' /* webpackChunkName: "components/prime-mocks-logs" */).then(c => wrapFunctional(c.default || c))
export const PrimeCheckoutStoreMqPrimeCashbackTag = () => import('../../client/components/prime/checkout-store/mq-prime-cashback-tag.vue' /* webpackChunkName: "components/prime-checkout-store-mq-prime-cashback-tag" */).then(c => wrapFunctional(c.default || c))
export const PrimeCheckoutStoreMqPrimeCheckoutTag = () => import('../../client/components/prime/checkout-store/mq-prime-checkout-tag.vue' /* webpackChunkName: "components/prime-checkout-store-mq-prime-checkout-tag" */).then(c => wrapFunctional(c.default || c))
export const PrimeDialogsMqCancelPrimePlanFailModal = () => import('../../client/components/prime/dialogs/mq-cancel-prime-plan-fail-modal.vue' /* webpackChunkName: "components/prime-dialogs-mq-cancel-prime-plan-fail-modal" */).then(c => wrapFunctional(c.default || c))
export const PrimeDialogsMqCancelPrimePlanModal = () => import('../../client/components/prime/dialogs/mq-cancel-prime-plan-modal.vue' /* webpackChunkName: "components/prime-dialogs-mq-cancel-prime-plan-modal" */).then(c => wrapFunctional(c.default || c))
export const PrimeDialogsMqCancelPrimePlanSuccessModal = () => import('../../client/components/prime/dialogs/mq-cancel-prime-plan-success-modal.vue' /* webpackChunkName: "components/prime-dialogs-mq-cancel-prime-plan-success-modal" */).then(c => wrapFunctional(c.default || c))
export const PrimeHomeMqActivePlan = () => import('../../client/components/prime/home/mq-active-plan.vue' /* webpackChunkName: "components/prime-home-mq-active-plan" */).then(c => wrapFunctional(c.default || c))
export const PrimeHomeMqBenefitsPrime = () => import('../../client/components/prime/home/mq-benefits-prime.vue' /* webpackChunkName: "components/prime-home-mq-benefits-prime" */).then(c => wrapFunctional(c.default || c))
export const PrimeHomeMqContentBuyPrime = () => import('../../client/components/prime/home/mq-content-buy-prime.vue' /* webpackChunkName: "components/prime-home-mq-content-buy-prime" */).then(c => wrapFunctional(c.default || c))
export const PrimeHomeMqHeaderBuyPrime = () => import('../../client/components/prime/home/mq-header-buy-prime.vue' /* webpackChunkName: "components/prime-home-mq-header-buy-prime" */).then(c => wrapFunctional(c.default || c))
export const PrimeHomeMqHeaderPrime = () => import('../../client/components/prime/home/mq-header-prime.vue' /* webpackChunkName: "components/prime-home-mq-header-prime" */).then(c => wrapFunctional(c.default || c))
export const PrimeHomeMqPlanBuyPrime = () => import('../../client/components/prime/home/mq-plan-buy-prime.vue' /* webpackChunkName: "components/prime-home-mq-plan-buy-prime" */).then(c => wrapFunctional(c.default || c))
export const PrimeHomeMqPlanTrialPrime = () => import('../../client/components/prime/home/mq-plan-trial-prime.vue' /* webpackChunkName: "components/prime-home-mq-plan-trial-prime" */).then(c => wrapFunctional(c.default || c))
export const PrimeHomeMqPlansEmptyPrime = () => import('../../client/components/prime/home/mq-plans-empty-prime.vue' /* webpackChunkName: "components/prime-home-mq-plans-empty-prime" */).then(c => wrapFunctional(c.default || c))
export const PrimeHomeMqPlansListPrime = () => import('../../client/components/prime/home/mq-plans-list-prime.vue' /* webpackChunkName: "components/prime-home-mq-plans-list-prime" */).then(c => wrapFunctional(c.default || c))
export const PrimeHomeMqPrimeAccordionInfo = () => import('../../client/components/prime/home/mq-prime-accordion-info.vue' /* webpackChunkName: "components/prime-home-mq-prime-accordion-info" */).then(c => wrapFunctional(c.default || c))
export const PrimeHomeMqPrimeAccordionList = () => import('../../client/components/prime/home/mq-prime-accordion-list.vue' /* webpackChunkName: "components/prime-home-mq-prime-accordion-list" */).then(c => wrapFunctional(c.default || c))
export const PrimeHomeMqPrimePlansHistoryMobile = () => import('../../client/components/prime/home/mq-prime-plans-history-mobile.vue' /* webpackChunkName: "components/prime-home-mq-prime-plans-history-mobile" */).then(c => wrapFunctional(c.default || c))
export const PrimePaymentMqAddCcPrime = () => import('../../client/components/prime/payment/mq-add-cc-prime.vue' /* webpackChunkName: "components/prime-payment-mq-add-cc-prime" */).then(c => wrapFunctional(c.default || c))
export const PrimePaymentMqBackPrime = () => import('../../client/components/prime/payment/mq-back-prime.vue' /* webpackChunkName: "components/prime-payment-mq-back-prime" */).then(c => wrapFunctional(c.default || c))
export const PrimePaymentMqBillingForm = () => import('../../client/components/prime/payment/mq-billing-form.vue' /* webpackChunkName: "components/prime-payment-mq-billing-form" */).then(c => wrapFunctional(c.default || c))
export const PrimePaymentMqContentPaymentPrime = () => import('../../client/components/prime/payment/mq-content-payment-prime.vue' /* webpackChunkName: "components/prime-payment-mq-content-payment-prime" */).then(c => wrapFunctional(c.default || c))
export const PrimePaymentMqDetailPaymentPrime = () => import('../../client/components/prime/payment/mq-detail-payment-prime.vue' /* webpackChunkName: "components/prime-payment-mq-detail-payment-prime" */).then(c => wrapFunctional(c.default || c))
export const PrimePaymentMqHeaderPaymentPrime = () => import('../../client/components/prime/payment/mq-header-payment-prime.vue' /* webpackChunkName: "components/prime-payment-mq-header-payment-prime" */).then(c => wrapFunctional(c.default || c))
export const PrimePaymentMqListCcPrime = () => import('../../client/components/prime/payment/mq-list-cc-prime.vue' /* webpackChunkName: "components/prime-payment-mq-list-cc-prime" */).then(c => wrapFunctional(c.default || c))
export const PrimePaymentMqNewCc = () => import('../../client/components/prime/payment/mq-new-cc.vue' /* webpackChunkName: "components/prime-payment-mq-new-cc" */).then(c => wrapFunctional(c.default || c))
export const PrimePaymentMqPlanPaymentPrime = () => import('../../client/components/prime/payment/mq-plan-payment-prime.vue' /* webpackChunkName: "components/prime-payment-mq-plan-payment-prime" */).then(c => wrapFunctional(c.default || c))
export const PrimeSuccessMqContentSuccessPrime = () => import('../../client/components/prime/success/mq-content-success-prime.vue' /* webpackChunkName: "components/prime-success-mq-content-success-prime" */).then(c => wrapFunctional(c.default || c))
export const TemplatesDepartmentsMqBrandroomTemplate = () => import('../../client/components/templates/departments/mq-brandroom-template.vue' /* webpackChunkName: "components/templates-departments-mq-brandroom-template" */).then(c => wrapFunctional(c.default || c))
export const TemplatesDepartmentsMqDepartmentTemplate = () => import('../../client/components/templates/departments/mq-department-template.vue' /* webpackChunkName: "components/templates-departments-mq-department-template" */).then(c => wrapFunctional(c.default || c))
export const TemplatesShelfsMqShelfTemplate = () => import('../../client/components/templates/shelfs/mq-shelf-template.vue' /* webpackChunkName: "components/templates-shelfs-mq-shelf-template" */).then(c => wrapFunctional(c.default || c))
export const WidgetsAccountMqCreditoItem = () => import('../../client/components/widgets/account/mq-credito-item.vue' /* webpackChunkName: "components/widgets-account-mq-credito-item" */).then(c => wrapFunctional(c.default || c))
export const WidgetsAccountMqCreditosHistoria = () => import('../../client/components/widgets/account/mq-creditos-historia.vue' /* webpackChunkName: "components/widgets-account-mq-creditos-historia" */).then(c => wrapFunctional(c.default || c))
export const WidgetsAccountMqCreditosInfo = () => import('../../client/components/widgets/account/mq-creditos-info.vue' /* webpackChunkName: "components/widgets-account-mq-creditos-info" */).then(c => wrapFunctional(c.default || c))
export const WidgetsAccountMqDirecciones = () => import('../../client/components/widgets/account/mq-direcciones.vue' /* webpackChunkName: "components/widgets-account-mq-direcciones" */).then(c => wrapFunctional(c.default || c))
export const WidgetsAccountMqItemAccionesPedido = () => import('../../client/components/widgets/account/mq-item-acciones-pedido.vue' /* webpackChunkName: "components/widgets-account-mq-item-acciones-pedido" */).then(c => wrapFunctional(c.default || c))
export const WidgetsAccountMqItemDirecciones = () => import('../../client/components/widgets/account/mq-item-direcciones.vue' /* webpackChunkName: "components/widgets-account-mq-item-direcciones" */).then(c => wrapFunctional(c.default || c))
export const WidgetsAccountMqItemEstadoPedido = () => import('../../client/components/widgets/account/mq-item-estado-pedido.vue' /* webpackChunkName: "components/widgets-account-mq-item-estado-pedido" */).then(c => wrapFunctional(c.default || c))
export const WidgetsAccountMqItemHeader = () => import('../../client/components/widgets/account/mq-item-header.vue' /* webpackChunkName: "components/widgets-account-mq-item-header" */).then(c => wrapFunctional(c.default || c))
export const WidgetsAccountMqItemMetodos = () => import('../../client/components/widgets/account/mq-item-metodos.vue' /* webpackChunkName: "components/widgets-account-mq-item-metodos" */).then(c => wrapFunctional(c.default || c))
export const WidgetsAccountMqItemPedido = () => import('../../client/components/widgets/account/mq-item-pedido.vue' /* webpackChunkName: "components/widgets-account-mq-item-pedido" */).then(c => wrapFunctional(c.default || c))
export const WidgetsAccountMqProductCard = () => import('../../client/components/widgets/account/mq-product-card.vue' /* webpackChunkName: "components/widgets-account-mq-product-card" */).then(c => wrapFunctional(c.default || c))
export const WidgetsAccountMqSidebarAccount = () => import('../../client/components/widgets/account/mq-sidebar-account.vue' /* webpackChunkName: "components/widgets-account-mq-sidebar-account" */).then(c => wrapFunctional(c.default || c))
export const WidgetsAddressGeneralMessageModal = () => import('../../client/components/widgets/address/general-message-modal.vue' /* webpackChunkName: "components/widgets-address-general-message-modal" */).then(c => wrapFunctional(c.default || c))
export const WidgetsAddressMqAddressChangecity = () => import('../../client/components/widgets/address/mq-address-changecity.vue' /* webpackChunkName: "components/widgets-address-mq-address-changecity" */).then(c => wrapFunctional(c.default || c))
export const WidgetsAddressMqAddressDirections = () => import('../../client/components/widgets/address/mq-address-directions.vue' /* webpackChunkName: "components/widgets-address-mq-address-directions" */).then(c => wrapFunctional(c.default || c))
export const WidgetsAddressMqAddressGpsAlertModal = () => import('../../client/components/widgets/address/mq-address-gps-alert-modal.vue' /* webpackChunkName: "components/widgets-address-mq-address-gps-alert-modal" */).then(c => wrapFunctional(c.default || c))
export const WidgetsAddressMqAddressGps = () => import('../../client/components/widgets/address/mq-address-gps.vue' /* webpackChunkName: "components/widgets-address-mq-address-gps" */).then(c => wrapFunctional(c.default || c))
export const WidgetsAddressMqAddressInitial = () => import('../../client/components/widgets/address/mq-address-initial.vue' /* webpackChunkName: "components/widgets-address-mq-address-initial" */).then(c => wrapFunctional(c.default || c))
export const WidgetsAddressMqAddressMap = () => import('../../client/components/widgets/address/mq-address-map.vue' /* webpackChunkName: "components/widgets-address-mq-address-map" */).then(c => wrapFunctional(c.default || c))
export const WidgetsAddressMqAddressMydir = () => import('../../client/components/widgets/address/mq-address-mydir.vue' /* webpackChunkName: "components/widgets-address-mq-address-mydir" */).then(c => wrapFunctional(c.default || c))
export const WidgetsAddressMqAddressNocoverage = () => import('../../client/components/widgets/address/mq-address-nocoverage.vue' /* webpackChunkName: "components/widgets-address-mq-address-nocoverage" */).then(c => wrapFunctional(c.default || c))
export const WidgetsAddressMqAddressWidgetModal = () => import('../../client/components/widgets/address/mq-address-widget-modal.vue' /* webpackChunkName: "components/widgets-address-mq-address-widget-modal" */).then(c => wrapFunctional(c.default || c))
export const WidgetsAddressMqAddressWidget = () => import('../../client/components/widgets/address/mq-address-widget.vue' /* webpackChunkName: "components/widgets-address-mq-address-widget" */).then(c => wrapFunctional(c.default || c))
export const WidgetsAddressMqAddress = () => import('../../client/components/widgets/address/mq-address.vue' /* webpackChunkName: "components/widgets-address-mq-address" */).then(c => wrapFunctional(c.default || c))
export const WidgetsCartMqAlertEmpty = () => import('../../client/components/widgets/cart/mq-alert-empty.vue' /* webpackChunkName: "components/widgets-cart-mq-alert-empty" */).then(c => wrapFunctional(c.default || c))
export const WidgetsCartMqCartDeliveryNotification = () => import('../../client/components/widgets/cart/mq-cart-delivery-notification.vue' /* webpackChunkName: "components/widgets-cart-mq-cart-delivery-notification" */).then(c => wrapFunctional(c.default || c))
export const WidgetsCartMqCartDelivery = () => import('../../client/components/widgets/cart/mq-cart-delivery.vue' /* webpackChunkName: "components/widgets-cart-mq-cart-delivery" */).then(c => wrapFunctional(c.default || c))
export const WidgetsCartMqCartEmpty = () => import('../../client/components/widgets/cart/mq-cart-empty.vue' /* webpackChunkName: "components/widgets-cart-mq-cart-empty" */).then(c => wrapFunctional(c.default || c))
export const WidgetsCartMqCartInfo = () => import('../../client/components/widgets/cart/mq-cart-info.vue' /* webpackChunkName: "components/widgets-cart-mq-cart-info" */).then(c => wrapFunctional(c.default || c))
export const WidgetsCartMqCartNotification = () => import('../../client/components/widgets/cart/mq-cart-notification.vue' /* webpackChunkName: "components/widgets-cart-mq-cart-notification" */).then(c => wrapFunctional(c.default || c))
export const WidgetsCartMqCartProductDeleted = () => import('../../client/components/widgets/cart/mq-cart-product-deleted.vue' /* webpackChunkName: "components/widgets-cart-mq-cart-product-deleted" */).then(c => wrapFunctional(c.default || c))
export const WidgetsCartMqCartProduct = () => import('../../client/components/widgets/cart/mq-cart-product.vue' /* webpackChunkName: "components/widgets-cart-mq-cart-product" */).then(c => wrapFunctional(c.default || c))
export const WidgetsCartMqCartProducts = () => import('../../client/components/widgets/cart/mq-cart-products.vue' /* webpackChunkName: "components/widgets-cart-mq-cart-products" */).then(c => wrapFunctional(c.default || c))
export const WidgetsCartMqCartSuggested = () => import('../../client/components/widgets/cart/mq-cart-suggested.vue' /* webpackChunkName: "components/widgets-cart-mq-cart-suggested" */).then(c => wrapFunctional(c.default || c))
export const WidgetsCartMqCartWidget = () => import('../../client/components/widgets/cart/mq-cart-widget.vue' /* webpackChunkName: "components/widgets-cart-mq-cart-widget" */).then(c => wrapFunctional(c.default || c))
export const WidgetsCartMqNewCart = () => import('../../client/components/widgets/cart/mq-new-cart.vue' /* webpackChunkName: "components/widgets-cart-mq-new-cart" */).then(c => wrapFunctional(c.default || c))
export const WidgetsCheckoutMqBtnBack = () => import('../../client/components/widgets/checkout/mq-btn-back.vue' /* webpackChunkName: "components/widgets-checkout-mq-btn-back" */).then(c => wrapFunctional(c.default || c))
export const WidgetsCheckoutMqCampaignPrizeSelection = () => import('../../client/components/widgets/checkout/mq-campaign-prize-selection.vue' /* webpackChunkName: "components/widgets-checkout-mq-campaign-prize-selection" */).then(c => wrapFunctional(c.default || c))
export const WidgetsCheckoutMqCampaignPrizeSuccess = () => import('../../client/components/widgets/checkout/mq-campaign-prize-success.vue' /* webpackChunkName: "components/widgets-checkout-mq-campaign-prize-success" */).then(c => wrapFunctional(c.default || c))
export const WidgetsCheckoutMqCardInfo = () => import('../../client/components/widgets/checkout/mq-card-info.vue' /* webpackChunkName: "components/widgets-checkout-mq-card-info" */).then(c => wrapFunctional(c.default || c))
export const WidgetsCheckoutMqCoupon = () => import('../../client/components/widgets/checkout/mq-coupon.vue' /* webpackChunkName: "components/widgets-checkout-mq-coupon" */).then(c => wrapFunctional(c.default || c))
export const WidgetsCheckoutMqCreditCard = () => import('../../client/components/widgets/checkout/mq-credit-card.vue' /* webpackChunkName: "components/widgets-checkout-mq-credit-card" */).then(c => wrapFunctional(c.default || c))
export const WidgetsCheckoutMqDeliveryConfirm = () => import('../../client/components/widgets/checkout/mq-delivery-confirm.vue' /* webpackChunkName: "components/widgets-checkout-mq-delivery-confirm" */).then(c => wrapFunctional(c.default || c))
export const WidgetsCheckoutMqDeliverySchedule = () => import('../../client/components/widgets/checkout/mq-delivery-schedule.vue' /* webpackChunkName: "components/widgets-checkout-mq-delivery-schedule" */).then(c => wrapFunctional(c.default || c))
export const WidgetsCheckoutMqDeliverySelect = () => import('../../client/components/widgets/checkout/mq-delivery-select.vue' /* webpackChunkName: "components/widgets-checkout-mq-delivery-select" */).then(c => wrapFunctional(c.default || c))
export const WidgetsCheckoutMqModalReceive = () => import('../../client/components/widgets/checkout/mq-modal-receive.vue' /* webpackChunkName: "components/widgets-checkout-mq-modal-receive" */).then(c => wrapFunctional(c.default || c))
export const WidgetsCheckoutMqNewCard = () => import('../../client/components/widgets/checkout/mq-new-card.vue' /* webpackChunkName: "components/widgets-checkout-mq-new-card" */).then(c => wrapFunctional(c.default || c))
export const WidgetsCheckoutMqPaySelect = () => import('../../client/components/widgets/checkout/mq-pay-select.vue' /* webpackChunkName: "components/widgets-checkout-mq-pay-select" */).then(c => wrapFunctional(c.default || c))
export const WidgetsCheckoutMqPseWait = () => import('../../client/components/widgets/checkout/mq-pse-wait.vue' /* webpackChunkName: "components/widgets-checkout-mq-pse-wait" */).then(c => wrapFunctional(c.default || c))
export const WidgetsCheckoutMqPse = () => import('../../client/components/widgets/checkout/mq-pse.vue' /* webpackChunkName: "components/widgets-checkout-mq-pse" */).then(c => wrapFunctional(c.default || c))
export const WidgetsCheckoutMqReceive = () => import('../../client/components/widgets/checkout/mq-receive.vue' /* webpackChunkName: "components/widgets-checkout-mq-receive" */).then(c => wrapFunctional(c.default || c))
export const WidgetsCheckoutMqScheduleRadio = () => import('../../client/components/widgets/checkout/mq-schedule-radio.vue' /* webpackChunkName: "components/widgets-checkout-mq-schedule-radio" */).then(c => wrapFunctional(c.default || c))
export const WidgetsCheckoutMqSummary = () => import('../../client/components/widgets/checkout/mq-summary.vue' /* webpackChunkName: "components/widgets-checkout-mq-summary" */).then(c => wrapFunctional(c.default || c))
export const WidgetsCountriesMqCountrySelector = () => import('../../client/components/widgets/countries/mq-country-selector.vue' /* webpackChunkName: "components/widgets-countries-mq-country-selector" */).then(c => wrapFunctional(c.default || c))
export const WidgetsCountriesMqNewCountriesWidget = () => import('../../client/components/widgets/countries/mq-new-countries-widget.vue' /* webpackChunkName: "components/widgets-countries-mq-new-countries-widget" */).then(c => wrapFunctional(c.default || c))
export const WidgetsExpressMqOnboardingExpressWidget = () => import('../../client/components/widgets/express/mq-onboarding-express-widget.vue' /* webpackChunkName: "components/widgets-express-mq-onboarding-express-widget" */).then(c => wrapFunctional(c.default || c))
export const WidgetsExpressMqOnboardingExpress = () => import('../../client/components/widgets/express/mq-onboarding-express.vue' /* webpackChunkName: "components/widgets-express-mq-onboarding-express" */).then(c => wrapFunctional(c.default || c))
export const WidgetsHelpCenterMqHelpCenterAddProductsList = () => import('../../client/components/widgets/helpCenter/mq-help-center-add-products-list.vue' /* webpackChunkName: "components/widgets-help-center-mq-help-center-add-products-list" */).then(c => wrapFunctional(c.default || c))
export const WidgetsHelpCenterMqHelpCenterAddProductsSearch = () => import('../../client/components/widgets/helpCenter/mq-help-center-add-products-search.vue' /* webpackChunkName: "components/widgets-help-center-mq-help-center-add-products-search" */).then(c => wrapFunctional(c.default || c))
export const WidgetsHelpCenterMqHelpCenterAddProducts = () => import('../../client/components/widgets/helpCenter/mq-help-center-add-products.vue' /* webpackChunkName: "components/widgets-help-center-mq-help-center-add-products" */).then(c => wrapFunctional(c.default || c))
export const WidgetsHelpCenterMqHelpCenterCancelOrderConfirm = () => import('../../client/components/widgets/helpCenter/mq-help-center-cancel-order-confirm.vue' /* webpackChunkName: "components/widgets-help-center-mq-help-center-cancel-order-confirm" */).then(c => wrapFunctional(c.default || c))
export const WidgetsHelpCenterMqHelpCenterCancelOrderRetry = () => import('../../client/components/widgets/helpCenter/mq-help-center-cancel-order-retry.vue' /* webpackChunkName: "components/widgets-help-center-mq-help-center-cancel-order-retry" */).then(c => wrapFunctional(c.default || c))
export const WidgetsHelpCenterMqHelpCenterCancelOrderSelectWant = () => import('../../client/components/widgets/helpCenter/mq-help-center-cancel-order-select-want.vue' /* webpackChunkName: "components/widgets-help-center-mq-help-center-cancel-order-select-want" */).then(c => wrapFunctional(c.default || c))
export const WidgetsHelpCenterMqHelpCenterCancelOrderWhy = () => import('../../client/components/widgets/helpCenter/mq-help-center-cancel-order-why.vue' /* webpackChunkName: "components/widgets-help-center-mq-help-center-cancel-order-why" */).then(c => wrapFunctional(c.default || c))
export const WidgetsHelpCenterMqHelpCenterCancelOrder = () => import('../../client/components/widgets/helpCenter/mq-help-center-cancel-order.vue' /* webpackChunkName: "components/widgets-help-center-mq-help-center-cancel-order" */).then(c => wrapFunctional(c.default || c))
export const WidgetsHelpCenterMqHelpCenterDeliveryTimesForm = () => import('../../client/components/widgets/helpCenter/mq-help-center-delivery-times-form.vue' /* webpackChunkName: "components/widgets-help-center-mq-help-center-delivery-times-form" */).then(c => wrapFunctional(c.default || c))
export const WidgetsHelpCenterMqHelpCenterDeliveryTimesMessages = () => import('../../client/components/widgets/helpCenter/mq-help-center-delivery-times-messages.vue' /* webpackChunkName: "components/widgets-help-center-mq-help-center-delivery-times-messages" */).then(c => wrapFunctional(c.default || c))
export const WidgetsHelpCenterMqHelpCenterDeliveryTimesOptions = () => import('../../client/components/widgets/helpCenter/mq-help-center-delivery-times-options.vue' /* webpackChunkName: "components/widgets-help-center-mq-help-center-delivery-times-options" */).then(c => wrapFunctional(c.default || c))
export const WidgetsHelpCenterMqHelpCenterDeliveryTimes = () => import('../../client/components/widgets/helpCenter/mq-help-center-delivery-times.vue' /* webpackChunkName: "components/widgets-help-center-mq-help-center-delivery-times" */).then(c => wrapFunctional(c.default || c))
export const WidgetsHelpCenterMqHelpCenterForm = () => import('../../client/components/widgets/helpCenter/mq-help-center-form.vue' /* webpackChunkName: "components/widgets-help-center-mq-help-center-form" */).then(c => wrapFunctional(c.default || c))
export const WidgetsHelpCenterMqHelpCenterMenu = () => import('../../client/components/widgets/helpCenter/mq-help-center-menu.vue' /* webpackChunkName: "components/widgets-help-center-mq-help-center-menu" */).then(c => wrapFunctional(c.default || c))
export const WidgetsHelpCenterMqHelpCenterMethodSuccess = () => import('../../client/components/widgets/helpCenter/mq-help-center-method-success.vue' /* webpackChunkName: "components/widgets-help-center-mq-help-center-method-success" */).then(c => wrapFunctional(c.default || c))
export const WidgetsHelpCenterMqHelpCenterModalConfirm = () => import('../../client/components/widgets/helpCenter/mq-help-center-modal-confirm.vue' /* webpackChunkName: "components/widgets-help-center-mq-help-center-modal-confirm" */).then(c => wrapFunctional(c.default || c))
export const WidgetsHelpCenterMqHelpCenterNoLoad = () => import('../../client/components/widgets/helpCenter/mq-help-center-no-load.vue' /* webpackChunkName: "components/widgets-help-center-mq-help-center-no-load" */).then(c => wrapFunctional(c.default || c))
export const WidgetsHelpCenterMqHelpCenterPaymentMethods = () => import('../../client/components/widgets/helpCenter/mq-help-center-payment-methods.vue' /* webpackChunkName: "components/widgets-help-center-mq-help-center-payment-methods" */).then(c => wrapFunctional(c.default || c))
export const WidgetsHelpCenterMqHelpCenterPaymentRevert = () => import('../../client/components/widgets/helpCenter/mq-help-center-payment-revert.vue' /* webpackChunkName: "components/widgets-help-center-mq-help-center-payment-revert" */).then(c => wrapFunctional(c.default || c))
export const WidgetsHelpCenterMqHelpCenterResult = () => import('../../client/components/widgets/helpCenter/mq-help-center-result.vue' /* webpackChunkName: "components/widgets-help-center-mq-help-center-result" */).then(c => wrapFunctional(c.default || c))
export const WidgetsInfobipMqAnimationsLoad = () => import('../../client/components/widgets/infobip/mq-animations-load.vue' /* webpackChunkName: "components/widgets-infobip-mq-animations-load" */).then(c => wrapFunctional(c.default || c))
export const WidgetsInfobipMqErrorsInfobip = () => import('../../client/components/widgets/infobip/mq-errors-infobip.vue' /* webpackChunkName: "components/widgets-infobip-mq-errors-infobip" */).then(c => wrapFunctional(c.default || c))
export const WidgetsInfobipMqFormEntryCode = () => import('../../client/components/widgets/infobip/mq-form-entry-code.vue' /* webpackChunkName: "components/widgets-infobip-mq-form-entry-code" */).then(c => wrapFunctional(c.default || c))
export const WidgetsInfobipMqFormNumber = () => import('../../client/components/widgets/infobip/mq-form-number.vue' /* webpackChunkName: "components/widgets-infobip-mq-form-number" */).then(c => wrapFunctional(c.default || c))
export const WidgetsInfobipMqInfobipWidget = () => import('../../client/components/widgets/infobip/mq-infobip-widget.vue' /* webpackChunkName: "components/widgets-infobip-mq-infobip-widget" */).then(c => wrapFunctional(c.default || c))
export const WidgetsInfobipMqInputBasic = () => import('../../client/components/widgets/infobip/mq-input-basic.vue' /* webpackChunkName: "components/widgets-infobip-mq-input-basic" */).then(c => wrapFunctional(c.default || c))
export const WidgetsInfobipMqInputNumber = () => import('../../client/components/widgets/infobip/mq-input-number.vue' /* webpackChunkName: "components/widgets-infobip-mq-input-number" */).then(c => wrapFunctional(c.default || c))
export const WidgetsInfobipMqNotReceiveCode = () => import('../../client/components/widgets/infobip/mq-not-receive-code.vue' /* webpackChunkName: "components/widgets-infobip-mq-not-receive-code" */).then(c => wrapFunctional(c.default || c))
export const WidgetsInfobipMqSelectCountry = () => import('../../client/components/widgets/infobip/mq-select-country.vue' /* webpackChunkName: "components/widgets-infobip-mq-select-country" */).then(c => wrapFunctional(c.default || c))
export const WidgetsOrdersMqOrderSummary = () => import('../../client/components/widgets/orders/mq-order-summary.vue' /* webpackChunkName: "components/widgets-orders-mq-order-summary" */).then(c => wrapFunctional(c.default || c))
export const WidgetsOrdersMqOrders = () => import('../../client/components/widgets/orders/mq-orders.vue' /* webpackChunkName: "components/widgets-orders-mq-orders" */).then(c => wrapFunctional(c.default || c))
export const WidgetsStoresMqFavorites = () => import('../../client/components/widgets/stores/mq-favorites.vue' /* webpackChunkName: "components/widgets-stores-mq-favorites" */).then(c => wrapFunctional(c.default || c))
export const WidgetsStoresMqHallways = () => import('../../client/components/widgets/stores/mq-hallways.vue' /* webpackChunkName: "components/widgets-stores-mq-hallways" */).then(c => wrapFunctional(c.default || c))
export const WidgetsStoresMqStoreWidgetDesktop = () => import('../../client/components/widgets/stores/mq-store-widget-desktop.vue' /* webpackChunkName: "components/widgets-stores-mq-store-widget-desktop" */).then(c => wrapFunctional(c.default || c))
export const WidgetsStoresMqStoreWidget = () => import('../../client/components/widgets/stores/mq-store-widget.vue' /* webpackChunkName: "components/widgets-stores-mq-store-widget" */).then(c => wrapFunctional(c.default || c))
export const WidgetsUserMqNewUser = () => import('../../client/components/widgets/user/mq-new-user.vue' /* webpackChunkName: "components/widgets-user-mq-new-user" */).then(c => wrapFunctional(c.default || c))
export const WidgetsUserMqUserFacebook = () => import('../../client/components/widgets/user/mq-user-facebook.vue' /* webpackChunkName: "components/widgets-user-mq-user-facebook" */).then(c => wrapFunctional(c.default || c))
export const WidgetsUserMqUserHelp = () => import('../../client/components/widgets/user/mq-user-help.vue' /* webpackChunkName: "components/widgets-user-mq-user-help" */).then(c => wrapFunctional(c.default || c))
export const WidgetsUserMqUserInfo = () => import('../../client/components/widgets/user/mq-user-info.vue' /* webpackChunkName: "components/widgets-user-mq-user-info" */).then(c => wrapFunctional(c.default || c))
export const WidgetsUserMqUserWidget = () => import('../../client/components/widgets/user/mq-user-widget.vue' /* webpackChunkName: "components/widgets-user-mq-user-widget" */).then(c => wrapFunctional(c.default || c))
export const WidgetsUserMqValidateNumber = () => import('../../client/components/widgets/user/mq-validate-number.vue' /* webpackChunkName: "components/widgets-user-mq-validate-number" */).then(c => wrapFunctional(c.default || c))
export const CampaignBinesDialogsMqAlertCampaignCheckout = () => import('../../client/components/campaign/bines/dialogs/mq-alert-campaign-checkout.vue' /* webpackChunkName: "components/campaign-bines-dialogs-mq-alert-campaign-checkout" */).then(c => wrapFunctional(c.default || c))
export const CampaignBinesDialogsMqAlertCampaignHcModifyOrder = () => import('../../client/components/campaign/bines/dialogs/mq-alert-campaign-hc-modify-order.vue' /* webpackChunkName: "components/campaign-bines-dialogs-mq-alert-campaign-hc-modify-order" */).then(c => wrapFunctional(c.default || c))
export const CampaignBinesDialogsMqAlertCampaignHcPaymentMethod = () => import('../../client/components/campaign/bines/dialogs/mq-alert-campaign-hc-payment-method.vue' /* webpackChunkName: "components/campaign-bines-dialogs-mq-alert-campaign-hc-payment-method" */).then(c => wrapFunctional(c.default || c))
export const CampaignBinesDialogsMqAlertCampaignWithoutProducts = () => import('../../client/components/campaign/bines/dialogs/mq-alert-campaign-without-products.vue' /* webpackChunkName: "components/campaign-bines-dialogs-mq-alert-campaign-without-products" */).then(c => wrapFunctional(c.default || c))
export const CampaignBinesDialogsMqBinesInformative = () => import('../../client/components/campaign/bines/dialogs/mq-bines-informative.vue' /* webpackChunkName: "components/campaign-bines-dialogs-mq-bines-informative" */).then(c => wrapFunctional(c.default || c))
export const CampaignBinesDialogsMqDiscountSuccessBinesUpdateOrder = () => import('../../client/components/campaign/bines/dialogs/mq-discount-success-bines-update-order.vue' /* webpackChunkName: "components/campaign-bines-dialogs-mq-discount-success-bines-update-order" */).then(c => wrapFunctional(c.default || c))
export const CampaignBinesDialogsMqDiscountSuccessModal = () => import('../../client/components/campaign/bines/dialogs/mq-discount-success-modal.vue' /* webpackChunkName: "components/campaign-bines-dialogs-mq-discount-success-modal" */).then(c => wrapFunctional(c.default || c))
export const CampaignBinesDialogsMqModalAlertLossCampaignCoupon = () => import('../../client/components/campaign/bines/dialogs/mq-modal-alert-loss-campaign-coupon.vue' /* webpackChunkName: "components/campaign-bines-dialogs-mq-modal-alert-loss-campaign-coupon" */).then(c => wrapFunctional(c.default || c))
export const CampaignBinesDialogsMqModalAlertLossCampaign = () => import('../../client/components/campaign/bines/dialogs/mq-modal-alert-loss-campaign.vue' /* webpackChunkName: "components/campaign-bines-dialogs-mq-modal-alert-loss-campaign" */).then(c => wrapFunctional(c.default || c))
export const CampaignBinesDialogsMqModalCampaignExpired = () => import('../../client/components/campaign/bines/dialogs/mq-modal-campaign-expired.vue' /* webpackChunkName: "components/campaign-bines-dialogs-mq-modal-campaign-expired" */).then(c => wrapFunctional(c.default || c))
export const CampaignBinesDialogsMqModalParticipateCampaign = () => import('../../client/components/campaign/bines/dialogs/mq-modal-participate-campaign.vue' /* webpackChunkName: "components/campaign-bines-dialogs-mq-modal-participate-campaign" */).then(c => wrapFunctional(c.default || c))
export const CampaignBinesDialogsMqModalWinCampaign = () => import('../../client/components/campaign/bines/dialogs/mq-modal-win-campaign.vue' /* webpackChunkName: "components/campaign-bines-dialogs-mq-modal-win-campaign" */).then(c => wrapFunctional(c.default || c))
export const CampaignBinesSlotsMqSlotInformativePaymentMethod = () => import('../../client/components/campaign/bines/slots/mq-slot-informative-payment-method.vue' /* webpackChunkName: "components/campaign-bines-slots-mq-slot-informative-payment-method" */).then(c => wrapFunctional(c.default || c))
export const WidgetsMyAccountChatMqChatAttachments = () => import('../../client/components/widgets/my-account/chat/mq-chat-attachments.vue' /* webpackChunkName: "components/widgets-my-account-chat-mq-chat-attachments" */).then(c => wrapFunctional(c.default || c))
export const WidgetsMyAccountChatMqChatMessageError = () => import('../../client/components/widgets/my-account/chat/mq-chat-message-error.vue' /* webpackChunkName: "components/widgets-my-account-chat-mq-chat-message-error" */).then(c => wrapFunctional(c.default || c))
export const WidgetsMyAccountChatMqChatMessageList = () => import('../../client/components/widgets/my-account/chat/mq-chat-message-list.vue' /* webpackChunkName: "components/widgets-my-account-chat-mq-chat-message-list" */).then(c => wrapFunctional(c.default || c))
export const WidgetsMyAccountChatMqChatMessage = () => import('../../client/components/widgets/my-account/chat/mq-chat-message.vue' /* webpackChunkName: "components/widgets-my-account-chat-mq-chat-message" */).then(c => wrapFunctional(c.default || c))
export const WidgetsMyAccountChatMqChatModule = () => import('../../client/components/widgets/my-account/chat/mq-chat-module.vue' /* webpackChunkName: "components/widgets-my-account-chat-mq-chat-module" */).then(c => wrapFunctional(c.default || c))
export const WidgetsMyAccountChatMqChatTitle = () => import('../../client/components/widgets/my-account/chat/mq-chat-title.vue' /* webpackChunkName: "components/widgets-my-account-chat-mq-chat-title" */).then(c => wrapFunctional(c.default || c))
export const WidgetsMyAccountChatComponentsMqChatSurveySelect = () => import('../../client/components/widgets/my-account/chat/components/mq-chat-survey-select.vue' /* webpackChunkName: "components/widgets-my-account-chat-components-mq-chat-survey-select" */).then(c => wrapFunctional(c.default || c))
export const WidgetsMyAccountChatDialogsMqChatSurveyDialog = () => import('../../client/components/widgets/my-account/chat/dialogs/mq-chat-survey-dialog.vue' /* webpackChunkName: "components/widgets-my-account-chat-dialogs-mq-chat-survey-dialog" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
