import Vue from 'vue';

Vue.prototype.$mqBigLoader = async callback => {
  let result = null;
  // TODO: validar $nuxt en variables global
  // eslint-disable-next-line
  $nuxt.$store.commit('SET_LOADING', true);

  try {
    result = await callback();
  } catch (e) {
    throw e;
  } finally {
    // eslint-disable-next-line
    $nuxt.$store.commit('SET_LOADING', false);
  }

  return result;
};

Vue.prototype.$mqBigLoaderCheckout = async callback => {
  let result = null;
  // eslint-disable-next-line
  $nuxt.$store.commit('SET_LOADING_CHECKOUT', true);

  try {
    result = await callback();
  } catch (e) {
    throw e;
  } finally {
    // eslint-disable-next-line
    $nuxt.$store.commit('SET_LOADING_CHECKOUT', false);
  }

  return result;
};
