import Vue from 'vue';
import { getMaxZindex } from '~/helpers/max-z-index';

export const state = Vue.observable({
  alert: undefined,
});

export let zIndex: number = getMaxZindex();
let closeTimeout: any = null;
let resolveFinish: any = null;

export function pushAlertUseCase(alert: any, delay: number) {
  zIndex = getMaxZindex();
  if (closeTimeout) {
    clearTimeout(closeTimeout);
    closeTimeout = null;
    resolveFinish();
  }
  return new Promise(async (resolve) => {
    resolveFinish = resolve;
    state.alert = alert;
    closeTimeout = setTimeout(async () => {
      await Vue.nextTick();
      await removeAlert(state.alert);
    }, delay);
  });
}

export async function removeAlert(alert: any) {
  if (state.alert && state.alert === alert) {
    Vue.set(alert, 'class', 'close');
    setTimeout(async () => {
      state.alert = undefined;
      await Vue.nextTick();
      closeTimeout = null;
      resolveFinish();
    }, 600);
  }
}
