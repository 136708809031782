import { ChangePaymentMethodEndpoint } from '~/endpoints/checkout/change-payment-method-endpoint';
import { errorTransforms } from '~/transforms/errors/error-transform';
import { changePaymentMethodTransform } from '~/transforms/helpCenter/change-payment-method-transform';

export async function ChangePaymentMethodService(params) {
  const { response, error } = await ChangePaymentMethodEndpoint(params);

  if (response?.data) {
    const dataUserTransform = changePaymentMethodTransform(response);

    return { data: dataUserTransform, error: null };
  }

  return { data: null, error: errorTransforms(error) };
}

export default ChangePaymentMethodService;
