import { StrategyManager } from '@/helpers/deeplinks/strategy-manager';
import { strategyStoreDeepLink } from '@/helpers/deeplinks/strategies/store-strategy';
import { strategyDepartmentDeepLink } from '@/helpers/deeplinks/strategies/department-strategy';
import { strategyShelfDeepLink } from '@/helpers/deeplinks/strategies/shelf-strategy';
import { strategyProductDeepLink } from '@/helpers/deeplinks/strategies/product-strategy';
import { strategyBannerDeepLink } from '@/helpers/deeplinks/strategies/banner-strategy';
import { strategyFavoriteDeepLink } from '@/helpers/deeplinks/strategies/favorites-strategy';
import { strategyMyAccountOrders } from '@/helpers/deeplinks/strategies/my-account-orders-strategy';
import { strategyStoryDeepLink } from '@/helpers/deeplinks/strategies/story-strategy';
import { strategySearchSchemeDeepLink } from '@/helpers/deeplinks/strategies/search-scheme-strategy';
import { strategyBrandRoom } from '@/helpers/deeplinks/strategies/brand-room-strategy';
import { strategyPrimeDeepLink } from '@/helpers/deeplinks/strategies/prime-strategy';
import { DEEP_LINKS_NAMES, DEEP_LINKS_TYPE } from '@/helpers/deeplinks/constants/deeplinks';

const dispatchEventStore = (dispatch, type, path) => {
  dispatch('events/setIsDeeplink', true);
  dispatch('events/incrementCounterEntryDeepLink');
  dispatch('events/setUrlDeepLink', path);
  dispatch('events/setTypeDeepLink', type);
};

const getCityBySlug = async (dispatch, getters, route) => {
  const { city } = route.params;

  if (!city) {
    const cities = await dispatch('home/getCities');
    return cities.length ? cities.find((item) => item.storeCovered?.id === parseInt(route.query?.store_id, 10)) : null;
  }

  return getters['home/getCityBySlug'](city);
};

const isLogin = (store) => {
  const { user } = store.state;
  return 'id' in user.user;
};

export async function validDeepLink({ store, route, redirect }) {
  dispatchEventStore(store.dispatch, route.query.type, route.fullPath);

  let cityBySlug = await getCityBySlug(store.dispatch, store.getters, route);

  const cityDefault = store.getters['home/getCity'];
  if (!cityBySlug && cityDefault) {
    cityBySlug = cityDefault;
  }

  await store.dispatch('home/setStore', cityBySlug);
  await store.dispatch('home/loadStores');

  const { type } = route.query;

  const nameStrategies = [
    {
      name: DEEP_LINKS_NAMES.store,
      value: type === DEEP_LINKS_TYPE.store,
    },
    {
      name: DEEP_LINKS_NAMES.department,
      value: type === DEEP_LINKS_TYPE.department && route.query?.department_id,
    },
    {
      name: DEEP_LINKS_NAMES.searchScheme,
      value: type === DEEP_LINKS_TYPE.searchScheme,
    },
    {
      name: DEEP_LINKS_NAMES.shelf,
      value: type === DEEP_LINKS_TYPE.shelf,
    },
    {
      name: DEEP_LINKS_NAMES.product,
      value: type === DEEP_LINKS_TYPE.product,
    },
    {
      name: DEEP_LINKS_NAMES.banner,
      value: type === DEEP_LINKS_TYPE.department && route.query?.banner_id,
    },
    {
      name: DEEP_LINKS_NAMES.orders,
      value: type === DEEP_LINKS_TYPE.orders && isLogin(store),
    },
    {
      name: DEEP_LINKS_NAMES.favorites,
      value: type === DEEP_LINKS_TYPE.favorites,
    },
    {
      name: DEEP_LINKS_NAMES.story,
      value: type === DEEP_LINKS_TYPE.story,
    },
    {
      name: DEEP_LINKS_NAMES.brandRoom,
      value:
        type === DEEP_LINKS_TYPE.brandRoom || type === DEEP_LINKS_TYPE.department || type === DEEP_LINKS_TYPE.shelf,
    },
    {
      name: DEEP_LINKS_NAMES.merqueoPrime,
      value: type === DEEP_LINKS_TYPE.merqueoPrime,
    },
  ];

  const strategyManager = new StrategyManager();

  strategyManager.addStrategy(strategyStoreDeepLink(store, route, cityBySlug));
  strategyManager.addStrategy(strategyDepartmentDeepLink(store, route, cityBySlug));
  strategyManager.addStrategy(strategyShelfDeepLink(store, route, cityBySlug));
  strategyManager.addStrategy(strategyProductDeepLink(store, route, cityBySlug));
  strategyManager.addStrategy(strategyBannerDeepLink(store, route, cityBySlug));
  strategyManager.addStrategy(strategyMyAccountOrders(store, route, cityBySlug));
  strategyManager.addStrategy(strategyFavoriteDeepLink(store, route, cityBySlug));
  strategyManager.addStrategy(strategyStoryDeepLink(store, route, cityBySlug));
  strategyManager.addStrategy(strategySearchSchemeDeepLink(store, route, cityBySlug));
  strategyManager.addStrategy(strategyBrandRoom(store, route, cityBySlug));
  strategyManager.addStrategy(strategyPrimeDeepLink(store));

  const findStrategy = nameStrategies.find((strategy) => strategy.value);

  const url = await strategyManager.getStrategy(findStrategy.name).doAction();

  redirect(url);
}

/**
 * DeepLink store
 * https://dev.merqueo.com/bogota?type=store&store_id=63&express=false
 *
 * DeepLink department
 * https://dev.merqueo.com/bogota?type=department&store_id=63&department_id=19&express=false
 *
 * DeepLink shelf
 * https://dev.merqueo.com/bogota?type=shelf&store_id=63&department_id=19&shelf_id=15&express=false
 *
 * DeepLink product
 * https://dev.merqueo.com/bogota?type=product&store_id=63&department_id=19&shelf_id=15&product_id=590632&express=false
 *
 * DeepLink banner
 * https://dev.merqueo.com/bogota?type=department&store_id=63&banner_id=28290&express=false
 *
 * DeepLink brandRooms
 * https://dev.merqueo.com/bogota?type=brandroom&store_id=63&brandroom_id=53&express=0
 *
 * DeepLink department o brandRooms
 * https://dev.merqueo.com/bogota/super-ahorro/coca-cola?type=department&store_id=63&department_id=408&express=false
 */

