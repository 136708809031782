var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'container-tooltip',
    _vm.isOpenWithClick && !_vm.open && 'no-hover',
    _vm.open && 'open',
    _vm.disabled && 'no-pointer-event' ],on:{"mouseover":_vm.emitHover,"mouseleave":_vm.emitHoverLeave}},[_c('div',{on:{"click":_vm.openWithClick}},[(_vm.icon)?_c('mq-icon',{attrs:{"icon":_vm.icon,"size":_vm.size,"theme":_vm.iconColor,"color":_vm.colorIcon}}):_vm._e()],1),_vm._v(" "),_c('div',{directives:[{name:"testing-name",rawName:"v-testing-name",value:('tooltip-div'),expression:"'tooltip-div'"}],class:[
      ("tooltip display-" + (_vm.alignDataAdjust ? _vm.alignDataAdjust : _vm.alignData) + " bg-" + _vm.theme + " tx-" + _vm.theme),
      _vm.textWeight && 'f-bold',
      !_vm.isCalculateAlignEnd && 'hidden-while-caculate' ],style:(_vm.additionalStyle)},[_c('p',{class:['label-info', 'paragraph', ("tx-" + _vm.textColor)]},[_vm._v("\n      "+_vm._s(_vm.text)+"\n    ")]),_vm._v(" "),(_vm.isOpenWithClick)?_c('div',{staticClass:"close",on:{"click":_vm.closeWithClick}},[_c('mq-icon',{attrs:{"icon":'close',"size":'15',"theme":'',"color":'#FFFFFF'}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }