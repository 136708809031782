//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex';
import { openModal } from '../utils/mq-modal-managment.vue';
import MqAddCart from '~/components/elements/mq-add-cart';
import isWebpSupported from '~/helpers/is-webp-supported';

export default {
  name: 'MqBanner',
  components: { MqAddCart },
  props: {
    banner: {
      type: Object,
      default: () => {},
    },
    id: {
      type: Number,
      default: () => 0,
    },
    position: {
      type: Number,
      default: () => 0,
    },
    brandRoomId: {
      type: Number,
      default: 0,
    },
    departmentId: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
    ...mapGetters({
      city: 'home/getCity',
      store: 'home/getCurrentStore',
    }),
    setBrandRoomId() {
      return this.brandRoomId !== 0 ? this.brandRoomId : 0;
    },
    setDepartmentId() {
      return this.departmentId !== 0 ? this.departmentId : 0;
    },
  },
  data: () => {
    return {
      isWebpSupported
    }
  },
  methods: {
    isDeepLink() {
      return this.banner.category === 'Deeplink';
    },
    isGroupProducts() {
      return this.banner.category === 'Conjunto de productos';
    },
    isInfo() {
      return this.banner.category === 'Informativo';
    },
    isProduct() {
      return this.banner.category === 'Producto';
    },
    isVideo() {
      return this.banner.category === 'Video';
    },
    isFullScreen() {
      return this.banner.templateVideo?.layout === 'fullscreen';
    },
    async getModalVertical() {
      const response = await openModal({
        component: () => import('~/components/dialogs/mq-modal-video-banner'),
        props: {
          banner: this.banner,
        },
        group: 'group-modal-helper',
        config: {
          position: {
            value: 'center',
          },
          behaviors: {
            closableContainer: false,
          },
        },
      });
    },
    goUrlTo() {
      if (this.isFullScreen()) {
        this.getModalVertical();
      } else if (this.isDeepLink()) {
        let urlBanner = this.banner.url;
        urlBanner = urlBanner.replace('/domicilios-super-ahorro', '');
        urlBanner = urlBanner.replace(window.location.origin, window.location.origin + process.env.path);
        if (RegExp('http').test(urlBanner)) {
          window.location = urlBanner;
        } else {
          this.$router.push(urlBanner);
        }
      } else if (this.isProduct() && 'productSlugs' in this.banner) {
        this.$router.push(
          `/${this.city.slug}/${this.store.slug}/${this.banner.productSlugs.department}/${this.banner.productSlugs.shelf}/${this.banner.productSlugs.product}/?screen=banner`,
        );
      } else {
        this.$router.push(`/${this.city.slug}/${this.store.slug}/banners/${this.id}`);
      }
    },
    triggerEvents() {
      this.$events.bannerClicked.track({
        screen: this.getScreen(),
        title: this.banner.title,
        type: 'content',
        departmentId: this.setDepartmentId,
        bannerId: this.banner.id,
        position: this.$props.position,
        brandRoomId: this.setBrandRoomId,
      });
      if (!this.isFullScreen()) {
        this.$store.commit('SET_LOADING', true);
        if (this.isProduct() && 'storeProduct' in this.banner) {
          const name = `${this.banner.storeProduct.name} ${this.banner.storeProduct.quantity} ${this.banner.storeProduct.unit}`;

          const price = this.banner.storeProduct.specialPrice
            ? this.banner.storeProduct.specialPrice
            : this.banner.storeProduct.price;

          this.$events.productViewed.track({
            departmentId: this.banner.storeProduct.department.id,
            shelfId: this.banner.storeProduct.shelf.id,
            name,
            price,
            bestPriceGuaranteed: this.banner.storeProduct.isBestPrice ? 1 : 0,
            bannerId: this.banner.id,
            position: this.$props.position,
            storeProductId: this.banner.storeProduct.storeId,
            sponsored: this.banner.storeProduct.sponsored ? 1 : 0,
            screen: 'banner',
            textSearched: 'N/A',
          });
        }
      }
    },
    getScreen() {
      const { name } = this.$route;
      let screen = '';
      screen = name === 'city-store' ? 'store' : screen;
      screen = name === 'city-store-department' ? 'department' : screen;
      screen = name === 'city-store-banners' ? 'bannerList' : screen;
      return screen;
    },
  },
};
