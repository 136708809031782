import { CUSTOM_SCHEME_SYMBOL } from '../constants/customSchemeSymbol';
import { ISchemeProperty } from '../interfaces/schemePropertyInterface';

export function schemeDescriptor(constructor: any): Map<String, ISchemeProperty> {
  const scheme = Reflect.getOwnMetadata(CUSTOM_SCHEME_SYMBOL, constructor);
  if (scheme instanceof Map) {
    return scheme;
  }
  Reflect.defineMetadata(CUSTOM_SCHEME_SYMBOL, new Map(), constructor);
  return Reflect.getOwnMetadata(CUSTOM_SCHEME_SYMBOL, constructor);
}
