export async function redirectCountry({ store, route, redirect }) {
  const { dispatch, getters, state } = store;
  const citySlug = route && route.params && route.params.city ? route.params.city : null;
  const cityBySlug = getters['home/getCityBySlug'](citySlug);
  const currentCity = getters['home/getCity'];

  // change country from home
  if ('redirect' in route.query) {
    const redirectCity = getters['home/getCityBySlug'](route.query.redirect);
    await dispatch('home/setStore', redirectCity);
    resetStoreAddress(store);
    redirect('/');
  }
  // change country from main modal when user entry web for first time
  else if ('redirect-main' in route.query || !(currentCity && currentCity.city)) {
    await dispatch('config/getConfig');
    const currentCountry = await dispatch('home/getCurrentMainCityOfCountry');
    const mainCity = currentCountry.mainCity;
    await dispatch('home/setStore', mainCity);
    resetStoreAddress(store);
    redirect('/');
  }
  //
  else if (cityBySlug instanceof Object && currentCity.slug !== cityBySlug.slug) {
    await dispatch('home/setStore', cityBySlug);
    resetStoreAddress(store);
  }
  // user entry to web for first time
  else if (currentCity && currentCity.slug !== state.home.store.slug) {
    await dispatch('home/setStore', currentCity);
    resetStoreAddress(store);
  }
}
function resetStoreAddress(store) {
  const { commit } = store;
  commit('address/SET_ADDRESS', null);
  commit('address/SET_ADDRESS_OBJECT', {});
  commit('checkout/resetState', null);
}

