import 'reflect-metadata';

if (new RegExp('index.html').test(location.href)) {
  location.href = '/';
}

if (typeof window !== 'undefined') {
  require('intersection-observer');
}

import Vue from 'vue';
import Vuex from 'vuex';
import FBSignInButton from 'vue-facebook-signin-button';
import VueVirtualScroller from 'vue-virtual-scroller';

import MqFinder from '~/components/elements/mq-finder';
import MqDownloadApp from '~/components/elements/mq-download-app';
import MqProductCard from '~/components/elements/mq-product-card';
import MqNewProductCard from '~/components/new-components/mq-card-product';
import MqProductOfferCard from '~/components/elements/mq-product-offer-card';
import MqProductHall from '~/components/elements/mq-product-hall';
import MqSortProducts from '~/components/elements/mq-sort-products';
import MqBannerHall from '~/components/elements/mq-banner-hall';
import MqBanner from '~/components/elements/mq-banner';
import MqTabsFooter from '~/components/elements/mq-tabs-footer';
import MqTabsShelves from '~/components/elements/mq-tabs-shelves';
import MqTypeProduct from '~/components/elements/mq-type-product';
import MqButton from '~/components/utils/mq-button';
import MqMenu from '~/components/utils/mq-menu';
import MqLogo from '~/components/utils/mq-logo';
import MqInput from '~/components/utils/mq-input';
import MqImgLazy from '~/components/utils/mq-img-lazy';
import MqSocialButtons from '~/components/utils/mq-social-buttons';
import MqProgress from '~/components/utils/mq-progress';
import MqListLink from '~/components/utils/mq-list-link';
import MqAppsLinks from '~/components/utils/mq-apps-links';
import MqSelect from '~/components/utils/mq-select';
import MqBreadcrumb from '~/components/utils/mq-breadcrumb';
import MqLabels from '~/components/utils/mq-labels';
import MqModal from '~/components/utils/mq-modal';
import MqModalTemplate from '~/components/utils/mq-modal-template';
import MqCheckbox from '~/components/utils/mq-checkbox';
import MqIcon from '~/components/utils/mq-icon';
import AccordionTransition from '~/components/transitions/accordion-transition';
import MqBackToTop from '~/components/utils/mq-backtotop';
import MqListCountries from '~/components/utils/mq-list-countries';
import MqInfobipWidget from '~/components/widgets/infobip/mq-infobip-widget';
import MqFragment from './functional/mq-fragment';
import MqTooltip from '~/components/utils/mq-tooltip';
import MqOnBoardingExpressWidget from '~/components/widgets/express/mq-onboarding-express-widget';
import Carousel from '~/components/elements/vue-carousel/Carousel';
import Slide from '~/components/elements/vue-carousel/Slide';

Vue.use(Vuex);
Vue.use(FBSignInButton);
Vue.use(VueVirtualScroller);

Vue.prototype.FBappId = process.env.FBappID;
Vue.prototype.pathUrl = process.env.path;
Vue.prototype.TagmanagerID = process.env.TagmanagerID;
window.fbPixelProductsIds = [];
window.fbPixelProducts = [];
window.fbTotalOrder = '';
window.fbInitiateCheckoutFbPixel = false;
window.fbProductFbPixel = {
  id: 0,
  price: 0,
  name: '',
  currency: '',
};
Vue.prototype.BackLink = () => {
  Vue.prototype.$nuxt.$store.commit('SET_LOADING', true);
  Vue.prototype.$nuxt.$router.go(-1);
};

export default () => {
  Vue.component(MqButton.name, MqButton);
  Vue.component(MqMenu.name, MqMenu);
  Vue.component(MqLogo.name, MqLogo);
  Vue.component(MqDownloadApp.name, MqDownloadApp);
  Vue.component(MqInput.name, MqInput);
  Vue.component(MqProgress.name, MqProgress);
  Vue.component(MqProductCard.name, MqProductCard);
  Vue.component(MqNewProductCard.name, MqNewProductCard);
  Vue.component(MqProductOfferCard.name, MqProductOfferCard);
  Vue.component(MqFinder.name, MqFinder);
  Vue.component(MqProductHall.name, MqProductHall);
  Vue.component(MqSortProducts.name, MqSortProducts);
  Vue.component(MqBannerHall.name, MqBannerHall);
  Vue.component(MqImgLazy.name, MqImgLazy);
  Vue.component(MqSocialButtons.name, MqSocialButtons);
  Vue.component(MqListLink.name, MqListLink);
  Vue.component(MqAppsLinks.name, MqAppsLinks);
  Vue.component(MqSelect.name, MqSelect);
  Vue.component(MqBanner.name, MqBanner);
  Vue.component(MqBreadcrumb.name, MqBreadcrumb);
  Vue.component(MqTabsFooter.name, MqTabsFooter);
  Vue.component(MqTabsShelves.name, MqTabsShelves);
  Vue.component(MqLabels.name, MqLabels);
  Vue.component(MqModal.name, MqModal);
  Vue.component(MqModalTemplate.name, MqModalTemplate);
  Vue.component(MqTypeProduct.name, MqTypeProduct);
  Vue.component(MqCheckbox.name, MqCheckbox);
  Vue.component(AccordionTransition.name, AccordionTransition);
  Vue.component(MqFragment.name, MqFragment);
  Vue.component(MqIcon.name, MqIcon);
  Vue.component(MqBackToTop.name, MqBackToTop);
  Vue.component(MqListCountries.name, MqListCountries);
  Vue.component(MqInfobipWidget.name, MqInfobipWidget);
  Vue.component(MqTooltip.name, MqTooltip);
  Vue.component(MqOnBoardingExpressWidget.name, MqOnBoardingExpressWidget);
  Vue.component(Carousel.name, Carousel);
  Vue.component(Slide.name, Slide);
};
