import { ValidateCoupons } from '~/endpoints/checkout/validate-coupons-endpoint';
import { ValidateCouponsParams } from '~/schemes/validate-coupons-scheme';

export async function validateCouponsUsecase(params: ValidateCouponsParams) {
  const { response, error } = await ValidateCoupons.getValidateCouponsEndpoint(new ValidateCouponsParams(params));
  if (error === null && response?.status === 200) {
    return { data: { status: true }, error: null };
  } else if (error === null && response?.status === 280) {
    return { data: null, error: response.data.errors[0] };
  } else if (error?.response?.data?.errors instanceof Array) {
    return { data: null, error: error.response.data.errors[0] };
  } else {
    return {};
  }
}
