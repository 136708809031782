import { getProductByIdEndpoint } from '~/endpoints/products/get-product-by-id';
import { error280Transforms, errorTransforms } from '~/transforms/errors/error-transform';
import { productTransforms } from '~/transforms/products/products-transform';

export async function getProductByIdService(storeId: number, productId: number) {
  const { response, error } = await getProductByIdEndpoint(storeId, productId);
  if (response?.status === 200 && response?.data) {
    const data = productTransforms(response.data);
    return { data, error: null };
  } else if (response?.status === 280 && error === null) {
    return { data: null, error: error280Transforms(response) };
  }
  return { data: null, error: errorTransforms(error) };
}