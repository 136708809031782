import { SplitStoreFlag } from '~/endpoints/split-store-flag';
import { AvailableSeparateStoreParams } from '~/schemes/available-separate-store-scheme';

export async function splitFlogFlagService(availableSeparateStoreParams: AvailableSeparateStoreParams) {
  const { response, error } = await SplitStoreFlag.splitStoreFlagEndpoint(
    new AvailableSeparateStoreParams(availableSeparateStoreParams),
  );
  if (!error && response) {
    return true;
  }
  return false;
}
