export default function ({ store, route }) {
  if (
    (store.state.checkout.isCheckoutSuccess &&
      route.fullPath !== '/checkout/success' &&
      route.fullPath !== '/checkout/campaign') ||
    (route.fullPath === '/checkout/campaign' && store.state.checkout.isGiftCampignAdded)
  ) {
    store.commit('cart/resetState');
    store.commit('checkout/resetState');
    store.commit('checkout/SET_CHECKOUT_SUCCESS', false);

    // se actualizan los datos del usuario {Número de ordenes}
    store.dispatch('user/me');
    store.dispatch('address/getMyAddress', {});
  }
}
