import { redirectLegacyBanners } from '~/usecases/redirect/redirect-legacy-banners';
import { redirectLegacyHalls } from '~/usecases/redirect/redirect-legacy-halls';

export default async function ({ store, route, redirect }) {
  const params = route.params;
  // Retro compatibility with old route of banners
  if (route.params.city && route.params?.store === 'banners') {
    redirectLegacyBanners({ route, redirect, params });
  }
  // Retro compatibility with old route of halls
  if (params.city && params.store) {
    await redirectLegacyHalls({ store, route, redirect, params });
  }
}
