import { nuxtInstance } from '~/plugins/nuxt-instance';

// default values
document.documentElement.style.setProperty('--widgettop', `150px`);

export default class UIUtils {
  static stackOverflow = false;
  static recalculate = null;
  static recalculateCssProperties() {
    clearTimeout(UIUtils.recalculate);
    UIUtils.recalculate = setTimeout(() => {
      const route = nuxtInstance?.app?.store?.$router?.app?.$route ?? null;
      // trick to correct sizing of vh
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vw = window.innerWidth * 0.01;
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vw', `${vw}px`);
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      document.documentElement.style.setProperty('--viewportWidth', `${vw * 100}px`);
      document.documentElement.style.setProperty('--viewportHeight', `${vh * 100}px`);

      if (document.querySelector('.app-layer__nav-up')) {
        // HeaderHeight on root css
        const headerHeight = document.querySelector('.app-layer__nav-up').clientHeight;
        document.documentElement.style.setProperty('--headerheight', `${headerHeight}px`);
        document.documentElement.style.setProperty('--widgettop', `${headerHeight - 2}px`);
        document.documentElement.style.setProperty('--widgetHeight', `${Math.floor(vh * 100 - (headerHeight + 2))}px`);
      }

      if (document.querySelector('.mq-departments__header')) {
        const navHallsHeight = document.querySelector('.mq-departments__header').clientHeight;
        document.documentElement.style.setProperty('--navHallsHeight', `${navHallsHeight}px`);
      } else {
        document.documentElement.style.setProperty('--navHallsHeight', `0px`);
      }
      if (route && route.name === 'city-store-department') {
        const productHallHeight = document.querySelector('.mq-product-hall')
          ? document.querySelector('.mq-product-hall').clientHeight
          : null;
        document.documentElement.style.setProperty('--productHallHeight', `${productHallHeight}px`);
      } else {
        document.documentElement.style.setProperty('--productHallHeight', `0px`);
      }

      if (document.querySelector('.mq-tabs-shelve')) {
        const shelvesHeight = document.querySelector('.mq-tabs-shelve').clientHeight;
        document.documentElement.style.setProperty('--shelvesHeight', `${shelvesHeight}px`);
      } else {
        document.documentElement.style.setProperty('--shelvesHeight', `0px`);
      }

      if (document.querySelector('.mq-lower-tabs')) {
        const heightLowerTabs = document.querySelector('.mq-lower-tabs').clientHeight;
        document.documentElement.style.setProperty('--heightLowerTabs', `${heightLowerTabs}px`);
      } else {
        document.documentElement.style.setProperty('--heightLowerTabs', `0px`);
      }

      if (document.querySelector('.content-layer .mq-header__search')) {
        const headerSearchMobile = document.querySelector('.content-layer .mq-header__search').getBoundingClientRect();
        const canvasTwoTopPosition = headerSearchMobile.top + headerSearchMobile.height;
        document.documentElement.style.setProperty('--canvasTwoTopPosition', `${canvasTwoTopPosition}px`);
      } else {
        const headerHeight = parseInt(
          document.documentElement.style.getPropertyValue('--headerheight').replace('px', ''),
        );
        const navHallsHeight = parseInt(
          document.documentElement.style.getPropertyValue('--navHallsHeight').replace('px', ''),
        );
        document.documentElement.style.setProperty('--canvasTwoTopPosition', `${headerHeight + navHallsHeight}px`);
      }

      if (document.querySelector('.mq-navbar')) {
        const navbarTop = document.querySelector('.mq-navbar').offsetTop;
        const widgetTopPosition = document.querySelector('.mq-navbar').clientHeight + navbarTop;
        document.documentElement.style.setProperty('--widgettop', `${widgetTopPosition}px`);

        document.documentElement.style.setProperty(
          '--widgetHeight',
          `${Math.floor(vh * 100 - (widgetTopPosition + 2))}px`,
        );
      }
      if (
        document.querySelector('.mq-departments-title') &&
        window.innerWidth >= 768 &&
        window.innerWidth <= 1280 &&
        !document.querySelector('.service-proposal')
      ) {
        const mqDeparmentsHeight = document.querySelector('.mq-departments-title').offsetHeight;
        document.documentElement.style.setProperty('--marTopToMqDeparment', `${mqDeparmentsHeight}px`);
      } else {
        document.documentElement.style.setProperty('--marTopToMqDeparment', `0px`);
      }
    }, 50); //time debounce to get correct changes
  }
  static increaseStackOverflow() {
    UIUtils.stackOverflow = true;
  }
  static decreaseStackOverflow() {
    UIUtils.stackOverflow = false;
  }
}
