//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { CART_TYPES } from '~/constants/cart-actions';
import MqAddCart from '~/components/elements/mq-add-cart';
import MqProductCardTag from '~/components/elements/mq-product-card-tag';
import MqProductDeliveryLabel from '~/components/elements/mq-product-delivery-label';
import getUrlProductMixin from '~/mixins/getUrlProduct';
import MqImgLazy from '~/components/utils/mq-img-lazy';
import MqTypeProduct from '~/components/elements/mq-type-product';
import MqIconPlay from '~/components/elements/mq-icon-play';
import MqProductFirstBuyLabel from '~/components/elements/mq-product-first-buy-label';

import { CLOSE_WIDGET_AND_MODAL } from '~/constants/modals-events';
import { PRODUCT_PARAMS } from '~/constants/products';

export default {
  name: 'MqProductCard',
  mixins: [getUrlProductMixin],
  components: {
    MqAddCart,
    MqProductCardTag,
    MqProductDeliveryLabel,
    MqImgLazy,
    MqTypeProduct,
    MqIconPlay,
    MqProductFirstBuyLabel,
  },
  props: {
    id: { type: Number, required: true },
    product: {
      type: Object,
      default() {
        return {
          sponsored: '',
        };
      },
    },
    theme: { type: String, default: 'mq-default shadow-0 hoverable' },
    hall: { type: String, default: undefined },
    limitPum: { type: Number, default: 23 },
    screen: { type: String, default: 'productDetail' },
    lazyImage: { type: Boolean, default: true },
    cartInstanceType: { type: String, default: CART_TYPES.DEFAULT },
    objectSlug: {
      type: Object,
      default() {
        return {
          city: '',
          store: '',
          department: '',
          shelf: '',
        };
      },
    },
    usingRouteData: {
      type: Boolean,
      default: false,
    },
    isAbleToLinkDetail: {
      type: Boolean,
      default: true,
    },
    position: {
      type: Number,
      default: 0,
    },
    isFromWidget: {
      type: Boolean,
      default: false,
    },
    brandRoom: {
      type: Number,
      default: 0,
    },
    addWrapTagBlock: {
      type: Boolean,
      default: true,
    },
    isSuggested: {
      type: Boolean,
      default: false,
    },
    attribution: {
      type: String,
      default: '',
    },
  },
  data() {
    const { product } = this;
    return {
      imageSelect: product.imageLargeUrl,
      widthScreen: window.innerWidth,
      currentRoute: null,
      indexCarousel: 0,
      urlProduct: this.getUrlProduct(true),
      textSearched: this.getUrlParam(),
      urlProductIconVideo: this.getUrlProduct(),
      tooltipInformation: {
        event: 'mouseenter',
        options: {
          text: this.$t('text_public_price'),
          style: {
            width: '208px',
            height: 'auto',
          },
          configTooltip: {
            placement: 'bottom-end',
            offset: [5, 10],
            hideOnClick: false,
            theme: 'material',
            animation: 'shift-away',
            trigger: 'mouseenter',
          },
        },
      },
      manyHover: 0,
    };
  },

  computed: {
    ...mapGetters({
      store: 'home/getCurrentStore',
      city: 'home/getCity',
      storyId: 'stories/getStoryIdInPlayer',
      willBeFirstBuy: 'user/willBeFirstBuy',
    }),
    getTheme() {
      const isSponsored = this.product.sponsored ? ' sponsored' : '';
      return this.theme + isSponsored;
    },
    isHall() {
      if (typeof this.hall !== 'undefined') {
        return true;
      }
      return this.hall;
    },
    slugDeparment() {
      return this.product.department ? this.product.department.slug : this.product.slugs.department;
    },
    slugShelf() {
      return this.product.shelf ? this.product.shelf.slug : this.product.slugs.shelf;
    },
    showPublicPrice() {
      const { publicPrice, price, isBestPrice, sponsored } = this.product;
      return !this.showSpecialPrice && !isBestPrice && !sponsored && !this.showFirstBuyPrice && publicPrice > price;
    },
    showSpecialPrice() {
      return this.product?.specialPrice > 0 && !this.product?.firstOrderSpecialPrice;
    },
    showFirstBuyPrice() {
      return this.product?.specialPrice > 0 && this.product?.firstOrderSpecialPrice && this.willBeFirstBuy;
    },
    isSomeSpecialPriceAvailable() {
      return this.showSpecialPrice || this.showFirstBuyPrice;
    },
    imageUrl() {
      if (this.screen === 'productDetail') {
        return this.product.imageLargeUrl;
      }
      return this.product.imageAppUrl;
    },
    linkClasses() {
      const classArray = [];

      if (this.isProductNotAvailable) {
        classArray.push('mq-not-available');
      }
      if (this.cartInstanceType === CART_TYPES.DEFAULT) {
        classArray.push('c-pointer');
      }
      return classArray.join(' ');
    },
    storyIdComputed() {
      let storyId;

      if (this.isFromWidget) {
        storyId = this.storyId;
      } else {
        storyId = this.$route.query?.story ?? 0;
      }
      return storyId;
    },
    isProductNotAvailable() {
      return this.product.status === false;
    },
  },
  methods: {
    getUrlParam() {
      let param = 'N/A';
      param = this.$route.params.q ? this.$route.params.q : param;
      return param;
    },
    changeImg(e) {
      this.imageSelect = e.srcElement.dataset.srcLarge ? e.srcElement.dataset.srcLarge : e.srcElement.src;
    },
    triggerEventProductViewed() {
      if (
        (window.location.pathname !== this.currentRoute &&
          typeof this.product.status === 'boolean' &&
          this.product.status) ||
        (window.location.pathname !== this.currentRoute && typeof this.product.status === 'undefined')
      ) {
        this.$store.commit('SET_LOADING', true);
      }
    },
    clickOnNuxtLink() {
      if (this.isFromWidget) this.$nuxt.$emit(CLOSE_WIDGET_AND_MODAL);
    },
    getUrlProduct(isLinkForImage) {
      const url = this.getUrlProductMixin(this.product, this.objectSlug, this.usingRouteData);
      const param = this.getUrlParam();
      const paramUrl = param !== 'N/A' ? `${url}?search=${param}` : url;
      if (isLinkForImage) {
        // return { path: url, query: { viewImage: PRODUCT_PARAMS.VIEW_IMAGE, search: param } };
        return { path: url };
      }
      return paramUrl;
    },
    trackEventAveragePriceOtherStores(event) {
      const send = event[0];
      const manyHover = event[1];
      if (!send) {
        this.manyHover = manyHover;
      }
      if (send && manyHover === 1) {
        this.$events.averagePriceOtherStores.track({
          storeProductId: this.product.storeId,
        });
      }
    },
  },
};
