import { validateScheme } from '~/decorators/validateScheme';
import { DeliveryTimesParams } from '~/schemes/delivery-times-scheme';
import $axios from '~/services/global-axios';

export class DeliveryTimes {
  @validateScheme
  static async getDeliveryTimesEndpointV32({
    storeId,
    zoneId,
    latitude,
    longitude,
    cart,
    campaignPrizeIds,
  }: DeliveryTimesParams) {
    const params = {
      zoneId,
      latitude,
      longitude,
      cart,
      campaignPrizeIds: campaignPrizeIds && campaignPrizeIds.length > 0 ? campaignPrizeIds : undefined,
    };
    try {
      const response = await $axios.post(`/3.2/stores/${storeId}/delivery-times`, params);
      return { response, error: null };
    } catch (e) {
      return { response: null, error: e as any };
    }
  }
}
