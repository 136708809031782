//
//
//
//
//
//
//
//
//
//
//

import Logo from '~/assets/img/Logo.svg';
import isWebpSupported from '~/helpers/is-webp-supported';

export default {
  name: 'Error404',
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  head() {
    return {
      title: 'Error 404',
    };
  },
  data() {
    return {
      Logo,
      isWebpSupported,
    };
  },
};
