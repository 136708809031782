//
//
//
//
//
//
//
//
//
//
//

import { useQuery } from 'vue-query';
import { tagStylesState } from '~/server-state/tags/tagStyleState';

export default {
  name: 'MqProductCardTag',
  props: {
    detail: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: Object,
      default: () => {},
    },
    status: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { tag } = props;
    const { data: tagStyles } = useQuery(tagStylesState.key, tagStylesState.handler(tag), tagStylesState.options);
    return {
      tagStyles,
    };
  },
  computed: {
    finalTag() {
      if (this.tag && 'data' in this.tag) {
        return this.mappedTag(this.tag.data);
      } else {
        return this.tag || {};
      }
    },
    finalColor() {
      if (this.status) {
        return this.tagStyles ? this.tagStyles.color : '#00444d';
      } else {
        return '#ffffff';
      }
    },
    finalBackground() {
      if (this.status) {
        return this.tagStyles ? this.tagStyles.background_color : '#abff77';
      } else {
        return '#808080';
      }
    },
  },
  methods: {
    showAlertTag() {
      let { state, dispatch } = this.$store;
      const modalParams = {
        title: this.finalTag.title,
        description: this.finalTag.description,
        buttonText: this.finalTag.buttonText,
      };
      if (this.status) {
        dispatch('LOAD_MODAL_CONTENT', modalParams);
        dispatch('LOAD_MODAL_WIDGET', ['campaignModal', !this.$store.state.ui.widgets.campaignModal]);
      }
    },
    mappedTag(data) {
      return {
        tagText: data.tag_text,
        title: data.title,
        description: data.description,
        buttonText: data.button_text,
        urlStyles: data.url_styles,
      };
    },
  },
};
