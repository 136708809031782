export default async function ({ store, redirect }) {
  const {
    state: {
      config: {
        config: { prime_activation_flag: statusFlag },
      },
    },
  } = store;
  if (!statusFlag) {
    redirect('/');
  }
}

