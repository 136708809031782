import { pushAlertUseCase } from '~/usecases/alerts/push-alert-usecase';
import { nuxtInstance } from '~/plugins/nuxt-instance';

/**
 * Type definitions
 * @typedef {import('~/models/product').default} Product
 * @typedef {import('~/models/cart_product').default} CartProduct
 * @typedef {Array<CartProduct>} Cart
 * @typedef {import('~/constants/cart-actions').ADD_PRODUCT_ACTION|import('~/constants/cart-actions').REMOVE_PRODUCT_ACTION} CampaignActions
 */

/**
 * Manage progressAlerts on stores
 * @param {Cart} cart
 */
export async function progressHandlerUseCase(cart) {
  const {
    store: { getters, state, commit },
  } = nuxtInstance;
  const freeDeliveryNotified = state.cart.freeDeliveryNotified;
  if (getters['home/isSuper']()) {
    const freeDeliveryProgress = getters['cart/getPercentFreeDelivery'];
    const mustNotifyFreeDelivery = getters['cart/mustNotifyFreeDelivery'];
    const isFreeDelivery = getters['home/isFreeDelivery'];
    const user = state.user.user;
    if (freeDeliveryProgress < 100 && !isFreeDelivery && 'id' in user && !mustNotifyFreeDelivery) {
      notifyFreeDeliveryProgress(freeDeliveryProgress);
    } else if ('id' in user && freeDeliveryProgress === 100 && !freeDeliveryNotified && mustNotifyFreeDelivery) {
      notifyFreeDeliveryFinish();
    }
    if (mustNotifyFreeDelivery) {
      commit('cart/SET_FREE_DELIVERY_NOTIFIED', true);
    } else {
      commit('cart/SET_FREE_DELIVERY_NOTIFIED', false);
    }
  } else if (getters['home/isExpress']()) {
    const expressBasketProgress = getters['cart/getVolumetricPercent'];
    if (cart.checkVolumetric()) {
      notifyBasketProgress(expressBasketProgress);
    } else {
      notifyBasketFinish();
    }
  }
}

/**
 * Notify with progress alert remaining of free delivery
 * @param {number} progress
 */
async function notifyFreeDeliveryProgress(progress) {
  await pushAlertUseCase(
    {
      component: () => import('~/components/elements/alerts/mq-free-delivery-progress-alert'),
      props: {
        progressWidth: progress,
      },
    },
    2500,
  );
}

/**
 * Notify with progress alert finish free delivery
 */
async function notifyFreeDeliveryFinish() {
  await pushAlertUseCase(
    {
      component: () => import('~/components/elements/alerts/mq-free-delivery-finish-alert'),
      props: {},
    },
    2500,
  );
}

/**
 * Notify with progress alert remaining of free delivery
 * @param {number} progress
 */
async function notifyBasketProgress(progress) {
  await pushAlertUseCase(
    {
      component: () => import('~/components/elements/alerts/mq-basket-progress-alert'),
      props: {
        progressWidth: progress,
      },
    },
    2500,
  );
}

/**
 * Notify with progress alert finish free delivery
 */
async function notifyBasketFinish() {
  await pushAlertUseCase(
    {
      component: () => import('~/components/elements/alerts/mq-basket-full-alert'),
      props: {},
    },
    2500,
  );
}
