import { AnalyticEvent } from './anaytic-event';

export class PageViewAnalyticEvent extends AnalyticEvent {
  constructor(store) {
    super(store);
    this.name = 'pageview';
  }
  track(dataVars) {
    const page = {
      path: dataVars.path,
      title: dataVars.title,
    };
    super.track({ page });
  }
}
