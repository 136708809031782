import { camelize } from '~/helpers/camelize';
import normalize from 'json-api-normalize';

export function getCampaignsTransform(responseData) {
  return normalize(responseData)
    .get([
      'id',
      'brand',
      'campaign_type',
      'end_date',
      'is_acquisition',
      'limit_to_apply',
      'logo',
      'minimum_order_amount',
      'start_date',
      'status',
      'tag',
      'url_terms_and_conditions',
      'campaignPrize.campaign_prize_type',
      'campaignPrize.discount_amount',
      'campaignPrize.discount_type',
      'campaignPrize.maximum_discount_per_order',
      'campaignPrize.id',
    ])
    .map((campaign) => {
      return camelize(campaign);
    });
}
export function getCampaignsTransformHelpCenter(responseData) {
  return normalize(responseData).get([
    'campaignPrizeDetail.id',
    'campaignPrizeDetail.brand',
    'campaignPrizeDetail.campaignPrizeType',
    'campaignPrizeDetail.campaignType',
    'campaignPrizeDetail.discountAmount',
    'campaignPrizeDetail.discountType',
    'campaignPrizeDetail.endDate',
    'campaignPrizeDetail.isAcquisition',
    'campaignPrizeDetail.limitToApply',
    'campaignPrizeDetail.logo',
    'campaignPrizeDetail.maximumDiscountPerOrder',
    'campaignPrizeDetail.minimumOrderAmount',
    'campaignPrizeDetail.startDate',
    'campaignPrizeDetail.status',
    'campaignPrizeDetail.tag',
    'campaignPrizeDetail.urlTermsAndConditions',
  ]);
}
