import $axios from '~/services/global-axios';

interface PayloadResponse {
  response: {
    status: number;
    data: any;
  } | null;
  error: any | null;
}

export async function deleteCreditCardsEndpoint(idCreditCard: number): Promise<PayloadResponse> {
  try {
    const response: any = await $axios.delete(`/3.1/credit-cards/${idCreditCard}`);
    return { error: null, response };
  } catch (error) {
    return { error, response: null };
  }
}
