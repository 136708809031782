//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MqAddCart from '~/components/elements/mq-add-cart';
import productResponsive from '~/assets/json/res-detail.js';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'MqProductOfferCard',
  components: { MqAddCart },
  props: {
    id: { type: Number, required: true },
    product: { type: Object, default: () => {} },
    theme: { type: String, default: () => 'mq-default shadow-0 hoverable' },
    hall: { type: String, default: () => '' },
    limitPum: { type: Number, default: () => 23 },
    screen: { type: String, default: () => '' },
    position: { type: Number, default: 0 },
  },
  data() {
    return {
      responsive: productResponsive(),
      imageSelect: this.product.image_large_url,
    };
  },
  computed: {
    ...mapGetters({
      store: 'home/getCurrentStore',
      city: 'home/getCity',
    }),
    ...mapState({
      screenMode: (state) => state.ui.screenMode,
    }),
    carousel() {
      if (typeof this.product.images !== 'undefined') {
        return this.product.images.length > 0;
      }
      return false;
    },
    isHall() {
      if (typeof this.hall !== 'undefined') {
        return true;
      }
      return this.hall;
    },
  },
  methods: {
    changeImg(e) {
      this.imageSelect = e.srcElement.dataset.srcLarge ? e.srcElement.dataset.srcLarge : e.srcElement.src;
    },
    triggerEventProductViewed() {
      const name = `${this.product.name} ${this.product.quantity} ${this.product.unit}`;
      const price = this.product.specialPrice ? this.product.specialPrice : this.product.price;
      this.$events.productViewed.track({
        departmentId: this.product.department.id,
        shelfId: this.product.shelf.id,
        name,
        price,
        bestPriceGuaranteed: this.product.isBestPrice,
        bannerId: this.product.bannerId,
        position: this.position,
        storeProductId: this.product.storeId,
        sponsored: this.product.sponsored ? 1 : 0,
        screen: this.screen === null ? 0 : this.screen,
        textSearched: 'N/A',
      });
    },
  },
};
