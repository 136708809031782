const middleware = {}

middleware['account-skeleton'] = require('../client/middleware/account-skeleton.js')
middleware['account-skeleton'] = middleware['account-skeleton'].default || middleware['account-skeleton']

middleware['clean-store'] = require('../client/middleware/clean-store.js')
middleware['clean-store'] = middleware['clean-store'].default || middleware['clean-store']

middleware['dispatch-config'] = require('../client/middleware/dispatch-config.js')
middleware['dispatch-config'] = middleware['dispatch-config'].default || middleware['dispatch-config']

middleware['end-checkout'] = require('../client/middleware/end-checkout.js')
middleware['end-checkout'] = middleware['end-checkout'].default || middleware['end-checkout']

middleware['global-skeleton'] = require('../client/middleware/global-skeleton.js')
middleware['global-skeleton'] = middleware['global-skeleton'].default || middleware['global-skeleton']

middleware['i18n'] = require('../client/middleware/i18n.js')
middleware['i18n'] = middleware['i18n'].default || middleware['i18n']

middleware['in-checkout'] = require('../client/middleware/in-checkout.js')
middleware['in-checkout'] = middleware['in-checkout'].default || middleware['in-checkout']

middleware['in-tab-section'] = require('../client/middleware/in-tab-section.js')
middleware['in-tab-section'] = middleware['in-tab-section'].default || middleware['in-tab-section']

middleware['is-login'] = require('../client/middleware/is-login.js')
middleware['is-login'] = middleware['is-login'].default || middleware['is-login']

middleware['load-store-resources'] = require('../client/middleware/load-store-resources.js')
middleware['load-store-resources'] = middleware['load-store-resources'].default || middleware['load-store-resources']

middleware['path-country-validation'] = require('../client/middleware/path-country-validation.js')
middleware['path-country-validation'] = middleware['path-country-validation'].default || middleware['path-country-validation']

middleware['payment-methods'] = require('../client/middleware/payment-methods.js')
middleware['payment-methods'] = middleware['payment-methods'].default || middleware['payment-methods']

middleware['prime-data-managment'] = require('../client/middleware/prime-data-managment.js')
middleware['prime-data-managment'] = middleware['prime-data-managment'].default || middleware['prime-data-managment']

middleware['prime-loader'] = require('../client/middleware/prime-loader.js')
middleware['prime-loader'] = middleware['prime-loader'].default || middleware['prime-loader']

middleware['prime-plans-list'] = require('../client/middleware/prime-plans-list.js')
middleware['prime-plans-list'] = middleware['prime-plans-list'].default || middleware['prime-plans-list']

middleware['redirect-on-store'] = require('../client/middleware/redirect-on-store.js')
middleware['redirect-on-store'] = middleware['redirect-on-store'].default || middleware['redirect-on-store']

middleware['redirect-path'] = require('../client/middleware/redirect-path.js')
middleware['redirect-path'] = middleware['redirect-path'].default || middleware['redirect-path']

middleware['require-address'] = require('../client/middleware/require-address.js')
middleware['require-address'] = middleware['require-address'].default || middleware['require-address']

middleware['validate-checkout'] = require('../client/middleware/validate-checkout.js')
middleware['validate-checkout'] = middleware['validate-checkout'].default || middleware['validate-checkout']

middleware['validate-deeplinks'] = require('../client/middleware/validate-deeplinks.js')
middleware['validate-deeplinks'] = middleware['validate-deeplinks'].default || middleware['validate-deeplinks']

middleware['validate-store'] = require('../client/middleware/validate-store.js')
middleware['validate-store'] = middleware['validate-store'].default || middleware['validate-store']

export default middleware
