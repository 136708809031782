import { nuxtInstance } from '~/plugins/nuxt-instance';
import { CAMPAIGNS_MAIN_TYPES } from '~/constants/campaigns_types';
import { openModal } from '~/components/utils/mq-modal-managment';
import { handlerValidateCampaignsUseCase } from '~/usecases/campaigns/handler-validate-campaigns-usecase';

let _cartProduct;
let _cartProducts;
let _mutation;

export function handlerAlertLossCampaignCart(cartProduct, cartProducts, mutation) {
  const { store } = nuxtInstance;
  const mainCampaign = store.getters['campaigns/getCampaignPriority'];
  const product = cartProduct?.product ?? cartProduct;
  const { storeProductCatalogTags } = product;
  _mutation = mutation;
  _cartProduct = cartProduct;
  _cartProducts = cartProducts;

  if (
    storeProductCatalogTags?.some((objTag) => objTag.tag === mainCampaign?.tag) &&
    mainCampaign.campaignType === CAMPAIGNS_MAIN_TYPES.GENERAL
  ) {
    const cartProductsFiltered = cartProducts.filter((cartProduct) => {
      const { storeProductCatalogTags } = cartProduct.product;
      return storeProductCatalogTags?.some((objTag) => objTag.tag === mainCampaign?.tag);
    });
    const price = product.specialPrice ? product.specialPrice : product.price;
    const quantity =
      mutation === 'DELETE_PRODUCT' ? cartProduct.quantityFullPrice + cartProduct.quantitySpecialPrice : 1;
    const amountToDelete = getSumTotals(cartProductsFiltered) - quantity * price;
    const percentageUpdated = calculatePercentageUpdated(mainCampaign?.minimumOrderAmount, amountToDelete);
    if (percentageUpdated < 100) {
      showModal(mainCampaign);
      return true;
    }
  }
  return false;
}
function calculatePercentageUpdated(minimumOrderAmount, amountToDelete) {
  return (amountToDelete / minimumOrderAmount) * 100;
}
function getSumTotals(cartProducts) {
  return cartProducts.reduce((previous, current) => {
    return previous + current.totalPriceProduct();
  }, 0);
}
async function showModal(mainCampaign) {
  const { urlTermsAndConditions } = mainCampaign;
  await openModal({
    component: () => import('~/components/campaign/bines/dialogs/mq-modal-alert-loss-campaign'),
    props: {
      title: nuxtInstance.app.i18n.t('campaign_alert_your_loss_prize', {
        prizeType: nuxtInstance.app.i18n.t('common_text_discount'),
        companyName: mainCampaign.brand,
      }),
      text: nuxtInstance.app.i18n.t('campaign_alert_your_loss_prize_conditions'),
      buttons: {
        left: {
          text: nuxtInstance.app.i18n.t('common_button_cancel'),
          fn: () => {},
        },
        right: {
          text: nuxtInstance.app.i18n.t('common_button_continue'),
          fn: deleteProduct,
        },
      },
      urlTermsAndConditions,
    },
    group: 'group-modal-bines',
    config: {
      position: {
        value: 'center',
      },
      behaviors: {
        closableContainer: false,
      },
    },
  });
}
async function deleteProduct() {
  const { store } = nuxtInstance;

  let quantity;
  if (_mutation === 'DELETE_PRODUCT') {
    const currentProduct = _cartProducts.find((cartProduct) => cartProduct.product.id === _cartProduct.product.id);

    quantity = currentProduct.quantityFullPrice + currentProduct.quantitySpecialPrice;

    _cartProducts.remove(_cartProduct, quantity);
    handlerValidateCampaignsUseCase(_cartProducts, CAMPAIGNS_MAIN_TYPES.GENERAL);
  } else if (_mutation === 'ADD_PRODUCT') {
    quantity = 1;
    _cartProducts.removeProduct(_cartProduct, quantity);
    handlerValidateCampaignsUseCase(_cartProducts, CAMPAIGNS_MAIN_TYPES.GENERAL);
  }

  await store.dispatch('campaigns/setMainCampaign', null);
}
