import { getSuggestedProductsEndpoint } from '~/endpoints/products/suggested-products-endpoints';
import { productsTransforms } from '~/transforms/products/products-transform';
import { errorTransforms, error280Transforms } from '~/transforms/errors/error-transform';

export async function getSuggestedProductsUsecase(storeId, params) {
  const { response, error } = await getSuggestedProductsEndpoint(storeId, params);
  if (error === null && response.status === 200) {
    if (response.data && !response.data.errors) {
      const suggestedData = await productsTransforms(response.data);
      return { data: suggestedData, error: null };
    } else {
      return { data: [], error: error280Transforms(response.data) };
    }
  } else {
    return { data: [], error: errorTransforms(error) };
  }
}
