import { setupCache } from 'axios-cache-adapter';

export default setupCache({
  maxAge: 60 * 60 * 1000,
  exclude: {
    query: false,
    filter: (ref) => {
      let currentUrl = ref.url;

      let regPages = [
        /\/countries/,
        /\/config/,
        /\/cities/,
        /\/stores\?cityId/,
        /\/stores\/(\d*)\?page/,
        /\/stores\/(\d*)\/menus/,
        /\/users\/me/,
        /\/stores\/(\d*)\/banners/,
        /\/stores\/(\d*)\/brand-room/,
        /\/stores\/(\d*)\/find(\S*)(_slug=*)(\S*)/,
        /\/stores\/(\d*)\/products\/colombia-bag-tax?/,
      ];

      let isPageCachedExclude = true;
      regPages.forEach((element) => {
        if (element.exec(currentUrl)) {
          isPageCachedExclude = false;
        }
      });

      return isPageCachedExclude;
    },
  },
  debug: false,
});
