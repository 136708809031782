const I18NKEYS = [
  ['Hoy', 'common_label_delivery_times_day_hoy'],
  ['Mañana', 'common_label_delivery_times_day_maniana'],
  ['Lunes', 'common_label_delivery_times_day_lunes'],
  ['Martes', 'common_label_delivery_times_day_martes'],
  ['Miércoles', 'common_label_delivery_times_day_miercoles'],
  ['Jueves', 'common_label_delivery_times_day_jueves'],
  ['Viernes', 'common_label_delivery_times_day_viernes'],
  ['Sábado', 'common_label_delivery_times_day_sabado'],
  ['Domingo', 'common_label_delivery_times_day_domingo'],
];

export const MAP_DELIVERY_TIMES_DAYS_KEYS = new Map(I18NKEYS);
