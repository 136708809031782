import { nuxtInstance } from '~/plugins/nuxt-instance';
import { getSumTotals } from '~/usecases/campaigns/validate-general-campaigns-usecase';
import { CAMPAIGNS_MAIN_TYPES } from '~/constants/campaigns_types';
import { PAYMENT_METHODS } from '~/constants/payment-methods';

export async function validatePaymentMethodCampaignsUseCase({ cart, typeMethod }) {
  const { store } = nuxtInstance;
  let campaignsParticipating = store.getters['campaigns/getCampaignsParticipating'];
  const campaignsList = store.getters['campaigns/getCampaigns'];
  campaignsParticipating = validateCampaignsParticipatingExist(campaignsList, campaignsParticipating, cart);
  const newCampaignsParticipating = buildPaymentMethodCampaignsApplied(
    campaignsList,
    campaignsParticipating,
    cart,
    typeMethod,
  );
  const campaignsUpdated = newCampaignsParticipating.size
    ? new Map([...campaignsParticipating, ...newCampaignsParticipating])
    : campaignsParticipating;
  await store.dispatch('campaigns/setCampaignsParticipating', campaignsUpdated);
}

function buildPaymentMethodCampaignsApplied(campaignList, campaignsParticipating, cartGroup, typeMethod) {
  const campaignsGroup = new Map();
  const campaignPayMethod = campaignList.filter((el) => el.campaignType === CAMPAIGNS_MAIN_TYPES.PAYMENT_METHOD);
  const totalCart = getSumTotals(cartGroup);
  campaignPayMethod.sort((campaignA, campaignB) => new Date(campaignA.endDate) - new Date(campaignB.endDate));
  const infoCampaign = campaignPayMethod.find((el) => totalCart >= el.minimumOrderAmount);
  if (infoCampaign && typeMethod === PAYMENT_METHODS.CREDIT_CARD) {
    const { tag } = infoCampaign;
    if (!campaignsParticipating.has(tag)) {
      campaignsGroup.set(tag, infoCampaign);
    }
  }
  return campaignsGroup;
}

/* Se validar si las campañas participantes no estan incluidas en la 
  lista de campañas de la tienda o que el monto del carrito actual no cumpla 
  con el monto minimo de la campaña participante 
*/
function validateCampaignsParticipatingExist(campaignList, campaignsParticipating, cartGroup) {
  const tagsList = campaignList.map((el) => el.tag);
  const totalCart = getSumTotals(cartGroup);
  [...campaignsParticipating].forEach(([_campaignKey, campaignValue]) => {
    if (
      campaignValue.campaignType === CAMPAIGNS_MAIN_TYPES.PAYMENT_METHOD &&
      (!tagsList.includes(_campaignKey) || totalCart < +campaignValue.minimumOrderAmount)
    ) {
      campaignsParticipating.delete(_campaignKey);
    }
  });
  return campaignsParticipating;
}
