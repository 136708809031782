import { camelize } from '~/helpers/camelize';
import normalize from 'json-api-normalize';

const addressListTransform = responseData => {
  const normalizedData = camelize(
    normalize(responseData).get([
      'id',
      'label',
      'address',
      'address_further',
      'neighborhood',
      'latitude',
      'longitude',
      'postal_code',
      'outdoor_number',
      'parts',
      'city.id',
      'city.city',
      'city.slug',
      'city.address_type',
      'country.id',
      'country.code',
    ]),
  );
  return {
    data: normalizedData,
    meta: {
      pagination: {
        total: responseData.meta.pagination.total,
        count: responseData.meta.pagination.count,
        per_page: responseData.meta.pagination.per_page,
        current_page: responseData.meta.pagination.current_page,
        total_pages: responseData.meta.pagination.total_pages,
        links: responseData.links,
      },
    },
  };
};
export default addressListTransform;
