import * as env from '~/../env.local';
const CURRENT_ENVIRONMENT = env.ENV_FLAG;

export const FEATURE_TOGGLES_KEYS = {
  SPLIT_STORE_GENERAL_TOGGLE: 'split-store-general-toggle',
  SPLIT_STORE_TOGGLE: 'split-store-toggle',
  ENABLE_ENDPOINT_VALIDATION_TOGGLE: 'enable-endpoint-validation-toggle',
  ANOTHER_EXAMPLE_TOGGLE: 'another-example-toggle',
  GEOLOCATION_TOGGLE: 'geolocation_toggle',
  PRODUCT_CARD_TOGGLE: 'product-card-toggle',
  ENABLE_FLOATING_FOOTER: 'enable_floating_footer',
  FLAG_AUTOMATION_LEGACY_LOGIN: 'flag-automation-legacy-login',
  FLAG_KUSTOMER: 'flag-kustomer',
  HIDE_TOOLTIP_CAMPAIGNS: 'hide_tooltip_campaings',
  HIDE_DOWNLOAD_APP_MODAL: 'hide-download-app-modal',
  FLAG_REDESIGN_BANNERS: 'flag-redesign-banners',
  HIDE_CITY_MARKET_TITLE: 'hide-city-market-title',
  SHOW_SECTION_WITHOUT_STORES_HOME: 'show-section-without-stores-home',
  BUY_ON_STORE_DISABLED: 'buy-disabled',
};

// HOW TO USE FEATURE TOGGLES: https://merqueo.atlassian.net/l/c/90g1xn9P
interface FeatureFlag {
  environments?: Array<string>; // environment values ['local', 'development', 'testing', 'production'],
  value?: boolean;
  cookie?: boolean;
}

const FEATURE_TOGGLES_TABLE: Map<String, FeatureFlag> = new Map([
  [
    FEATURE_TOGGLES_KEYS.SPLIT_STORE_GENERAL_TOGGLE,
    { environments: ['local', 'development', 'production'], value: false },
  ],
  [FEATURE_TOGGLES_KEYS.SPLIT_STORE_TOGGLE, { environments: ['local', 'development', 'production'], value: true }],
  [FEATURE_TOGGLES_KEYS.ENABLE_ENDPOINT_VALIDATION_TOGGLE, { environments: ['testing', 'local'], value: false }],
  [FEATURE_TOGGLES_KEYS.ANOTHER_EXAMPLE_TOGGLE, { environments: ['local'], value: true }],
  [FEATURE_TOGGLES_KEYS.GEOLOCATION_TOGGLE, { environments: ['local', 'development', 'production'], value: true }],
  [FEATURE_TOGGLES_KEYS.PRODUCT_CARD_TOGGLE, { environments: ['local'], value: true }], // 'development'
  [FEATURE_TOGGLES_KEYS.SHOW_SECTION_WITHOUT_STORES_HOME, { environments: ['local', 'development'], value: true }],
  [FEATURE_TOGGLES_KEYS.ENABLE_FLOATING_FOOTER, { environments: ['local', 'development', 'production'], value: true }],
  [
    FEATURE_TOGGLES_KEYS.FLAG_AUTOMATION_LEGACY_LOGIN,
    { environments: ['local', 'development', 'testing'], cookie: true },
  ],
  [FEATURE_TOGGLES_KEYS.FLAG_KUSTOMER, { environments: ['local', 'development', 'production'], value: false }],
  [FEATURE_TOGGLES_KEYS.HIDE_TOOLTIP_CAMPAIGNS, { value: true }],
  [FEATURE_TOGGLES_KEYS.HIDE_DOWNLOAD_APP_MODAL, { value: false }],
  [FEATURE_TOGGLES_KEYS.FLAG_REDESIGN_BANNERS, { value: true }],
  [FEATURE_TOGGLES_KEYS.HIDE_CITY_MARKET_TITLE, { value: true }],
  [FEATURE_TOGGLES_KEYS.HIDE_CITY_MARKET_TITLE, { value: true }],
  [
    FEATURE_TOGGLES_KEYS.BUY_ON_STORE_DISABLED,
    { environments: ['local', 'testing', 'development', 'production'], value: false },
  ],
]);

// Function to return flag value
export function checkFeatureFlag(key: string) {
  const toggle = FEATURE_TOGGLES_TABLE.get(key);
  if (!toggle?.environments && !toggle?.cookie) return toggle?.value; // code
  if (toggle?.environments && !toggle?.cookie)
    return (toggle.environments?.find((e: string) => e === CURRENT_ENVIRONMENT) && toggle?.value) ?? false; // env
  if (toggle?.environments && toggle?.cookie) return checkFeatureFlagByCookie(key, toggle.environments); //cookie
  return false;
}

export function updateFeatureFlag(key: string, value: boolean) {
  const toggle = FEATURE_TOGGLES_TABLE.get(key);
  if (toggle instanceof Object) {
    toggle.value = value;
    FEATURE_TOGGLES_TABLE.set(key, toggle);
  }
}

// function to validate by cookie
function checkFeatureFlagByCookie(key: string, environments: Array<string>) {
  const cookies = document.cookie.replace(/ /g, '').split(';');
  const cookiesValues = cookies.map((c: string) => c.split('='));
  const cookie = cookiesValues.find((c: any) => c[0] === key);
  if (cookie && environments) {
    if (!environments.find((e: string) => e === CURRENT_ENVIRONMENT)) return false;
    return cookie[1] === '1';
  }
  return false;
}
