import $axios from '~/services/global-axios';

export async function getFavoritesHighlightsEndpoint({ zoneId, storeId }) {
  try {
    zoneId = zoneId || 0;
    const response = await $axios.get(`/3.1/stores/${storeId}/users/products/popular/highlights?zoneId=${zoneId}`);
    return { error: null, response };
  } catch (error) {
    return { error, response: null };
  }
}

