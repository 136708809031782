import { getCampaignsEndpoint } from '~/endpoints/campaigns/get-campaigns-endpoint';
import { errorTransforms, error280Transforms } from '~/transforms/errors/error-transform';
import { getCampaignsTransform } from '~/transforms/campaigns/get-campaigns-transform';

export async function getCampaignsService(storeId) {
  const { response, error } = await getCampaignsEndpoint(storeId);
  if (response && response.status === 200) {
    const data = getCampaignsTransform(response.data);
    return { data, error };
  } else if (response && response.status === 280) {
    return { data: null, error: error280Transforms(response) };
  } else {
    return { data: null, error: errorTransforms(error) };
  }
}
