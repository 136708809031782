import moment from 'moment';
import getPaymentMethodsUseCase from '~/usecases/helpCenter/get-payment-methods-usecase';
import getCreditCardsUseCase from '~/usecases/helpCenter/get-credit-cards-usecase';
import { updateBasicUserData as updateBasicUserDataUseCase } from '~/usecases/account/update-user';
import { orderDetailUsecase } from '~/usecases/orders/order-detail-usecase';
import { userPolicyUpdateUsecase } from '~/usecases/policy/user-policy-update-usecase';
import { getUserCreditsService } from '~/services/account/get-user-credits-service';
import { getUserTotalCreditsService } from '~/services/account/get-user-total-credits-service';
import UserMeService from '~/services/userme-service';
import { deleteCreditCardService } from '~/services/credit-cards/delete-credit-card-service';
import { refreshTokenService } from '~/services/refresh-token-service';

const env = require('./../../../env.local');

// initial state
const getDefaultState = () => {
  return {
    user: {
      identityType: null,
      identityNumber: null,
    },
    token: null,
    lastTimeToken: null,
    isMaxAge: false,
    register: {
      id: null,
      first_name: null,
      last_name: null,
      email: null,
      phone: null,
      password: null,
      referalCode: null,
    },
    isLoginBy: 'normal',
    orderDetail: {},
    orderInvoice: {},
    addressData: {},
    analytics: {},
    idCreditCardDelete: null,
    showBarResponsiveDownload: false,
    downloadApp: true,
    showBar: true,
    isMigrate: false,
    prevPage: null,
    webVersion: 'v2',
    helpCenter: {
      orderId: undefined,
      currentOrder: null,
      categoryPreselected: undefined,
      paymentMethods: null,
      creditCards: null,
      retryPaymentMethods: 0,
      fromPaymentMethod: null,
      toPaymentMethod: null,
      currentParamsToPayment: null,
      campaigns: null,
    },
  };
};

const state = getDefaultState();

// getters
const getters = {
  isUserValid(state) {
    return typeof state.user.firstName === 'string' && typeof state.user.id === 'number';
  },
  getUserMapped: ({ data: { id, attributes }, included }) => {
    const freeDeliveryDays = included.find((item) => {
      return item.type === 'freeDeliveryDays';
    });
    const userMapped = {
      identityNumber: attributes.identity_number,
      identityType: attributes.identity_type,
      firstName: attributes.first_name,
      lastName: attributes.last_name,
      email: attributes.email,
      id: id,
      href: attributes.href,
      phoneValidatedDate: attributes.phone_validated_date,
      phone: attributes.phone,
      referralCode: attributes.referral_code,
      status: true,
      totalOrders: attributes.total_orders,
      phonePrefix: attributes.phone_prefix,
      freeDeliveryDays: freeDeliveryDays ? freeDeliveryDays.attributes : null,
    };
    return userMapped;
  },
  isAcquisition(state) {
    const userData = state.user || {};
    const isLogin = 'id' in userData;
    const hasOrders = userData.totalOrders > 0;
    return isLogin && hasOrders ? 0 : 1;
  },
  willBeFirstBuy({ user }) {
    const hasOrders = 'id' in user && user?.totalOrders > 0;
    return !hasOrders;
  },
  getOrderDetail(state) {
    return state.orderDetail;
  },
  getUser(state) {
    return state.user;
  },
  getUserId(state) {
    return state.user.id;
  },
  getReferalCode(state) {
    return state.user.referralCode;
  },
};

// actions
const actions = {
  async updatePrivacyPolicies({ rootGetters }) {
    const country = rootGetters['home/getCurrentCountryCode'];
    const deviceId = localStorage.getItem('__leanplum_device_id');
    const version = rootGetters['home/getCurrentVersion'];
    await userPolicyUpdateUsecase({ country, deviceId, version, base: env.productServicesBase });
  },
  setUser({ commit }, userData) {
    commit('SET_USER', userData);
  },

  setToken({ commit }, token) {
    commit('SET_TOKEN', token);
  },

  setLastTimeToken({ commit }) {
    commit('SET_LAST_TIME_TOKEN');
  },

  async loginSocialInfobip({ commit }, datos) {
    const response = await this.$axios.$post(`/3.0/social`, datos);
    const { included } = response;
    const tokenObject = included.find((item) => item.type === 'token');
    const token = tokenObject.attributes.value;
    const user = getters.getUserMapped(response);
    commit('SET_TOKEN', token);
    commit('SET_USER', user);
    commit('SET_LAST_TIME_TOKEN');

    return response;
  },

  async loginNequi({commit}, nequiToken) {
    const response = await this.$axios.post(`/3.2/social`, {
      token: nequiToken,
      type: 'nequi'
    });

    if (response.status === 200) {
      commit('SET_USER_FROM_LOGIN_OR_REGISTER', {commit, response})
    }

    if (response.status === 280) {
      const userDataFromNequi = response.data.errors[0].source.data
      const payload = {
        phone: userDataFromNequi.phone,
        firstName: `${userDataFromNequi.first_name} ${userDataFromNequi.second_name}`,
        lastName: `${userDataFromNequi.first_lastname} ${userDataFromNequi.second_lastname}`,
        email: userDataFromNequi.email,
        typeDocument: userDataFromNequi.document_type,
        document: userDataFromNequi.document_number,
        birthday: userDataFromNequi.birthdate
      }
      commit('nequi/SET_NEQUI_USER', payload, {root: true});
    }

    return response;
  },
  async registerNequi({commit, dispatch}, payload) {
    const response = await this.$axios.post(`/3.2/register`, payload, {
      headers: {
        'X-Merqueo-Country': 'co',
        'X-Merqueo-Agent': 'Web',
      },
    });

    if (response.status === 200) {
      await commit('SET_USER_FROM_LOGIN_OR_REGISTER', {commit, response})
      await dispatch('updatePrivacyPolicies');
      await dispatch('me', true);
    }

    return response
  },
  async me({commit}) {
      const {data: user, error} = await UserMeService({freeDeliveryDays: true});
      if (!error && user) {
          commit('SET_USER', user);
      }
  },
  async logout({ commit, dispatch }) {
    commit('LOGOUT');
    commit('checkout/resetState', null, { root: true });
    commit('cart/resetState', null, { root: true });
    commit('resetState');
  },

  async sendRecovery(res, datos) {
    const data = await this.$axios.$post(`/2.0/users/send-reminder`, datos);
    return data;
  },

  async registerv3({ commit, dispatch }, datos) {
    const response = await this.$axios.$post(`/3.0/register`, datos);
    const { included } = response;
    const tokenObject = included.find((item) => item.type === 'token');
    const token = tokenObject.attributes.value;
    const user = getters.getUserMapped(response);

    commit('SET_TOKEN', token);
    commit('SET_USER', user);
    commit('SET_REGISTER', {});
    commit('SET_LAST_TIME_TOKEN');
    await dispatch('updatePrivacyPolicies');
    await dispatch('me', true);
    return response;
  },

  async updateInfoUser({ commit }, userData) {
    const { data, error } = await updateBasicUserDataUseCase(userData);
    if (data?.data) {
      const user = transformUser(data.data.attributes, data.data.id);
      commit('SET_USER', user);
    }

    return { data, error };
  },

  async getOrderDetail({}, { orderId = null }) {
    return await orderDetailUsecase({ orderId });
  },

  async updateInfoInvoice({ rootState }, { orderId = null, params = {} }) {
    let data = [];
    if ('token' in rootState.user) {
      // this.$axios.setToken(rootState.user.token, "Bearer");
      data = await this.$axios.$put(`/2.0/orders/${orderId}`, params);
    }

    return data;
  },

  async refreshToken({ commit, rootState }, tokenId) {
    if ('token' in rootState.user) {
      const { data: newToken, error } = await refreshTokenService(tokenId);
      if (!error && newToken) {
        commit('SET_TOKEN', newToken);
        return null;
      }
      return error;
    }
  },

  //Todo: validar uso
  async saveCreditCard({ rootState }, dataCreditCard = {}) {
    let data = [];
    if ('token' in rootState.user) {
      data = await this.$axios.post(`/2.0/credit-cards`, dataCreditCard);
    }
    return data;
  },
  async deleteCreditCardv3({ rootState }, idCreditCardDelete) {
    let data = [];
    if ('token' in rootState.user) {
      data = await deleteCreditCardService(idCreditCardDelete);
    }
    return data;
  },

  async getUserCredits({ rootState }) {
    const { currentCountry } = rootState.home;
    const { code } = currentCountry;
    const { data = [] } = await getUserCreditsService(code);
    return data;
  },
  async getUserTotalCredits({ rootState }) {
    const { currentCountry } = rootState.home;
    const { code } = currentCountry;
    const { data = [] } = await getUserTotalCreditsService(code);
    return data;
  },
  //Todo: validar uso
  async referrals(res, { code = null }) {
    let data = [];

    let params = `?code=${code}`;
    data = await this.$axios.$get(`/2.0/referrals${params}`);

    return data;
  },
  //Todo: validar uso
  async getAnalyticsData({ commit, rootState }) {
    const analytics = rootState.user.analytics || {};
    const timeStamp = analytics.timeStamp;
    try {
      if (!timeStamp || moment().diff(timeStamp, 'minutes') > 10) {
        const response = await this.$axios.$get('/2.0/analytics');
        const withTimeStamp = {
          timeStamp: new Date(),
          attributes: response.data,
        };

        commit('SET_ANALYTICS', withTimeStamp);

        return withTimeStamp;
      }
    } catch (e) {}
    return analytics;
  },
  //Actions HelpCenter
  async loadPaymentMethods({ commit }, orderId) {
    const { data, error } = await getPaymentMethodsUseCase(orderId);
    if (data) {
      commit('SET_PAYMENT_METHODS_HELP_CENTER', data);
      return true;
    } else if (error) {
      commit('SET_PAYMENT_METHODS_HELP_CENTER', null);
      return null;
    }
  },
  async loadCreditCards({ commit }, orderId) {
    const { data, error } = await getCreditCardsUseCase(orderId);
    if (data) {
      commit('SET_CREDIT_CARDS_HELP_CENTER', data);
      return true;
    } else {
      commit('SET_CREDIT_CARDS_HELP_CENTER', null);
      return null;
    }
  },
  async loadAllPaymentMethods({ dispatch }, orderId) {
    const paymentMethods = await dispatch('loadPaymentMethods', orderId);
    const creditCards = await dispatch('loadCreditCards', orderId);
    return { paymentMethods, creditCards };
  },
  async changePaymentMethods({ commit }, params) {
    const { data, error } = await changePaymentMethodUseCase(params);
    commit('SET_CURRENT_PARAMS_TO_PAYMENT', params);
    return { data, error };
  },
  setCurrentParamsToPayment({ commit }, params) {
    commit('SET_CURRENT_PARAMS_TO_PAYMENT', params);
  },
  async clearAllStateHelpCenter({ commit }) {
    commit('SET_HELP_CENTER', { orderId: null, categoryPreselected: null });
    commit('SET_HELP_CENTER_CURRENT_ORDER', null);
    commit('SET_FROM_PAYMENT_METHOD', null);
    commit('SET_PAYMENT_METHODS_HELP_CENTER', null);
    commit('SET_CREDIT_CARDS_HELP_CENTER', null);
    commit('SET_RETRY_PAYMENT_METHODS', 0);
    commit('SET_TO_PAYMENT_METHOD', null);
    commit('SET_CURRENT_PARAMS_TO_PAYMENT', null);
  },
};

// mutations
const mutations = {
  SET_TOKEN(state, token) {
    state.token = token;
  },

  SET_USER(state, user) {
    state.user = user;
  },

  SET_USER_FROM_LOGIN_OR_REGISTER(_, {commit, response}) {
    const {included} = response.data;
    const tokenObject = included.find((item) => item.type === 'token');
    const token = tokenObject.attributes.value;
    const user = getters.getUserMapped(response.data);
    commit('SET_TOKEN', token);
    commit('SET_USER', user);
    commit('SET_LAST_TIME_TOKEN');
  },

  LOGOUT(state) {
    state.token = null;
    state.user = {};
  },

  SET_IS_MAX_AGE(state, isMaxAge) {
    state.isMaxAge = isMaxAge;
  },

  SET_REGISTER(
    state,
    {
      id = null,
      first_name = null,
      last_name = null,
      email = null,
      phone = null,
      password = null,
      referalCode = null,
      phoneCode = null,
    },
  ) {
    state.register = {
      id: id,
      first_name: first_name,
      last_name: last_name,
      email: email,
      phone: phone,
      password: password,
      referalCode: referalCode,
      phoneCode: phoneCode,
    };
  },

  SET_IS_LOGIN_BY(state, isLoginBy) {
    state.isLoginBy = isLoginBy;
  },

  SET_HELP_CENTER(state, { orderId, categoryPreselected, directLink = false, isOpenedFromChat = false }) {
    state.helpCenter.orderId = orderId;
    state.helpCenter.categoryPreselected = categoryPreselected;
    state.helpCenter.directLink = directLink;
    state.helpCenter.isOpenedFromChat = isOpenedFromChat;
  },

  SET_HELP_CENTER_CURRENT_ORDER(state, value) {
    state.helpCenter.currentOrder = value;
  },

  SET_ORDER_DETAIL(state, orderDetail) {
    state.orderDetail = orderDetail;
  },

  SET_ORDER_INVOICE(state, orderInvoice) {
    state.orderInvoice = orderInvoice;
  },

  SET_ADDRESS_DATA(state, addressData) {
    state.addressData = addressData;
  },

  SET_LAST_TIME_TOKEN(state) {
    state.lastTimeToken = moment().unix();
  },

  SET_ID_CREDITCARD_DEL(state, idCreditCardDelete) {
    state.idCreditCardDelete = idCreditCardDelete;
  },

  SET_ANALYTICS(state, attributes) {
    state.analytics = attributes;
  },

  SET_HIDE_BAR_RESPONSIVE_DOWNLOAD(state) {
    state.showBarResponsiveDownload = false;
  },

  SET_DOWNLOAD_APP(state, value) {
    state.downloadApp = value;
  },

  SET_SHOW_BAR_RESPONSIVE_DOWNLOAD(state) {
    state.showBarResponsiveDownload = true;
  },

  SET_HIDE_BAR(state) {
    state.showBar = false;
  },
  SET_MIGRATE(state, isMigrate) {
    state.isMigrate = isMigrate;
  },
  SET_PREVPAGE(state, prevPage) {
    state.prevPage = prevPage;
  },
  resetState(state) {
    let estados = getDefaultState();
    Object.assign(state, estados);
  },
  SET_WEB_VERSION(state, webVersion) {
    state.webVersion = webVersion;
  },
  SET_PAYMENT_METHODS_HELP_CENTER(state, value) {
    state.helpCenter.paymentMethods = value;
  },
  SET_CREDIT_CARDS_HELP_CENTER(state, value) {
    state.helpCenter.creditCards = value;
  },
  SET_RETRY_PAYMENT_METHODS(state, value) {
    state.helpCenter.retryPaymentMethods = value;
  },
  SET_FROM_PAYMENT_METHOD(state, value) {
    state.helpCenter.fromPaymentMethod = value;
  },
  SET_TO_PAYMENT_METHOD(state, value) {
    state.helpCenter.toPaymentMethod = value;
  },
  SET_CURRENT_PARAMS_TO_PAYMENT(state, value) {
    state.helpCenter.currentParamsToPayment = value;
  },

  SET_ACCOUNT_PREFERENCES_ADVERTISING(state, value) {
    state.user.sendAdvertising = value;
  },
};

const transformUser = (objectUser, id) => {
  const {
    fb_id: fbId,
    first_name: firstName,
    identity_number: identityNumber,
    identity_type: identityType,
    last_name: lastName,
    phone_prefix: phonePrefix,
    phone_validated_date: phoneValidatedDate,
    referral_code: referralCode,
    referral_code_blocked: referralCodeBlocked,
    send_advertising: sendAdvertising,
    total_orders: totalOrders,
    ...left
  } = objectUser;
  return {
    fbId,
    firstName,
    identityNumber,
    identityType,
    lastName,
    phonePrefix,
    phoneValidatedDate,
    referralCode,
    referralCodeBlocked,
    sendAdvertising,
    totalOrders,
    id: parseInt(id),
    ...left,
  };
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
