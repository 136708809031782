import { getProductsByTagEndpoint } from '~/endpoints/products/history-products-endpoint';
import { productsTransforms } from '~/transforms/products/products-transform';
import { errorTransformsFetch, error280Transforms } from '~/transforms/errors/error-transform';

export async function getProductsByTagUsecase(params) {
  const { error, response } = await getProductsByTagEndpoint(params);
  const responseJson = await response.json();
  if (!error && response.status === 200) {
    const products = productsTransforms(responseJson);
    return { data: products, error: null };
  } else if (!error && response.status === 280) {
    return { data: null, error: error280Transforms({ data: responseJson }) };
  } else {
    return { data: null, error: errorTransformsFetch(responseJson) };
  }
}
