import { CAMPAIGNS_MAIN_TYPES, CAMPAIGNS_SUB_TYPES } from '~/constants/campaigns_types';

const state = {
  campaigns: null,
  campaignApplied: false,
  campaignsParticipating: [],
  campaignsViewed: [],
  mainCampaign: null,
  campaignsPrizeIdsErrorsHC: [],
  campaignPrizeIdsToSendTotals: [],
  HCModifyOrderCampaignWon: {},
};
const getters = {
  getCampaignPrizeIdsToSendTotals(state) {
    return state.campaignPrizeIdsToSendTotals ?? [];
  },
  getCampaignsPrizeIdsErrorsHC(state) {
    return state.campaignsPrizeIdsErrorsHC ?? [];
  },
  getHCModifyOrderCampaignWon(state) {
    return state.HCModifyOrderCampaignWon ?? {};
  },
  getCampaigns(state) {
    return state.campaigns ?? [];
  },
  getCampaignApplied(state) {
    return state.campaignApplied;
  },
  getCampaignsParticipating(state) {
    return new Map(state.campaignsParticipating);
  },
  getCampaignsViewed(state) {
    return state.campaignsViewed;
  },
  isCampaignViewed: (state) => (campaignTag) => {
    return state.campaignsViewed.some((campaign) => campaign === campaignTag);
  },
  isCampaignParticipating: (_state, getters) => (campaignTag) => {
    return getters['getCampaignsParticipating'].has(campaignTag);
  },
  isCampaignWin: (_state, getters) => (campaignTag) => {
    const campaignsParticipating = getters['getCampaignsParticipating'];
    const campaign = campaignsParticipating.get(campaignTag);
    if (campaign?.campaignType == CAMPAIGNS_MAIN_TYPES.PAYMENT_METHOD) {
      return true;
    } else if (
      campaign?.campaignType == CAMPAIGNS_MAIN_TYPES.GENERAL &&
      campaign?.campaignPrize[0].campaignPrizeType == CAMPAIGNS_SUB_TYPES.FREE_DELIVERY
    ) {
      return campaign.percentage === 100;
    }
  },
  getCampaignPriority(_state, getters) {
    const campaignsParticipating = getters['getCampaignsParticipating'];
    const campaignsToWinGeneral = [...campaignsParticipating?.values()]?.filter(
      (campaign) => campaign.campaignType === CAMPAIGNS_MAIN_TYPES.GENERAL && campaign.percentage === 100,
    );
    const campaignsToWinPaymentMethod = [...campaignsParticipating?.values()]?.filter(
      (campaign) => campaign.campaignType === CAMPAIGNS_MAIN_TYPES.PAYMENT_METHOD,
    );
    campaignsToWinGeneral?.sort((campaignA, campaignB) => new Date(campaignA.endDate) - new Date(campaignB.endDate));
    const campaignsOrdered = [...campaignsToWinPaymentMethod, ...campaignsToWinGeneral];
    return campaignsOrdered[0];
  },
  getCampaignProgress: (_state, getters) => (campaignTag) => {
    return getters['getCampaignsParticipating'].get(campaignTag)?.percentage;
  },
  getMainCampaign(state) {
    return state.mainCampaign;
  },
  getCampaignCheckout(_state, getters) {
    const campaignsParticipating = getters['getCampaignsParticipating'];
    return campaignsParticipating
      ? [...campaignsParticipating.values()].filter(
          (campaign) =>
            (campaign.campaignType === CAMPAIGNS_MAIN_TYPES.GENERAL &&
              campaign.percentage === 100 &&
              campaign.campaignPrize[0].campaignPrizeType === CAMPAIGNS_SUB_TYPES.FREE_DELIVERY) ||
            campaign.campaignType === CAMPAIGNS_MAIN_TYPES.PAYMENT_METHOD,
        )
      : [];
  },
};
const actions = {
  setHCModifyOrderCampaignWon({ commit }, HCModifyOrderCampaignWon) {
    commit('SET_HC_MODIFY_ORDER_CAMPAIGN_WON', HCModifyOrderCampaignWon);
  },
  setCampaignPrizeIdsToSendTotals({ commit }, campaignPrizeIdsToSendTotals) {
    commit('SET_CAMPAIGN_PRIZE_IDS_TO_SEND_TOTALS', campaignPrizeIdsToSendTotals);
  },
  setCampaignsPrizeIdsErrorsHC({ commit }, campaignsPrizeIdsErrorsHC) {
    commit('CAMPAIGNS_PRIZE_IDS_ERRORS_HC', campaignsPrizeIdsErrorsHC);
  },
  setCampaigns({ commit }, campaigns) {
    commit('SET_CAMPAIGNS', campaigns);
  },
  setCampaignApplied({ commit }, value) {
    commit('SET_CAMPAIGN_APPLIED', value);
  },
  setCampaignsParticipating({ commit }, value) {
    commit('SET_CAMPAIGNS_PARTICIPATING', value);
  },
  setCampaignViewed({ commit }, value) {
    commit('SET_CAMPAIGN_VIEWED', value);
  },
  setMainCampaign({ commit }, value) {
    commit('SET_MAIN_CAMPAIGN', value);
  },
  clearAllCampaingsData({ commit }) {
    commit('SET_CAMPAIGNS', null);
    commit('SET_CAMPAIGN_APPLIED', null);
    commit('SET_CAMPAIGNS_PARTICIPATING', new Map());
    commit('SET_CAMPAIGN_PRIZE_IDS_TO_SEND_TOTALS', null);
    commit('CAMPAIGNS_PRIZE_IDS_ERRORS_HC', null);
  },
  removeCampaignsParticipating({ getters, commit }, arrayId) {
    const campaignsParticipating = getters['getCampaignsParticipating'];
    [...campaignsParticipating].forEach(([key, value]) => {
      const { id } = value;
      if (arrayId.some((el) => el == id)) {
        campaignsParticipating.delete(key);
      }
    });
    commit('SET_CAMPAIGNS_PARTICIPATING', campaignsParticipating);
  },
};
const mutations = {
  SET_HC_MODIFY_ORDER_CAMPAIGN_WON(state, HCModifyOrderCampaignWon) {
    state.HCModifyOrderCampaignWon = HCModifyOrderCampaignWon;
  },
  SET_CAMPAIGN_PRIZE_IDS_TO_SEND_TOTALS(state, campaignPrizeIdsToSendTotals) {
    state.campaignPrizeIdsToSendTotals = campaignPrizeIdsToSendTotals;
  },
  CAMPAIGNS_PRIZE_IDS_ERRORS_HC(state, campaignsPrizeIdsErrorsHC) {
    state.campaignsPrizeIdsErrorsHC = campaignsPrizeIdsErrorsHC;
  },
  SET_CAMPAIGNS(state, value) {
    state.campaigns = value;
  },
  SET_CAMPAIGN_APPLIED(state, value) {
    state.campaignApplied = value;
  },
  SET_CAMPAIGNS_PARTICIPATING(state, value) {
    if (value instanceof Map) {
      state.campaignsParticipating = [...value.entries()];
    }
  },
  SET_CAMPAIGN_VIEWED(state, value) {
    const campaignsViewed = [...state.campaignsViewed];
    campaignsViewed.push(value);
    state.campaignsViewed = campaignsViewed;
  },
  SET_MAIN_CAMPAIGN(state, value) {
    state.mainCampaign = value;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
