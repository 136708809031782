import { getProductsBannerEndpoint } from '~/endpoints/products/get-products-banner-endpoint';
import { productTransforms } from '~/transforms/products/products-transform';
import { errorTransformsFetch, errorTransforms } from '~/transforms/errors/error-transform';

/**
 *
 * @param {number} storeId
 * @param {number} bannerId,
 * @param {number} zoneId,
 */
export async function getProductsBannerUseCase({ storeId, bannerId, zoneId }) {
  const { error, response } = await getProductsBannerEndpoint({ storeId, bannerId, zoneId });
  if (!error && response.status === 200) {
    const data = await response.json();
    const products = productTransforms(data);
    return { data: products, error: null };
  } else if (!error && response.status === 280) {
    return { data: null, error: errorTransformsFetch(await response.json()) };
  } else if (error) {
    return { data: null, error };
  } else {
    return {};
  }
}
