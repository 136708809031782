import { validateScheme } from '~/decorators/validateScheme';
import { DeliveryTimesParams } from '~/schemes/delivery-times-scheme';
import $axios from '~/services/global-axios';

export class DeliveryTimes {
  @validateScheme
  static async getDeliveryTimesEndpoint({
    storeId,
    zoneId,
    latitude,
    longitude,
    cart,
    slotExpress,
    campaignPrizeIds,
  }: DeliveryTimesParams): Promise<{ response: any; error: any }> {
    const params = {
      zoneId,
      latitude,
      longitude,
      cart,
      slotExpress,
      campaignPrizeIds: campaignPrizeIds && campaignPrizeIds.length > 0 ? campaignPrizeIds : undefined,
    };
    try {
      const response = await $axios.post(`/3.1/stores/${storeId}/delivery-times`, params);
      return { response, error: null };
    } catch (error) {
      return { response: null, error };
    }
  }
}
