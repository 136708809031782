import Vue from 'vue';
import tippy, { roundArrow } from 'tippy.js';
import MqTooltipText from '~/components/utils/mq-tooltip-text';
import { getMaxZindex } from '~/helpers/max-z-index';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/dist/svg-arrow.css';
import 'tippy.js/animations/scale.css';
import 'tippy.js/animations/shift-away.css';

const listEvent = new Map();
const listTooltip = new Map();

function createTooltipText(el, options) {
  return () => {
    if (listTooltip.has(el)) return;
    const { text, style, configTooltip } = options;
    const ComponentClass = Vue.extend(MqTooltipText);
    const instance = new ComponentClass({
      propsData: {
        text,
        styleEl: {
          ...style,
        },
      },
    });
    instance.$mount();
    const tooltip = tippy(el, {
      content: instance.$el,
      interactive: true,
      zIndex: getMaxZindex() + 1,
      arrow: roundArrow,
      trigger: 'manual',
      appendTo: () => document.body,
      theme: 'light',
      onHidden: () => {
        listTooltip.delete(el);
      },
      ...configTooltip,
    });
    tooltip.show();
    listTooltip.set(el, tooltip);
  };
}

function createTooltipComp(el, options) {
  return () => {
    if (listTooltip.has(el)) return;
    const { component, propsData, configTooltip } = options;
    const ComponentClass = Vue.extend(component);
    const instance = new ComponentClass({
      propsData: propsData ?? {},
    });
    instance.$mount();
    const tooltip = tippy(el, {
      content: instance.$el,
      interactive: true,
      zIndex: getMaxZindex() + 1,
      arrow: roundArrow,
      trigger: 'manual',
      appendTo: () => document.body,
      theme: 'light',
      onHidden: () => {
        listTooltip.delete(el);
      },
      ...configTooltip,
    });
    tooltip.show();
    instance.closeCTA = () => {
      tooltip.hide();
    };
    listTooltip.set(el, tooltip);
  };
}

function statusTooltip(options, handler) {
  /* Show open tooltip */
  const { triggerOpen = false, delay = 1000 } = options;
  if (triggerOpen) {
    setTimeout(() => {
      handler();
    }, delay);
  }
}

export default function manageTooltip(el, binding, mode, type) {
  if (!(binding.value instanceof Object)) return;
  const {
    value: { event, options },
  } = binding;
  if (mode === 'set' && !listEvent.has(el)) {
    const handler = type === 'text' ? createTooltipText(el, options) : createTooltipComp(el, options);
    if (event !== '') {
      el.addEventListener(event, handler, false);
      listEvent.set(el, handler);
    }
    statusTooltip(options, handler);
  } else if (mode === 'remove') {
    const handler = listEvent.get(el);
    if (listTooltip.has(el)) {
      const instance = listTooltip.get(el);
      instance.hide();
    }
    if (event !== '') {
      el.removeEventListener(event, handler, false);
      listEvent.delete(el);
    }
  }
}

