import { camelize } from '~/helpers/camelize';
import normalize from 'json-api-normalize';

export function creditCardsTransforms(responseData) {
  let normalized = camelize(normalize(responseData).get(['last_four', 'id']));
  normalized.forEach((item, index) => {
    item.type = responseData.data[index].attributes.type;
    item.id = item.id.toString();
  });
  return normalized;
}
