//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { CART_TYPES } from '~/constants/cart-actions';
import MqAddCart from '~/components/elements/mq-add-cart';

export default {
  name: 'MqProductFinder',
  components: { MqAddCart },
  props: {
    product: { type: Object, default: () => {} },
    pumLimit: { type: Number, default: () => 23 },
    cartInstanceType: { type: String, default: CART_TYPES.DEFAULT },
    position: { type: Number, default: 0 },
    textSearched: {
      type: String,
      default: 'N/A',
    },
  },
  data() {
    return {
      removing: false,
      adding: false,
      added: false,
      count: 0,
    };
  },
  computed: {
    ...mapGetters({
      store: 'home/getCurrentStore',
      city: 'home/getCity',
    }),
  },

  methods: {
    resolveAction() {
      const { textSearched } = this;
      if (this.cartInstanceType === CART_TYPES.DEFAULT) {
        const { city, store, product } = this;
        this.$store.commit('SET_FINDER', false);
        this.$router.push(
          `/${city.slug}/${store.slug}/${product.department.slug}/${product.shelf.slug}/${product.slug}?search=${textSearched}`,
        );
      }
    },
  },
};
