import $axios from '~/services/global-axios';

export async function getUserCreditsEndpoint(countryCode) {
  try {
    const response = await $axios.get(`/3.1/users/credits-history?country_code=${countryCode}`);
    return { response, error: null };
  } catch (error) {
    return { response: null, error };
  }
}
