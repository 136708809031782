//
//
//
//
//
//

export default {
  name: 'MqBacktotop',
  props: ['position'],
  data: function() {
    return {
      visible: false,
    };
  },
  mounted: function() {
    this.$nextTick(function() {
      this.topButton();
    });
  },
  methods: {
    topButton: function() {
      $(document).bind(
        'scroll',
        function() {
          let backToTopButton = $('.backtotop');
          if ($(document).scrollTop() > 250) {
            backToTopButton.addClass('visible');
            this.visible = true;
          } else {
            backToTopButton.removeClass('visible');
            this.visible = false;
          }
        }.bind(this),
      );
    },
    backToTop: function() {
      $('html,body')
        .stop()
        .animate(
          {
            scrollTop: 0,
          },
          'slow',
          'swing',
        );
    },
  },
};
