import UIUtils from './ui-utils';
import { getScreenMode } from '~/helpers/screen-mode';

var oldAddEvent = window.addEventListener;
window.addEventListener = function (...arg) {
  // sentry
  if (arg[0] !== 'resize') {
    // evita el registro en el evento para no afectar el perfomance
    oldAddEvent(...arg);
  }
};

export function safeEventSubscribe(...arg) {
  oldAddEvent(...arg);
}

export function safeEventUnsubscribe(...arg) {
  window.removeEventListener(...arg);
}

export default ({ app, store }) => {
  let currentRoute = null;
  if (process.browser) {
    app.router.beforeEach((to, from, next) => {
      currentRoute = to.name;
      if (from.name == 'checkout-success' && store.state.ui.widgets.surveyModal) {
        next(false);
      } else {
        next();
      }
    });

    window.addEventListener(`scroll`, async () => {
      if (document.querySelector('.mq-header--mobile__nav') && currentRoute === 'city-store') {
        const mqHeaderMobileNav = document.querySelector('.mq-header--mobile__nav').getBoundingClientRect();
        const topMqHeaderMobile = mqHeaderMobileNav.top + mqHeaderMobileNav.height;
        const mqHeaderSearchMobile = document.querySelector('.mq-header__search--mobile');
        if (mqHeaderSearchMobile && mqHeaderSearchMobile.getBoundingClientRect().top <= parseInt(topMqHeaderMobile)) {
          await store.dispatch('home/setTitleStore', true);
        } else {
          await store.dispatch('home/setTitleStore', false);
        }
      }
      if (currentRoute === 'city-store-department' && document.querySelector('.mq-header--mobile--STORE')) {
        const headerMobileStore = document.querySelector('.mq-header--mobile--STORE').getBoundingClientRect().height;
        const searchMobile = document.querySelector('.mq-header__search--mobile').getBoundingClientRect();
        const positionToFixedTabsOnY = headerMobileStore + searchMobile.height;
        document.documentElement.style.setProperty('--tabsFakeTopFixed', `${positionToFixedTabsOnY}px`);
        return false;
      }
      if (UIUtils.stackOverflow) return false; // bloquea navup si hay ventanas flotantes
    });

    window.onload = () => {
      setTimeout(() => {
        UIUtils.recalculateCssProperties();
      }, 50); //time debounce to get correct changes
    };

    let resizeTimeOut = null;
    const onResizeChanges = () => {
      clearTimeout(resizeTimeOut);
      updateScreenMode(store);
      resizeTimeOut = setTimeout(() => {
        UIUtils.recalculateCssProperties();
      }, 50); //time debounce to get correct changes
    };
    safeEventSubscribe('resize', onResizeChanges);
    safeEventSubscribe('scroll', onResizeChanges);
  }
};

/**
 * Update screen mode
 */
async function updateScreenMode(store) {
  const { dispatch, state } = store;
  const {
    ui: { screenMode },
  } = state;
  const newScreenMode = getScreenMode();
  if (screenMode !== newScreenMode) {
    await dispatch('CHANGE_SCREEN_MODE', getScreenMode());
  }
  UIUtils.recalculateCssProperties();
}
