//
//
//

export default {
  name: 'MqTooltipText',
  props: {
    text: {
      type: String,
      require: true,
    },
    styleEl: {
      type: Object,
      require: true,
    },
  },
};
