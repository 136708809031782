//
//
//
//
//
//
//
//
//

export default {
  name: 'MqLabels',
  props: ['title', 'img'],
};
