
import $axios from '../../services/global-axios';
//import data_mock from '~/tests/__Mocks__/brandroom/list-brandroom';

export async function getBrandRoomEndPoint(storeId, wareHouseId) {
  try {
    const baseUrl = process.env.baseApi;
    const response = await fetch(`${baseUrl}/4.0/brandroom/store/${storeId}/warehouse/${wareHouseId}`);
    if (response && response.ok) {
      return [null, response];
    } else {
      const error = new Error(response.status);
      return [error, null];
    }
  } catch (error) {
    return [error, null];
  }
}
