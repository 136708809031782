import { getCreditCards } from '~/endpoints/helpCenter/get-credit-cards-endpoint';
import { creditCardsTransforms } from '~/transforms/helpCenter/get-credit-cards-transform';
const getCreditCardsUseCase = async orderId => {
  const { response, error } = await getCreditCards(orderId);
  if (error === null && response) {
    const creditCards = await creditCardsTransforms(response);
    return { data: creditCards, error: null };
  } else if (
    error &&
    error.response instanceof Object &&
    error.response.data instanceof Object &&
    error.response.data.errors instanceof Array
  ) {
    return { data: null, error: error.response.data.errors[0] };
  } else {
    return {};
  }
};
export default getCreditCardsUseCase;
