/**
 * @param {number} storeId
 * @param {URLSearchParams} searchParams
 */
export async function getBannersNewServiceEndpoint({ storeId, adq, department_id, platform, warehouseId }) {
  try {
    let endPoint = '';
    if (department_id) endPoint += `/department/${department_id}`;
    if (warehouseId) endPoint += `/warehouse/${warehouseId}`;
    if (adq || adq === 0) endPoint += `/adq/${adq}`;
    if (platform) endPoint += `/os/${platform}`;
    const baseUrl = process.env.baseApi;
    const response = await fetch(`${baseUrl}/4.0/featured/store/${storeId}${endPoint}`);
    if (response?.ok) {
      return { response, error: null };
    } else {
      const error = new Error(response.status);
      return { response: null, error };
    }
  } catch (error) {
    return { response: null, error };
  }
}

/**
 * @param {number} storeId
 * @param {URLSearchParams} searchParams
 */
export async function getBannersEndpoint({ storeId, zoneId, departmentId, isAcquisition, platform = 'web' }) {
  try {
    const searchParams = new URLSearchParams();
    if (typeof zoneId === 'number') {
      searchParams.append('zoneId', zoneId);
    }
    if (typeof departmentId === 'number') {
      searchParams.append('department_id', departmentId);
    }
    searchParams.append('platform', platform);
    searchParams.append('adq', isAcquisition);
    const baseUrl = process.env.baseApi;
    const response = await fetch(`${baseUrl}/3.1/stores/${storeId}/banners?${searchParams.toString()}`);
    if (response?.ok) {
      return { response, error: null };
    } else {
      const error = new Error(response.status);
      return { response: null, error };
    }
  } catch (error) {
    return { response: null, error };
  }
}
