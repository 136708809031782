import { getCitiesEndpoint } from '~/endpoints/cities/get-cities-endpoint';
import { citiesMainAndNeighborhoodTransform } from '~/transforms/cities/cities-main-and-neighborhood-transform';

export async function getCitiesMainAndNeighborhoodUseCase(countryId) {
  const { error, response } = await getCitiesEndpoint(countryId);
  if (error === null && response.status === 200) {
    return { error: null, data: citiesMainAndNeighborhoodTransform(response.data) };
  }
  return { error, data: null };
}

