//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MqSelect',
  props: {
    theme: { type: String, default: '' },
    label: { type: String, default: '' },
    value: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      openOptions: false,
    };
  },
  watch: {
    value() {
      this.openOptions = false;
    },
  },
  methods: {
    toggleOptions() {
      if (!this.disabled) {
        this.openOptions = !this.openOptions;
      }
    },
  },
};
