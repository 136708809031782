import { Store } from 'vuex';
import { getProductByIdService } from '~/services/products/get-product-by-id-service';
import { getPathShelf } from './get-path-shelf';
import { IQuery } from './get-store-slug';

async function getProductById(storeId: number, productId: number) {
  const { data, error } = await getProductByIdService(storeId, productId);
  if (error === null) {
    return data;
  }
  return {};
}

export async function getPathProduct(store: Store<any>, { query }: IQuery) {
  const storeId = parseInt(query.store_id, 10);
  const productId = parseInt(query.product_id, 10);
  const shelfPath = await getPathShelf(store, { query });
  const product = await getProductById(storeId, productId);
  const productSlug = product?.slug ?? '';

  return shelfPath && productSlug ? `${shelfPath}/${productSlug}` : shelfPath;
}

