import { Store } from "vuex";
import { Strategy } from "@/helpers/deeplinks/strategy";
import { IQuery, ICity } from "@/helpers/deeplinks/utils/get-store-slug";
import { DEEP_LINKS_NAMES } from '~/helpers/deeplinks/constants/deeplinks';

export const strategySearchSchemeDeepLink = (
  store: Store<any>,
  route: IQuery,
  city: ICity
) =>
  new Strategy(DEEP_LINKS_NAMES.searchScheme, async () => {
    const { q: param } = route.query;
    const { slug: StoreSlug } = store.getters["home/getDefaultStore"];
    return `/${city?.slug}/${StoreSlug}/search/${param}`;
  });
