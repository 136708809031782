//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MqCheckbox',
  props: {
    theme: { type: String, default: 'material' },
    label: String,
    value: String,
    val: String,
    check: { type: String },
    labelFurther: String,
    htmlText: { type: String, default: null },
    name: String,
  },
  computed: {
    selectValue: {
      get() {
        return this.value;
      },
      set() {},
    },
    setAddress() {
      if (/,/.test(this.val)) {
        const addressSplited = this.val?.split(',');
        addressSplited?.splice(addressSplited.length - 2, 2);
        return addressSplited?.join();
      }
      return this.val;
    },
  },
  methods: {
    setValue() {
      this.$emit('change', this.val);
    },
  },
};
