import $axios from '~/services/global-axios';

export async function UsermeEndpoint(params) {
  try {
    const response = await $axios.get(`/3.0/users/me${params}`);
    return { response, error: null };
  } catch (error) {
    return { response: null, error };
  }
}
