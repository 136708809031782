const state = () => ({
  locales: ['es-CO', 'es-MX', 'pt-BR'],
  locale: process.env.lang,
});

const actions = {
  setLang({ commit }) {
    commit('SET_LANG', 'es-CO');
  },
};

const mutations = {
  SET_LANG(state, locale) {
    if (state.locales.indexOf(locale) !== -1) {
      state.locale = locale;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
