//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MqLogo',
  props: {
    color: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
  },
  computed: {
    pathToHome() {
      return '/';
    },
  },
  methods: {
    goHome() {
      this.$nuxt.$emit('closeDropdown');
      if (window.location.pathname !== '/') {
        this.$store.commit('SET_LOADING', true);
      }
    },
  },
};
