import { productServicesBase } from '~/../env.local';
import { error280Transforms } from '~/transforms/errors/error-transform';
import { camelize } from '~/helpers/camelize';
import { nuxtInstance } from '~/plugins/nuxt-instance';
import { generateRequestHeaders } from '~/helpers/fetch-request-headers';

export async function getStatusPrimeEndpoint() {
  const {
    store: { state },
  } = nuxtInstance;
  const {
    user: { token },
  } = state;

  const requestHeaders = generateRequestHeaders();
  requestHeaders.delete('X-Merqueo-Version');
  requestHeaders.set('Authorization', `Bearer ${token}`);
  try {
    const response = await fetch(`${productServicesBase}/active-services/v1`, {
      headers: requestHeaders,
    });
    return { response, error: null };
  } catch (error) {
    return { response: null, error };
  }
}

export async function getStatusPrimeService() {
  const { response, error } = await getStatusPrimeEndpoint();
  if (response?.ok && response.status === 200) {
    const jsonData = await response.json();
    const {
      data: { attributes },
    } = jsonData;
    return { data: camelize(attributes), error };
  } else if (response?.ok && response.status === 280) {
    return { data: null, error: error280Transforms(response) };
  }

  return { data: null, error: new Error('Request error') };
}

export async function getStatusPrimeUseCase() {
  const { data, error } = await getStatusPrimeService();
  if (data instanceof Object) {
    const {
      store: { getters },
    } = nuxtInstance;
    const { plan, expirationDate, hasTrial } = data;
    const { period, price, originalPrice } = plan;
    const keyMonth = period === 1 ? 'common_label_month' : 'common_label_months';
    const dateRenewal = new Date(expirationDate);
    const options1 = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'UTC',
    };
    const options2 = {
      ...options1,
      month: 'long',
    };
    const currentLocale = getters['home/getCurrentLocale'];
    const titleVal = `${period} ${nuxtInstance?.app?.i18n?.t(keyMonth)}`.toLowerCase();
    const titleFree = nuxtInstance?.app?.i18n?.t('prime_title_free_trial');
    return {
      data: {
        ...data,
        plan: {
          ...plan,
          title: hasTrial ? titleFree : `${period} ${nuxtInstance?.app?.i18n?.t(keyMonth)}`.toLowerCase(),
          renewal: new Intl.DateTimeFormat(currentLocale, options1).format(dateRenewal).replace(/\//g, '-'),
          renewalExtensive: new Intl.DateTimeFormat(currentLocale, options2).format(dateRenewal),
          validity: titleVal,
          perMonth: +price / period,
          priceDiff: originalPrice ? +originalPrice - +price : originalPrice,
          term: `${period} ${nuxtInstance?.app?.i18n?.t(keyMonth)}`.toLowerCase(),
        },
        showFooter: hasTrial,
      },
      error,
    };
  }
  return { data: null, error };
}

