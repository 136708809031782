import * as Sentry from '@sentry/browser';

export default function (context) {
  const { store, route } = context;
  let user = store.state.user;
  let userIsLogged = 'id' in user.user;

  const user_id = userIsLogged ? user.user.id : null;
  const email = userIsLogged ? user.user.email : null;

  // Set user information, as well as tags and further extras
  Sentry.configureScope((scope) => {
    const isUserInScope = 'id' in scope._user;
    if (userIsLogged && !isUserInScope) {
      scope.setUser({ id: user_id, email: email });
    } else if (!userIsLogged && isUserInScope) {
      scope.clear();
    }
  });
  setTimeout(async () => {
    let { dispatch } = store;
    if (!userIsLogged) {
      if (
        route instanceof Object &&
        route.name !== 'login' &&
        route.name !== 'sign-up' &&
        route.name !== 'sign-up-success' &&
        route.name !== 'password-recovery' &&
        route.name !== 'reset-password-token'
      ) {
        store.dispatch('home/setPreviusPath', route.fullPath);
      }

      switch (route.name) {
        case 'city-productos-comprados':
          store.$router.push('/');
          break;
        case 'checkout':
        case 'checkout-payment':
        case 'checkout-success':
          store.commit('user/SET_IS_LOGIN_BY', 'checkout');
          store.$router.push({ path: '/login' });
          break;
        case 'my-account':
        case 'my-account-your-data':
        case 'my-account-orders':
        case 'my-account-address':
        case 'my-account-payment-methods':
        case 'my-account-credits':
        case 'my-account-advertising':
        case 'prime':
        case 'prime-payment':
        case 'prime-payment-add-card':
        case 'prime-payment-billing-data':
        case 'prime-success':
          store.$router.push('/login');
          break;

        default:
          break;
      }
    } else {
      const beforeRouteLogin = store.state.home.beforeRouteLogin;
      const addressObject = store.state.address.address_object;

      //Si retorna string, no es la 1ra vez que se ingresa
      const isFirstEnterToApp = typeof (sessionStorage.getItem('isFirstEnterToApp') ?? true) === 'boolean';
      if (isFirstEnterToApp) {
        await store.dispatch('home/validatePrivacyPolicy', 'firstEnterApp');
      }
      const mustAcceptNewPolicy = sessionStorage.getItem('mustAcceptNewPolicy') === 'true';
      if (mustAcceptNewPolicy) {
        store.dispatch('LOAD_MODAL_WIDGET', ['changePrivacyPoliciesModal', true]);
      }

      if (
        userIsLogged &&
        beforeRouteLogin &&
        !addressObject.zoneId &&
        route.name !== 'sign-up-success' &&
        !mustAcceptNewPolicy
      ) {
        dispatch('LOAD_MODAL_WIDGET', ['addressWidgetModal', true]);
      }
    }
  }, 1000);
}
