/**
 *
 * @param {number} storeId
 * @param {number} bannerId,
 * @param {number} zoneId,
 */
export async function getBannerDetailsEndpoint({ storeId, bannerId, zoneId }) {
  try {
    const baseUrl = process.env.baseApi;
    const response = await fetch(`${baseUrl}/3.1/stores/${storeId}/banners/${bannerId}?zoneId=${zoneId}`);
    if (response.ok) return { response, error: null };
    else {
      return { response: null, error: response };
    }
  } catch (error) {
    return { response: null, error };
  }
}
