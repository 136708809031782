import { onLoad } from '~/helpers/onLoad';

export default () => {
  onLoad(() => {
    const script = document.createElement('script');
    script.defer = true;
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/bodymovin/5.6.4/lottie.js';
    document.head.appendChild(script);
  }, 100);
};
