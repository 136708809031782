import $axios from '~/services/global-axios';

export async function getCreditCards(orderId) {
  try {
    let response = await $axios.$get(`/3.0/help-center/payment-methods/user-credit-cards/${orderId}`);
    return { response, error: null };
  } catch (error) {
    return { response: null, error };
  }
}
