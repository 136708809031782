import { required } from '~/decorators/required';
import { scheme } from '~/decorators/scheme';

@scheme()
export class PaymentMethodsParams {
  @required()
  zoneId: number;

  constructor(params: Partial<PaymentMethodsParams>) {
    Object.assign(this, params);
  }
}
