import Cart from '~/models/cart';
import { CART_ACTIONS } from '~/constants/cart-actions';

export function addBagTax(cart: Cart, action: string) {
  const countBagTax = cart.countBagTax();
  const totalProducts = cart.sum((cart) => { return cart.getCount(); });

  const totalProductWithoutBagTax = totalProducts - countBagTax;
  const bagTaxProduct = cart.getBagTaxProduct();

  if (action === CART_ACTIONS.ADD_PRODUCT_ACTION && totalProductWithoutBagTax >= 10 && countBagTax < 2 && bagTaxProduct) {
    bagTaxProduct.addProduct();
  }

  if ((action === CART_ACTIONS.REMOVE_PRODUCT_ACTION && totalProductWithoutBagTax < 10 && countBagTax === 2 && bagTaxProduct) || (countBagTax > 2 && bagTaxProduct)) {
    bagTaxProduct.removeProduct();
  }

  if (totalProductWithoutBagTax === 0) {
      cart.clear();
  }
}
