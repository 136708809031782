import { pushAlertUseCase } from '~/usecases/alerts/push-alert-usecase';
import { CART_ACTIONS } from '~/constants/cart-actions';
import { CAMPAIGNS_ALERT_ACTION } from '~/constants/campaigns-alert';
import { campaignsAlertEndpoint } from '~/endpoints/campaigns/campaigns-alert-endpoint';
import { AlertInterface, campaignsTransforms } from '~/transforms/campaigns/campaigns-alert-transform';
import { nuxtInstance } from '~/plugins/nuxt-instance';
import Cart from '~/models/cart';
import Product from '~/models/product';
import CartProduct from '~/models/cart_product';

interface AlertStateInterface {
  alerts: AlertInterface | undefined;
  searched: boolean;
  goalShow: boolean;
}

export const alertState: AlertStateInterface = {
  alerts: undefined,
  searched: false,
  goalShow: false,
};

/**
 * Logic to calculate total of product add to card with the campaign
 * @param {Cart} cart
 * @param {Product} product
 * @param {string} action
 */
export async function campaignHandlerUsecase(cart: Cart, product: Product, action: string) {
  const alert = alertState.alerts;
  // @ts-ignore
  if (!(product.tag instanceof Object) || alert === undefined) {
    return;
  }
  const campaignAmount = alert.minimumAmount;
  const total = calculateCampaignQuantityUsecase(cart);
  const remaining = campaignAmount - total;
  const progress = (total / campaignAmount) * 100;
  const notifyAction = action === CART_ACTIONS.ADD_PRODUCT_ACTION ? 'positive' : 'negative';
  if (remaining > 0) {
    alertState.goalShow = false;
    const totalProductsCampaign = calculateTotalProductsCampaign(cart);
    if (totalProductsCampaign === 1 && action === CART_ACTIONS.ADD_PRODUCT_ACTION) {
      const {
        store: { dispatch },
      } = nuxtInstance;
      const modalParams = {
        title: alert.initialTitle,
        description: alert.initialDescription,
        buttonText: alert.initialButtonText,
      };
      dispatch('LOAD_MODAL_CONTENT', modalParams);
      dispatch('CALLBACK_CLOSE_WIDGET', {
        widget: 'campaignModal',
        callback: () => {
          notify(notifyAction, remaining > 0 ? remaining : 0, progress > 100 ? 100 : progress, alert.progressbarText);
        },
      });
      dispatch('LOAD_MODAL_WIDGET', ['campaignModal', true]);
    } else {
      notify(notifyAction, remaining > 0 ? remaining : 0, progress > 100 ? 100 : progress, alert.progressbarText);
    }
  } else if (remaining <= 0 && action === CART_ACTIONS.ADD_PRODUCT_ACTION && !alertState.goalShow) {
    alertState.goalShow = true;
    notify(notifyAction, remaining > 0 ? remaining : 0, progress > 100 ? 100 : progress, alert.progressbarText);
    const {
      store: { dispatch },
    } = nuxtInstance;
    const modalParams = {
      title: alert.finalTitle,
      description: alert.finalDescription,
      buttonText: alert.finalButtonText,
      image: require('~/assets/img/campaigns-image.svg'),
    };
    dispatch('LOAD_MODAL_CONTENT', modalParams);
    dispatch('LOAD_MODAL_WIDGET', ['campaignModal', true]);
  }
}

/**
 * Logic to calculate total of product add to card with the campaign
 * @param {Cart} cart
 */
export function calculateCampaignQuantityUsecase(cart: Cart) {
  return cart.filter(productWithCampaign).reduce((p, cartProduct) => {
    return p + cartProduct.totalPriceProduct();
  }, 0);
}

/**
 * Logic to calculate total of product add to card with the campaign
 * @param {Cart} cart
 */
function calculateTotalProductsCampaign(cart: Cart) {
  return cart.filter(productWithCampaign).reduce((p, cartProduct) => p + cartProduct.getCount(), 0);
}

/**
 * Filter to verify if is part of a campaign
 * @param {CartProduct} cartProduct
 */
function productWithCampaign(cartProduct: CartProduct) {
  // @ts-ignore
  return cartProduct.product.tag instanceof Object;
}

export async function updateAlertDataUsecase(storeId: number) {
  const [err, response] = await campaignsAlertEndpoint(storeId);
  if (!err && response.status === 200) {
    alertState.alerts = campaignsTransforms(response.data);
  } else if (err) {
    alertState.alerts = undefined;
  }
  alertState.searched = true;
}

/**
 * Notify with progress alert remaining amount to complete campaign
 * @param {'positive'|'negative'} alertType
 * @param {number} quantity
 * @param {number} progress
 * @param {string} progressBarText
 */
async function notify(alertType: 'positive' | 'negative', quantity: number, progress: number, progressBarText: string) {
  await pushAlertUseCase(
    {
      component: () => import('~/components/elements/mq-campaigns-alert.vue'),
      props: {
        quantity: quantity,
        progressWidth: progress,
        typeAction: alertType,
        progressBarText: progressBarText,
      },
    },
    2500,
  );
}
