import Leanplum from 'leanplum-sdk';

import Event from './leanplum';

export function leanplum(name) {
  return class LeanplumEvent extends Event {
    constructor(store) {
      // eslint-disable-next-line
      super(Leanplum, name, store);
    }
  };
}
