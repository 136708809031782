//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex';
import productResponsive from '~/assets/json/res-product-hall-carousel';
import SkCard from '~/components/skeleton/sk-card';
import MqMessageShelf from '~/components/elements/mq-message-shelf';
import MqVueCarousel from '~/components/elements/mq-vue-carousel';
import { nextButton, prevButton } from '~/assets/json/config-slider-brand-room';
import configCarousel from '~/mixins/configCarouselEvent';

export default {
  name: 'MqProductHall',
  mixins: [configCarousel],
  components: { MqMessageShelf, SkCard, MqVueCarousel },
  props: {
    id: {
      type: String,
      required: true,
    },
    products: {
      type: Array,
      default: null,
    },
    department: {
      type: Object,
      default: null,
    },
    showUploadApp: { type: Boolean },
    screen: {
      type: String,
      default: null,
    },
    productParent: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      responsive: productResponsive(),
      nextLabel: nextButton,
      prevLabel: prevButton,
    };
  },
  computed: {
    ...mapState({
      tooltipProductId: (state) => state.product.tooltipProductIdActive,
    }),
    ...mapGetters({
      store: 'home/getCurrentStore',
      city: 'home/getCity',
    }),
    ...mapState({
      screenMode: (state) => state.ui.screenMode,
    }),
    someProductHaveWrapTag() {
      const filterForWrapTag = this.products.filter((product) => product.tag || product.deliveryDiscountAmount);
      return filterForWrapTag.length > 0;
    },
  },
  methods: {
    getCallEvent(data) {
      return () => {
        const { screen } = this;
        if (screen !== 'productDetailSponsoredList') return;
        const { item: product, position } = data;
        const price = product.specialPrice ? product.specialPrice : product.price;
        this.$events.productViewedSponsored.track({
          departmentId: product.department instanceof Object ? product.department.id : 0,
          shelfId: product.shelf instanceof Object ? product.shelf.id : 0,
          name: product.name,
          price,
          bestPriceGuaranteed: product.isBestPrice ? 1 : 0,
          position,
          storeProductIdParent: this.productParent?.id,
          storeProductId: product.id,
          sponsored: product.sponsored ? 1 : 0,
          screen,
          textSearched: 'N/A',
        });
      };
    },
    goToDepartment() {
      const { commit } = this.$store;
      commit('SET_LOADING', true);
      commit('events/SET_DEPARTMENT_SOURCE', 'store');
    },
    getOptionsCarousel() {
      return {
        rootMargin: '0px 0px 0px 0px',
        threshold: [0, 0.25, 0.5, 0.75, 1],
      };
    },
  },
};
