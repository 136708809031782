import Vue from 'vue';
import mqToast from './functional/mq-toast';

Vue.prototype.$mqToast = (messagge, icon, type, delay = 3000) => {
  const id = new Date().getTime();

  new Vue({
    el: document.getElementById('mq-alerts'),
    render(h) {
      return h(mqToast, {
        props: {
          id,
          type,
          messagge,
          icon,
          delay,
        },
      });
    },
  });
};
