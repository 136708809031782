import { camelize } from '~/helpers/camelize';
import normalize from 'json-api-normalize';

export function creditsTransforms(responseData) {
  const normalizeCredits = normalize(responseData).get([
    'expiration_day',
    'current_amount',
    'initial_amount',
    'message_code',
    'extra',
  ]);
  return camelize(normalizeCredits);
}

export function totalCreditsTransforms(responseData) {
  const normalizeTotalCredits = normalize(responseData).get(['available']);
  return camelize(normalizeTotalCredits);
}
