import { camelize } from '~/helpers/camelize';
import { MAP_COUNTRIES_KEYS } from '~/constants/countries-i18n-keys';
import { nuxtInstance } from '~/plugins/nuxt-instance';
import { findElementInMapObject } from '~/helpers/find-element-in-map-object';
import normalize from 'json-api-normalize';

export function getCountriesTransform(responseData) {
  return normalize(responseData)
    .get([
      'type',
      'id',
      'name',
      'code',
      'language',
      'phone_code',
      'url_domain',
      'decimal_amount',
      'url_country_flag',
      'mainCity',
      'neighborhoods',
      'parentCities',
    ])
    .map(country => {
      return {
        ...camelize(country),
        get countryName() {
          return nuxtInstance?.app?.i18n?.t(findElementInMapObject(MAP_COUNTRIES_KEYS, country.name));
        },
      };
    });
}
