import { getFavoritesEndpoint } from '~/endpoints/favorites/get-favorites-endpoint';
import { getFavoritesHighlightsEndpoint } from '~/endpoints/favorites/get-favorites-highlights-endpoint';
import { productTransforms } from '~/transforms/products/products-transform';

export async function getFavoritesUseCase({ isHighlights = false, zoneId, storeId }) {
  const { error, response } = isHighlights
    ? await getFavoritesHighlightsEndpoint({ zoneId, storeId })
    : await getFavoritesEndpoint({ zoneId, storeId });

  if (error === null && response.status === 200) {
    return { error: null, data: productTransforms(response.data) };
  }
  return { error, response: null };
}

