import { getPaymentMethods } from '~/endpoints/helpCenter/list-payment-methods';
import { paymentMethodsTransforms } from '~/transforms/helpCenter/get-payment-methods-transform';

const getPaymentMethodsUseCase = async orderId => {
  const { response, error } = await getPaymentMethods(orderId);
  if (error === null && response) {
    const PaymentMethods = await paymentMethodsTransforms(response);
    return { data: PaymentMethods, error: null };
  } else if (
    error &&
    error.response instanceof Object &&
    error.response.data instanceof Object &&
    error.response.data.errors instanceof Array
  ) {
    return { data: null, error: error.response.data.errors[0] };
  } else {
    return {};
  }
};
export default getPaymentMethodsUseCase;
