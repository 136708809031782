//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import { disableBodyScroll, enableBodyScroll } from '~/plugins/scroll-lock';

export default {
  name: 'MqModalTemplate',
  props: {
    open: Boolean,
    theme: {
      type: String,
      default: 'mq-default shadow-0 hoverable',
    },
    id: {
      type: String,
      required: true,
    },
    nameClass: {
      type: Array,
      default: () => [],
    },
    showCloseIcon: {
      type: Boolean,
      default: true,
    },
    preventClose: {
      type: Boolean,
      default: false,
    },
    tracking: {
      required: true,
      type: Function,
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      headerHidden: false,
    };
  },
  computed: {
    ...mapState({
      addressWidgetModal: (state) => state.ui.widgets.addressWidgetModal,
    }),
  },
  watch: {
    open() {
      this.checkBodyScroll();
    },
  },
  mounted() {
    this.checkBodyScroll();
  },
  beforeDestroy() {
    enableBodyScroll(this.$refs.modalBody);
  },
  created() {
    this.$nuxt.$on('hc-finder-typing', (value) => {
      this.headerHidden = value;
    });
  },
  methods: {
    toggleModal() {
      if (this.preventClose) {
        this.$nuxt.$emit('attempt-to-close-modal');
      }
      this.tracking('CLOSE');
    },
    checkBodyScroll() {
      if (!this.isScrollable(this.$refs.modalBody)) return;
      if (this.open) {
        disableBodyScroll(this.$refs.modalBody);
      } else {
        enableBodyScroll(this.$refs.modalBody);
      }
    },
    isScrollable(element) {
      if (element instanceof HTMLElement) {
        return element.scrollHeight > element.clientHeight;
      }
    },
  },
};
