import { ValidateTotals } from '~/endpoints/checkout/validate-totals-endpoints';
import { ValidateTotalsParams } from '~/schemes/validate-totals-endpoints-scheme';
import { validateTotalsTransforms } from '~/transforms/checkout/validate-totals-transform';
import { errorTransforms } from '~/transforms/errors/error-transform';

export async function getValidateTotalsUsecase(params: ValidateTotalsParams) {
  /* This is a way to solve ts error:
  Element implicitly has an 'any' type because index expression is not of type 'number'.ts(7015)
  reference found solution: https://newbedev.com/typescript-ts7015-element-implicitly-has-an-any-type-because-index-expression-is-not-of-type-number
  👇🏻 */
  // TODO: esto se debe quitar y manejarse como un evento personalizado
  let fbTotalOrder = (window as { [key: string]: any }).fbTotalOrder as string;

  const { response, error } = await ValidateTotals.getValidateTotalsEndpoint(new ValidateTotalsParams(params));
  if (error === null && response.status === 200) {
    const products: any = await validateTotalsTransforms(response.data);
    fbTotalOrder = products.total;
    return { data: products, error: null };
  } else if (error === null && response.status === 280) {
    return { data: null, error: response?.data?.errors };
  }
  return { data: null, error: [errorTransforms(error)] };
}
