export function camelize<K>(model: K): K {
  const copyModel = model as any;
  const camelModel: any = {};
  Object.keys(model).forEach((propName) => {
    const regex = /_([a-z0-9])/g;
    const camelName = propName.replace(regex, (g: string) => g[1].toUpperCase());
    if (copyModel[propName] instanceof Object || copyModel[propName] instanceof Array) {
      camelModel[camelName] = camelize(copyModel[propName]);
    } else if (propName === 'id' && !isNaN(copyModel[propName])) {
      camelModel[camelName] = parseInt(copyModel[propName]);
    } else {
      camelModel[camelName] = copyModel[propName];
    }
  });
  return model instanceof Array ? Object.values(camelModel) : (camelModel as any);
}
