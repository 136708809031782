/* vue query provider global server state */
import { useQueryProvider } from 'vue-query';

export default function ({ app }) {
  app.mixins = (app.mixins || []).concat({
    setup() {
      useQueryProvider();
    },
  });
}
