import createPersistedState from 'vuex-persistedstate';
import * as Sentry from '@sentry/browser';
import { memorySizeOf } from '~/helpers/memory-size-of';
import { tryParseJSON } from '~/helpers/try-parse-json';

const regexMutationsPermit = [/checkout\//];
let sentryReportFlag = false;

export function createCheckoutStorageState(store) {
  return createPersistedState({
    key: 'checkout',
    paths: ['checkout'],
    filter: (data) => {
      let isMutationPermit = false;
      if ('type' in data) {
        let currentMutation = data.type;
        regexMutationsPermit.forEach((element) => {
          if (element.exec(currentMutation)) {
            isMutationPermit = true;
          }
        });
      }
      return isMutationPermit;
    },
    getState(key, storage) {
      const storageState = storage.getItem(key);
      try {
        const state = JSON.parse(storageState);
        return state;
      } catch (_e) {
        return {};
      }
    },
    setState(key, state, storage) {
      try {
        const stateMappedString = JSON.stringify(state);
        storage.setItem(key, stateMappedString);
      } catch (error) {
        if (!sentryReportFlag) {
          const sizeOfMemoryStateToSave = memorySizeOf(state);
          const sizeOfMemoryStateOnState = memorySizeOf(tryParseJSON(storage.getItem(key)));
          Sentry.captureException(error, { extra: { sizeOfMemoryStateToSave, sizeOfMemoryStateOnState } });
          sentryReportFlag = true;
        }
      }
    },
    storage: window.localStorage,
  })(store);
}
