//import $axios from '~/services/global-axios';

/**
 *
 * @param {number} departmentId
 * @param {number} storeId
 */
export async function getShelvesByDepartmentEndpoint(departmentId, storeId, zoneId) {
  try {
    let zoneParam = '';
    if (zoneId) {
      zoneParam = `?zoneId=${zoneId}`;
    }
    const baseUrl = process.env.baseApi;
    const response = await fetch(`${baseUrl}/3.1/stores/${storeId}/department/${departmentId}/shelves${zoneParam}`);
    if (response.ok && response.status === 200) {
      return { response, error: null };
    } else if (response.ok && response.status !== 200) {
      const error = await response.json();
      return { response: null, error };
    } else {
      const error = new Error(response.status);
      return { response: null, error };
    }
  } catch (error) {
    return { response: null, error };
  }
}
