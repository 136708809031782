import moment from 'moment';
import { Event } from '../event';
import { nuxtInstance } from '~/plugins/nuxt-instance';

const env = require('~/../env.local');

let stackQuery = [];
let startTracking = false;

const botRegex = new RegExp(
  '(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)',
  'i',
);
const result = {
  bot: botRegex.test(navigator.userAgent),
  device: {
    type: (function (a) {
      return (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a,
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4),
        )
      );
    })(navigator.userAgent || navigator.vendor || window.opera)
      ? 'smartphone'
      : 'desktop',
  },
};

export class BigQueryEvent extends Event {
  constructor(store) {
    super();

    const HAS_TRUE = 1;
    const HAS_FALSE = 0;
    const webVersion = env.deployVersion;

    this.store = store;
    const { state, getters } = store;
    const { user } = state;
    const nameRoute = store.$router.history.current.name;
    const isLogin = 'id' in user.user ? HAS_TRUE : HAS_FALSE;
    const userId = isLogin ? user.user.id : null;

    const warehouseId = getters['home/getWarehouseId'];
    const zoneId = getters['home/getZoneId'];
    const storeId = nameRoute === 'index' ? 0 : getters['home/getCurrentStoreId'];
    const cityId = getters['home/getCityId'];
    const country = getters['home/getCurrentCountryCode'];
    const intentionalStore = getters['home/getIntentionalStore'];
    const marketplaceStore = intentionalStore ?? getters['home/getCurrentStore'];
    const storeType = nameRoute === 'index' ? 'Home' : marketplaceStore?.storeType ?? 'Home';

    let deliveryType = 'index';
    if (nameRoute === 'index' && intentionalStore === null) {
      deliveryType = 'Home';
    } else if (getters['home/isExpress'](marketplaceStore)) {
      deliveryType = 'Express';
    } else if (marketplaceStore) {
      deliveryType = 'Normal';
    }

    const hasOrdered = userId && user.user.totalOrders > 0 ? HAS_TRUE : HAS_FALSE;

    let typeDevice = null;
    const {
      config: { isHuaweiHMS },
    } = state;
    if (isHuaweiHMS) {
      typeDevice = 'mobile_huawei';
    } else if ('bot' in result && result.bot) {
      typeDevice = 'bot';
    } else if (result && result.device && result.device.type === 'smartphone') {
      typeDevice = 'mobile';
    } else if (result && result.device) {
      typeDevice = result.device.type;
    }

    this.baseData = {
      user: {
        id: userId,
        webVersion: webVersion ?? '3.0.0',
        deviceId: localStorage.getItem('__leanplum_device_id'),
        properties: {
          login: isLogin,
          warehouseId,
          zoneId,
          hasOrdered,
          platform: typeDevice,
          storeId,
          cityId,
          country,
          deliveryType,
          storeType,
        },
      },
      event: {
        name: null,
        date: moment().format('YYYY-MM-DD HH:mm:ss'),
        properties: {},
      },
    };
  }

  /**
   * @param data
   */
  track(data) {
    const allData = this.baseData;
    allData.event.properties = Object.assign({}, data);
    allData.event.name = this.name;
    return catchError(this.processEvent(allData));
  }

  /**
   * @param event
   * @param data
   */
  processEvent(data) {
    if (startTracking) {
      return this.store.dispatch('events/trackBigQuery', data);
    } else {
      stackQuery.push({
        event: this,
        data: data,
      });
    }
  }
}

function catchError(event) {
  new Promise((resolve) => {
    if (event instanceof Promise) {
      event.then((_data) => resolve()).catch((_err) => resolve());
    } else {
      resolve();
    }
  });
}

setTimeout(function () {
  const {
    store: { commit },
  } = nuxtInstance;
  enableTrack();
  commit('events/SET_IS_DEEPLINK', false);
}, 7000);

function enableTrack() {
  startTracking = true;
  stackQuery.forEach((event) => {
    event.event.processEvent(event.data);
  });
  stackQuery = [];
}
