import { getStoriesMainBrandroomServices } from '~/services/stories/main-brandroom-services';

// initial statew
const getDefaultState = () => {
  return {
    sctructureVerison: { stories: { lastVersion: 1, currentVersion: 0 } },
    channels: [],
    brandroomChannels: [],
    stories: [],
    storiesUpdated: [],
    storyIdInPlayer: null,
  };
};

const state = getDefaultState();

// getters
const getters = {
  getChannels(state, _getters, _rootState, _rootGetters) {
    return state.channels;
  },
  getStories(state, _getters, _rootState, rootGetters) {
    const storeId = rootGetters['home/getCurrentStoreId'];
    return state.stories.filter((storie) => storie.storeId === storeId);
  },
  getStoriesOnChannel: (state, _getters, _rootState, rootGetters) => (channelId) => {
    const { stories } = state;
    const storeId = rootGetters['home/getCurrentStoreId'];
    const storiesFiltered = stories.filter(
      (storie) => storie.storeId === storeId && storie.channelId.includes(channelId),
    );
    return storiesFiltered;
  },
  getStoryIdInPlayer(state) {
    return state.storyIdInPlayer;
  },
  getUniqueChannelFilterWithGlobal: (state) => (uniqueChannel) => {
    const channelMerge = state.channels.find((channel) => channel.id === uniqueChannel.id);
    return { ...channelMerge, ...uniqueChannel };
  },
};

// actions
const actions = {
  updateOrderChannels({ commit, state, rootGetters }) {
    const storeId = rootGetters['home/getCurrentStoreId'];
    commit('SET_ORDERED_CHANNELS', { channels: state.channels, storeId });
  },
  setStories({ commit, rootGetters }, payload) {
    const storeId = rootGetters['home/getCurrentStoreId'];
    commit('SET_STORIES', { ...payload, storeId });
  },
  readStorie({ commit, rootGetters }, { channelId, storyId }) {
    const storeId = rootGetters['home/getCurrentStoreId'];
    commit('READ_STORY', { channelId, storyId, storeId });
  },
  clearChannels({ commit }) {
    commit('CLEAR_CHANNELS');
  },
  setStoryIdInPlayer({ commit }, storyId) {
    commit('SET_STORY_ID_IN_PLAYER', storyId);
  },
  async getStoriesMainBrandroom({ rootGetters }, { brandroomId, channelId }) {
    const storeId = rootGetters['home/getCurrentStoreId'];
    const warehouseId = rootGetters['home/getWarehouseId'];
    const { data = [] } = await getStoriesMainBrandroomServices({ storeId, channelId, brandroomId, warehouseId });
    return data;
  },
};

const orderChannels = (channels) => {
  const findUnread = (channel) => !channel.storiesRead;
  const findRead = (channel) => channel.storiesRead;
  const orderByPosition = (channelA, channelB) => {
    const aPosition = typeof channelA.position === 'number' || 999999;
    const bPosition = typeof channelB.position === 'number' || 999999;
    return aPosition - bPosition;
  };

  const unreadChannels = channels.filter(findUnread).sort(orderByPosition);
  const readChannels = channels.filter(findRead).sort(orderByPosition);

  return [...unreadChannels, ...readChannels];
};

const orderStories = (stories) => {
  const orderByDate = (storyA, storyB) => new Date(storyA.startDate) - new Date(storyB.startDate);
  return stories.sort(orderByDate);
};

// functions
const useUpdateChannels = (oldChannels, newChannels, stories, storeId) => {
  const channelMap = new Map();
  [...newChannels].forEach((channel) => {
    const oldChannelValues = oldChannels.find((ch) => channel.id === ch.id);
    channelMap.set(channel.id, oldChannelValues instanceof Object ? { ...oldChannelValues, ...channel } : channel);
  });
  return useCheckAndUpdateChannelsRead([...channelMap.values()], stories, storeId);
};

const useUpdateStories = (oldStories, newStories) => {
  const otherStories = oldStories.filter((oldStorie) => {
    return !newStories.find((newStorie) => newStorie.storeId === oldStorie.storeId && oldStorie.id === newStorie.id);
  });

  const newStoriesMap = new Map();
  newStories.forEach((storie) => {
    const oldStorie = oldStories.find((s) => s.id === storie.id && s.storeId === storie.storeId);
    newStoriesMap.set(
      storie.id,
      oldStorie instanceof Object
        ? {
            ...storie,
            read: oldStorie.read,
            channelId: [...new Set([...oldStorie.channelId, storie.channelId[0]])],
          }
        : storie,
    );
  });
  return orderStories([...otherStories, ...newStoriesMap.values()]);
};

const useCheckAndUpdateChannelsRead = (channels, stories, storeId) => {
  channels.forEach((channel) => {
    const storiesByChannel = stories.filter(
      (storie) => storie.storeId === storeId && storie.channelId.includes(channel.id),
    );
    if (storiesByChannel.length) {
      channel.storiesRead = storiesByChannel.reduce((readFlag, storie) => readFlag && storie.read, true);
    } else {
      channel.storiesRead = false;
    }
  });
  return channels;
};
const isActiveStories = (stories) => {
  const activatedStories = [];
  stories.forEach((story) => {
    const validatedStory = validateActiveStorie(story);
    if (validatedStory) {
      activatedStories.push(validatedStory);
    }
  });
  return activatedStories;
};
const validateActiveStorie = (story) => {
  const { startDate, lifetimeMinutes } = story;
  const timeToExpire = new Date(startDate);
  timeToExpire.setMinutes(lifetimeMinutes);
  if (new Date() < timeToExpire) {
    return story;
  }
};

// mutations
const mutations = {
  SET_CHANNELS(state, newChannels) {
    state.channels = orderChannels(
      useUpdateChannels(
        state.channels,
        newChannels.map((channel) => ({ ...channel, storiesRead: false })),
        state.stories,
      ),
    );
  },

  SET_ORDERED_CHANNELS(state, { channels, storeId }) {
    state.channels = orderChannels(useCheckAndUpdateChannelsRead(channels, state.stories, storeId));
  },

  SET_STORY_ID_IN_PLAYER(state, storyId) {
    state.storyIdInPlayer = storyId;
  },
  SET_STORIES(state, { newStories, channelId, storeId }) {
    const oldStories = state.stories;
    const allStories = useUpdateStories(
      oldStories,
      newStories.map((storie) => ({
        ...storie,
        channelId: [channelId],
        storeId,
        read: false,
      })),
    );

    const orderedStoriesUpdated = allStories.sort((storyA, storyB) => storyB.read - storyA.read);
    state.stories = isActiveStories(orderedStoriesUpdated);
    state.channels = useUpdateChannels(state.channels, state.channels, state.stories, storeId);
  },

  READ_CHANNEL(state, { channelId, storeId }) {
    const channelCastRead = (channel) => ({
      ...channel,
      storiesRead: parseInt(channelId) === parseInt(channel.id) ? true : channel.storiesRead,
    });

    state.channels = useUpdateChannels(state.channels.map(channelCastRead), state.channels, state.stories, storeId);
  },

  READ_STORY(state, { channelId, storyId, storeId }) {
    const storieCastRead = (storie) => {
      const isTrue =
        storeId === storie.storeId && storie.channelId.includes(channelId) && storie.id === storyId
          ? true
          : storie.read;
      return {
        ...storie,
        read: isTrue,
      };
    };

    state.stories = state.stories.map(storieCastRead);
    state.channels = useUpdateChannels(state.channels, state.channels, state.stories, storeId);
  },

  CLEAR_CHANNELS(state) {
    state.channels = [];
  },
  SET_UPDATED_STORIES(state, stories) {
    state.storiesUpdated = [...state.storiesUpdated, ...stories];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
