import $ from 'jquery';
window['$'] = $;
window['JQuery'] = $;

import Vue from 'vue';
import VueRouter from 'vue-router';
import Cart from '../models/cart.js';
import CartProduct from '../models/cart_product';
import Product from '../models/product.js';
import { storeRedirectUseCase } from '~/usecases/stores/store-redirect-usecase.js';
import { orderDetailUsecase } from '~/usecases/orders/order-detail-usecase';
import { getImage } from '~/helpers/get-image';
import { REMOTE_CONFIG_PARAMS } from '~/constants/config/remote-cofig-params.js';

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.prototype.$marketplacePath = function () {
  const { getters } = this.$store;
  const city = getters['home/getCity'];
  const marketplaceStore = getters['home/getCurrentStore'];
  if (marketplaceStore instanceof Object && city instanceof Object) {
    return `/${city.slug}/${marketplaceStore.slug}`;
  }
  return '/';
};

Vue.prototype.$pathToRedirect = function () {
  const {
    home: { store, marketplaceStore },
  } = this.$store.state;
  if ('slug' in store && 'slug' in marketplaceStore) {
    return `/${store.slug}/${marketplaceStore.slug}`;
  }
  return '/';
};

Vue.prototype.$getImage = getImage;

Vue.prototype.$setCheckoutTemp = function (order) {
  let stateProducts = [];
  let cart = null;

  if (order.products?.data) {
    order.products.data.map((product) => {
      let cartProduct = new CartProduct();
      cartProduct.product = new Product(product.storeProduct.data);

      cart = new Cart(stateProducts, 'temp');

      for (let i = 0; i < product.quantity; i++) {
        const exist = cart.find((cartProduct) => cartProduct.product.id === product.orderProduct.id);
        if (typeof exist === 'undefined') {
          cartProduct.store = 'temp';
          cartProduct.addProduct();
          cart.add(cartProduct);
        } else {
          exist.addProduct();
        }
      }

      stateProducts = cart.models;
    });
  }

  if (order.products?.orderProducts) {
    order.products.orderProducts.forEach((product) => {
      let cartProduct = new CartProduct();
      cartProduct.product = new Product(product.storeProduct);
      cartProduct.product.detailProduct = product.detailProduct;
      cartProduct.product.imageSmallUrl = product.detailProduct.image;
      cartProduct.product.name = product.detailProduct.name;
      cartProduct.product.storeProduct = product.storeProduct;

      cart = new Cart(stateProducts, 'temp');

      for (let i = 0; i < product.quantityCart; i++) {
        const exist = cart.find((cartProduct) => {
          return cartProduct.product.id === product.storeProduct.id;
        });
        if (typeof exist === 'undefined') {
          cartProduct.store = 'temp';
          cartProduct.addProduct();
          cart.add(cartProduct);
        } else {
          exist.addProduct();
        }
      }

      stateProducts = cart.models;
    });
  }

  this.$store.commit('checkout/SET_TOTALS', {
    discount: order.discount,
    deliveryAmount: order.deliveryAmount,
    subTotal: order.subTotal,
    total: order.total,
    totalProducts: order.totalProducts,
  });

  this.$store.commit('checkout/SET_CART_TEMP', stateProducts);
  this.$store.dispatch('cart/setAllProducts', stateProducts);
};

Vue.prototype.$getOrder = async function (order, callback) {
  this.$store.commit('checkout/SET_PAYMENT', {
    type: 'reference',
    value: order.reference,
  });
  this.$store.commit('checkout/SET_PAYMENT', {
    type: 'orderCreated',
    value: true,
  });

  callback();

  let { data, error } = await orderDetailUsecase({ orderId: order.id });
  if (data) {
    return data;
  } else if (error) {
    return error;
  }
};

Vue.prototype.$serializeOrderToOrderGroup = async function (orderDetail) {
  // aplicar un serialize para convertir la data de Order a OrderGroup
  let orderGroup = {
    discount: orderDetail.discount,
    deliveryAmount: orderDetail.deliveryAmount,
    subTotal: orderDetail.subTotal || orderDetail.subtotal,
    total: orderDetail.total,
    totalProducts: orderDetail.totalProducts,
    address: {
      id: orderDetail.address.id,
      label: orderDetail.address.label,
      addressFurther: orderDetail.address.addressFurther,
      neighborhood: orderDetail.address.neighborhood,
      latitude: orderDetail.address.latitude,
      longitude: orderDetail.address.longitude,
      parts: orderDetail.address.parts,
      address_text: orderDetail.address.address,
    },
    orders: [
      {
        id: orderDetail.id,
        status: orderDetail.status,
        realStatus: orderDetail.realStatus,
        paymentMethod: orderDetail.paymentMethod,
        reference: orderDetail.reference,
        date: orderDetail.date,
        time: orderDetail.time,
        totalProducts: orderDetail.totalProducts,
        discount: orderDetail.discount,
        deliveryAmount: orderDetail.deliveryAmount,
        subTotal: orderDetail.subTotal,
        total: orderDetail.total,
        userIdentityType: orderDetail.userIdentityType,
        userIdentityNumber: orderDetail.userIdentityNumber,
        userBusinessName: orderDetail.userBusinessName,
      },
    ],
  };

  return orderGroup;
};

Vue.prototype.$setOrderInState = async function (orderGroup, orderDetail) {
  this.$store.commit('checkout/SET_ORDER_SUCCESS_TEMP', orderGroup);
  this.$setCheckoutTemp(orderDetail);
};
