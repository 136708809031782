import { listServicesPrimeUseCase } from '~/services/prime/list-services-prime-service';

export default async function ({ store }) {
  const {
    state: {
      user: {
        user: { id },
      },
    },
  } = store;
  if (id) {
    const { data } = await listServicesPrimeUseCase();
    if (data instanceof Object && data?.plans?.length > 0) {
      await store.dispatch('prime/setDataPlanPrime', data);
    }
  }
}

