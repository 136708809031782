var client;
export function setClient(newclient) {
  client = newclient;
}

// Request helpers
const reqMethods = [
  'request',
  'delete',
  'get',
  'head',
  'options',
  '$request',
  '$delete',
  '$get',
  '$head',
  '$options',
  'post',
  'put',
  'patch',
  '$post',
  '$put',
  '$patch',
];

let api = {
  get: async (...path) => {},
  post: async (...path) => {},
};

reqMethods.forEach((method) => {
  api[method] = function () {
    if (!client) throw new Error('client not installed');
    return client[method].apply(null, arguments);
  };
});

export default api;
