import { AnalyticEvent } from './anaytic-event';

export class OrderCompletedAnalyticEvent extends AnalyticEvent {
  constructor(store) {
    super(store);
    this.name = 'order_completed';
  }
  track(params){
    super.track({
      eventsParams: { ...params }
    })
  }
}
