import { Store } from 'vuex';
import { getBrandRoom } from '~/usecases/products/brand-room-usecase';
import { getStoreSlug, IQuery } from './get-store-slug';

export async function getPathDepartment(store: Store<any>, { query }: IQuery) {
  const { slug: storeSlug, store: currentStore } = await getStoreSlug(store, { query });
  await store.dispatch('home/loadHalls');
  const hallId = parseInt(query.department_id, 10);
  const hall = store.getters['home/getHallById'](hallId);
  const responseBrandRoom = await getBrandRoom(currentStore?.id, currentStore?.warehouseId);
  const brandRoom = responseBrandRoom.find((brand: any) => brand.departmentId && brand.departmentId === hallId);
  const slugHall = hall?.slug ?? brandRoom?.slug;

  return storeSlug && slugHall ? `${storeSlug}/${slugHall}` : '';
}

