import { mapState, mapGetters, mapActions } from 'vuex';
import { openModal } from '~/components/utils/mq-modal-managment';
import { CART_TYPES, ADD_PRODUCT_ACTION } from '~/constants/cart-actions';
import { handlerAlertWinCampaignCart } from '~/usecases/campaigns/handler-alert-win-campaign-cart';

export const AddCart = {
  props: {
    cartInstanceType: {
      type: String,
      default: CART_TYPES.DEFAULT,
    },
    isFromWidget: {
      type: Boolean,
      default: false,
    },
    screen: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      storyIdInPlayer: 'stories/getStoryIdInPlayer',
      campaignsParticipating: 'campaigns/getCampaignsParticipating',
    }),
    ...mapState({
      address: (state) => state.address.address,
      userIsMaxAge: (state) => state.user.isMaxAge,
      user: (state) => state.user.user,
    }),
    isPrincipalCartInstance() {
      return this.cartInstanceType === CART_TYPES.DEFAULT;
    },
    isOrderEditInstance() {
      return this.cartInstanceType === CART_TYPES.ORDER_EDIT;
    },
    getCartProduct() {
      return this.cartProducts.findCached(this.productMixin.id);
    },
    storyIdComputed() {
      if (this.isFromWidget) {
        return this.storyIdInPlayer;
      }
      return this.$route.query?.story ?? null;
    },
  },
  data() {
    return {
      productMixin: this?.product,
    };
  },
  methods: {
    ...mapActions({
      setMenu: 'setMenu',
      setProductWithoutAddress: 'address/setProductWithoutAddress',
    }),
    async setProductToAddFromWidgetMixin(product) {
      this.productMixin = product;
      return this.addToCartMixin();
    },
    async addToCartMixin() {
      const { commit } = this.$store;
      this.setMenu(false);
      this.productMixin.story = this.storyIdComputed || this.$options?.propsData?.storyId;
      if (this.productMixin.hasWarning && !this.userIsMaxAge) {
        if (!this.productMixin.thereIsProductMaxAgeOmitted) {
          await this.setModalMaxAgeMixin();
        }
        if (!this.userIsMaxAge) {
          return { isBreak: false, omitThisProduct: true };
        }
      }
      let isAddressSetted = true;
      await this.isAddressSettedMixin().then((value) => {
        isAddressSetted = value;
        this.$nuxt.$off('widgetAddressOut');
      });
      if ((this.productMixin.hasWarning && !this.userIsMaxAge) || (!isAddressSetted && !this.isOrderEditInstance)) {
        return { isBreak: true };
      } else if (this.maxSpecialPriceMixin()) {
        await this.setModalSpecialPriceMixin();
      } else {
        if (this.screen === 'bannerDetail') {
          this.productMixin.bannerId = this.$route.params.id;
        } else {
          this.productMixin.bannerId = this.bannerId;
        }

        this.productMixin.position = this.position;
        this.productMixin.textSearched = this.textSearched;
        this.productMixin.brandRoom = this.brandRoom;
        this.productMixin.attribution = this.attribution;

        const productsCart = this.getCartProduct;
        const listCampaignOld = [...this.campaignsParticipating.values()];
        commit(`${this.cartInstanceType}/ADD_PRODUCT`, {
          product: this.productMixin,
          type: ADD_PRODUCT_ACTION,
          screen: this.screen,
          attribution: this.attribution,
          attributionValue: this.attributionValue,
        });
        const listCampaignParticipating = [...this.campaignsParticipating.values()];
        handlerAlertWinCampaignCart(listCampaignOld, listCampaignParticipating);

        commit(`${CART_TYPES.DEFAULT}/SET_PRODUCT_MODAL`, {});
        commit(`${CART_TYPES.ORDER_EDIT}/SET_PRODUCT_MODAL`, {});

        const citySlug = this.$route.params.city;
        // validacion si el producto no existe para enviar sugeridos
        if (!productsCart) this.$store.dispatch('cart/getSuggestedProduct', { city: citySlug });
      }
      return { isBreak: false };
    },
    setModalMaxAgeMixin() {
      if (this.isPrincipalCartInstance || this.isOrderEditInstance) {
        this.setProductModal();
        return openModal({
          component: () => import('~/components/dialogs/mq-max-age-modal'),
          props: {},
          group: 'group-modal-max-age',
          config: {
            position: {
              value: 'center',
            },
            behaviors: {
              closableContainer: false,
            },
          },
        });
      }
      return Promise.resolve();
    },
    setModalSpecialPriceMixin() {
      const { commit } = this.$store;
      if (this.isPrincipalCartInstance) {
        this.setProductModal();
        return openModal({
          component: () => import('~/components/dialogs/mq-max-special-modal'),
          props: {},
          group: 'group-modal-max-special',
          config: {
            position: {
              value: 'center',
            },
            behaviors: {
              closableContainer: false,
            },
          },
        });
      } else if (this.isOrderEditInstance) {
        commit(`${CART_TYPES.ORDER_EDIT}/SET_PRODUCT_MODAL`, this.product);
        this.$nuxt.$emit('hc-active-modal-special-price');
      }
      return Promise.resolve();
    },
    isAddressSettedMixin() {
      return new Promise((resolve) => {
        const { $events: events } = this;
        this.setProductWithoutAddress({});
        if (!this.address) {
          this.setProductWithoutAddress(this.productMixin);
          this.$nuxt.$emit('openAddressDropdown');
          events.addressInputViewed.track({ screen: 'productAdded' });
          this.$nuxt.$on('widgetAddressOut', () => {
            resolve(false);
          });
        } else {
          resolve(true);
        }
      });
    },
    setProductModal() {
      this.$store.commit(`${this.cartInstanceType}/SET_PRODUCT_MODAL`, this.productMixin);
    },
    maxSpecialPriceMixin() {
      return this.getCartProduct ? this.getCartProduct.maxSpecialPrice() : false;
    },
  },
};

export default AddCart;
