import { getShelvesByDepartmentEndpoint } from '~/endpoints/products/shelves-by-department-endpoint';
import { errorTransformsFetch } from '~/transforms/errors/error-transform';
import { getShelvesTransform } from '~/transforms/home/get-shelves-transform';

/**
 *
 * @param {number} departmentId
 * @param {number} storeId
 */
export async function getShelvesByDepartmentUseCase(departmentId, storeId, zoneId) {
  const { error, response } = await getShelvesByDepartmentEndpoint(departmentId, storeId, zoneId);
  if (error === null && response.status === 200) {
    const data = await response.json();
    return { data: getShelvesTransform(data), error: null };
  }
  return { data: null, error: errorTransformsFetch(error) };
}
