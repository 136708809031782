import { AnalyticEvent } from './anaytic-event';

export class ProductAddedAnalyticEvent extends AnalyticEvent {
  constructor(store) {
    super(store);
    this.name = 'product_added';
  }
  track(dataVars) {
    const dataMap = {
      deparmentId: dataVars.departmentId,
      shelfId: dataVars.shelfId,
    };
    fbq('track', 'AddToCart', {
      content_type: 'product',
      content_name: dataVars.name,
      content_ids: [dataVars.storeProductId],
      value: dataVars.price,
      currency: fbProductFbPixel.currency,
    });
    super.track(dataMap);
  }
}
