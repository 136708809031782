import { render, staticRenderFns } from "./mq-apps-links.vue?vue&type=template&id=4b2cfe31&scoped=true&"
import script from "./mq-apps-links.vue?vue&type=script&lang=js&"
export * from "./mq-apps-links.vue?vue&type=script&lang=js&"
import style0 from "./mq-apps-links.vue?vue&type=style&index=0&id=4b2cfe31&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b2cfe31",
  null
  
)

export default component.exports