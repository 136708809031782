import $axios from '~/services/global-axios';

export async function refreshTokenEndpoint(tokenId) {
  try {
    const response = await $axios.post(`/3.2/token/refresh-token?refreshToken=${tokenId}`);
    return { response, error: null };
  } catch (error) {
    return { response: null, error };
  }
}
