//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import UIUtils from '~/plugins/ui-utils';

export default {
  name: 'MqTabsShelves',
  data() {
    return {
      activeShelf: 0,
      departmentHall: undefined,
      shelvesList: [],
    };
  },
  computed: {
    ...mapState({
      halls: (state) => state.home.halls,
      shelves: (state) => state.home.shelves,
      brandRooms: (state) => state.home.brandRooms,
    }),
    ...mapGetters({
      store: 'home/getCurrentStore',
      city: 'home/getCity',
      storeId: 'home/getCurrentStoreId',
      warehouseId: 'home/getWarehouseId',
    }),
  },
  watch: {
    activeShelf() {
      this.updateActiveShelfScrollTo();
    },
    $route() {
      this.updateShowTab();
    },
    departmentHall() {
      UIUtils.recalculateCssProperties();
    },
    shelves() {
      this.updateShowTab();
    },
  },
  async created() {
    const { params } = this.$route;
    if ((params.shelf && params.city) || (params.department && params.city)) {
      this.updateShowTab();
    }
  },
  methods: {
    updateShowTab() {
      if (
        this.$route.name === 'city-store-department' ||
        (this.$route.name === 'city-store-department-shelf' && window.innerWidth < 799)
      ) {
        this.showTabs = true;
        this.updateDepartment();
      } else {
        this.departmentHall = undefined;
      }
    },
    async updateDepartment() {
      if (!this.shelvesFromDeparment()) {
        return;
      }
      if (this.$route.name === 'city-store-department') {
        this.departmentHall = this.shelvesFromDeparment();
        this.shelvesList = this.shelves.length > 0 ? this.shelves : this.departmentHall.shelves;
        if (this.shelvesList instanceof Array && this.shelvesList.length > 0) {
          this.activeShelf = this.shelvesList[0].id;
        }
      } else if (this.$route.name === 'city-store-department-shelf') {
        const store = this.$store;
        const { params } = this.$route;
        const shelf = await store.dispatch('home/getShelfBySlug', {
          departmentSlug: params.department,
          shelfSlug: params.shelf,
        });
        if (!shelf) return;
        this.departmentHall = this.shelvesFromShelf();
        this.shelvesList = this.shelves.length > 0 ? this.shelves : this.departmentHall.shelves;
        this.activeShelf = shelf.id;
      } else {
        this.departmentHall = null;
      }
    },
    updateActiveShelfScrollTo() {
      setTimeout(() => {
        const tabsFooter = document.querySelector('.mq-tabs-footer');
        const tabActive = document.querySelector('.mq-tabs li.active');
        if (tabsFooter && tabActive) {
          tabsFooter.scrollLeft = tabActive.offsetLeft - tabsFooter.clientWidth / 2 + tabActive.clientWidth / 2;
        }
      }, 300);
    },
    shelvesFromDeparment() {
      const { params } = this.$route;
      if (!(this.halls && params.department)) return null;
      if (
        this.brandRooms instanceof Array &&
        this.brandRooms.findIndex((brandRoom) => brandRoom.slug === params.department) !== -1
      )
        return null;

      return this.halls.find((d) => d.slug === params.department);
    },
    shelvesFromShelf() {
      const { params } = this.$route;
      if (!(this.halls && params.department && params.shelf)) return [];
      const department = this.halls.find((d) => d.slug === params.department);
      if (department && department.shelves.find((s) => s.slug === params.shelf) !== undefined) {
        return department;
      }
      return [];
    },
  },
};
