/* [[width,itemsPerPage]] */
export const nextButton = `<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 12H18" stroke="#333333" stroke-width="2" stroke-linecap="round"/>
<path d="M12 5L19 12L12 19" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;
export const prevButton = `<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 5L5 12M5 12L12 19M5 12H19" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;
export default [
  [0, 2],
  [340, 3.25],
  [400, 3.5],
  [500, 4.5],
  [670, 5],
  [800, 6],
  [920, 7],
  [1000, 7],
  [1100, 8],
  [1200, 8],
  [1300, 8],
  [1570, 9],
  [1920, 11],
];
