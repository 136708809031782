import { Plugin } from '@nuxt/types';
import { checkFeatureFlag, FEATURE_TOGGLES_KEYS } from '~/feature-flags/feature-toggles';

declare module 'vue/types/vue' {
  interface Vue {
    $FEATURE_TOGGLES_KEYS: typeof FEATURE_TOGGLES_KEYS;
    $checkFeatureFlag: typeof checkFeatureFlag;
  }
}

const featureFlagPlugin: Plugin = (_context, inject) => {
  inject('checkFeatureFlag', checkFeatureFlag);
  inject('FEATURE_TOGGLES_KEYS', FEATURE_TOGGLES_KEYS);
};

export default featureFlagPlugin;
