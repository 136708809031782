import Vue from 'vue';
import moment from 'moment';
import 'moment/locale/es.js';
import { nuxtInstance } from '~/plugins/nuxt-instance';

let intlIsWorking = false;
try {
  formatCurrencyThreeDigits(
    {
      decimal_quantity: 2,
      decimal_separator: '.',
      thousand_separator: ',',
      currency_code: 'cop',
    },
    20000,
  );
  intlIsWorking = true;
} catch (error) {
  intlIsWorking = false;
}

function oldFormatCurrency(currencyFormatter, value) {
  const nativeFormatted = `${currencyFormatter.currency_symbol}${value
    .toLocaleString(`en-${currencyFormatter.code.toUpperCase()}`, {
      minimumFractionDigits: currencyFormatter.decimal_quantity,
      maximumFractionDigits: currencyFormatter.decimal_quantity,
    })
    .toString()}`;
  const diccionary = { ',': currencyFormatter.thousand_separator, '.': currencyFormatter.decimal_separator };
  return currencyFormatter.code === 'co' ? nativeFormatted : nativeFormatted.replace(/[\.\,]/g, (m) => diccionary[m]);
}

function formatCurrencyThreeDigits(currencyFormatter, value) {
  const { decimal_quantity, decimal_separator, thousand_separator, currency_code } = currencyFormatter;
  const numberFormated = new Intl.NumberFormat('en-CO', {
    style: 'currency',
    currency: currency_code,
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: decimal_quantity,
    minimumFractionDigits: decimal_quantity,
  }).format(value);
  const mapSeparators = { ',': thousand_separator, '.': decimal_separator };
  return `${numberFormated.replace(/[\,\.]/g, (m) => mapSeparators[m])}`;
}

export default async ({ store }) => {
  Vue.filter('currency', function (value) {
    const { config } = store.state;
    const { currency_formatter } = config.config;
    const { language, code } = store.getters['home/getCurrentCountry'];
    const currencyValue = value ?? 0;
    if (!intlIsWorking) {
      return oldFormatCurrency({ ...currency_formatter, code, language }, currencyValue);
    } else if (code === 'mx' || code === 'co') {
      return formatCurrencyThreeDigits(currency_formatter, currencyValue);
    }
    return new Intl.NumberFormat(`${language}-${code.toUpperCase()}`, {
      style: 'currency',
      currency: currency_formatter.currency_code,
      maximumFractionDigits: currency_formatter.decimal_quantity,
      minimumFractionDigits: currency_formatter.decimal_quantity,
      currencyDisplay: 'narrowSymbol',
    }).format(currencyValue);
  });

  Vue.filter('textDiscountApplied', function (value) {
    if (value > 0) {
      return nuxtInstance?.app?.i18n?.tc('product_card_discount_unit', value > 1 ? 2 : 1, {
        unit: value,
      });
    }
    return null;
  });

  Vue.filter('textDeliveryDays', function (value) {
    let textDay = ``;
    if (value === 1) {
      textDay = `día habíl `;
    } else if (value > 1) {
      textDay = `días habíles`;
    }
    // Todo: Variable local redundante
    let text = `Entrega en ${value} ${textDay}  `;
    return text;
  });

  Vue.filter('toPercent', function (value) {
    if (value > 0) {
      return `${value}%`;
    } else {
      return '';
    }
  });

  Vue.filter('dateBeauty', function (date, format = 'dddd D [de] MMMM') {
    let fecha = moment(date, 'YYYY-MM-DD', 'es');
    if (fecha.isValid()) {
      return fecha.format(format);
    }
    return null;
  });

  Vue.filter('hoursBeauty', function (hour) {
    if (hour) {
      let horas = hour.split('-');

      horas = horas.map((hora) => {
        let horaString = moment(hora, 'HH:mm:ss', 'es');
        if (horaString.isValid()) {
          if (store.getters['home/getCurrentCountryCode'] === 'br') {
            return horaString.format('HH:mm');
          }
          return horaString.format('hh:mm a');
        }
      });

      return horas.join(' - ');
    }
    return null;
  });

  Vue.filter('toCamelCase', function (string) {
    string = string.toLowerCase().replace(/(?:(^.)|([-_\s]+.))/g, function (match) {
      return match.charAt(match.length - 1).toUpperCase();
    });
    return string.charAt(0).toUpperCase() + string.substring(1);
  });

  Vue.filter('textColor', function (words, query, style) {
    return words.replace(query, `<span class="${style}">${query}</span>`);
  });

  Vue.filter('limit', function (pum, limit) {
    let { code } = store.getters['home/getCurrentCountry'];
    if (code == 'mx') {
      return '';
    } else {
      if (limit == -1) {
        return pum.join('<br>');
      }
      if (pum && pum.length) {
        pum = pum.toString();
        if (pum) {
          let suffix = pum.length < 23 ? '' : '...';
          return pum.substr(0, limit) + suffix;
        }
      }
    }
    return '';
  });

  Vue.filter('splitWords', function (word) {
    try {
      word = word.split(' ');
      return word.length ? word[0] : '';
    } catch (error) {
      return '';
    }
  });

  Vue.filter('reverse', function (value) {
    return value.slice().reverse();
  });

  Vue.filter('uppercase', function (value) {
    if (!value) return '';
    value = value.toString();
    return value.toUpperCase();
  });
};
