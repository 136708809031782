import { nuxtInstance } from '~/plugins/nuxt-instance';
import { FEES_BY_COUNTRY } from '~/constants/number-fees';
import getDeliveryTimesUseCase from '~/usecases/delivery-times/delivery-times-usecase';
import { getPaymentMethodsUsecase } from '~/usecases/checkout/payment-methods-usecase';
import { getStoresUseCase } from '~/usecases/stores/stores-usecase';
import { getMenusUsecase } from '~/usecases/home/get-menus-usecase';
import { getBannersUseCase, getBannersNewServiceUseCase } from '~/usecases/banners/get-banners-usecase';
import { getCountriesUsecase } from '~/usecases/countries/get-countries-usecase';
import { getFavoritesUseCase } from '~/services/store/favorites/get-favorites-usecase';
import { userPolicyValidateUsecase } from '~/usecases/policy/user-policy-validate-usecase';
import { getBestProducstByDptoUseCase } from '~/usecases/products/get-best-products-by-dpto-usecase';
import { getBannerDetailsUseCase } from '~/usecases/banners/get-banner-details-usecase';
import { getProductsBannerUseCase } from '~/usecases/products/get-products-banner-usecase';
import { getBrandRoom } from '~/usecases/products/brand-room-usecase';
import { MAP_COUNTRIES_KEYS } from '~/constants/countries-i18n-keys';
import { findElementInMapObject } from '~/helpers/find-element-in-map-object';
import { MAP_CITIES_KEYS } from '~/constants/cities-i18n-keys';
import { STORE_MODEL_ENUM, STORE_TYPE_ENUM } from '~/constants/stores';
import { getCitiesMainAndNeighborhoodUseCase } from '~/usecases/cities/get-cities-usecase';
import { checkFeatureFlag, FEATURE_TOGGLES_KEYS } from '~/feature-flags/feature-toggles';

const env = require('~/../env.local');

// initial state
const STATE = {
  halls: [],
  shelves: [],
  brandRooms: [],
  departments: [],
  departments_page: {
    current: 1,
    total: null,
  },
  banners: [],
  banner: [],
  cities: [],
  store: {
    storeCovered: {},
  },
  footer: [],
  products: [],
  products_page: {
    current: 1,
    total: null,
  },
  pareycompare: {
    show: false,
    count: 0,
  },
  currentCountry: {
    type: '',
    id: '',
    name: '',
    countryName: '',
    code: 'co',
    language: 'es',
    urlDomain: '',
    decimalAmount: 0,
    urlCountryFlag: '',
    mainCity: [],
    neighborhoods: [],
    parentCities: [],
  },
  countries: [],
  hasSelectedCountry: false,
  showToolTipCountry: false,
  documentTypes: [],
  countTimeInExpress: -1,
  firstTimeExpress: false,
  deliveryTimesDescription: '',
  showDeliveryTimes: false,
  nextAvailableDelivery: '',
  nextAvailableDeliveryId: null,
  installments: null,
  showInstallments: null,
  stores: [],
  temporalStores: [],
  fullScreenModeParams: null,
  currentTab: 'featured',
  favorites: [],
  marketplaceStore: {},
  marketplaceStoreRedirect: null,
  storesByCity: null,
  currentStoreClosed: false,
  storeSelectedGrid: false,
  superEnable: false,
  showTitleStore: false,
  previousPath: null,
  redirectPath: null,
  showDropdownAddress: false,
  showStorie: false,
  deeplinkData: {},
  intentionalStore: null,
  orderChat: { open: false, orderDetail: {} },
  typeStore: 'Merqueo'
};

// getters
const GETTERS = {
  getTypeStore(state) {
    return state.typeStore;
  },
  getOrderChat(state) {
    return state.orderChat ?? { open: false, detail: {} };
  },
  getIntentionalStore(state) {
    return state.intentionalStore ?? null;
  },
  getTemporalStores(state) {
    return state.temporalStores;
  },
  getSpecializedStores(state) {
    return state.stores.filter((store) => store.storeType === 'Marketplace');
  },
  getCurrentVersion() {
    return env?.deployVersion ?? '2.0.0';
  },
  getMainStores(state) {
    return state.stores.filter((store) => store.storeType === 'Merqueo');
  },
  isFreeDelivery(state, _getters, rootState) {
    const { user } = rootState.user;
    const marketplace = state.marketplaceStore;

    if (user.totalOrders === 0 && marketplace && marketplace.freeDeliveryFirstOrder) {
      // caso primera compra
      return true;
    } else if (marketplace && marketplace.freeDelivery && marketplace.freeDelivery.days >= 0) {
      // caso cuando tiene domicilio gratis por x tiempo
      return true;
    }
    return false;
  },
  getCurrentCountry(state) {
    const { currentCountry } = state;
    if (currentCountry instanceof Object && !currentCountry.countryName) {
      Object.defineProperty(currentCountry, 'countryName', {
        enumerable: true,
        configurable: false,
        get() {
          return nuxtInstance?.app?.i18n?.t(findElementInMapObject(MAP_COUNTRIES_KEYS, currentCountry.name));
        },
      });
    }

    return currentCountry ?? {};
  },
  getCurrentCountryCode(_state, getters) {
    if (getters.getCurrentCountry) {
      return getters.getCurrentCountry.code;
    }
    return 'co';
  },
  getCurrentCountryId(_state, getters) {
    if (getters.getCurrentCountry) {
      return getters.getCurrentCountry.id;
    }
    return 1;
  },
  getCurrentCountryLanguage(_state, getters) {
    if (getters.getCurrentCountry) {
      return getters.getCurrentCountry.language;
    }
    return 'es';
  },
  getCurrentLocale(_state, getters) {
    if (getters.getCurrentCountry) {
      return `${getters.getCurrentCountryLanguage}-${getters.getCurrentCountryCode.toUpperCase()}`;
    }
    return 'es-CO';
  },
  getUrlBase(_state, getters) {
    return getters.getCurrentCountry?.urlDomain ?? 'https://merqueo.com';
  },
  getHelpCenterkLink(_state, getters) {
    const countryCode = getters.getCurrentCountryCode;
    const countryLanguage = getters.getCurrentCountryLanguage;
    if (checkFeatureFlag(FEATURE_TOGGLES_KEYS.FLAG_KUSTOMER)) {
      const baseUrlKustomer = 'https://ayuda.merqueo.com/lang/';
      const isCountryNeccessary = countryCode === 'br' ? `_${countryCode}` : '';
      return `${baseUrlKustomer}${countryLanguage}${isCountryNeccessary}`;
    }
    const baseUrlZenddesk = 'https://merqueo.zendesk.com/hc/';
    return `${baseUrlZenddesk}${countryLanguage}-${countryCode}`;
  },
  isValidStore(state, getters) {
    return getters.getCurrentStore instanceof Object && !Number.isNaN(parseInt(state.currentCountry.id, 10));
  },
  isCountry: (state) => (countryCode) =>
    state.currentCountry instanceof Object && state.currentCountry.code === countryCode,
  getZoneId(state) {
    return state.marketplaceStore instanceof Object ? state.marketplaceStore.zoneId : undefined;
  },
  getWarehouseId(state) {
    return state.marketplaceStore instanceof Object ? state.marketplaceStore.warehouseId : undefined;
  },
  getMainCity(state) {
    return state.currentCountry.mainCity;
  },
  getCity(state) {
    const city = 'slug' in state.store ? state.store : state.currentCountry.mainCity ?? {};
    if (city instanceof Object && !city.cityName) {
      Object.defineProperty(city, 'cityName', {
        enumerable: true,
        configurable: false,
        get() {
          return nuxtInstance?.app?.i18n?.t(findElementInMapObject(MAP_CITIES_KEYS, city.slug, city.city));
        },
      });
    }
    if (city instanceof Object && !city.address_type && city.addressType) {
      city.address_type = city.addressType;
    }
    return city;
  },
  getCityId(_state, getters) {
    return getters.getCity?.id;
  },
  getCityName(_state, getters) {
    return getters.getCity?.name;
  },
  getCurrentStore(state) {
    return state.marketplaceStore ? { ...state.marketplaceStore, id: parseInt(state.marketplaceStore.id, 10) } : {};
  },
  getCurrentStoreId(state, getters) {
    const { store } = state;
    const storeId = getters.getCurrentStore?.id;
    return storeId ?? store?.storeCovered?.id;
  },
  getDefaultStore(state, getters) {
    return state.stores?.find(getters.isMerqueoSuper);
  },
  getMarketPlaceStore(state) {
    return state.marketplaceStore;
  },
  getMarketplaceStoreRedirect(state) {
    return state.marketplaceStoreRedirect;
  },
  getHalls(state) {
    return state.halls;
  },
  getCityBySlug: (state) => (citySlug) => {
    const city = state.cities ? state.cities.find((c) => c.slug === citySlug) : null;
    if (city && city.slug && city.storeCovered) {
      return city;
    }
    return null;
  },
  getMerqueoSuper(state, getters) {
    return state.stores.find(getters.isMerqueoSuper);
  },
  getMerqueoExpress(state, getters) {
    return state.stores.find(getters.isMerqueoExpress);
  },
  getAddressMapped: () => (resultAddresses) => {
    const resultAddressMapped = resultAddresses.data.map((result) => ({
      address: result.attributes.address,
      latitude: result.attributes.latitude,
      longitude: result.attributes.longitude,
      warehouseId: result.attributes.warehouse_id,
      zoneId: result.attributes.zone_id,
    }));
    return resultAddressMapped;
  },
  getCurrentDomain() {
    return location.origin;
  },
  getMinimumOrderAmount(_state, getters) {
    return getters.getCurrentStore instanceof Object ? getters.getCurrentStore.minimumOrderAmount : undefined;
  },
  isMerqueoSuper: (_state, getters) => (storeParam) => {
    const store = storeParam ?? getters.getCurrentStore;
    return (
      store instanceof Object &&
      store.storeType === STORE_TYPE_ENUM.MERQUEO &&
      store.model === STORE_MODEL_ENUM.NORMAL_SUPERMARKET
    );
  },
  isMerqueoExpress: (_state, getters) => (storeParam) => {
    const store = storeParam ?? getters.getCurrentStore;
    return (
      store instanceof Object &&
      store.storeType === STORE_TYPE_ENUM.MERQUEO &&
      store.model === STORE_MODEL_ENUM.FAST_EXPRESS
    );
  },
  isSuper: (_state, getters) => (storeParam) => {
    const store = storeParam ?? getters.getCurrentStore;
    return store instanceof Object && store.model === STORE_MODEL_ENUM.NORMAL_SUPERMARKET;
  },
  isExpress: (_state, getters) => (storeParam) => {
    const store = storeParam ?? getters.getCurrentStore;
    return store instanceof Object && store.model === STORE_MODEL_ENUM.FAST_EXPRESS;
  },
  getMarketplaceStoreBySlug: (state) => (slug) => state.stores.find((_store) => _store.slug === slug),
  isLayout() {
    return env.FEATURE_MARKETPLACE;
  },
  getStoreById: (state) => (storeId) =>
    state.stores.length ? state.stores.find((store) => `${store.id}` === `${storeId}`) : null,
  getHallById: (state) => (hallId) => state.halls.length ? state.halls.find((hall) => hall.id === hallId) : null,
  getShelfById: (state) => (shelfId) =>
    state.shelves?.length ? state.shelves.find((shelf) => shelf.id === shelfId) : null,
  getBannerDetail(state) {
    return state.banner;
  },
  getFavorites(state) {
    return state.favorites;
  },
};

const ACTIONS = {
  setOrderChatState({ commit }, orderChat) {
    commit('SET_ORDER_CHAT', orderChat);
  },
  async loadHalls({ commit, getters }) {
    const storeId = getters.getCurrentStore?.id ?? getters.getCity?.storeCovered?.id;
    const zoneId = getters.getZoneId;

    const { error, data: menuData } = await getMenusUsecase(storeId, zoneId);
    if (!error && menuData instanceof Array) {
      commit('SET_HALLS', menuData);
      return menuData;
    }
    return [];
  },
  async loadStores({ commit, getters, dispatch, rootState }, fromCache = true) {
    const cityId = getters.getCityId;
    const typeStore = getters.getTypeStore;
    const { latitude } = rootState.address.address_object ?? rootState.address.address_temp ?? {};
    const { longitude } = rootState.address.address_object ?? rootState.address.address_temp ?? {};

    const { error, data } = await getStoresUseCase({ cityId, typeStore, latitude, longitude, cache: fromCache });
    if (!error && data instanceof Array) {
      const sortedStores = await dispatch('sortStores', data);
      commit('SET_STORES', sortedStores);
    }
  },
  setStores({ commit }, stores) {
    commit('SET_STORES', stores);
  },
  setTemporalStores({ commit }, stores) {
    commit('SET_TEMPORAL_STORES', stores);
  },
  sortStores(_unused, stores) {
    // Set a priority to allow sort by
    stores.forEach((store) => {
      if (store.businessStatus === 'AVAILABLE') {
        Object.assign(store, { priority: 1 });
      } else if (store.businessStatus === 'CLOSED') {
        Object.assign(store, { priority: 2 });
      } else {
        Object.assign(store, { priority: 3 });
      }
    });

    const merqueoStores = stores.filter((store) => store.storeType === 'Merqueo');
    merqueoStores.sort((a, b) => a.priority - b.priority);

    const marketplaceStores = stores.filter((store) => store.storeType === 'Marketplace');
    marketplaceStores.sort((a, b) => a.priority - b.priority);

    return merqueoStores.concat(marketplaceStores);
  },
  async loadStoreByCity({ commit, getters }) {
    const cityId = getters.getCityId;
    const typeStore = getters.getTypeStore;
    const { error, data } = await getStoresUseCase({ cityId, typeStore, latitude: null, longitude: null, cache: true });
    if (!error && data instanceof Array) {
      commit('SET_STORES_CITY', data);
    }
  },
  validateStoreClosed({ commit, state, getters }, { params }) {
    const storesSlug = [];
    const { stores } = state;
    const marketplaceStore = getters.getMarketplaceStoreBySlug(params.store);
    stores.forEach((_store) => {
      storesSlug.push(_store.slug);
    });
    if (
      !storesSlug.includes(params.store) ||
      (storesSlug.includes(params.store) && marketplaceStore.businessStatus !== 'AVAILABLE')
    ) {
      commit('SET_CURRENT_STORE_CLOSED', true);
    }
  },

  async refreshMarketPlaceStoreBySlug({ state, dispatch }, storeSlug) {
    if (state.stores) {
      state.stores.forEach(async (marketplaceStore) => {
        if (marketplaceStore.slug === storeSlug) {
          await dispatch('setMarketplaceStore', marketplaceStore);
        }
      });
    }
  },

  /**
   * @param {{state: typeof state}} params
   * @param {string} slug
   *
   * @return {Department | undefined}
   */
  async getDepartmentBySlug({ state }, slug = '') {
    const departments = [...state.halls];
    return departments.find((department) => department.slug === slug);
  },

  /**
   * @param {{state: typeof state}} params
   * @param {string} slug
   *
   * @return {Banner | undefined}
   */
  async getBrandroomBySlug({ state }, slug = '') {
    const brandRooms = [...state.brandRooms];
    const existBrandroomBySlug = (brandroom) => brandroom.slug === slug;
    return brandRooms.find(existBrandroomBySlug);
  },

  /**
   * @param {{state: typeof state}} params
   * @param {string} slug
   *
   * @return {Shelf | undefined}
   */
  async getShelfBySlug({ state }, { departmentSlug = '', shelfSlug = '' }) {
    let returnShelf;
    state.halls.forEach((department) => {
      if (department.slug === departmentSlug) {
        department.shelves.forEach((shelf) => {
          if (shelf.slug === shelfSlug) {
            returnShelf = shelf;
          }
        });
      }
    });
    return returnShelf;
  },
  async setDepartmentsWithBestProducts({ commit, getters }, { hall, storeId, firstLoad }) {
    const { id: departmentId } = hall;
    const zoneId = getters.getZoneId;
    if (firstLoad) {
      commit('SET_DEPARTMENTS', []);
    }
    const { data } = await getBestProducstByDptoUseCase({ storeId, departmentId, zoneId });
    if (data) {
      Object.assign(hall, { storeProducts: data });
      commit('SET_DEPARTMENTS_WITH_BEST_PRODUCTS', hall);
    }
  },

  async getBrandRooms({ commit, getters }) {
    const storeId = getters.getCurrentStoreId;
    const warehouseId = getters.getWarehouseId;

    const brandRooms = await getBrandRoom(storeId, warehouseId);
    if (brandRooms instanceof Array) {
      commit('SET_BRAND_ROOMS', brandRooms);
    } else {
      commit('SET_BRAND_ROOMS', []);
    }
  },

  async getBanners({ commit, getters, rootGetters }, { id = null }) {
    const storeId = getters.getCurrentStoreId;
    const zoneId = getters.getZoneId;
    const departmentId = id;
    const isAcquisition = rootGetters['user/isAcquisition'];
    const { data, error } = await getBannersUseCase({
      storeId,
      zoneId,
      departmentId,
      isAcquisition,
    });
    if (data instanceof Array && !error) {
      commit('SET_BANNERS', data);
      return data;
    }
    commit('SET_BANNERS', []);
    return [];
  },

  async getBannersNewService({ commit, getters, rootGetters }, { id = null }) {
    const pathParam = {};
    pathParam.storeId = getters.getCurrentStoreId;
    pathParam.zoneId = getters.getZoneId;
    pathParam.warehouseId = getters.getWarehouseId;
    pathParam.department_id = id;
    pathParam.adq = rootGetters['user/isAcquisition'];
    pathParam.platform = 'web';
    const { data, error } = await getBannersNewServiceUseCase(pathParam);
    if (data instanceof Array && !error) {
      commit('SET_BANNERS', data);
      return data;
    }
    commit('SET_BANNERS', []);
    return [];
  },

  async getBanner({ commit, getters }, { id }) {
    const storeId = getters.getCurrentStoreId;
    const zoneId = getters.getZoneId;

    const dataBanner = await getBannerDetailsUseCase({ storeId, bannerId: id, zoneId });
    const dataProducts = await getProductsBannerUseCase({ storeId, bannerId: id, zoneId });
    const data = { ...dataBanner.data, groupStoreProducts: dataProducts.data };
    commit('SET_BANNER', data || {});
    return data;
  },
  async getDeliveryTimes({}, { zoneId, latitude, longitude, storeId }) {
    const data = await getDeliveryTimesUseCase({ storeId, zoneId, latitude, longitude });
    return data;
  },
  async getCities({ state, dispatch }) {
    const mainCities = await dispatch('getMainCitiesByCountry', state.currentCountry.id);
    return mainCities;
  },

  async getAddress({ getters }, address) {
    const addressResult = getters.getAddressMapped(address);
    return addressResult;
  },

  async setStore({ commit }, store) {
    await commit('SET_STORE', store);
  },

  async setFavorites({ getters, commit }) {
    const storeId = getters.getCurrentStoreId;
    const zoneId = getters.getZoneId;
    const { data } = await getFavoritesUseCase({ zoneId, storeId });
    commit('SET_FAVORITES', data);
    return data;
  },
  setCountries({ commit }, countries) {
    commit('SET_COUNTRIES', countries);
  },

  setCurrentCountry({ commit }, country) {
    commit('SET_CURRENT_COUNTRY', country);
  },

  setHasSelectedCountry({ commit }, value) {
    commit('SET_HAS_SELECTED_COUNTRY', value);
  },

  async getCurrentCountry({ state, dispatch }) {
    const { data: countries } = await getCountriesUsecase();
    if (env.ENABLE_MERQUEO_LOCAL_DOMAIN) {
      if (countries[0]) {
        Object.assign(countries[0], { urlDomain: 'https://dev.merqueo.com' });
      }
      if (countries[1]) {
        Object.assign(countries[1], { urlDomain: 'https://dev.merqueo.com.mx' });
      }
      if (countries[2]) {
        Object.assign(countries[2], { urlDomain: 'https://dev.merqueo.com.br' });
      }
    }
    const currentLocation = location.hostname;
    countries.forEach((country) => {
      const urlDomain = new URL(country.urlDomain);
      if (urlDomain.hostname === currentLocation) {
        dispatch('setCurrentCountry', country);
      }
    });
    return state.currentCountry;
  },

  async getCurrentMainCityOfCountry({ state, dispatch, commit }) {
    const { currentCountry } = state;
    const { id } = currentCountry;

    const countryId = id ?? 1;

    const mainCity = await dispatch('getMainCityOfCountry', countryId);
    commit('SET_CURRENT_MAIN_CITY', mainCity);
    return state.currentCountry;
  },

  async getMainCityOfCountry({ dispatch }, countryId = 1) {
    const cities = await dispatch('getMainCitiesByCountry', countryId);
    const mainCity = cities.sort((a, b) => a.id - b.id)[0];
    return mainCity;
  },

  async getMainCitiesByCountry({ commit }, countryId = 1) {
    const { data: mainCities, error } = await getCitiesMainAndNeighborhoodUseCase(countryId);
    if (!error && mainCities instanceof Array) {
      commit('SET_PARENTS_CITY', mainCities);
      commit('SET_CITIES', mainCities);
      return mainCities;
    }
    return [];
  },

  setDocumentsToArray({ commit, rootState }) {
    const arrayDocuments = [];
    Object.entries(rootState.config.documents).forEach((documentType) => {
      const documentObject = {};
      documentObject.id = documentType[1].attributes.code;
      documentObject.text = documentType[1].attributes.document;
      arrayDocuments.push(documentObject);
    });
    commit('SET_DOCUMENT_TYPE', arrayDocuments);
  },
  // TODO: Esto es algo que quedo eliminado con marketplace pero hay que preguntar para habilitarlo
  setFirstTimeExpress({ commit, state }, typeStore) {
    const { countTimeInExpress } = state;
    if (typeStore === 'express' && countTimeInExpress < 1) {
      commit('SET_TIMES_EXPRESS', 1);
      commit('SET_FIRST_TIME_EXPRESS', true);
      commit('SET_WIDGET', ['onBoardingExpressModal', true], { root: true });
    } else {
      commit('SET_FIRST_TIME_EXPRESS', false);
    }
  },
  async loadInstallments({ commit, rootGetters, dispatch }) {
    const zoneId = rootGetters['home/getZoneId'];
    const { data, error } = await getPaymentMethodsUsecase({ zoneId });
    if (!error && data?.installments) {
      commit('SET_INSTALLMENTS', data.installments);
      await dispatch('setShowInstallments', data.showInstallments);
    }
  },
  setShowInstallments({ commit }, value) {
    commit('SET_SHOW_INSTALLMENTS', value);
  },
  async getNumberFees({}, { installments, creditCardType }) {
    let installment = null;
    if (!(installments && installments instanceof Array)) return installment;
    installments.forEach((_installment) => {
      if (creditCardType === _installment.type) {
        installment = _installment.value;
      } else if (creditCardType !== _installment.type && _installment.type === FEES_BY_COUNTRY.DEFAULT) {
        installment = _installment.value;
      }
    });
    return installment;
  },
  async setMarketplaceStore({ commit, dispatch }, marketplaceStore) {
    commit('SET_MARKETPLACE_STORE', marketplaceStore);
    await dispatch('cart/setCartProductStore', marketplaceStore ? marketplaceStore.id : undefined, {
      root: true,
    });
  },
  async setMarketplaceStoreRedirect({ commit }, marketplaceStore) {
    commit('SET_MARKETPLACE_STORE_REDIRECT', marketplaceStore);
  },
  async setTitleStore({ commit }, value) {
    commit('SET_TITLE_STORE', value);
  },
  setPreviusPath({ commit }, value) {
    commit('SET_PREVIUS_PATH', value);
  },
  setRedirectPath({ commit }, value) {
    commit('SET_REDIRECT_PATH', value);
  },
  /* TODO: @AddressFlow setStoreSelectedBeforeAddress, setCheckoutSelectedBeforeAddress => aqui solo debería tener un set del intentional
   */

  setIntentionalStore({ commit }, intentionalStore) {
    commit('SET_INTENTIONAL_STORE', intentionalStore);
  },
  showToolTipCountry({ commit }, value) {
    commit('SHOW_TOOLTIP_COUNTRY', value);
  },
  async validatePrivacyPolicy({ dispatch, rootGetters }, action = 'default') {
    dispatch('setFirstEnterApp', false);
    const country = rootGetters['home/getCurrentCountryCode'];
    const data = await userPolicyValidateUsecase({ country, base: env.productServicesBase });
    if (data?.error?.status === '280') {
      dispatch('setMustAcceptPolicy', [true, action]);
      return;
    }
    dispatch('setMustAcceptPolicy', [false]);
  },
  // TODO: esto no debe ser parte del estado de la aplicación, o debe implementarse un mecanismo para guardar en sesion storage el estado como esta actualmente el estado en localStorage
  setFirstEnterApp({}, value = false) {
    sessionStorage.setItem('isFirstEnterToApp', value);
  },
  // TODO: esto no debe ser parte del estado de la aplicación, o debe implementarse un mecanismo para guardar en sesion storage el estado como esta actualmente el estado en localStorage
  setMustAcceptPolicy({}, [valuePolicy = false, action = '']) {
    sessionStorage.setItem('mustAcceptNewPolicy', valuePolicy);
    if (action) sessionStorage.setItem('typeActionPolicy', action);
  },
  setShowDropdownAddress({ commit }, value) {
    commit('SET_SHOW_DROPDOWN_ADDRESS', value);
  },
  setDeeplinkConfig({ commit }, data) {
    commit('SET_DEEPLINK_CONFIG', data);
  },
  setShowStorie({ commit }, value) {
    commit('SET_SHOW_STORIE', value);
  },
  getDeeplinkConfig({ state }, key) {
    return state.deeplinkData[key];
  },
};

const MUTATIONS = {
  SET_ORDER_CHAT(state, orderObject) {
    state.orderChat = orderObject;
  },
  SET_INTENTIONAL_STORE(state, intentionalStore) {
    state.intentionalStore = intentionalStore;
  },
  SET_TEMPORAL_STORES(state, temporalStores) {
    state.temporalStores = temporalStores;
  },
  SET_MARKETPLACE_STORE(state, marketplaceStore) {
    state.marketplaceStore = marketplaceStore;
  },
  SET_MARKETPLACE_STORE_REDIRECT(state, marketplaceStore) {
    state.marketplaceStoreRedirect = marketplaceStore;
  },
  SET_HALLS(state, halls) {
    state.halls = halls;
  },
  SET_DOCUMENT_TYPE(state, documents) {
    state.documentTypes = documents;
  },
  SET_DEPARTMENTS(state, departments) {
    state.departments = departments;
  },

  APPEND_DEPARTMENTS(state, departments) {
    state.departments = state.departments.concat(departments);
  },

  SET_DEPARTMENTS_WITH_BEST_PRODUCTS(state, departments) {
    state.departments.push(departments);
  },

  SET_BANNERS(state, banners) {
    state.banners = banners;
  },

  SET_BRAND_ROOMS(state, brandRooms) {
    state.brandRooms = brandRooms;
  },

  SET_BANNER(state, banner) {
    state.banner = banner;
  },

  SET_DELIVERY_TIMES_DESCRIPTION(state, value) {
    state.deliveryTimesDescription = value;
  },
  SET_SHOW_DELIVERY_TIMES(state, value) {
    state.showDeliveryTimes = value;
  },
  NEXT_AVAILABLE_DELIVERY(state, value) {
    state.nextAvailableDelivery = value;
  },
  NEXT_AVAILABLE_DELIVERY_ID(state, value) {
    state.nextAvailableDeliveryId = value;
  },
  SET_CITIES(state, cities) {
    state.cities = cities;
  },

  SET_PARENTS_CITY(state, parentsCity) {
    state.parentCities = parentsCity;
  },

  SET_STORE(state, store) {
    Object.assign(store, { neighborhoods: undefined });
    state.store = store;
  },

  SET_FOOTER(state, footer) {
    state.footer = footer;
  },

  SET_PRODUCTS(state, products) {
    state.products = products;
  },

  APPEND_PRODUCTS(state, products) {
    state.products = state.products.concat(products);
  },

  ADD_PRODUCTS_PAGE(state, reset = false) {
    if (!reset) {
      state.products_page.current += 1;
    } else {
      state.products_page.current = 1;
    }
  },

  SET_PRODUCTS_PAGE_TOTAL(state, totalPages) {
    state.products_page.total = totalPages;
  },

  SET_PARE_Y_COMPARE(state, [key, value]) {
    state.pareycompare[key] = value;
  },

  SET_CURRENT_COUNTRY(state, country) {
    state.currentCountry = country;
  },

  SET_CURRENT_MAIN_CITY(state, city) {
    state.currentCountry.mainCity = city;
  },

  SET_COUNTRIES(state, countries) {
    state.countries = countries;
  },

  SET_HAS_SELECTED_COUNTRY(state, value) {
    state.hasSelectedCountry = value;
  },

  SET_FIRST_TIME_EXPRESS(state, value) {
    state.firstTimeExpress = value;
  },

  SET_TIMES_EXPRESS(state, value) {
    state.countTimeInExpress = value;
  },
  SET_INSTALLMENTS(state, value) {
    state.installments = value;
  },
  SET_SHOW_INSTALLMENTS(state, value) {
    state.showInstallments = value;
  },
  SET_STORES(state, value) {
    state.stores = value;
  },
  SET_CURRENT_TAB(state, tab) {
    state.currentTab = tab;
  },
  SET_FAVORITES(state, value) {
    state.favorites = value;
  },
  SET_STORES_CITY(state, value) {
    state.storesByCity = value;
  },
  SET_CURRENT_STORE_CLOSED(state, value) {
    state.currentStoreClosed = value;
  },
  SET_STORE_SELECTED_GRID(state, value) {
    state.storeSelectedGrid = value;
  },
  SET_SUPER_ENABLE(state, value) {
    state.superEnable = value;
  },
  SET_SHELVES(state, shelves) {
    state.shelves = shelves;
  },
  SET_PARAMS_FULLSCREEN_MODE(state, value) {
    state.fullScreenModeParams = value;
  },
  SET_TITLE_STORE(state, value) {
    state.showTitleStore = value;
  },
  SET_PREVIUS_PATH(state, value) {
    state.previousPath = value;
  },
  SET_REDIRECT_PATH(state, redirectPath) {
    state.redirectPath = redirectPath;
  },
  SHOW_TOOLTIP_COUNTRY(state, value) {
    state.showToolTipCountry = value;
  },

  SET_SHOW_DROPDOWN_ADDRESS(state, value) {
    state.showDropdownAddress = value;
  },
  SET_SHOW_STORIE(state, value) {
    state.showStorie = value;
  },
  SET_DEEPLINK_CONFIG(state, data) {
    const { key, value } = data;
    state.deeplinkData[key] = value;
  },

  SET_USER_TYPE_STORE(state, typeStore) {
    state.typeStore = typeStore;
  },
};

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
};

