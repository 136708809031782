//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import MqImgLazy from '~/components/utils/mq-img-lazy';
import { getProductTagsSettings } from '~/helpers/get-product-tags-settings';
import MqActionProduct from '~/components/new-components/mq-action-product';
import { AddCart } from '~/mixins/addProduct';
import { RemoveCart } from '~/mixins/removeProduct';
import MqProductCardTag from '~/components/new-components/mq-product-card-tag';
import MqIconPlay from '~/components/elements/mq-icon-play';
import { PRODUCT_PARAMS } from '~/constants/products';
import getUrlProductMixin from '~/mixins/getUrlProduct';

export default {
  name: 'MqNewProductCard',
  mixins: [AddCart, RemoveCart, getUrlProductMixin],
  components: {
    MqImgLazy,
    MqActionProduct,
    MqProductCardTag,
    MqIconPlay,
  },
  props: {
    id: { type: Number, required: true },
    product: {
      type: Object,
      default() {
        return {
          sponsored: '',
        };
      },
    },
    theme: { type: String, default: 'mq-default shadow-0 hoverable' },
    limitPum: { type: Number, default: 23 },
    screen: { type: String, default: 'productDetail' },
    lazyImage: { type: Boolean, default: true },
    isFromWidget: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      cartProducts() {
        const typeInstanceCart = `${this.cartInstanceType}/getCart`;
        return this.$store.getters[typeInstanceCart];
      },
    }),

    imageUrl() {
      if (this.screen === 'productDetail') {
        return this.product.imageLargeUrl;
      }
      return this.product.imageAppUrl;
    },
    isProductNotAvailable() {
      return this.product.status === false;
    },
    showSpecialPrice() {
      return this.product?.specialPrice > 0;
    },

    quantity() {
      return this.getCartProduct ? this.getCartProduct.getCount() : 0;
    },
    isAdded() {
      return this.getCartProduct ? this.getCartProduct.getCount() > 0 : false;
    },
    getCartProduct() {
      const cart = this.cartProducts;
      return cart.findCached(this.product.id);
    },
    productTags() {
      const TAGS = getProductTagsSettings(this.product);
      return TAGS.tagsApplied;
    },
    hasIconVideo() {
      const {
        status: { iconVideo },
      } = getProductTagsSettings(this.product);
      return iconVideo;
    },
  },
  methods: {
    getUrlProduct(isLinkForImage = false) {
      const url = this.getUrlProductMixin(this.product, this.objectSlug, this.usingRouteData);
      const param = this.getUrlParam();
      const paramUrl = param !== 'N/A' ? `${url}?search=${param}` : url;
      if (isLinkForImage) {
        // return { path: url, query: { viewImage: PRODUCT_PARAMS.VIEW_IMAGE, search: param } };
        return { path: url };
      }
      return paramUrl;
    },
    getUrlParam() {
      let param = 'N/A';
      param = this.$route.params.q ? this.$route.params.q : param;
      return param;
    },
  },
};
