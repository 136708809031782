import { required } from '~/decorators/required';
import { scheme } from '~/decorators/scheme';

@scheme()
export class AvailableSeparateStoreParams {
  @required()
  cityId: number;

  constructor(params: Partial<AvailableSeparateStoreParams>) {
    Object.assign(this, params);
  }
}
