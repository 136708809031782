const isRequired = function() {
  throw new Error('Missing parameter');
};
export default {
  methods: {
    getUrlProductMixin(product = isRequired(), objectSlug = {}, usingRouteData = false) {
      const city = this.$store.getters['home/getCity'];
      const store = this.$store.getters['home/getCurrentStore'];
      let urlByProductData = `/${city.slug}/${store.slug}/${product?.department?.slug}/${product?.shelf?.slug}/${
        product?.slug
      }`;
      let urlByRoutedata = `/${objectSlug.city}/${objectSlug.store}/${objectSlug.department}/${objectSlug.shelf}/${
        product.slug
      }`;
      if (this.$router.query?.story) {
        const story = this.$route.query?.story;
        urlByProductData = `${urlByProductData}?story=${story}`;
        urlByRoutedata = `${urlByRoutedata}?story=${story}`;
      }
      const currentRoute = usingRouteData ? urlByRoutedata : urlByProductData;
      return currentRoute;
    },
  },
};
