export const MERQUEO_VERSION = '2.0.0';

export class Event {
  constructor() {
    this.name = '';
    this.baseData = {};
  }

  /**
   * @param {Object|null} data
   * @return {Promise<*>}
   */
  // Todo: validar variable de entrada;
  // eslint-disable-next-line
  track(data) {
    //TODO: validar data
    throw new Error('This method must be implement!');
  }
}

export class EventGroup extends Event {
  constructor(events, store) {
    super();
    this.events = events;
    this.store = store;
  }

  /**
   * Tracks an event and resolve its items.
   * @param data
   * @returns {Promise<Array>}
   */
  track(data) {
    const promises = [];

    for (let event of this.events) {
      let objectEvent = new event(this.store);
      promises.push(objectEvent.track(data));
    }

    return Promise.resolve(promises);
  }
}
