import { DeliveryTimes as DeliveryTimesV32 } from '~/endpoints/checkout/delivery-times-endpoints-V32';
import { DeliveryTimes } from '~/endpoints/checkout/delivery-times-endpoints';
import { checkFeatureFlag, FEATURE_TOGGLES_KEYS } from '~/feature-flags/feature-toggles';
import { DeliveryTimesParams } from '~/schemes/delivery-times-scheme';
import { deliveryTimesTransforms } from '~/transforms/checkout/delivery-times-transform';
import { errorTransforms } from '~/transforms/errors/error-transform';

export async function getDeliveryTimesUseCase({
  storeId,
  zoneId,
  latitude,
  longitude,
  cart = [],
  slotExpress,
  campaignPrizeIds,
}: DeliveryTimesParams & { slotExpress: boolean }) {
  let responseEndpoint;
  if (checkFeatureFlag(FEATURE_TOGGLES_KEYS.SPLIT_STORE_TOGGLE)) {
    responseEndpoint = await DeliveryTimesV32.getDeliveryTimesEndpointV32(
      new DeliveryTimesParams({ storeId, zoneId, latitude, longitude, cart, campaignPrizeIds }),
    );
  } else {
    responseEndpoint = await DeliveryTimes.getDeliveryTimesEndpoint(
      new DeliveryTimesParams({
        storeId,
        zoneId,
        latitude,
        longitude,
        cart,
        slotExpress,
        campaignPrizeIds,
      }),
    );
  }
  const { response, error } = responseEndpoint;
  if (error === null && response?.status === 200) {
    const products = await deliveryTimesTransforms(response.data);
    return { data: products, error: null };
  }
  return { data: null, error: errorTransforms(error) };
}

export default getDeliveryTimesUseCase;
