export const PAYMENT_METHODS = {
  CREDIT_CARD: 'Tarjeta de crédito',
  CODENSA: 'CODENSA',
  AMEX: 'AMEX',
  CASH: 'Efectivo',
  DATAPHONE: 'Datáfono',
  DATAPHONE_SODEXO: 'Datáfono - Sodexo',
  ONLINE: 'Pago en línea',
  HOME: 'Pago en casa',
  PSE: 'Débito - PSE',
  TERMINAL: 'Terminal',
};

export const PAYMENT_METHODS_TEXT = {
  CREDIT_CARD: 'credit_card',
  DATAPHONE: 'debit_card',
  CASH: 'cash',
  PSE: 'pse',
  SODEXO: 'sodexo',
};
