
import Vue from 'vue';
import { merge } from 'lodash';
import { nuxtInstance } from '~/plugins/nuxt-instance';
import { getMaxZindex } from '~/helpers/max-z-index';
import { uuidv4 } from '~/helpers/uuid';

const configDefault = {
  position: {
    value: 'center',
  },
  behaviors: {
    closableContainer: false,
  },
  animation: {
    duration: 1,
  },
};

const state = Vue.observable({
  modals: {},
});

let zIndex;

function configItemModal(config) {
  let response = [];
  if (config.position) {
    switch (config.position.value) {
      case 'center':
        response.push('center-modal');
        break;
      case 'bottom':
        response.push('bottom-modal', 'show');
        break;
    }
  }
  return response;
}

let trackingModal = undefined;

async function trackingModalPrivate(resolve, info, data) {
  const { event, id, config } = info;
  const { store } = nuxtInstance;
  await store.dispatch('setMaxLayerZindex', null);
  switch (info.event) {
    case 'CANCEL':
    case 'CLOSE':
      const modalId = state.modals.hasOwnProperty(id);
      if (modalId) {
        const deleteFunction = async () => {
          Vue.delete(state.modals, id);
          await Vue.nextTick();
          resolve({ event, data });
        };
        if (config.position.value == 'bottom') {
          const { duration } = config.animation;
          const component = this.$refs[`modal-item-${id}`];
          if (component?.$el instanceof HTMLElement) {
            component.$el.classList.remove('show');
            component.$el.classList.add('hide');
            setTimeout(deleteFunction, duration * 1000);
          }
        } else {
          deleteFunction();
        }
      }
      break;
    case 'CLOSE-ALL':
      await closeAllModal();
      break;
    case 'WAITING':
      resolve({ event, data });
      break;
    default:
      break;
  }
}

export function generateIdUnique() {
  return uuidv4();
}

export async function closeGroup(group) {
  const modals = { ...state.modals };
  for (const id in modals) {
    let modal = modals[id];
    if (modal?.group == group) {
      Vue.delete(state.modals, id);
    }
  }
  await Vue.nextTick();
}

export async function closeAllModal() {
  Vue.set(state, 'modals', {});
  await Vue.nextTick();
}

export async function openModal(modalData) {
  const { store } = nuxtInstance;
  const maxZindex = getMaxZindex();
  await store.dispatch('setMaxLayerZindex', maxZindex);
  zIndex ??= maxZindex;
  const modalInfo = { ...modalData };
  modalInfo.config = JSON.parse(JSON.stringify(merge(configDefault, modalInfo.config)));
  const response = new Promise(async (resolve) => {
    const id = uuidv4();
    const { config, group } = modalInfo;
    const obj = {
      id,
      group,
      item: modalInfo,
      tracking: (event, data) => {
        if (typeof trackingModal === 'function') trackingModal(resolve, { event, id, config }, data);
      },
    };
    Vue.set(state.modals, id, obj);
  });
  return response;
}

export default {
  name: 'MqModalManagment',
  created() {
    trackingModal = trackingModalPrivate.bind(this);
  },
  render(createElement) {
    if (Object.keys(state.modals).length > 0) {
      const modalMap = (elem, i) =>
        createElement(
          'div',
          {
            class: ['mq-modal-container-obj'],
            style: {
              zIndex: i + 1,
            },
            on: {
              click: (e) => {
                if (
                  e.target.classList.contains('mq-modal-container-obj') &&
                  elem.item.config?.behaviors?.closableContainer
                ) {
                  elem.tracking('CLOSE');
                }
              },
            },
          },
          [
            createElement(elem.item.component, {
              class: ['mq-modal-container-obj-item', ...configItemModal(elem.item.config)],
              props: {
                ...elem.item.props,
                tracking: elem.tracking,
              },
              style: {
                animationDuration: `${elem.item.config.animation.duration}s`,
              },
              ref: `modal-item-${elem.id}`,
              key: `modal-item-${elem.id}`,
              directives: [
                {
                  name: 'testing-name',
                  value: elem.id,
                },
              ],
            }),
          ],
        );
      const list = Object.values(state.modals);
      const modalList = list.map(modalMap);
      return createElement(
        'section',
        {
          class: ['mq-modal-container', 'dark-bg'],
          style: {
            zIndex: zIndex + 1,
          },
        },
        modalList,
      );
    }
    return createElement();
  },
};
