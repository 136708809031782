import { camelize } from '~/helpers/camelize';
import normalize from 'json-api-normalize';
import { DEPARTMENT_TRANFORM_KEYS } from '../departments/department-transform';
import { SHELF_TRANSFORM_KEYS } from '../shelfs/shelf-transform';

export function getMenusTransform(responseData) {
  return camelize(
    normalize(responseData).get([...DEPARTMENT_TRANFORM_KEYS, ...SHELF_TRANSFORM_KEYS.map(key => `shelves.${key}`)]),
  );
}
