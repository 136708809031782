import { nuxtInstance } from '~/plugins/nuxt-instance';

export function findElementInMapObject(mapObject, element, original = undefined) {
  if (mapObject.has(element)) {
    return mapObject.get(element);
  }
  return original ?? element;
}

export function translateFromMapObject(mapObject, hash, defaultValue = undefined) {
  if (mapObject.has(hash)) {
    return nuxtInstance?.app?.i18n?.t(mapObject.get(hash));
  }
  return defaultValue ?? hash;
}
