import { Store } from "vuex";
import { Strategy } from "@/helpers/deeplinks/strategy";
import { IQuery, ICity, getStoreSlug } from "@/helpers/deeplinks/utils/get-store-slug";
import { DEEP_LINKS_NAMES } from '~/helpers/deeplinks/constants/deeplinks';

export const strategyStoryDeepLink = (
  store: Store<any>,
  route: IQuery,
  city: ICity
) =>
  new Strategy(DEEP_LINKS_NAMES.story, async () => {
    const storyDeepLink = {
      storyId: parseInt(route.query.story_id, 10),
      channelId: parseInt(route.query.channel_id, 10),
    };
    store.dispatch("events/setStoryDeepLink", storyDeepLink);

    const { slug } = await getStoreSlug(store, route);
    if (!slug) {
      store.dispatch("events/setIsDeeplink", false);
      return "/";
    }
    return `/${city?.slug}/${slug}`;
  });
