import { Store } from 'vuex';
import { Strategy } from '@/helpers/deeplinks/strategy';
import { getPathDepartment } from '@/helpers/deeplinks/utils/get-path-department';
import { IQuery, ICity } from '@/helpers/deeplinks/utils/get-store-slug';
import { DEEP_LINKS_NAMES } from '~/helpers/deeplinks/constants/deeplinks';

export const strategyDepartmentDeepLink = (store: Store<any>, route: IQuery, cityBySlug: ICity) =>
  new Strategy(DEEP_LINKS_NAMES.department, async () => {
    const departmentPath = await getPathDepartment(store, route);

    return departmentPath ? `/${cityBySlug?.slug}/${departmentPath}` : '';
  });

