//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { openModal } from '../utils/mq-modal-managment.vue';
import { FEATURE_TOGGLES_KEYS, checkFeatureFlag } from '~/feature-flags/feature-toggles';
export default {
  name: 'MqIconPlay',
  props: {
    isGray: {
      type: Boolean,
      default: true,
    },
    linkDetail: {
      type: String,
      default: '',
    },
    product: {
      type: Object,
      default: new Object(),
    },
  },
  computed: {
    imageUrl() {
      return !checkFeatureFlag(FEATURE_TOGGLES_KEYS.PRODUCT_CARD_TOGGLE)
        ? require('~/assets/img/play-colored.svg')
        : require('~/assets/icons/play.svg');
    },
  },
  methods: {
    async clickIconColored() {
      const response = await openModal({
        component: () => import('~/components/dialogs/mq-modal-video-product'),
        props: {
          product: this.product,
          urlDetail: this.linkDetail,
        },
        group: 'group-modal-helper',
        config: {
          position: {
            value: 'center',
          },
          behaviors: {
            closableContainer: true,
          },
        },
      });
    },
    clickIconGray() {
      this.$router.push(this.linkDetail);
    },
  },
};
