import { mapActions } from 'vuex';
import { CART_TYPES, REMOVE_PRODUCT_ACTION } from '~/constants/cart-actions';
import { handlerAlertLossCampaignCart } from '~/usecases/campaigns/handler-alert-loss-campaign-cart';

export const RemoveCart = {
  props: {
    cartInstanceType: {
      type: String,
      default: CART_TYPES.DEFAULT,
    },
    isFromWidget: {
      type: Boolean,
      default: false,
    },
    screen: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      productMixin: this?.product,
    };
  },
  methods: {
    ...mapActions({
      setMenu: 'setMenu',
      setProductWithoutAddress: 'address/setProductWithoutAddress',
      addProductCart: 'cart/addProduct',
    }),
    removeCartMixin() {
      this.productMixin.story = this.storyIdComputed;
      this.productMixin.attribution = this.attribution;
      if (this.cartInstanceType === CART_TYPES.ORDER_EDIT && this.quantity === 1) {
        this.setModalConfirmRemove();
      } else {
        const products = this.cartProducts;
        const isLossCampaign = handlerAlertLossCampaignCart(this.productMixin, products, 'ADD_PRODUCT');
        if (!isLossCampaign) {
          this.$store.commit(`${this.cartInstanceType}/ADD_PRODUCT`, {
            product: this.productMixin,
            type: REMOVE_PRODUCT_ACTION,
            screen: this.screen,
          });
          const citySlug = this.$route.params.city;
          const productsCart = this.getCartProduct;
          // validacion si el producto no existe para enviar sugeridos
          if (!productsCart) this.$store.dispatch('cart/getSuggestedProduct', { city: citySlug });
        }
      }
    },
    setModalConfirmRemove() {
      const { commit } = this.$store;
      commit(`${CART_TYPES.ORDER_EDIT}/SET_PRODUCT_MODAL`, this.productMixin);
      this.$nuxt.$emit('hc-active-modal-confirm-remove', this.productMixin);
    },
    removeListOfProductsMixin(productsToRemove) {
      if (productsToRemove.length > 0) {
        productsToRemove.forEach((product) => {
          this.addProductCart({ product, type: REMOVE_PRODUCT_ACTION });
        });
      }
    },
  },
  watch: {
    screen(newVal) {
      this.productMixin.screen = newVal;
    },
  },
};

export default RemoveCart;
