//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MqTooltip from '~/components/utils/mq-tooltip';

export default {
  name: 'MqProductFirstBuyLabel',
  components: {
    MqTooltip,
  },
  props: {
    product: {
      type: Object,
      default: new Object(),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isSuggested: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { product } = this;
    return {
      isResponsive: window.innerWidth < 768,
      alignTopRight: window.innerWidth < 965,
      specialPrice: product.specialPrice,
      price: product.price,
    };
  },
  computed: {
    isDetailProduct() {
      return this.$route.params.product ? true : false;
    },
    allowAlignRight() {
      return window.innerWidth > 768;
    },
    colorIcon() {
      return this.disabled ? '#666666' : '#491fab';
    },
  },
};
