import { getDeliveryTimes } from '~/endpoints/delivery-times/delivery-times-endpoints';
import { deliveryTimesTransforms } from '~/transforms/checkout/delivery-times-transform';

const getDeliveryTimesUseCase = async ({ zoneId, storeId }) => {
  const { response, error } = await getDeliveryTimes({ zoneId, storeId });
  if (error === null && response && response.data) {
    const listDelivery = await deliveryTimesTransforms(response);
    return { data: listDelivery, error: null };
  } else if (error === null && response && response.errors && response.errors instanceof Array) {
    return { data: null, error: response.errors[0] };
  } else if (
    error &&
    error.response instanceof Object &&
    error.response.data instanceof Object &&
    error.response.data.errors instanceof Array
  ) {
    return { data: null, error: error.response.data.errors[0] };
  } else {
    return {};
  }
};
export default getDeliveryTimesUseCase;
