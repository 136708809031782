import { storeRedirectUseCase } from '~/usecases/stores/store-redirect-usecase';
import Vue from 'vue';

export default function ({ store, app }) {
  const cartProducts = store.getters['cart/getCart'];
  const minimumOrderAmount = store.getters['home/getMinimumOrderAmount'];
  store.dispatch('user/me');
  if (
    cartProducts.total() < minimumOrderAmount &&
    !store.state.checkout.isCheckoutSuccess &&
    !store.state.checkout.payment.orderCreated &&
    store.state.user.token
  ) {
    const minimumOrderAmountMessage = app.i18n.t('common_label_minimum_amount', {
      minimumOrderAmount,
    });

    Vue.prototype.$mqToast(minimumOrderAmountMessage, 'close', 'error');

    storeRedirectUseCase();
  }
}
