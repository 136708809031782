import { PaymentMethods } from '~/endpoints/checkout/payment-methods-endpoints';
import { PaymentMethodsParams } from '~/schemes/payment-methods-scheme';
import { paymentMethodsTransforms } from '~/transforms/checkout/payment-methods-transform';

export async function getPaymentMethodsUsecase(params: PaymentMethodsParams) {
  const { response, error } = await PaymentMethods.getPaymentMethodsEndpoint(new PaymentMethodsParams(params));
  if (error === null && response.status === 200) {
    const payments = await paymentMethodsTransforms(response.data);
    return { data: payments, error: null };
  } else if (error?.response?.data instanceof Object && error.response.data.errors instanceof Array) {
    return { data: null, error: error.response.data.errors[0] };
  }
  return {};
}
