export const SORT_TYPES = {
  RELEVANCE: {
    NAME: 'sort_type_default',
    PARAM_NAME: 'relevance',
    EVENT_NAME: 'default',
  },
  PROMOTION: {
    NAME: 'sort_type_discount',
    PARAM_NAME: 'discount_desc',
    EVENT_NAME: 'promotion',
  },
  PRICE_DESC: {
    NAME: 'sort_type_lower_price',
    PARAM_NAME: 'price_asc',
    EVENT_NAME: 'lowestPrice',
  },
  PRICE_ASC: {
    NAME: 'sort_type_higher_price',
    PARAM_NAME: 'price_desc',
    EVENT_NAME: 'highestPrice',
  },
};
export const DEFAULT = {
  NAME: 'sort_type_default',
  PARAM_NAME: 'relevance',
  EVENT_NAME: 'default',
};
