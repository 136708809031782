import Leanplum from 'leanplum-sdk';
import Vue from 'vue';
import Wizard from './leanplum/wizard';
import { leanplum } from './leanplum/events';
import { EventGroup } from './event';
import {
  StoreOpenedBigQuery,
  DepartmentViewedBigQuery,
  AddressInputViewedBigQuery,
  ShelfClickedBigQuery,
  AddressEnteredBigQuery,
  AddressSelectedFromZonesBigQuery,
  AddressValidatedBigQuery,
  ProductAddedBigQuery,
  CartViewedBigQuery,
  CheckoutStartedBigQuery,
  OrderCompletedBigQuery,
  ProductPurchasedBigQuery,
  WidgetAccountClickedBigQuery,
  AddressesViewedByWidgetAccountBigQuery,
  OrdersViewedByWidgetAccountBigQuery,
  PaymentViewedByWidgetAccountBigQuery,
  CreditsViewedByWidgetAccountBigQuery,
  AddressesViewedByAccountBigQuery,
  OrdersViewedByAccountBigQuery,
  PaymentViewedByAccountBigQuery,
  CreditsViewedByAccountBigQuery,
  OrdersViewedBigQuery,
  AddressInputViewedByAccountBigQuery,
  AddressEditedByAccountBigQuery,
  AddressDeletedByAccountBigQuery,
  AddressUpdatedByAccountBigQuery,
  BillViewedBigQuery,
  ProductsSearchedBigQuery,
  ProductViewedBigQuery,
  CartDeletedBigQuery,
  LastProductsBoughtViewedBigQuery,
  ProductDealsOpenedBigQuery,
  ProductRemovedBigQuery,
  SuggestedProductViewedBigQuery,
  AddressDetailsEnteredBigQuery,
  DeliveryDatetimeSavedBigQuery,
  PaymentMethodCofirmedBigQuery,
  CreditCardInfoEnteredBigQuery,
  BannerViewedBigQuery,
  BannerClickedBigQuery,
  PromotionsViewedBigQuery,
  checkoutErrorBigQuery,
  OrderStatusViewedBigQuery,
  CreditCardAddedByAccountBigQuery,
  CreditCardDeletedByAccountBigQuery,
  CreditCardEnteredByAccountBigQuery,
  ReferralCodeCopiedBigQuery,
  ReferralCodeSharedBigQuery,
  OldToNewTransitionBigQuery,
  ProductAddedFromPastOrderBigQuery,
  DownloadAppWebBigQuery,
  ServiceProposalImpresionBigQuery,
  ServiceProposalResponseBigQuery,
  ServiceProposalClosedBigQuery,
  ProductViewedSponsoredBigQuery,
  AddressMapViewed,
  AddressConfirmed,
  NextAvailableDeliverySlotAlert,
  HomeOptionSelected,
  CitySelected,
  CountrySelected,
  ProductsListViewed,
  NoCoverage,
  AddressTyped,
  EditPersonalDataBigQuery,
  EditPersonalDataNameBigQuery,
  EditPersonalDataMailBigQuery,
  EditPersonalDataPhoneBigQuery,
  AdvertisingPreferencesBigQuery,
  AdvertisingPreferencesOnBigQuery,
  AdvertisingPreferencesOffBigQuery,
  AdvertisingPreferencesOffConfirmedBigQuery,
  DeliveryDatetimeSelected,
  PaymentMethodViewed,
  PaymentMethodSelected,
  ProductChangeCheckoutBigQuery,
  ProductChangeModifiedBigQuery,
  DeliveryDatetimeViewShown,
  ShippingDetailsEntered,
  CellphoneLoginVerification,
  NoVerificationCode,
  UserLogout,
  ChannelClicked,
  StoryViewed,
  StoryCtaClicked,
  StoryProfileClicked,
  StoryExited,
  StoryError,
  LastStoryFinished,
  OrdersListViewed,
  PasswordRecovery,
  UserMenuSelected,
  UserLogin,
  UserLoginWithNequi,
  CellphoneLoginMethod,
  EmailLogin,
  StoreSelectedBigQuery,
  HomeOpenedBigQuery,
  AddressSelectedBigQuery,
  SortBySelected,
  FrequentlyAskedQuestionsBigQuery,
  PrivacyPolicyViewed,
  TermsAndConditionsViewed,
  AddressList,
  RecoverySend,
  SupportClicked,
  DeleteAccount,
  DeleteAccountConfirmed,
  BrandRoomViewed,
  PrivacyPolicyAccepted,
  CreditCardNumberAdded,
  UserDetailsViewed,
  PrivacyPolicyClicked,
  VideoBannerViewed,
  VideoBannerExited,
  VideoBannerCtaClicked,
  ProductVideoViewed,
  ProductVideoCtaClicked,
  ProductVideoExited,
  OrderDetailViewed,
  CreditsViewed,
  CreditCardAdded,
  CreditCardRemoved,
  AdressRemoved,
  DeepLinkNotFound,
  AveragePriceOtherStores,
  StoryShared,
  SavingModalViewed,
  PrizeClicked,
  InfoCampaigns,
  GiftProgressTrackerClicked,
  ConfirmOrderViewed,
  RefundRequestSelected,
  RefundRequestSend,
  HelpClicked,
  DifferentProblem,
  CanceledOrder,
  CanceledOrderOption,
  LateOrder,
  ChangeDeliveryDatetime,
  ChangeDeliveryDatetimeOption,
  ChangeDeliveryDatetimeSelected,
  ChangePaymentMethod,
  ChangePaymentMethodOption,
  CancelOrderSac,
  SupportOptionSent,
  ForgottenCoupon,
  ForgottenCouponRedeemed,
  DeliveredServiceDriverSac,
  DeliveredWrongAmountSac,
  DeliveredOrderNeverArrivedSac,
  DeliveredOrderProductsSac,
  DeliveredOtherSac,
  InvoiceToCompany,
  HelpCenterHomeViewed,
  ProductChangeConfirmed,
  AddressChange,
  StreetNumberEdited,
  addProductGroup,
  GeolocationClicked,
  GeolocationSelected,
  GeolocationViewed,
  StoriesInfoClicked,
  SellersInfoClicked,
  ReceiveBySelected,
  ReceivedByOtherPersonConfirmed,
  CreditCardAddAttempt,
  PrimeOptionSelected,
  PrimeAvailablePlans,
  PrimePlansViewed,
  OrderDetailHcChat,
  CloseChat,
  ChatForm,
  LiveChat,
  ReturnToOrder,
  PrimePlanSelected,
  PrimeSubscriptionSummary,
  PrimeSubscriptionSummaryViewed,
  PrimeSelectPaymentMethodClicked,
  CreditCardNameAdded,
  CreditCardExpirationDateAdded,
  CreditCardCvvAdded,
  CreditCardIdAdded,
  PrimeBillingFormViewed,
  PrimePaymentOk,
  PrimePaymentFailed,
  PrimeSubscriptionConfirmation,
  PrimeConfirmationViewed,
  GoToHomeClicked,
  GoToSubscriptionInfoClicked,
  PrimeSubscriptionInfo,
  PrimeInfoViewed,
  PrimeCancellationStarted,
  PrimeCancellationConfirmed,
  PrimeTrialViewed,
  PrimeTrialSelected,
  PrimePaymentAttempted,
  SurveySentChat,
  SurveyOmissionChat,
} from './bigquery/events';

import { PageViewAnalyticEvent } from './analytics/analytic-pageview';
import { AcquisitionAnalyticEvent } from './analytics/analytic-acquisition';
import { ProductAddedAnalyticEvent } from './analytics/analytic-add-product';
import { ReorderAnalyticEvent } from './analytics/analytic-reorder';
import { OrderCompletedAnalyticEvent } from './analytics/analytic-order-completed';

const events = {};

/**
 * @param store
 * @param wizard
 * @return Promise<null>
 */
async function setUserAttributes(store, wizard) {
  const data = await store.dispatch('user/getAnalyticsData');
  if (data && data.attributes) {
    wizard.setVariables(data.attributes);
  }
}
/**
 * Inicializa la configuración de Leanplum
 *
 * @return {Promise<boolean>}
 */
function initLeanplum(store) {
  const user = Array.isArray(store.state.user.user) ? {} : store.state.user.user;
  // eslint-disable-next-line
  const wizard = new Wizard(Leanplum, {
    devMode: process.env.leanplum.devMode,
    appId: process.env.leanplum.appId,
    appKey: process.env.leanplum.appKey,
  });

  if (user.id) {
    setUserAttributes(store, wizard);
    wizard.setUserId(user.id);
  }

  return wizard.start(user.id, {});
}
export default function initEvents({ store }) {
  initLeanplum(store);

  // pageview
  events.pageView = new EventGroup([PageViewAnalyticEvent], store);

  // home_opened
  events.homeOpened = new EventGroup([HomeOpenedBigQuery, leanplum('home_opened')], store);

  // store_opened
  events.storeOpened = new EventGroup([StoreOpenedBigQuery, leanplum('store_opened')], store);

  // store_selected
  events.storeSelected = new EventGroup([StoreSelectedBigQuery, leanplum('store_selected')], store);

  // department_viewed
  events.departmentViewed = new EventGroup([DepartmentViewedBigQuery, leanplum('department_viewed')], store);

  // shelf_clicked
  events.shelfClicked = new EventGroup([ShelfClickedBigQuery, leanplum('shelf_viewed')], store);

  // address_selected
  events.addressSelected = new EventGroup([AddressSelectedBigQuery, leanplum('address_selected')], store);

  // address_input_viewed
  events.addressInputViewed = new EventGroup([AddressInputViewedBigQuery, leanplum('address_input_viewed')], store);

  // address_entered
  events.addressEntered = new EventGroup([AddressEnteredBigQuery, leanplum('address_entered')], store);

  // frequently_asked_questions
  events.frequentlyAskedQuestions = new EventGroup(
    [FrequentlyAskedQuestionsBigQuery, leanplum('frequently_asked_questions')],
    store,
  );

  // address_selected_from_zones
  events.addressSelectedFromZones = new EventGroup([AddressSelectedFromZonesBigQuery], store);

  // address_validated
  events.addressValidated = new EventGroup([AddressValidatedBigQuery, leanplum('address_validated')], store);

  // product_added
  events.productAdded = new EventGroup(
    [ProductAddedBigQuery, ProductAddedAnalyticEvent, leanplum('product_added')],
    store,
  );

  // cart_viewed
  events.cartViewed = new EventGroup([CartViewedBigQuery, leanplum('cart_viewed')], store);

  // checkout_started
  events.checkoutStarted = new EventGroup([CheckoutStartedBigQuery, leanplum('checkout_started')], store);

  // order_completed
  events.orderCompleted = new EventGroup(
    [OrderCompletedBigQuery, OrderCompletedAnalyticEvent, leanplum('order_completed')],
    store,
  );

  // product_purchased
  events.productPurchased = new EventGroup([ProductPurchasedBigQuery, leanplum('product_purchased')], store);

  // widget_account_clicked
  events.widgetAccountClicked = new EventGroup([WidgetAccountClickedBigQuery], store);

  // addresses_viewed_by_widget_account
  events.addressesViewedByWidgetAccount = new EventGroup([AddressesViewedByWidgetAccountBigQuery], store);

  // orders_viewed_by_ widget_account
  events.ordersViewedByWidgetAccount = new EventGroup([OrdersViewedByWidgetAccountBigQuery], store);

  // payment methods_viewed_by_ widget_account
  events.paymentViewedByWidgetAccount = new EventGroup([PaymentViewedByWidgetAccountBigQuery], store);

  // credits_viewed_by_ widget_account
  events.creditsViewedByWidgetAccount = new EventGroup([CreditsViewedByWidgetAccountBigQuery], store);

  // addresses_viewed_by_account
  events.addressesViewedByAccount = new EventGroup([AddressesViewedByAccountBigQuery], store);

  // orders_viewed_by_account
  events.ordersViewedByAccount = new EventGroup([OrdersViewedByAccountBigQuery], store);

  // payment methods_viewed_by_account
  events.paymentViewedByAccount = new EventGroup([PaymentViewedByAccountBigQuery], store);

  // The user opens the credits tab
  events.creditsViewedByAccount = new EventGroup(
    [CreditsViewedByAccountBigQuery, leanplum('referral_code_viewed')],
    store,
  );

  // orders_viewed_by_account
  events.ordersViewed = new EventGroup([OrdersViewedBigQuery], store);

  // address_input_viewed_by_account
  events.addressInputViewedByAccount = new EventGroup([AddressInputViewedByAccountBigQuery], store);

  // address_edited_by_account
  events.addressEditedByAccount = new EventGroup([AddressEditedByAccountBigQuery], store);

  // address_deleted_by_account
  events.addressDeletedByAccount = new EventGroup([AddressDeletedByAccountBigQuery], store);

  // address_updated_by_account
  events.addressUpdatedByAccount = new EventGroup([AddressUpdatedByAccountBigQuery], store);

  // bill_viewed
  events.billViewed = new EventGroup([BillViewedBigQuery], store);

  // cart_deleted
  events.cartDeleted = new EventGroup([CartDeletedBigQuery], store);

  // product_viewed
  events.productViewed = new EventGroup([ProductViewedBigQuery, leanplum('product_viewed')], store);

  // products_searched
  events.productsSearched = new EventGroup([ProductsSearchedBigQuery, leanplum('product_searched')], store);

  // last_Products_bought_viewed
  events.lastProductsBoughtViewed = new EventGroup(
    [LastProductsBoughtViewedBigQuery, leanplum('last_products_bought_viewed')],
    store,
  );

  // last_Products_bought_viewed
  events.productDealsOpened = new EventGroup([ProductDealsOpenedBigQuery, leanplum('product_deals_opened')], store);

  // product_removed
  events.productRemoved = new EventGroup([ProductRemovedBigQuery, leanplum('product_removed')], store);

  // suggested_product_viewed
  events.suggestedProductViewed = new EventGroup(
    [SuggestedProductViewedBigQuery, leanplum('suggested_product_added')],
    store,
  );

  // address_details_entered
  events.addressDetailsEntered = new EventGroup([AddressDetailsEnteredBigQuery], store);

  // delivery_datetime_saved
  events.deliveryDatetimeSaved = new EventGroup(
    [
      DeliveryDatetimeSavedBigQuery,
      leanplum('delivery_datetime_saved'), // ¿Cómo enviarle los datos del evento?
    ],
    store,
  );

  // payment_method_cofirmed
  events.paymentMethodCofirmed = new EventGroup(
    [PaymentMethodCofirmedBigQuery, leanplum('payment_method_selected')],
    store,
  );

  // credit_card_info_entered
  events.creditCardInfoEntered = new EventGroup(
    [CreditCardInfoEnteredBigQuery, leanplum('credit_card_info_entered')],
    store,
  );

  // banner_viewed
  events.bannerViewed = new EventGroup([BannerViewedBigQuery], store);

  // banner_clicked
  events.bannerClicked = new EventGroup([BannerClickedBigQuery, leanplum('banner_clicked')], store);

  // promotions_viewed
  events.promotionsViewed = new EventGroup([PromotionsViewedBigQuery], store);

  // checkout_error
  events.checkoutError = new EventGroup([checkoutErrorBigQuery], store);

  // order_status_viewed
  events.orderStatusViewed = new EventGroup([OrderStatusViewedBigQuery], store);

  // credit_card_added_by_account
  events.creditCardAddedByAccount = new EventGroup([CreditCardAddedByAccountBigQuery], store);

  // credit_card_deleted_by_account
  events.creditCardDeletedByAccount = new EventGroup([CreditCardDeletedByAccountBigQuery], store);

  // credit_card_entered_by_account
  events.creditCardEnteredByAccount = new EventGroup([CreditCardEnteredByAccountBigQuery], store);

  // referral_code_copied
  events.referralCodeCopied = new EventGroup([ReferralCodeCopiedBigQuery], store);

  // referral_code_shared
  events.referralCodeShared = new EventGroup([ReferralCodeSharedBigQuery], store);

  // address_list
  events.addressList = new EventGroup([AddressList, leanplum('address_list')], store);

  // address_confirmed
  events.addressConfirmed = new EventGroup([AddressConfirmed, leanplum('address_confirmed')], store);

  // code_entered
  events.codeEntered = new EventGroup([leanplum('code_entered')], store);
  // code_entered
  events.coverage = new EventGroup([leanplum('coverage')], store);

  // acquisition
  events.acquisition = new EventGroup([AcquisitionAnalyticEvent, leanplum('acquisition')], store);

  // The cart has reached the minimum order amount
  events.minimumOrderReached = new EventGroup([leanplum('minimum_order')], store);

  // User click on shared referral code button
  events.referralCodeShared = new EventGroup([leanplum('referral_code_shared')], store);

  // User sees an unavailable product
  events.unavailableProducts = new EventGroup([leanplum('unavailable_products')], store);

  // User signed in
  events.userSignedIn = new EventGroup([leanplum('user_signed_in')], store);

  // User signed up
  events.userSignedUp = new EventGroup([leanplum('user_signed_up')], store);

  // Reorder
  events.reorder = new EventGroup([ReorderAnalyticEvent, leanplum('reorder')], store);

  // store_opened_final
  events.storeOpenedFinal = new EventGroup([leanplum('store_opened_final')], store);

  // products_added_from_past_order
  events.productsAddedFromPastOrder = new EventGroup(
    [ProductAddedFromPastOrderBigQuery, leanplum('products_added_from_past_order')],
    store,
  );

  // old_to_new_transition
  events.oldToNewTransition = new EventGroup([OldToNewTransitionBigQuery], store);

  // old_to_new_transition
  events.downloadAppWeb = new EventGroup([DownloadAppWebBigQuery], store);

  events.serviceProposalImpresionBigQuery = new EventGroup([ServiceProposalImpresionBigQuery], store);

  events.serviceProposalResponseBigQuery = new EventGroup([ServiceProposalResponseBigQuery], store);

  events.serviceProposalClosedBigQuery = new EventGroup([ServiceProposalClosedBigQuery], store);

  events.productViewedSponsored = new EventGroup([ProductViewedSponsoredBigQuery], store);

  events.addressMapViewed = new EventGroup([AddressMapViewed], store);

  // user_menu_selected
  events.userMenuSelected = new EventGroup([UserMenuSelected], store);
  // next_available_delivery_slot_alert
  events.nextAvailableDeliverySlotAlert = new EventGroup([NextAvailableDeliverySlotAlert], store);
  // home_option_selected
  events.homeOptionSelected = new EventGroup([HomeOptionSelected], store);
  // city_selected
  events.citySelected = new EventGroup([CitySelected], store);
  // country_selected
  events.countrySelected = new EventGroup([CountrySelected], store);
  // product_list_viewed
  events.productsListViewed = new EventGroup([ProductsListViewed], store);
  // no_coverage
  events.noCoverage = new EventGroup([NoCoverage], store);
  // address_typed
  events.addressTyped = new EventGroup([AddressTyped], store);

  events.editPersonalData = new EventGroup([EditPersonalDataBigQuery], store);
  events.editPersonalDataName = new EventGroup([EditPersonalDataNameBigQuery], store);
  events.editPersonalDataMail = new EventGroup([EditPersonalDataMailBigQuery], store);
  events.editPersonalDataPhone = new EventGroup([EditPersonalDataPhoneBigQuery], store);

  events.advertisingPreferences = new EventGroup([AdvertisingPreferencesBigQuery], store);
  events.advertisingPreferencesOn = new EventGroup([AdvertisingPreferencesOnBigQuery], store);
  events.advertisingPreferencesOff = new EventGroup([AdvertisingPreferencesOffBigQuery], store);
  events.advertisingPreferencesOffConfirmed = new EventGroup([AdvertisingPreferencesOffConfirmedBigQuery], store);

  // delivery_datetime_selected
  events.deliveryDatetimeSelected = new EventGroup([DeliveryDatetimeSelected], store);
  // payment_method_viewed
  events.paymentMethodViewed = new EventGroup([PaymentMethodViewed], store);
  // payment_method_selected
  events.paymentMethodSelected = new EventGroup([PaymentMethodSelected], store);
  // Help center add products
  events.productChangeCheckout = new EventGroup([ProductChangeCheckoutBigQuery], store);
  // product_change_modified
  events.productChangeModified = new EventGroup([ProductChangeModifiedBigQuery], store);
  // delivery_datetime_view_shown
  events.deliveryDatetimeViewShown = new EventGroup([DeliveryDatetimeViewShown], store);
  // shipping_details_entered
  events.shippingDetailsEntered = new EventGroup([ShippingDetailsEntered], store);
  // cellphone_login_verification
  events.cellphoneLoginVerification = new EventGroup([CellphoneLoginVerification], store);
  // no_verification_code
  events.noVerificationCode = new EventGroup([NoVerificationCode], store);
  // user_logout
  events.userLogout = new EventGroup([UserLogout], store);

  // Stories
  events.channelClicked = new EventGroup([ChannelClicked], store);
  events.storyViewed = new EventGroup([StoryViewed], store);
  events.storyCtaClicked = new EventGroup([StoryCtaClicked], store);
  events.storyProfileClicked = new EventGroup([StoryProfileClicked], store);
  events.storyExited = new EventGroup([StoryExited], store);
  events.lastStoryFinished = new EventGroup([LastStoryFinished], store);
  events.storyError = new EventGroup([StoryError], store);

  // order_list_viewed
  events.ordersListViewed = new EventGroup([OrdersListViewed], store);
  // password_recovery
  events.passwordRecovery = new EventGroup([PasswordRecovery], store);
  // user_menu_selected
  events.userMenuSelected = new EventGroup([UserMenuSelected], store);
  // user_login
  events.userLogin = new EventGroup([UserLogin], store);
  // user_login_with_nequi
  events.userLoginWithNequi = new EventGroup([UserLoginWithNequi], store);
  // cellphone_login_method
  events.cellphoneLoginMethod = new EventGroup([CellphoneLoginMethod], store);
  // email_login
  events.emailLogin = new EventGroup([EmailLogin], store);
  //  sort_by_selected
  events.SortBySelected = new EventGroup([SortBySelected], store);
  // privacy_policy_viewed
  events.privacyPolicyViewed = new EventGroup([PrivacyPolicyViewed], store);
  // terms_and_conditions_viewed
  events.termsAndConditionsViewed = new EventGroup([TermsAndConditionsViewed], store);
  // recovery_send
  events.recoverySend = new EventGroup([RecoverySend], store);
  // support_clicked
  events.supportClicked = new EventGroup([SupportClicked], store);
  // delete_account
  events.deleteAccount = new EventGroup([DeleteAccount], store);
  // delete_account_confirmed
  events.deleteAccountConfirmed = new EventGroup([DeleteAccountConfirmed], store);
  // brand_room_viewed
  events.brandRoomViewed = new EventGroup([BrandRoomViewed], store);
  // privacy_policy_accepted
  events.privacyPolicyAccepted = new EventGroup([PrivacyPolicyAccepted], store);
  // credit_card_number_added
  events.creditCardNumberAdded = new EventGroup([CreditCardNumberAdded], store);
  // user_details_viewed
  events.userDetailsViewed = new EventGroup([UserDetailsViewed], store);
  // privacy_policy_clicked
  events.privacyPolicyClicked = new EventGroup([PrivacyPolicyClicked], store);
  // video_banner_viewed
  events.videoBannerViewed = new EventGroup([VideoBannerViewed], store);
  // video_banner_exited
  events.videoBannerExited = new EventGroup([VideoBannerExited], store);
  // video_banner_exited
  events.videoBannerCtaClicked = new EventGroup([VideoBannerCtaClicked], store);
  // product_video_viewed
  events.productVideoViewed = new EventGroup([ProductVideoViewed], store);
  // product_video_cta_clicked
  events.productVideoCtaClicked = new EventGroup([ProductVideoCtaClicked], store);
  // product_video_exited
  events.productVideoExited = new EventGroup([ProductVideoExited], store);
  // order_detail_viewed
  events.orderDetailViewed = new EventGroup([OrderDetailViewed], store);
  // credits_viewed
  events.creditsViewed = new EventGroup([CreditsViewed], store);
  // credit_card_added
  events.creditCardAdded = new EventGroup([CreditCardAdded], store);
  // credit_card_removed
  events.creditCardRemoved = new EventGroup([CreditCardRemoved], store);
  // adress_removed
  events.adressRemoved = new EventGroup([AdressRemoved], store);
  // story_shared
  events.storyShared = new EventGroup([StoryShared], store);
  // saving_modal_viewed
  events.savingModalViewed = new EventGroup([SavingModalViewed], store);
  // deeplink_not_found
  events.deeplinkNotFound = new EventGroup([DeepLinkNotFound], store);
  // average_price_other_stores
  events.averagePriceOtherStores = new EventGroup([AveragePriceOtherStores], store);

  events.prizeClicked = new EventGroup([PrizeClicked], store);

  events.infoCampaigns = new EventGroup([InfoCampaigns], store);

  events.giftProgressTrackerClicked = new EventGroup([GiftProgressTrackerClicked], store);
  // confirm_order_viewed
  events.confirmOrderViewed = new EventGroup([ConfirmOrderViewed], store);
  // refund_request_selected
  events.refundRequestSelected = new EventGroup([RefundRequestSelected], store);
  // refund_request_sent
  events.refundRequestSend = new EventGroup([RefundRequestSend], store);
  // help_clicked
  events.helpClicked = new EventGroup([HelpClicked], store);
  // different_problem;
  events.differentProblem = new EventGroup([DifferentProblem], store);
  // canceled_order
  events.canceledOrder = new EventGroup([CanceledOrder], store);
  // cancel_order_sac;
  events.cancelOrderSac = new EventGroup([CancelOrderSac], store);
  // canceled_order_option
  events.canceledOrderOption = new EventGroup([CanceledOrderOption], store);
  // late_order;
  events.lateOrder = new EventGroup([LateOrder], store);
  // change_delivery_datetime;
  events.changeDeliveryDatetime = new EventGroup([ChangeDeliveryDatetime], store);
  // change_delivery_datetime_option;
  events.changeDeliveryDatetimeOption = new EventGroup([ChangeDeliveryDatetimeOption], store);
  // change_delivery_datetime_selected;
  events.changeDeliveryDatetimeSelected = new EventGroup([ChangeDeliveryDatetimeSelected], store);
  // change_payment_method;
  events.changePaymentMethod = new EventGroup([ChangePaymentMethod], store);
  // change_payment_method_option
  events.changePaymentMethodOption = new EventGroup([ChangePaymentMethodOption], store);

  // support_option_sent;
  events.supportOptionSent = new EventGroup([SupportOptionSent], store);
  // forgotten_coupon;
  events.forgottenCoupon = new EventGroup([ForgottenCoupon], store);
  // delivered_service_driver_sac
  events.deliveredServiceDriverSac = new EventGroup([DeliveredServiceDriverSac], store);
  // delivered_wrong_amount_sac
  events.deliveredWrongAmountSac = new EventGroup([DeliveredWrongAmountSac], store);
  // delivered_order_never_arrived_sac;
  events.deliveredOrderNeverArrivedSac = new EventGroup([DeliveredOrderNeverArrivedSac], store);
  // delivered_order_products_sac;
  events.deliveredOrderProductsSac = new EventGroup([DeliveredOrderProductsSac], store);
  // delivered_other_sac
  events.deliveredOtherSac = new EventGroup([DeliveredOtherSac], store);
  // forgotten_coupon_redeemed;
  events.forgottenCouponRedeemed = new EventGroup([ForgottenCouponRedeemed], store);
  // invoice_to_company;
  events.invoiceToCompany = new EventGroup([InvoiceToCompany], store);
  // help_center_home_viewed;
  events.helpCenterHomeViewed = new EventGroup([HelpCenterHomeViewed], store);
  // product_change_confirmed;
  events.productChangeConfirmed = new EventGroup([ProductChangeConfirmed], store);
  // address_change
  events.addressChange = new EventGroup([AddressChange], store);
  // street_number_edited'
  events.streetNumberEdited = new EventGroup([StreetNumberEdited], store);
  // Products story widget added'
  events.addProductGroup = new EventGroup([addProductGroup], store);
  // geolocation_clicked
  events.geolocationClicked = new EventGroup([GeolocationClicked], store);
  // geolocation_selected
  events.geolocationSelected = new EventGroup([GeolocationSelected], store);
  // geolocation_confirmed
  events.geolocationViewed = new EventGroup([GeolocationViewed], store);
  // address_change
  events.storiesInfoClicked = new EventGroup([StoriesInfoClicked], store);
  // street_number_edited'
  events.sellersInfoClicked = new EventGroup([SellersInfoClicked], store);
  // received_by_selected
  events.receiveBySelected = new EventGroup([ReceiveBySelected], store);
  // received_by_other_person_confirmed
  events.receivedByOtherPersonConfirmed = new EventGroup([ReceivedByOtherPersonConfirmed], store);
  // credit_card_add_attempt
  events.creditCardAddAttempt = new EventGroup([CreditCardAddAttempt], store);
  // prime_option_selected
  events.primeOptionSelected = new EventGroup([PrimeOptionSelected], store);
  // prime_available_plans
  events.primeAvailablePlans = new EventGroup([PrimeAvailablePlans], store);
  // prime_plans_viewed
  events.primePlansViewed = new EventGroup([PrimePlansViewed], store);
  // orderdetail_hc_chat
  events.orderDetailHcChat = new EventGroup([OrderDetailHcChat], store);
  // close_chat
  events.closeChat = new EventGroup([CloseChat], store);
  // chat_form
  events.chatForm = new EventGroup([ChatForm], store);
  // live_chat
  events.liveChat = new EventGroup([LiveChat], store);
  // return_to_order
  events.returnToOrder = new EventGroup([ReturnToOrder], store);
  // prime_plan_selected
  events.primePlanSelected = new EventGroup([PrimePlanSelected], store);
  // prime_subscription_summary
  events.primeSubscriptionSummary = new EventGroup([PrimeSubscriptionSummary], store);
  // prime_subscription_summary_viewed
  events.primeSubscriptionSummaryViewed = new EventGroup([PrimeSubscriptionSummaryViewed], store);
  // prime_select_payment_method_clicked
  events.PrimeSelectPaymentMethodClicked = new EventGroup([PrimeSelectPaymentMethodClicked], store);
  // credit_card_name_added
  events.creditCardNameAdded = new EventGroup([CreditCardNameAdded], store);
  // credit_card_expiration_date_added
  events.creditCardExpirationDateAdded = new EventGroup([CreditCardExpirationDateAdded], store);
  // credit_card_cvv_added
  events.creditCardCvvAdded = new EventGroup([CreditCardCvvAdded], store);
  // credit_card_id_added
  events.creditCardIdAdded = new EventGroup([CreditCardIdAdded], store);
  // prime_billing_form_viewed
  events.primeBillingFormViewed = new EventGroup([PrimeBillingFormViewed], store);
  // prime_payment_ok
  events.primePaymentOk = new EventGroup([PrimePaymentOk], store);
  // prime_payment_failed
  events.primePaymentFailed = new EventGroup([PrimePaymentFailed], store);
  // prime_subscription_confirmation
  events.primeSubscriptionConfirmation = new EventGroup([PrimeSubscriptionConfirmation], store);
  // prime_confirmation_viewed
  events.primeConfirmationViewed = new EventGroup([PrimeConfirmationViewed], store);
  // go_to_home_clicked
  events.goToHomeClicked = new EventGroup([GoToHomeClicked], store);
  // go_to_subscription_info_clicked
  events.goToSubscriptionInfoClicked = new EventGroup([GoToSubscriptionInfoClicked], store);
  // prime_subscription_info
  events.primeSubscriptionInfo = new EventGroup([PrimeSubscriptionInfo], store);
  // prime_info_viewed
  events.primeInfoViewed = new EventGroup([PrimeInfoViewed], store);
  // prime_cancellation_started
  events.primeCancellationStarted = new EventGroup([PrimeCancellationStarted], store);
  // prime_cancellation_started
  events.primeCancellationConfirmed = new EventGroup([PrimeCancellationConfirmed], store);
  // prime_trial_viewed
  events.primeTrialViewed = new EventGroup([PrimeTrialViewed], store);
  // prime_trial_selected
  events.primeTrialSelected = new EventGroup([PrimeTrialSelected], store);
  // prime_payment_attempted
  events.primePaymentAttempted = new EventGroup([PrimePaymentAttempted], store);

  // survey_sent_chat
  events.surveySentChat = new EventGroup([SurveySentChat], store);

  // survey_omission_chat
  events.surveyOmissionChat = new EventGroup([SurveyOmissionChat], store);
}

Vue.prototype.$events = events;
