import getMyAddress from '~/endpoints/address/address-list-endpoints';
import addressListTransform from '~/transforms/address/address-list-transform';

const getMyAddressUseCase = async ({ currentPage = 1, perPage = 30 }) => {
  const { response, error } = await getMyAddress({ currentPage, perPage });
  if (error === null && response) {
    const addressList = await addressListTransform(response);
    return { data: addressList, error: null };
  } else {
    if (error && error.response.data instanceof Object && error.response.data.errors instanceof Array) {
      return { data: null, error: error.response.data.errors[0] };
    }
    return { data: null, error };
  }
};

export default getMyAddressUseCase;
