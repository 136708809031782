export function getPercentFreeDelivery(freeDelivery, cart, freeDeliveryDays) {
  if (freeDelivery === null || freeDelivery === 0 || freeDeliveryDays) {
    return 100;
  } else {
    let percentTotal = (100 / freeDelivery) * cart.total();

    return percentTotal > 100 ? 100 : percentTotal;
  }
}
export function mustNotifyFreeDelivery(freeDelivery, cart) {
  if (freeDelivery === null) {
    if (cart.total() > 0) {
      return true;
    }
    return false;
  } else {
    let percentTotal = (100 / freeDelivery) * cart.total();
    if (percentTotal >= 100 && freeDelivery > 0) {
      return true;
    }
    return false;
  }
}
