export default function ({ isHMR, app, store, route, params, error, redirect }) {
  const defaultLocale = app.i18n.fallbackLocale;

  const currentCountry = store.getters['home/getCurrentCountry'];
  const { code, language } = currentCountry;
  const langName = `${language}-${code.toUpperCase()}`;

  store.commit('i18n/SET_LANG', langName);
  const currentLocale = store.state.i18n.locale;

  // if (!Trans.isLocaleSupported(locale)) return Promise.reject(new Error('Locale not supported'))
  if (app.i18n.locale === currentLocale) return;
  // const response = loadLocaleFile(currentLocale);
  // app.i18n.setLocaleMessage(currentLocale, response);
  app.i18n.locale = currentLocale ? currentLocale : defaultLocale;
  // loadLocaleFile(currentLocale).then(response => {
  //   app.i18n.setLocaleMessage(currentLocale, response);
  //   app.i18n.locale = currentLocale ? currentLocale : defaultLocale;
  // });
}

function loadLocaleFile(locale) {
  const localeFilePath = `~/assets/lang/${locale}.json`;
  return import(localeFilePath);
}
