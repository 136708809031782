import * as Hammer from 'hammerjs';

const listEvent = new Map();

export function manageHammerEvents(item) {
  const { el, mode } = this;
  const { type, event, cta } = item;
  if (mode == 'set') {
    const hammerEvent = Hammer(el);
    hammerEvent.get(type).set({
      direction: Hammer.DIRECTION_ALL,
    });
    hammerEvent.on(event, ev => {
      cta(ev);
    });
    listEvent.set(el, hammerEvent);
  } else {
    const hammerEvent = listEvent.get(el);
    hammerEvent.remove(type);
    hammerEvent.off(event);
  }
}

export function assignHammerEvents(el, binding, mode) {
  const { value } = binding;
  const arr = Array.isArray(value) ? value : [value];
  arr.forEach(manageHammerEvents, { el, mode });
}
