export default {
  data: () => ({
    dragging: false,
  }),
  methods: {
    listenDragEvent(ref) {
      this.$watch(
        () => this.$refs[ref].dragging,
        (val) => {
          this.dragging = val;
        },
      );
    },
    renderVueCarousel(ref) {
      setTimeout(this.$refs[ref].computeCarouselWidth, 300);
    },
    combineCarouselFun(ref) {
      this.listenDragEvent(ref);
      this.renderVueCarousel(ref);
    },
  },
};
