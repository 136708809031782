import { nuxtInstance } from '~/plugins/nuxt-instance';
import { CAMPAIGNS_MAIN_TYPES } from '~/constants/campaigns_types';
import { openModal } from '~/components/utils/mq-modal-managment';

export function handlerAlertWinCampaignCart(listCampaignOld, listCampaignWin) {
  const listNewWinCampaigns = [];
  if (listCampaignOld.length > 0 && listCampaignWin.length > 0) {
    listCampaignWin.forEach((item) => {
      const { tag, percentage, campaignType } = item;
      if (
        listCampaignOld.some(
          (el) =>
            el.tag === tag &&
            percentage === 100 &&
            el.percentage < 100 &&
            campaignType === CAMPAIGNS_MAIN_TYPES.GENERAL,
        )
      ) {
        listNewWinCampaigns.push(item);
      }
    });
  } else if (listCampaignOld.length === 0 && listCampaignWin.length > 0) {
    listNewWinCampaigns.push(
      ...listCampaignWin.filter((el) => el.percentage === 100 && el.campaignType === CAMPAIGNS_MAIN_TYPES.GENERAL),
    );
  }
  listNewWinCampaigns.sort((campaignA, campaignB) => new Date(campaignA.endDate) - new Date(campaignB.endDate));
  const [first] = listNewWinCampaigns;
  if (first) {
    showModal(first);
  }
}
async function showModal(campaign) {
  await openModal({
    component: () => import('~/components/campaign/bines/dialogs/mq-modal-win-campaign'),
    props: {
      campaign,
      verifyCampaignViewed: false,
    },
    group: 'group-modal-bines',
    config: {
      position: {
        value: 'center',
      },
      behaviors: {
        closableContainer: false,
      },
    },
  });
}
