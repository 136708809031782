//
//
//
//
//
//
//
//
//
//
//

import VLazyImage from 'v-lazy-image';

export default {
  name: 'MqImgLazy',
  components: { VLazyImage },
  props: {
    alt: {
      type: String,
      default: 'Merqueo supermercado',
    },
    src: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default() {
        return this.$getImage('no_disponible.svg');
      },
    },
    errorFunction: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    onImageError(e) {
      e.target.src = this.placeholder;
      e.target.classList.add('v-lazy-image-loaded');
      this.errorFunction();
    },
  },
};
