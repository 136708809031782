import { getBannerDetailsEndpoint } from '~/endpoints/banners/get-banner-details-endpoint';
import { getBannerTransform } from '~/transforms/banners/get-banners-transform';
import { errorTransformsFetch } from '~/transforms/errors/error-transform';

/**
 * @param {{storeId: number, bannerId: number, zoneId: number}}
 */
export async function getBannerDetailsUseCase({ storeId, bannerId, zoneId }) {
  const { error, response } = await getBannerDetailsEndpoint({ storeId, bannerId, zoneId });
  if (!error && response.status === 200) {
    const data = await response.json();
    const banners = getBannerTransform(data);
    return { data: banners, error: null };
  } else if (!error && response.status === 280) {
    return { data: null, error: errorTransformsFetch(await response.json()) };
  } else if (error) {
    return { data: null, error };
  }
  return {};
}
