//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

export default {
  name: 'MqTabsFooter',
  props: ['hidden'],
  computed: {
    ...mapState({
      banners: state => state.home.banners,
    }),
    bannerBottom() {
      const { banners } = this;
      return this.$route.name === 'city-store-department' && banners && banners.length;
    },
  },
};
