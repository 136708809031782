// @flow
// Adopted and modified solution from Bohdan Didukh (2017)

import { getScreenMode } from '~/helpers/screen-mode';

// Older browsers don't support event options, feature detect it.
let hasPassiveEvents = false;
if (typeof window !== 'undefined') {
  const passiveTestOptions = {
    get passive() {
      hasPassiveEvents = true;
      return undefined;
    },
  };
  window.addEventListener('testPassive', null, passiveTestOptions);
  window.removeEventListener('testPassive', null, passiveTestOptions);
}

let locks = [];
let documentListenerAdded = false;
let initialClientY = -1;

// returns true if `el` should be allowed to receive touchmove events
const allowTouchMove = el =>
  locks.some(lock => {
    if (lock.options.allowTouchMove && lock.options.allowTouchMove(el)) {
      return true;
    }

    return false;
  });

const preventDefault = rawEvent => {
  const e = rawEvent || window.event;

  // For the case whereby consumers adds a touchmove event listener to document.
  // Recall that we do document.addEventListener('touchmove', preventDefault, { passive: false })
  // in disableBodyScroll - so if we provide this opportunity to allowTouchMove, then
  // the touchmove event on document will break.
  if (allowTouchMove(e.target)) {
    return true;
  }

  // Do not prevent if the event has more than one touch (usually meaning this is a multi touch gesture like pinch to zoom)
  if (e.touches.length > 1) return true;

  if (e.preventDefault) e.preventDefault();

  return false;
};

// https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions
const isTargetElementTotallyScrolled = targetElement =>
  targetElement ? targetElement.scrollHeight - targetElement.scrollTop <= targetElement.clientHeight : false;

const handleScroll = (event, targetElement) => {
  const clientY = event.targetTouches[0].clientY - initialClientY;

  if (allowTouchMove(event.target)) {
    return false;
  }

  if (targetElement && targetElement.scrollTop === 0 && clientY > 0) {
    // element is at the top of its scroll
    return preventDefault(event);
  }

  if (isTargetElementTotallyScrolled(targetElement) && clientY < 0) {
    // element is at the top of its scroll
    return preventDefault(event);
  }

  event.stopPropagation();
  return true;
};

export const disableBodyScroll = (targetElement, options) => {
  // targetElement must be provided, and disableBodyScroll must not have been
  // called on this targetElement before.
  if (!targetElement) {
    // eslint-disable-next-line no-console
    console.error(
      'disableBodyScroll unsuccessful - targetElement must be provided when calling disableBodyScroll on IOS devices.',
    );
    return;
  }

  if (targetElement && !locks.some(lock => lock.targetElement === targetElement)) {
    const lock = {
      targetElement,
      options: options || {},
    };

    locks = [...locks, lock];

    targetElement.ontouchstart = event => {
      if (event.targetTouches.length === 1) {
        // detect single touch
        initialClientY = event.targetTouches[0].clientY;
      }
    };
    targetElement.ontouchmove = event => {
      if (event.targetTouches.length === 1) {
        // detect single touch
        handleScroll(event, targetElement);
      }
    };

    if (!documentListenerAdded) {
      document.addEventListener('touchmove', preventDefault, hasPassiveEvents ? { passive: false } : undefined);
      documentListenerAdded = true;
    }
  }
};

export const clearAllBodyScrollLocks = () => {
  locks.forEach(lock => {
    lock.targetElement.ontouchstart = null;
    lock.targetElement.ontouchmove = null;
  });

  if (documentListenerAdded) {
    document.removeEventListener('touchmove', preventDefault, hasPassiveEvents ? { passive: false } : undefined);
    documentListenerAdded = false;
  }

  locks = [];

  // Reset initial clientY
  initialClientY = -1;
};

export const enableBodyScroll = targetElement => {
  if (!targetElement) {
    // eslint-disable-next-line no-console
    console.error(
      'enableBodyScroll unsuccessful - targetElement must be provided when calling enableBodyScroll on IOS devices.',
    );
    return;
  }

  targetElement.ontouchstart = null;
  targetElement.ontouchmove = null;

  locks = locks.filter(lock => lock.targetElement !== targetElement);

  if (documentListenerAdded && locks.length === 0) {
    document.removeEventListener('touchmove', preventDefault, hasPassiveEvents ? { passive: false } : undefined);

    documentListenerAdded = false;
  }
};

const $body = document.querySelector('body');
let scrollPosition = 0;
export function simpleDisableBodyScroll() {
  const screenMode = getScreenMode();
  if (screenMode === 'mobile' && $body.style.position !== 'fixed') {
    scrollPosition = window.pageYOffset;
    $body.style.overflow = 'hidden';
    $body.style.position = 'fixed';
    $body.style.top = `-${scrollPosition}px`;
    $body.style.width = '100%';
  } else {
    $body.style.overflow = 'hidden';
  }
}
export function simpleEnableBodyScroll() {
  const screenMode = getScreenMode();
  if (screenMode === 'mobile') {
    $body.style.removeProperty('overflow');
    $body.style.removeProperty('position');
    $body.style.removeProperty('top');
    $body.style.removeProperty('width');
    window.scrollTo(0, scrollPosition);
  } else {
    $body.style.removeProperty('overflow');
  }
}
export function simpleDisableContainerLayerScroll() {
  const containerContentLayer = document.querySelector('.container-content-layer');
  if (containerContentLayer) {
    containerContentLayer.style.pointerEvents = 'none';
  }
}
export function simpleEnableContainerLayerScroll() {
  const containerContentLayer = document.querySelector('.container-content-layer');
  if (containerContentLayer) {
    containerContentLayer.style.removeProperty('pointer-events');
  }
}
