import { optional } from '~/decorators/optional';
import { required } from '~/decorators/required';
import { scheme } from '~/decorators/scheme';

@scheme()
class ProductCart {
  @required()
  full_price: number;

  @required()
  id: number;

  @required()
  quantity_full_price: number;

  @required()
  quantity_special_price: number;

  @required()
  special_price: number;
}

@scheme()
class Merqueo {
  @required()
  date: string;

  @required()
  deliveryWindowId: number;

  @required()
  hour: string;
}

@scheme()
class Holder {
  @required()
  address: string;

  @required()
  documentNumber: string;

  @required()
  documentType: string;

  @required()
  name: string;

  @required()
  phone: string;
}

@scheme()
class InfoCreditCard {
  @required()
  cvv?: string;

  @required()
  holder: Holder;

  @optional()
  id?: number;

  @required()
  installments: string;

  @required()
  month: string;

  @required()
  number: number;

  @required()
  year: string;
}

@scheme()
class DeliveryTimes {
  @required()
  merqueo: Merqueo;
}

@scheme()
export class ValidateTotalsParams {
  @required()
  cart: ProductCart[];

  @optional()
  campaignPrizeIds?: Array<number>;

  @optional()
  code?: string;

  @optional()
  creditCard?: InfoCreditCard;

  @required()
  deliveryTimes: DeliveryTimes;

  @optional()
  paymentMethod?: string;

  @required()
  storeId: number;

  @optional()
  useCredits?: boolean;

  @required()
  zoneId: number;

  constructor(params: Partial<ValidateTotalsParams>) {
    Object.assign(this, params);
  }
}
