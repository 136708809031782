import moment from 'moment';
import cache from './axios_cache';
import { handlerErrors } from '~/errors/catchErrors';
import { openModal } from '~/components/utils/mq-modal-managment';

/* TODO: @remove - Esto es solo para arreglar los token que se
 * encuentran en el localstorage de forma invalida, remover al mes de su creación
 */
let onlyForceOnce = false;
function checkFailureToken(token) {
  const jsonAttributes = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString('utf8'));
  return !(jsonAttributes?.email && jsonAttributes?.status);
}
// TODO: @remove - fin del ajuste

export default function ({ $axios, redirect, store }) {
  const defaults = $axios.defaults;
  const servicesIgnored = ['/promotional-codes', '/add-remove-products'];
  const httpCodesIgnored = [429];

  defaults.adapter = cache.adapter;
  $axios.onRequest((config) => {
    if (store.state.user.token && !new RegExp('refresh-token', 'gi').test(config.url)) {
      const currentToken = store.state.user.token;
      /* TODO: @remove - Esto es solo para arreglar los token que se
       * encuentran en el localstorage de forma invalida, remover al mes de su creación
       */
      if (typeof currentToken === 'string' && checkFailureToken(currentToken) && !onlyForceOnce) {
        onlyForceOnce = true;
        store.commit('user/SET_LAST_TIME_TOKEN');
        store.dispatch('user/refreshToken', currentToken).then((error) => {
          if (error) {
            store.commit('user/LOGOUT');
          }
        });
      }
      // TODO: @remove - Fin de ajuste
      config.headers.common['Authorization'] = `Bearer ${store.state.user.token}`;
      // config.headers.common['X-Merqueo-Agent'] = 'Web';
      let time = moment().unix();
      let lastTime = store.state.user.lastTimeToken;
      let diff = time - lastTime;

      if (config.url !== '/refresh-token') {
        // se refresca el token cada dia
        if (diff >= 86400) {
          // si es menor a 7 dias se refresca, si no se desloguea.
          if (diff <= 604800) {
            store.commit('user/SET_LAST_TIME_TOKEN');
            store.dispatch('user/refreshToken', currentToken);
          } else {
            store.commit('user/LOGOUT');
          }
        }
      }
    }
  });

  $axios.onResponse((result) => {
    let isOpenModalNotAvailable = store.state.ui.widgets.productsNotPromo;
    const regexUrlIgnored = new RegExp(servicesIgnored.join('|'));

    if (
      result.data &&
      result.config &&
      result.data.errors &&
      result.config.url.includes('/3.1') &&
      result.status === 280 &&
      !regexUrlIgnored.test(result.config.url)
    ) {
      handlerErrors(result, store);
    }

    if (!result.data.status && !isOpenModalNotAvailable) {
      let messageNotAvailable = null;

      let isModifyCart = false;

      switch (result.data.message) {
        case 'PRODUCT_NOT_VALID': {
          let productsNot = result.data.data;
          let objectCart = store.getters['cart/getCart'];

          let filterProductsNot = objectCart.filter((prod) => {
            let findProduct = productsNot.find((element) => {
              return element.id === prod.product.id;
            });

            if (findProduct) {
              store.commit('cart/DELETE_PRODUCT', prod, { root: true });
              return prod;
            }
          });
          let newProductsNotToStore = filterProductsNot.map((prod) => {
            return prod.product;
          });

          if (newProductsNotToStore.length) {
            store.commit('checkout/SET_PRODUCTS_NOT_AVAILABLE', newProductsNotToStore, {
              root: true,
            });
            messageNotAvailable = 'Los siguientes productos no están disponibles';
          }

          isModifyCart = true;
          break;
        }
        case 'PRODUCT_PRICE_CHANGED': {
          let productsPrice = result.data.data;
          if (productsPrice.length) {
            store.commit('checkout/SET_PRODUCTS_NOT_AVAILABLE', productsPrice, {
              root: true,
            });
            messageNotAvailable = 'El precio de los siguientes productos se han modificado';
            store.dispatch('checkout/updateProductsNotAvailable');
          }
          isModifyCart = true;
          break;
        }
        case 'ALREADY_BOUGHT': {
          let productsAlready = result.data.data;

          let objectCartAlready = store.getters['cart/getCart'];

          let newProductsAlready = productsAlready.map((prod) => {
            let findProduct = objectCartAlready.find({
              product: { id: prod.id },
            });
            if (findProduct) {
              store.commit('cart/DELETE_PRODUCT', findProduct, { root: true });
              findProduct.quantityFullPrice += findProduct.quantitySpecialPrice;
              findProduct.quantitySpecialPrice = null;
              findProduct.specialPrice = null;

              findProduct.product.specialPrice = null;
              findProduct.product.quantitySpecialPrice = null;

              store.commit(
                'cart/ADD_PRODUCT',
                {
                  product: findProduct.product,
                  type: 'increment',
                  quantity: findProduct.quantityFullPrice,
                },
                { root: true },
              );

              return findProduct.product;
            }
          });

          store.commit('checkout/SET_PRODUCTS_NOT_AVAILABLE', newProductsAlready, {
            root: true,
          });
          messageNotAvailable =
            'Ya pediste los siguientes productos con promoción en un pedido anterior, seran agregados con precio normal';
          isModifyCart = true;
          break;
        }
      }

      if (isModifyCart) {
        let objectCartNew = store.getters['cart/getCart'];
        store.commit('checkout/SET_CART_TEMP', objectCartNew.createCloneWithStore('temp'));
      }

      // enable modal producto no available
      if (messageNotAvailable) {
        store.commit('checkout/SET_MESSAGE_NOT_AVAILABLE', messageNotAvailable, {
          root: true,
        });
        const res = openModal({
          component: () => import('~/components/dialogs/mq-no-products'),
          props: {},
          group: 'group-modal-products-changes',
          config: {
            position: {
              value: 'center',
            },
            behaviors: {
              closableContainer: false,
            },
          },
        });
      }
    }
  });

  $axios.onError((err) => {
    const regexUrlIgnored = new RegExp(servicesIgnored.join('|'));

    if (httpCodesIgnored.includes(err.response.status)) {
      return;
    }

    if (
      err.config &&
      (err.config.url.includes('/api/3.0') || err.config.url.includes('/api/3.1')) &&
      !err.config.url.includes('/help-center') &&
      err.status !== 200 &&
      !regexUrlIgnored.test(err.config.url)
    ) {
      handlerErrors(err.response, store);
    }
    if (err.response?.status === 418) {
      store.dispatch('user/logout');
      store.commit('SET_WIDGET', ['deletedAccountModal', true]);
    }
    let code = parseInt(err.response && err.response?.status);
    let currentUrl = err.config.url;
    let regPages = [/\/stores\/(\d*)\/banners\/(\S*)/, /\/stores\/(\d*)\/find(\S*)(_slug=*)(\S*)/, /\/referrals/];

    let isPageError = false;
    regPages.forEach((element) => {
      if (element.exec(currentUrl)) {
        isPageError = true;
      }
    });

    // si es error 429 debe mostrar error
    isPageError = code === 429;

    let redirection = true;

    const messageResponse = err.response?.data?.message || '';

    switch (messageResponse) {
      case 'ZONE_CHANGED': {
        let getStoreID = store.getters['home/getCurrentStore'];
        let address = store.state.address.address_object;
        let address_text = store.state.address.address;
        redirection = false;
        store
          .dispatch('address/validateCoverage', {
            address: address_text,
            latitude: address.latitude,
            longitude: address.longitude,
            city: getStoreID.storeCovered.id,
          })
          .then((result) => {
            if (result.status) {
              store.commit('address/SET_ZONE_ID', result.data[0].zoneId);
            } else {
              store.commit('address/SET_ZONE_ID', null);
              store.commit('address/SET_ADDRESS', null);
              store.commit('address/SET_ADDRESS_OBJECT', {});
            }
          })
          .catch(() => {
            store.commit('address/SET_ZONE_ID', null);
            store.commit('address/SET_ADDRESS', null);
            store.commit('address/SET_ADDRESS_OBJECT', {});
          });

        break;
      }

      default:
        break;
    }

    if (isNaN(code)) {
      code = 500;
    }

    if (code !== 400 && redirection && isPageError) redirect(`/error/${code}`);
  });
}
