//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex';
import MqVueCarousel from '~/components/elements/mq-vue-carousel';
import SkBanners from '~/components/skeleton/sk-banners';
import bannersResponsive from '~/assets/json/res-banners';
import bannersCarResp from '~/assets/json/res-banners-vue-carousel';
import { nextButton, prevButton } from '~/assets/json/config-slider-brand-room';
import configCarousel from '~/mixins/configCarouselEvent';

export default {
  name: 'MqBannerHall',
  mixins: [configCarousel],
  components: { SkBanners, MqVueCarousel },
  props: {
    header: {
      type: Boolean,
      default: true,
    },
    id: {
      type: [String, Number],
      default: null,
    },
    brandRoomId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      responsive: bannersResponsive,
      responsiveBannerCar: bannersCarResp(),
      viewTop: getComputedStyle(document.documentElement).getPropertyValue('--widgettop'),
      nextLabel: nextButton,
      prevLabel: prevButton,
    };
  },
  computed: {
    ...mapState({
      banners: (state) => state.home.banners,
      screenMode: (state) => state.ui.screenMode,
      showDropdownAddress: (state) => state.home.showDropdownAddress,
      showStorie: (state) => state.home.showStorie,
    }),
    ...mapGetters({
      city: 'home/getCity',
      store: 'home/getCurrentStore',
    }),
    setBrandRoomId() {
      return typeof this.brandRoomId === 'number' ? this.brandRoomId : 0;
    },
    setDepartmentId() {
      return typeof this.id === 'number' ? this.id : 0;
    },
  },
  methods: {
    goToAllBanners() {
      this.$store.commit('SET_LOADING', true);
      this.$router.push(`/${this.city.slug}/${this.store.slug}/banners`);
    },
    getScreen() {
      const storeOptions = {
        'city-store': 'store',
        'city-store-department': 'department',
      };
      return storeOptions[this.$route.name];
    },
    getCallEvent(data) {
      return () => {
        const { item: bannerModel, position } = data;
        // prevent send event 'banner_viewed' when dropdown address is open
        if (!this.showDropdownAddress) {
          this.$events.bannerViewed.track({
            screen: this.getScreen(),
            title: bannerModel.title,
            type: bannerModel.category,
            departmentId: this.setDepartmentId,
            bannerId: bannerModel.id,
            brandRoomId: this.setBrandRoomId,
            position,
          });
        }
      };
    },
    getOptionsCarousel() {
      return {
        rootMargin: `-${this.viewTop} 0px 0px 0px`,
        threshold: [0, 0.25, 0.5, 0.75, 1],
      };
    },
  },
};
