import { nuxtInstance } from '~/plugins/nuxt-instance';
import { PRODUCT_DISCOUNT, PRODUCT_TAG_TYPE, PRODUCT_TAG_LABEL, PRODUCT_TAG_STATUS } from '~/constants/product-tags';

export function getProductTagsSettings(product) {
  const {
    specialPrice,
    firstOrderSpecialPrice,
    discountPercentage,
    templateVideo,
    sponsored,
    tag,
    deliveryDiscountAmount,
    publicPrice,
    status,
    price,
  } = product;

  const tooltipInformation = {
    event: 'mouseenter',
    options: {
      text: nuxtInstance?.app?.i18n?.t('text_public_price'),
      style: {
        width: '208px',
        height: 'auto',
      },
      configTooltip: {
        placement: 'bottom-end',
        offset: [5, 10],
        hideOnClick: false,
        theme: 'material',
        animation: 'shift-away',
        trigger: 'mouseenter',
      },
    },
  };

  const TAGS = {
    status: {
      discountNormal: '',
      isDisable: false,
      iconVideo: false,
    },
    tagsApplied: [],
  };

  // Descuento regular
  if (specialPrice > 0) {
    const tagDiscount = {
      theme: `${PRODUCT_DISCOUNT.DEFAULT} ${
        firstOrderSpecialPrice ? PRODUCT_DISCOUNT.ADQUISITION : PRODUCT_DISCOUNT.NORMAL
      }`,
      label: `-${discountPercentage} %`,
      tooltipInformation: null,
    };
    TAGS.tagsApplied.push(tagDiscount);
  }

  // Video - producto destacado - trade

  if (templateVideo !== null) {
    TAGS.status.iconVideo = true;
  } else if (sponsored || tag) {
    let themeTag = '';
    let labelTag = '';
    if (sponsored) {
      themeTag = PRODUCT_TAG_TYPE.SPONSORED;
      labelTag = nuxtInstance?.app?.i18n?.t('product_card_sponsored_text');
    } else if (tag) {
      themeTag = PRODUCT_TAG_TYPE.TRADE;
      labelTag = tag.tagText;
    }

    const tagTop = {
      theme: `${PRODUCT_TAG_TYPE.DEFAULT} ${themeTag}`,
      label: `${labelTag}`,
      tooltipInformation: null,
    };
    TAGS.tagsApplied.push(tagTop);
  }

  // No disponible - Cliente nuevo - ahorra en envio - en otro supermercado
  if (status === PRODUCT_TAG_STATUS.NOT_AVAILABLE) {
    const TagBottom = {
      theme: `${PRODUCT_TAG_LABEL.DEFAULT} ${PRODUCT_TAG_LABEL.NOT_AVAILABLE}`,
      label: nuxtInstance?.app?.i18n?.t('text_product_not_available'),
      tooltipInformation: {
        ...tooltipInformation,
        options: { ...tooltipInformation.options, text: nuxtInstance?.app?.i18n?.t('text_public_price') },
      },
    };
    TAGS.tagsApplied.push(TagBottom);
  } else if (firstOrderSpecialPrice || deliveryDiscountAmount > 0 || publicPrice !== 0) {
    let themeTag = '';
    let labelTag = '';
    let tooltipText = '';

    if (firstOrderSpecialPrice) {
      themeTag = PRODUCT_TAG_LABEL.ADQUISITION;
      labelTag = nuxtInstance?.app?.i18n?.t('product_card_tag_new_user');
      tooltipText = nuxtInstance?.app?.i18n?.t('text_first_buy_price', { price });
    } else if (deliveryDiscountAmount > 0) {
      themeTag = PRODUCT_TAG_LABEL.DELIVERY;
      labelTag = nuxtInstance?.app?.i18n?.t('product_card_tag_discount', { deliveryDiscountAmount });
      tooltipText = nuxtInstance?.app?.i18n?.t('product_label_delivery_discount');
    } else if (publicPrice !== 0) {
      themeTag = PRODUCT_TAG_LABEL.ADQUISITION;
      labelTag = nuxtInstance?.app?.i18n?.t('product_card_other_shop', { publicPrice });
      tooltipText = nuxtInstance?.app?.i18n?.t('text_public_price');
    }

    const TagBottom = {
      theme: `${PRODUCT_TAG_LABEL.DEFAULT} ${themeTag}`,
      label: `${labelTag}`,
      tooltipInformation: {
        ...tooltipInformation,
        options: { ...tooltipInformation.options, text: tooltipText },
      },
    };
    TAGS.tagsApplied.push(TagBottom);
  }
  return TAGS;
}

