import { getCampaignsService } from '~/services/campaigns/campaigns-service';
import { nuxtInstance } from '~/plugins/nuxt-instance';

export async function getCampaignsUsecase(storeId) {
  const { store } = nuxtInstance;
  const { data, error } = await getCampaignsService(storeId);
  if (data) {
    await store.dispatch('campaigns/setCampaigns', data);
    return { data, error: null };
  } else {
    await store.dispatch('campaigns/setCampaigns', []);
    return { data: null, error };
  }
}
