function breakPointsProdCarousel(
  MinResolution = 290,
  MaxResolution = 4000,
  StepResolution = 30,
  navWork = 1280,
  navSize = 260,
  minCardSize = 250,
) {
  const breakpoints = [[0, 1]];
  for (let resolution = MinResolution; resolution <= MaxResolution; resolution += StepResolution) {
    const slidesPerView = parseFloat(((resolution - (resolution >= navWork ? navSize : 0)) / minCardSize).toFixed(2));
    breakpoints.push([resolution, slidesPerView]);
  }
  return breakpoints;
}

export default breakPointsProdCarousel;
