//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MqOnBoardingExpressWidget',
  components: {
    MqOnBoardingExpress: () => import('./mq-onboarding-express'),
  },
  data() {
    return {
      selected: {},
      tab: 0,
      widthScreen: window.innerWidth,
    };
  },
  computed: {
    showBack() {
      return this.tab > 0;
    },
    showForeward() {
      return this.tab < 2;
    },
    showCenterButton() {
      return this.showBack || this.widthScreen < 768;
    },
    infoSlider() {
      return [
        {
          image: 'express-initial.svg',
          title: this.$t('express_onboarding_first_title'),
          subtitle: this.$t('express_onboarding_first_subtitle'),
          content: this.$t('express_onboarding_first_text'),
          classSubtitle: 'content__subtitle--initial',
          classText: 'content__text--margin-two',
        },
        {
          image: 'express-map.svg',
          title: this.$t('express_onboarding_secound_title'),
          subtitle: this.$t('express_onboarding_secound_subtitle'),
          content: this.$t('express_onboarding_secound_text'),
          classSubtitle: 'content__subtitle--map',
          classText: 'content__text--margin-four',
        },
        {
          image: 'express-delete-cart.svg',
          title: this.$t('express_onboarding_third_title'),
          subtitle: this.$t('express_onboarding_third_subtitle'),
          content: this.$t('express_onboarding_third_text'),
          classSubtitle: 'content__subtitle--delete-cart',
          classText: 'content__text--margin-two',
        },
      ];
    },
  },
  mounted() {
    this.selected = this.infoSlider[0];
  },
  methods: {
    foreward(event) {
      event.preventDefault();
      this.tab++;
      this.selected = this.infoSlider[this.tab];
    },
    back(event) {
      event.preventDefault();
      this.tab--;
      this.selected = this.infoSlider[this.tab];
    },
    showBlueStep(step) {
      return step === this.tab;
    },
    tryExpress() {
      this.$store.commit('SET_WIDGET', ['onBoardingExpressModal', false]);
      this.simulateReload();
    },
    simulateReload() {
      if (document.querySelector('.fake-link')) {
        document.querySelector('.fake-link').click();
      }
    },
  },
};
