import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

export default ({ app, store }) => {
  app.i18n = new VueI18n({
    silentFallbackWarn: true,
    locale: store.state.locale,
    fallbackLocale: 'es-CO',
    messages: {
      'es-CO': require('~/assets/lang/es-CO.json'),
      'es-MX': require('~/assets/lang/es-MX.json'),
      'pt-BR': require('~/assets/lang/pt-BR.json'),
    },
    dateTimeFormats: {
      'es-CO': require('~/dateTimeFormats/es-CO.json'),
      'es-MX': require('~/dateTimeFormats/es-MX.json'),
    },
  });
};
