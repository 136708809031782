function getResponsiveBanner() {
  return [
    [0, 1.2],
    [340, 1.2],
    [450, 1.6],
    [576, 2.02],
    [650, 2.27],
    [720, 2.5],
    [800, 2.67],
    [920, 3.17],
    [1000, 3.43],
    [1100, 3.77],
    [1200, 4.1],
    [1300, 3.7],
    [1400, 4.06],
    [1580, 4.7],
    [1620, 5.06],
    [1800, 5.49],
    [1910, 5.9],
  ];
}

export default getResponsiveBanner;
