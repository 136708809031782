import { camelize } from '~/helpers/camelize';
import { nuxtInstance } from '~/plugins/nuxt-instance';
import normalize from 'json-api-normalize';
import { MAP_DELIVERY_TIMES_DAYS_KEYS } from '~/constants/delivery-times-days-i18n-keys';
import { findElementInMapObject } from '~/helpers/find-element-in-map-object';

export function deliveryTimesTransforms(responseData) {
  let normalized = camelize(normalize(responseData).get(['day', 'date', 'value', 'selected', 'times']));
  let translatedDeliveryTimes = normalized.map((deliveryTime) => ({
    ...deliveryTime,
    get day() {
      return nuxtInstance?.app?.i18n?.t(findElementInMapObject(MAP_DELIVERY_TIMES_DAYS_KEYS, deliveryTime.day));
    },
    get date() {
      return new Intl.DateTimeFormat(nuxtInstance?.app?.i18n?.locale, { day: 'numeric', month: 'long' }).format(
        new Date(deliveryTime.value.replace(/-/g, '/')),
      );
    },
    times: [
      ...deliveryTime.times.map((time) => ({
        ...time,
        deliveryAmount: parseInt(time.deliveryAmount),
        realDeliveryAmount: parseInt(time.realDeliveryAmount),
      })),
    ],
  }));
  return {
    id: 'merqueo',
    days: translatedDeliveryTimes,
  };
}
