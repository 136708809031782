//
//
//
//
//

export default {
  name: 'MqIcon',
  props: {
    icon: String,
    color: String,
    width: String,
    height: String,
    size: String,
    theme: String,
    onClick: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    styles() {
      let style = {};
      if (this.size || this.width) {
        style.width = `${this.size || this.width}px`;
        style.height = `${this.size || this.height}px`;
      }
      if (this.color) style.fill = this.color;
      return style;
    },
  },
};
