import { getOrderDetailEndpoint } from '~/endpoints/orders/order-detail-endpoint';
import { getOrderDetailTransform } from '~/transforms/orders/order-detail-transform';
import { errorTransforms } from '~/transforms/errors/error-transform';

export async function orderDetailService({ orderId }) {
  const { response, error } = await getOrderDetailEndpoint({ orderId });
  if (error === null && response.status === 200) {
    const orderTransformed = getOrderDetailTransform(response.data);
    return { error: null, data: orderTransformed };
  }
  return { error: errorTransforms(error), data: null };
}
