//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SORT_TYPES, DEFAULT } from '~/constants/sort-types';

export default {
  name: 'MqSortProducts',
  data() {
    return {
      load: false,
      sortName: undefined,
      SORT_TYPES,
      DEFAULT,
      overflowBkp: '',
    };
  },
  computed: {
    sortNameSelected() {
      return this.sortName ?? this.$t(DEFAULT.NAME);
    },
  },
  mounted() {
    setTimeout(() => {
      this.load = true;
    }, 1000);
  },
  methods: {
    sortProductsBy(sort) {
      switch (this.$t(sort.NAME)) {
        case this.$t(SORT_TYPES.RELEVANCE.NAME):
          this.$events.SortBySelected.track({ sortedBy: SORT_TYPES.RELEVANCE.EVENT_NAME });
          break;
        case this.$t(SORT_TYPES.PROMOTION.NAME):
          this.$events.SortBySelected.track({ sortedBy: SORT_TYPES.PROMOTION.EVENT_NAME });
          break;
        case this.$t(SORT_TYPES.PRICE_DESC.NAME):
          this.$events.SortBySelected.track({ sortedBy: SORT_TYPES.PRICE_DESC.EVENT_NAME });
          break;
        case this.$t(SORT_TYPES.PRICE_ASC.NAME):
          this.$events.SortBySelected.track({ sortedBy: SORT_TYPES.PRICE_ASC.EVENT_NAME });
          break;
        default:
          this.$events.SortBySelected.track({ sortedBy: DEFAULT.EVENT_NAME });
          break;
      }
      this.sortName = this.$t(sort.NAME);
      this.$emit('sort-change', sort.PARAM_NAME);
    },
    toggleWidget() {
      const { state, commit } = this.$store;
      const { sortSelect } = state.ui.widgets;
      commit('SET_WIDGET', ['sortSelect', !sortSelect]);
      const bodyStore = document.querySelector('.container-content-layer');

      if (!sortSelect && bodyStore) {
        this.overflowBkp = document.querySelector('.container-content-layer').style.overflow;
        bodyStore.style.overflow = 'hidden';
      } else if (bodyStore) {
        bodyStore.style.overflow = this.overflowBkp;
      }
    },
  },
};
