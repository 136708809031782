export const STORE_MODEL_ENUM = {
  NORMAL_SUPERMARKET: 'Supermercado',
  FAST_EXPRESS: 'Tienda',
};

export const STORE_TYPE_ENUM = {
  MERQUEO: 'Merqueo',
  MARKETPLACE: 'Marketplace',
};

export const MARKETPLACE_BUSINESS_STATUS = {
  CLOSED: 'CLOSED',
  AVAILABLE: 'AVAILABLE',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
};

export const MERQUEO_STORES_TYPE = {
  MERQUEO: 'Merqueo',
  MARKETPLACE: 'Marketplace',
};

export const PROMOTION_TAGS = {
  FREE_DELIVERY: 'Free delivery',
  DISCOUNT: 'Discount',
  CASHBACK: 'Cashback',
  NONE: 'None',
};

export const PROMOTION_TAGS_THEMES = {
  LIGTH: 'light',
  DARK: 'dark',
};
