import { deployVersion } from '~/../env.local';
import { nuxtInstance } from '~/plugins/nuxt-instance';

export function generateRequestHeaders() {
  const { store } = nuxtInstance;
  const currentCountryCode = store.getters['home/getCurrentCountryCode'] ?? 'co';
  const webDeployVersion = deployVersion ?? '3.0.0';
  const requestHeaders = new Headers();
  requestHeaders.set('Content-Type', 'application/json');
  requestHeaders.set('X-Merqueo-Agent', 'Web');
  requestHeaders.set('X-Merqueo-Version', `${webDeployVersion}`);
  requestHeaders.set('X-Merqueo-Country', `${currentCountryCode}`);
  return requestHeaders;
}

