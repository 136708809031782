import { optional } from '~/decorators/optional';
import { required } from '~/decorators/required';
import { scheme } from '~/decorators/scheme';

export class ProductCart {
  @optional()
  fullPrice?: number;

  @required()
  id: number;

  @optional()
  quantityFullPrice?: number;

  @optional()
  quantitySpecialPrice?: number;

  @optional()
  specialPrice?: number;
}

export class Holder {
  @required()
  address: string;

  @required()
  documentNumber: string;

  @required()
  documentType: string;

  @required()
  name: string;

  @required()
  phone: string;
}

export class CreditCard {
  @required()
  cvv?: string;

  @required()
  holder: Holder;

  @optional()
  id?: number;

  @required()
  installments: string;

  @required()
  month: string;

  @required()
  number: number;

  @required()
  year: string;
}

@scheme()
export class ValidateCouponsParams {
  @required()
  cart: ProductCart[];

  @required()
  code: string;

  @optional()
  creditCard?: CreditCard;

  @required()
  paymentMethod: string;

  @required()
  storeId: number;

  @required()
  zoneId: number;

  constructor(params: Partial<ValidateCouponsParams>) {
    Object.assign(this, params);
  }
}
