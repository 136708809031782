import { optional } from '~/decorators/optional';
import { required } from '~/decorators/required';
import { scheme } from '~/decorators/scheme';
import { ProductCart } from './product-cart-scheme';

@scheme()
export class DeliveryTimesParams {
  @optional()
  campaignPrizeIds?: Array<number>;

  @required()
  cart: ProductCart[];

  @required()
  latitude: string;

  @required()
  longitude: string;

  @optional()
  slotExpress?: boolean;

  @required()
  storeId: number;

  @required()
  zoneId: number;

  constructor(params: Partial<DeliveryTimesParams>) {
    Object.assign(this, params);
  }
}
