import $axios from '~/services/global-axios';

export async function getMenusEndpoint(storeId, zoneId) {
  let zoneParam = '';
  if (zoneId) {
    zoneParam = `?zoneId=${zoneId}`;
  }
  try {
    let response = await $axios.get(`/3.0/stores/${storeId}/menus${zoneParam}`);
    return { response, error: null };
  } catch (error) {
    return { response: null, error };
  }
}
