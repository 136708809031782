import { getStoresEndpoint } from '~/endpoints/stores/stores-endpoint';
import { StoresEndpoint } from '~/endpoints/stores/stores-endpoint-v32';
import { checkFeatureFlag, FEATURE_TOGGLES_KEYS } from '~/feature-flags/feature-toggles';
import { StoresParams } from '~/schemes/stores-params-schemes';
import { storeTransforms } from '~/transforms/stores/stores-transform';

export async function getStoresUseCase(storeParams: StoresParams) {
  const { cityId, typeStore, latitude, longitude, cache = true } = storeParams;
  let responseEndpoint;
  if (checkFeatureFlag(FEATURE_TOGGLES_KEYS.SPLIT_STORE_TOGGLE)) {
    responseEndpoint = await StoresEndpoint.getStoresEndpointV32(
      new StoresParams({ cityId, typeStore, latitude, longitude, cache }),
    );
  } else {
    responseEndpoint = await getStoresEndpoint(cityId, latitude, longitude, cache);
  }
  const { error, response } = responseEndpoint;
  if (error === null && response?.status === 200) {
    return { error: null, data: storeTransforms(response.data) };
  }
  return { error, data: null };
}
