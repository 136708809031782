//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MqButton',
  props: {
    mqIcon: String,
    theme: String,
    onClick: {
      type: Function,
      default: () => null,
    },
    onMouseover: {
      type: Function,
      default: () => null,
    },
    onMouseout: {
      type: Function,
      default: () => null,
    },
    progress: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    done: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    icon() {
      return this.mqIcon ? `btn-icon ${this.mqIcon}` : '';
    },
  },
};
