import { nuxtInstance } from '~/plugins/nuxt-instance';
import { CAMPAIGNS_MAIN_TYPES, CAMPAIGNS_SUB_TYPES } from '~/constants/campaigns_types';
/**
 * Validate and set campaigns which user is participating
 * **/
export async function validateGeneralCampaignsUseCase(cart) {
  const { store } = nuxtInstance;
  const _campaignsParticipating = store.getters['campaigns/getCampaignsParticipating'];
  const campaignsGroup = buildGroupCampaigns(cart);
  const validCampaigns = getValidCampaignsUseCase(campaignsGroup);
  const campaignsParticipating = AddPercentToCampaigns(validCampaigns);
  const existDifferentCampaignType = [..._campaignsParticipating]?.some(
    ([_campaignKey, campaignValue]) => campaignValue.campaignType !== CAMPAIGNS_MAIN_TYPES.GENERAL,
  );
  const campaignsUpdated = existDifferentCampaignType
    ? new Map([..._campaignsParticipating, ...campaignsParticipating])
    : campaignsParticipating;
  await store.dispatch('campaigns/setCampaignsParticipating', campaignsUpdated);
}

/**
 * Build and return the dictionary of general campaigns
 * **/
function buildGroupCampaigns(cart) {
  const campaignsGroup = new Map();
  cart.forEach((cartProduct) => {
    const storeProductCatalogTags = getCampaignTagsByProduct(cartProduct);
    storeProductCatalogTags?.forEach((tagObject) => {
      if (campaignsGroup.has(tagObject.tag)) {
        const campaign = campaignsGroup.get(tagObject.tag);
        campaign.cartProducts.push(cartProduct);
      } else {
        const campaignByTag = getCampaignByTag(tagObject.tag);
        if (
          campaignByTag?.campaignType === CAMPAIGNS_MAIN_TYPES.GENERAL &&
          campaignByTag?.campaignPrize[0].campaignPrizeType === CAMPAIGNS_SUB_TYPES.FREE_DELIVERY
        ) {
          const campaignParticipating = {
            ...campaignByTag,
            tagAttributes: tagObject,
            cartProducts: [cartProduct],
            percentage: 0,
            amount: 0,
          };
          campaignsGroup.set(tagObject.tag, campaignParticipating);
        }
      }
    });
  });
  return campaignsGroup;
}

/**
 * return a dictionary of valid campaigns
 * **/
export function getValidCampaignsUseCase(campaignGroup) {
  const { store } = nuxtInstance;

  const validCampaigns = store.getters['campaigns/getCampaigns'];
  return new Map(
    [...campaignGroup].filter(([campaignKey, _campaignValue]) =>
      validCampaigns?.some((campaign) => campaign.tag === campaignKey),
    ),
  );
}

/**
 * Return campaigns type general which user is participating
 * **/
function AddPercentToCampaigns(campaignsGroup) {
  campaignsGroup.forEach((campaign) => {
    const tag = campaign.tagAttributes.tag;
    const _campaign = getCampaignByTag(tag);
    const cartProducts = campaign.cartProducts;
    const totalPriceByCampaign = getSumTotals(cartProducts);
    const percentage = getPercentCampaign(_campaign.minimumOrderAmount, totalPriceByCampaign);
    campaign.percentage = percentage;
    campaign.amount = totalPriceByCampaign;
  });
  return campaignsGroup;
}

/**
 * Return the campaign tags pass a CartProduct by argument
 * **/
function getCampaignTagsByProduct(cartProduct) {
  return cartProduct?.product?.storeProductCatalogTags?.filter((tagObject) => tagObject.tag.includes('CAMPAIGN'));
}

/**
 * Return a campaign pass tag by argument
 * **/
function getCampaignByTag(tag) {
  const { store } = nuxtInstance;
  const validCampaigns = store.getters['campaigns/getCampaigns'];
  return validCampaigns?.find((campaign) => campaign.tag === tag);
}

/**
 * Return the percent of pass minimumOrderAmount and totalPriceByCampaign
 * **/
function getPercentCampaign(minimumOrderAmount, totalPriceByCampaign) {
  const progressQuantity = totalPriceByCampaign;
  const absoluteQuantity = minimumOrderAmount;
  let percentage = 0;
  if (progressQuantity <= absoluteQuantity) {
    percentage = (progressQuantity / absoluteQuantity) * 100;
  } else if (progressQuantity > absoluteQuantity) {
    percentage = 100;
  }

  return percentage;
}

/**
 * Return the sum of totals of products
 * **/
export function getSumTotals(cartProducts) {
  return cartProducts.reduce((previous, current) => {
    return previous + current.totalPriceProduct();
  }, 0);
}
