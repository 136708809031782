import { camelize } from '~/helpers/camelize';
import normalize from 'json-api-normalize';

export function storiesTransforms(responseData) {
  return normalize(responseData)
    .get([
      'id',
      'category',
      'cta',
      'deeplink_url',
      'description',
      'display_duration',
      'inapp_url',
      'url_web',
      'lifetime_minutes',
      'name',
      'start_date',
      'status',
      'type_multimedia',
      'url_multimedia',
      'tag',
      'type',
      'category',
      'enable_add_all_products',
    ])
    .map((story) => {
      return camelize(story);
    });
}
