import { Store } from "vuex";
import { Strategy } from "@/helpers/deeplinks/strategy";
import { getStoreSlug, IQuery } from "@/helpers/deeplinks/utils/get-store-slug";
import { DEEP_LINKS_NAMES } from '~/helpers/deeplinks/constants/deeplinks';

interface ICity {
  slug: string;
}

export const strategyStoreDeepLink = (
  store: Store<any>,
  route: IQuery,
  cityBySlug: ICity
) =>
  new Strategy(DEEP_LINKS_NAMES.store, async () => {
    const { slug, store: marketplace } = await getStoreSlug(store, route);

    if (!marketplace && !store.getters["address/isAddressValid"]) {
      return "/";
    }
    if (!marketplace) {
      return "/error/not-available";
    }
    return `/${cityBySlug?.slug}/${slug}`;
  });
