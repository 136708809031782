import { FEATURE_TOGGLES_KEYS, updateFeatureFlag } from '~/feature-flags/feature-toggles';
import { nuxtInstance } from '~/plugins/nuxt-instance';
import { splitFlogFlagService } from '~/services/split-store-flag';

export async function splitStoreUsecase(fromService = true) {
  const {
    store: { getters },
  } = nuxtInstance;
  if (fromService) {
    const cityId = getters['home/getCityId'];
    const splitStoreFlag = await splitFlogFlagService({ cityId });
    updateFeatureFlag(FEATURE_TOGGLES_KEYS.SPLIT_STORE_TOGGLE, splitStoreFlag);
    localStorage.setItem('splitStoreEnable', `${splitStoreFlag}`);
  } else {
    const splitStoreFlag = localStorage.getItem('splitStoreEnable') === 'true';
    updateFeatureFlag(FEATURE_TOGGLES_KEYS.SPLIT_STORE_TOGGLE, splitStoreFlag);
  }
}
