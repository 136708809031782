//
//
//
//
//
//

export default {
  name: 'AccordionTransition',
  props: {
    isOpen: Boolean,
  },
  data() {
    return {
      height: null,
    };
  },
  mounted() {
    if (this.$el?.scrollHeight !== 0 && this.$el?.style?.height) {
      this.$el.style.height = `${this.$el.scrollHeight}px`;
    } else if (this.isOpen) {
      setTimeout(() => {
        this.$el.style.height = `${this.$el.scrollHeight}px`;
      }, 1000);
    }
  },
  beforeUpdate() {
    let $child = this.$el.childNodes[0];
    if ($child && $($child).hasClass('mq-body-container')) {
      let height = parseInt(this.$el.style.height.slice(0, -2));
      setTimeout(() => {
        if (height && $child.scrollHeight !== height) this.$el.style.height = `${$child.scrollHeight}px`;
      }, 500);
    }
  },
  methods: {
    beforeEnter(el) {
      el.style.height = '0';
    },
    enter(el) {
      el.style.height = `${el.scrollHeight}px`;
    },
    beforeLeave(el) {
      el.style.height = `${el.scrollHeight}px`;
    },
    leave(el) {
      el.style.height = `0`;
    },
  },
};
