import { Context } from '@nuxt/types';

export let nuxtInstance: Context = {} as any;

export default (_nuxtInstance: any) => {
  nuxtInstance = _nuxtInstance;
  const {
    app: { router },
  } = _nuxtInstance;
  router.beforeEach((_to: any, from: any, next: any) => {
    router.previousRoute = from;
    next();
  });
};
