//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MqToast',
  props: ['id', 'type', 'messagge', 'icon', 'delay'],
  data() {
    return { open: true };
  },
  mounted() {
    setTimeout(() => {
      this.open = false;
    }, this.delay || 3000);
  },
  updated() {
    if (!this.open) {
      const html = document.createElement('div');
      html.id = 'mq-alerts';
      document.body.appendChild(html);
    }
  },
  methods: {
    destroy() {
      this.open = false;
    },
  },
};
