import UIUtils from './../../plugins/ui-utils';
import { getScreenMode } from '~/helpers/screen-mode';
import { simpleDisableBodyScroll, simpleEnableBodyScroll } from '~/plugins/scroll-lock';

const getOriginalState = () => {
  return {
    loading: false,
    primeLoading: false,
    loadingCheckout: false,
    navbarScroll: true,
    menu: false,
    screenMode: getScreenMode(),
    fullScreenMode: false,
    forceDirectionsUpdate: 'forceDirectionsUpdate:0',
    modalHistory: [],
    modalContent: {
      title: '',
      description: '',
      image: '',
      buttonText: '',
    },
    widgets: {
      addressWidget: false,
      userWidget: false,
      cartWidget: false,
      finderWidget: false,
      coverageModal: false,
      contactModal: false,
      maxProductPromoModal: false,
      maxAgeModal: false,
      noProductsModal: false,
      productsNotPromo: false,
      surveyModal: false,
      sortSelect: false,
      deleteDirectionModal: false,
      editDirectionModal: false,
      orderDetailModal: false,
      newCardModal: false,
      updateOrderModal: false,
      widgetOverflow: false,
      deleteMethodModal: false,
      cancelTransactionModal: false,
      retryTransactionModal: false,
      selectCountryModal: false,
      validateNumberModal: false,
      youAreSavingModal: false,
      campaignModal: false,
      basketFull: false,
      confirmDisable: false,
      onBoardingExpressModal: false,
      helpCenterModal: false,
      deliveryTimes: false,
      storesWidget: false,
      userHaveOrders: false,
      deleteAccount: false,
      deleteAccountInfo: false,
      deletedAccountModal: false,
      showInvalidEmailModal: false,
      hallwaysdWidget: false,
      favorites: false,
      addressWidgetModal: false,
      changesHelpcenterProductsModal: false,
      deleteAccountErrorModal: false,
      changePrivacyPoliciesModal: false,
      binesInformativeModal: false,
      discountSuccessBinesUpdateOrder: false,
      alertCampaignHcModifyOrder: false,
    },
    callbackWidgetClose: {},
    layoutMode: 'store',
    maxLayerZindex: null,
    skeletons: {
      myAccount: false,
    },
  };
};

const resetWidgets = (state, name) => {
  const noClose = ['campaignModal', 'helpCenterModal'];
  for (let prop in state.widgets) {
    if (prop !== name && !noClose.includes(prop)) {
      state.widgets[prop] = false;
    }
  }
};

const getters = {
  getForceDirectionsUpdate(state) {
    return state.forceDirectionsUpdate;
  },
  getMaxLayerZindex(state) {
    return state.maxLayerZindex;
  },
  getSkeletonState(state) {
    return state.skeletons;
  },
  getLoadingState(state) {
    return state.loading;
  },
  getPrimeLoadingState(state) {
    return state.primeLoading;
  },
};
const state = getOriginalState();

const mutations = {
  INCREMENT_UPDATE_DIRECTIONS(state) {
    const increment = state.forceDirectionsUpdate;
    state.forceDirectionsUpdate = increment
      .split(':')
      .map((v, index) => (index === 1 ? ++v : v))
      .join(':');
  },
  SET_WIDGET(state, payload) {
    let name = payload[0];
    let value = payload[1];

    resetWidgets(state, name);
    state.widgets[name] = value;
    state.navbarScroll = !value;

    if (value === true && state.modalHistory.findIndex((n) => n === name) !== -1) {
      state.modalHistory.push(name);
    } else {
      const indexOf = state.modalHistory.findIndex((v) => name === v);
      if (indexOf !== -1) {
        state.modalHistory.splice(indexOf, 1);
      }
      const n = state.modalHistory.pop();
      if (typeof n === 'string') {
        state.widgets[n] = true;
        value = true;
      }
    }

    let val = value;
    for (let prop in state.widgets) {
      if (state.widgets[prop] === true && prop !== 'widgetOverflow') val = true;
    }
    state.widgets.widgetOverflow = val && state.screenMode !== 'desktop';
    if (state.widgets.widgetOverflow) {
      simpleDisableBodyScroll();
    } else {
      simpleEnableBodyScroll();
    }

    if (value) UIUtils.increaseStackOverflow();
    else UIUtils.decreaseStackOverflow();
  },
  SET_WIDGET_CONTENT(state, { title, description, image, buttonText }) {
    state.modalContent.title = title ? title : null;
    state.modalContent.description = description ? description : null;
    state.modalContent.image = image ? image : null;
    state.modalContent.buttonText = buttonText ? buttonText : null;
  },
  SET_LOADING(state, value) {
    state.loading = value;
  },
  SET_PRIME_LOADING(state, value) {
    state.primeLoading = value;
  },
  SET_LOADING_CHECKOUT(state, value) {
    state.loadingCheckout = value;
  },
  SET_OVERFLOW(state, value) {
    //Todo: validar error
    //eslint-disable-next-line
    let val = value;

    for (let prop in state.widgets) {
      if (state.widgets[prop] === true) val = true;
    }

    state.widgets.widgetOverflow = val;
  },
  SET_MENU(state, value) {
    state.menu = value;
  },
  CLEAN_STATE(state, val) {
    let stateSurvey = state.widgets.surveyModal;

    Object.assign(state, getOriginalState());
    state.loading = val;
    state.widgets.surveyModal = stateSurvey;
  },
  SET_CALLBACK_WIDGET_CLOSE(state, payload) {
    state.callbackWidgetClose[payload.widget] = payload.callback;
  },
  REMOVE_CALLBACK_WIDGET_CLOSE(state, payload) {
    state.callbackWidgetClose[payload] = null;
  },
  SET_SCREEN_MODE(state, mode) {
    state.screenMode = mode;
  },
  RESET_WIDGETS(state) {
    resetWidgets(state, '');
  },
  SET_LAYOUT_MODE(state, mode) {
    state.layoutMode = mode;
  },
  SET_FULLSCREEN_MODE(state, value) {
    state.fullScreenMode = value;
  },
  SET_MAX_LAYER_ZINDEX(state, value) {
    state.maxLayerZindex = value;
  },
  SET_SKELETONS(state, payload) {
    const { key, value } = payload;
    state.skeletons[key] = value;
  },
};

const actions = {
  forceUpdateDirections({ commit }) {
    commit('INCREMENT_UPDATE_DIRECTIONS');
  },
  LOAD_MODAL_WIDGET({ commit, state }, payload) {
    if (
      state.callbackWidgetClose[payload[0]] &&
      typeof state.callbackWidgetClose[payload[0]] === 'function' &&
      !payload[1]
    ) {
      state.callbackWidgetClose[payload[0]]();
      commit('REMOVE_CALLBACK_WIDGET_CLOSE', payload[0]);
    }
    commit('SET_WIDGET', payload);
  },
  LOAD_MODAL_WIDGET_SIMPLE({ commit }, payload) {
    commit('SET_WIDGET', payload);
  },
  LOAD_MODAL_CONTENT({ commit }, payload) {
    commit('SET_WIDGET_CONTENT', payload);
  },
  CALLBACK_CLOSE_WIDGET({ commit }, payload) {
    commit('SET_CALLBACK_WIDGET_CLOSE', payload);
  },
  CHANGE_SCREEN_MODE({ commit }, mode) {
    commit('SET_SCREEN_MODE', mode);
  },
  RESET_WIDGETS({ commit }) {
    commit('RESET_WIDGETS');
  },
  setMaxLayerZindex({ commit }, value) {
    commit('SET_MAX_LAYER_ZINDEX', value);
  },
  setSkeletons({ commit }, payload) {
    commit('SET_SKELETONS', payload);
  },
  setMenu({ commit }, payload) {
    commit('SET_MENU', payload);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
