import { Strategy } from "./strategy";

export class StrategyManager {

    constructor(private strategies: Array<Strategy> = []) {}

    addStrategy(strategy: Strategy): void {
        this.strategies = [...this.strategies, strategy];
    }

    getStrategy(name: string): Strategy | undefined {
        return this.strategies.find((strategy) => strategy.getName() === name);
    }
}