import isWebpSupported from '~/helpers/is-webp-supported';

export function getImage(image) {
  const images = require.context('~/assets/img', false, /\.*$/);
  try {
    return images(`./${image}`);
  } catch (e) {
    return images(`./no_disponible.${isWebpSupported() ? 'webp' : 'jpg'}`);
  }
}
