import { getMenusEndpoint } from '~/endpoints/home/get-menus-endpoint';
import { getMenusTransform } from '~/transforms/home/get-menus-transform';
import { errorTransforms } from '~/transforms/errors/error-transform';

export async function getMenusUsecase(storeId, zoneId) {
  const { response, error } = await getMenusEndpoint(storeId, zoneId);
  if (error === null && response.status === 200) {
    const menusData = await getMenusTransform(response.data);
    return { data: menusData, error: null };
  } else {
    return { data: null, error: errorTransforms(error) };
  }
}
