//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MqProductDeliveryLabel',
  props: {
    deliveryDiscountAmount: {
      type: Number,
      default: 0,
    },
    view: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
