import Vue from 'vue';
import { checkFeatureFlag, FEATURE_TOGGLES_KEYS, updateFeatureFlag } from '~/feature-flags/feature-toggles';
import { splitStoreUsecase } from '~/usecases/split-store-usecase';
import { getInfoDeviceHuaweiService } from '~/services/config/get-info-device-huawei-service';

export default async function ({ store, route }) {
  const { path, name: routeName } = route;
  if (Vue.prototype.$events) {
    const { $events } = Vue.prototype;
    setTimeout(() => {
      $events.pageView.track({
        path,
        title: document.title,
      });
    }, 2000);
  }

  const disabledBuy = await Vue.prototype.$getAsyncRemoteConfigParam('web_store_buy_disabled', 'boolean');
  const kustomerEnable = Vue.prototype.$getRemoteConfigParam('is_kustomer_enable', 'boolean');
  updateFeatureFlag(FEATURE_TOGGLES_KEYS.BUY_ON_STORE_DISABLED, disabledBuy);
  updateFeatureFlag(FEATURE_TOGGLES_KEYS.FLAG_KUSTOMER, kustomerEnable);

  await store.dispatch('home/getCurrentCountry');
  await Promise.all([
    store.dispatch('config/getConfig').then(() => Promise.all([store.dispatch('home/setDocumentsToArray')])),
    store.dispatch('home/getCurrentMainCityOfCountry'),
  ]);

  if (checkFeatureFlag(FEATURE_TOGGLES_KEYS.SPLIT_STORE_GENERAL_TOGGLE)) {
    await splitStoreUsecase(routeName === 'index');
  }

  if (routeName === 'index') {
    await store.dispatch('home/loadStores', false);
  }

  // eslint-disable-next-line no-undef
  fbProductFbPixel.currency = store.state.config.config.currency
    ? store.state.config.config.currency
    : store.state.config.config.currency_formatter.currency_code;
  const status = await getInfoDeviceHuaweiService();
  await store.dispatch('config/setStatusHuaweiDevice', status);
}
