import $axios from '~/services/global-axios';

const getAddressEndpoint = async ({ storeId, params }) => {
  try {
    let response = await $axios.$get(`/3.0/stores/${storeId}/addresses`, { params });
    return { response, error: null };
  } catch (error) {
    return { response: null, error };
  }
};
export default getAddressEndpoint;
