import { getStatusPrimeUseCase } from '~/services/prime/get-status-prime-service';
import { PRIME_STORAGE, PRIME_STATUS } from '~/constants/prime-config-components';

export default async function ({ store, route }) {
  const {
    state: {
      user: {
        user: { id },
      },
    },
  } = store;
  if (id) {
    const { fullPath } = route;
    const pathLoad = ['/prime', '/'];
    const { PRIME_STATE } = PRIME_STORAGE;
    const { ACTIVE, INACTIVE } = PRIME_STATUS;
    const validationStorage = sessionStorage.getItem(PRIME_STATE) === null;
    let data;
    let error;
    if (validationStorage || pathLoad.includes(fullPath)) {
      ({ data, error } = await getStatusPrimeUseCase());
      sessionStorage.setItem(PRIME_STATE, data instanceof Object ? ACTIVE : INACTIVE);
    }

    const userHavePrime = sessionStorage.getItem(PRIME_STATE) === ACTIVE;
    await store.dispatch(`prime/setUserHavePrime`, userHavePrime);
    await store.dispatch(`prime/setPrimeStatus`, data);
    await store.dispatch(`prime/setErrorPrimeData`, error);
  }
}

