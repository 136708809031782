/* eslint-disable max-classes-per-file */
import { optional } from '~/decorators/optional';
import { required } from '~/decorators/required';
import { scheme } from '~/decorators/scheme';
// import { subScheme } from '~/decorators/subScheme';

@scheme()
class Address {
  @optional()
  further?: string;

  @required()
  id: number;

  @optional()
  label?: string;

  @optional()
  latitude?: string;

  @optional()
  longitude?: string;

  @required()
  name: string;

  @required()
  neighborhood: string;

  @optional()
  outdoorNumber?: string;

  @optional()
  postalCode?: string;

  @optional()
  route?: string;
}
@scheme()
class ProductCart {
  @required()
  full_price: number;

  @required()
  id: number;

  @required()
  quantity_full_price: number;

  @required()
  quantity_special_price: number;

  @required()
  special_price: number;
}

@scheme()
class Holder {
  @required()
  address: string;

  @required()
  documentNumber: string;

  @required()
  documentType: string;

  @required()
  name: string;

  @required()
  phone: string;
}

@scheme()
class Merqueo {
  @required()
  date: string;

  @required()
  deliveryWindowId: number;

  @required()
  hour: string;
}

@scheme()
class InfoCreditCard {
  @required()
  cvv?: string;

  @required()
  holder: Holder;

  @required()
  id: number;

  @required()
  installments: string;

  @optional()
  isSelected?: boolean;

  @optional()
  lastFour?: string;

  @required()
  month: string;

  @required()
  number: number;

  @optional()
  requireCvv?: boolean;

  @optional()
  type?: string;

  @required()
  year: string;
}

@scheme()
class DeliveryTimes {
  @optional()
  marketplace?: any;

  @required()
  merqueo?: Merqueo;
}

@scheme()
class OrdersSaving {
  @required()
  amountSaved?: number;

  @required()
  discountSaved?: number;

  @required()
  timeSaved?: number;
}

@scheme()
class WhoReceives {
  @optional()
  name_receives?: string;

  @optional()
  phone_receives?: string;

  @required()
  stage_type?: string;

  @optional()
  user_comments?: string;
}

@scheme()
export class CheckoutEndpointParams {
  address?: Address;

  @optional()
  businessIdentityNumber?: string;

  @optional()
  businessIdentityType?: string;

  @optional()
  businessName?: string;

  @optional()
  campaignPrizeIds?: Array<number>;

  @required()
  cart?: ProductCart[];

  @optional()
  cityId?: number;

  @optional()
  code?: string;

  @optional()
  creditCard?: InfoCreditCard;

  @required()
  deliveryTimes?: DeliveryTimes;

  @optional()
  deviceId?: string;

  @optional()
  dniTypeId?: string;

  @optional()
  ordersSaving?: OrdersSaving;

  @required()
  paymentMethod?: string;

  @optional()
  psePayer?: string;

  @optional()
  pushToken?: string;

  @optional()
  useCredits?: boolean;

  @optional()
  whoReceives?: WhoReceives;

  @optional()
  zoneId?: number;

  @required()
  storeId?: number;

  constructor(params: Partial<CheckoutEndpointParams>) {
    Object.assign(this, params);
  }
}
