//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
export default {
  name: 'MqListLink',
  props: ['title', 'data'],
  computed: {
    ...mapGetters({
      store: 'home/getCurrentStore',
      city: 'home/getCity',
    }),
  },
};
