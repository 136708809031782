import { Store } from 'vuex';
import { Strategy } from '@/helpers/deeplinks/strategy';
import { DEEP_LINKS_NAMES } from '~/helpers/deeplinks/constants/deeplinks';
import { getStatusPrimeUseCase } from '~/services/prime/get-status-prime-service';
import { PRIME_STORAGE, PRIME_STATUS } from '~/constants/prime-config-components';

export const strategyPrimeDeepLink = (store: Store<any>) =>
  new Strategy(DEEP_LINKS_NAMES.merqueoPrime, async () => {
    const isLogin = 'id' in store.state.user.user;
    if (!isLogin) {
      return `/login`;
    }
    const { data, error } = await getStatusPrimeUseCase();
    if (data instanceof Object || (error instanceof Object && !(error instanceof Error))) {
      const { PRIME_STATE } = PRIME_STORAGE;
      const { ACTIVE, INACTIVE } = PRIME_STATUS;
      if (data instanceof Object) await store.dispatch(`prime/setPrimeStatus`, data);
      sessionStorage.setItem(PRIME_STATE, data instanceof Object ? ACTIVE : INACTIVE);
      await store.dispatch(`prime/setUserHavePrime`, data instanceof Object);
      return `/prime`;
    }
    return `/`;
  });

