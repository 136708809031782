import normalize from 'json-api-normalize';
import { camelize } from '~/helpers/camelize';

export function userTransforms(responseData) {
  return camelize(
    normalize(responseData).get([
      'id',
      'dni_type_id',
      'email',
      'fb_id',
      'first_name',
      'href',
      'identity_number',
      'identity_type',
      'last_name',
      'phone',
      'phone_prefix',
      'phone_validated_date',
      'referral_code',
      'referral_code_blocked',
      'send_advertising',
      'status',
      'total_orders',
      'token.id',
      'token.refresh_token',
      'token.refresh_token_url',
      'token.value',
    ]),
  );
}
