export const onLoad = (callback, delay = 1) => {
  if (document.readyState === 'complete') {
    setTimeout(() => callback(), delay);
  } else {
    window.addEventListener('load', () => {
      setTimeout(() => callback(), delay);
    });
  }
};

