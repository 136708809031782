export default {
  0: {
    slidesPerView: 1.2,
    slidesOffsetAfter: 0,
    slidesOffsetBefore: 0,
  },
  340: {
    slidesPerView: 1.23,
    slidesOffsetAfter: 0,
    slidesOffsetBefore: 0,
  },
  450: {
    slidesPerView: 1.6,
    slidesOffsetAfter: 0,
    slidesOffsetBefore: 0,
  },
  576: {
    slidesPerView: 2.02,
    slidesOffsetAfter: 0,
    slidesOffsetBefore: 0,
  },
  650: {
    slidesPerView: 2.27,
    slidesOffsetAfter: 0,
    slidesOffsetBefore: 0,
  },
  720: {
    slidesPerView: 2.5,
    slidesOffsetAfter: 0,
    slidesOffsetBefore: 0,
  },
  800: {
    slidesPerView: 2.67,
    slidesOffsetAfter: 60,
    slidesOffsetBefore: 60,
  },
  920: {
    slidesPerView: 3.17,
    slidesOffsetAfter: 60,
    slidesOffsetBefore: 60,
  },
  1000: {
    slidesPerView: 3.43,
    slidesOffsetAfter: 60,
    slidesOffsetBefore: 60,
  },
  1100: {
    slidesPerView: 3.77,
    slidesOffsetAfter: 60,
    slidesOffsetBefore: 60,
  },
  1200: {
    slidesPerView: 4.1,
    slidesOffsetAfter: 60,
    slidesOffsetBefore: 60,
  },
  1300: {
    slidesPerView: 3.7,
    slidesOffsetAfter: 60,
    slidesOffsetBefore: 60,
  },
  1400: {
    slidesPerView: 4.06,
    slidesOffsetAfter: 60,
    slidesOffsetBefore: 60,
  },
  1580: {
    slidesPerView: 4.7,
    slidesOffsetAfter: 60,
    slidesOffsetBefore: 60,
  },
  1620: {
    slidesPerView: 5.06,
    slidesOffsetAfter: 60,
    slidesOffsetBefore: 60,
  },
  1800: {
    slidesPerView: 5.49,
    slidesOffsetAfter: 60,
    slidesOffsetBefore: 60,
  },
  1910: {
    slidesPerView: 5.9,
    slidesOffsetAfter: 60,
    slidesOffsetBefore: 60,
  },
};
