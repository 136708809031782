export const ADD_PRODUCT_ACTION = 'increment';
export const REMOVE_PRODUCT_ACTION = 'decrement';
const UNDO_REMOVE_PRODUCT_ACTION = 'UNDO_REMOVE_PRODUCT_ACTION';
export const CART_ACTIONS = {
  ADD_PRODUCT_ACTION,
  REMOVE_PRODUCT_ACTION,
  UNDO_REMOVE_PRODUCT_ACTION,
};

export const CART_TYPES = {
  DEFAULT: 'cart',
  ORDER_EDIT: 'order_edit',
};
