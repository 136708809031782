import { AnalyticEvent } from './anaytic-event';

export class ReorderAnalyticEvent extends AnalyticEvent {
  constructor(store) {
    super(store);
    this.name = 'reorder';
  }
  track(params){
    super.track({
      eventsParams: { ...params }
    })
  }
}
