import { camelize } from '~/helpers/camelize';
import normalize from 'json-api-normalize';

const addressTransform = responseData => {
  const addressList = responseData['data'];

  const addressMap = address => ({
    ...address,
    attributes: {
      ...address.attributes,
      address_type: address.type,
      zone_express_id: address.attributes.zone_express_id,
      warehouse_express_id: address.attributes.warehouse_express_id,
    },
  });

  if (addressList instanceof Array) {
    responseData['data'] = addressList.map(addressMap);
  } else {
    responseData['data'] = addressMap(addressList);
  }

  const normalizedData = camelize(
    normalize(responseData).get([
      'id',
      'address',
      'latitude',
      'longitude',
      'address_type',
      'zone_express_id',
      'warehouse_express_id',
      'zone_id',
      'warehouse_id',
      'type',
      'route',
    ]),
  );
  return normalizedData;
};
export default addressTransform;
