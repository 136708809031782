import { camelize } from '~/helpers/camelize';
import normalize from 'json-api-normalize';

import { findElementInMapObject } from '~/helpers/find-element-in-map-object';
import { MAP_PAYMENT_METHODS_KEYS } from '~/constants/payment-methods-map';
import nuxtInstance from '~/plugins/nuxt-instance';

export function paymentMethodsTransforms(responseData) {
  const normalized = camelize(normalize(responseData).get(['name', 'image']));
  const paymentMethods = normalized.map(paymentMethod => ({
    ...paymentMethod,
    translatedName: findElementInMapObject(MAP_PAYMENT_METHODS_KEYS, paymentMethod.name),
  }));
  return paymentMethods;
}
