import $axios from '~/services/global-axios';

export async function getStoresEndpoint(cityId, latitude, longitude, cache) {
  try {
    let query =
      latitude && longitude
        ? `/3.1/stores?cityId=${cityId}&latitude=${latitude}&longitude=${longitude}`
        : `/3.1/stores?cityId=${cityId}`;

    if (!cache) {
      query = `${query}&time=${new Date().getTime()}`;
    }

    const response = await $axios.get(query);
    return { error: null, response };
  } catch (error) {
    return { error, response: null };
  }
}
