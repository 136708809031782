import Vue from 'vue';
import { find, findIndex, countBy, sumBy, groupBy, filter } from 'lodash';
import * as Sentry from '@sentry/browser';
import CartProduct from './cart_product';
import Product from './product';
import { nuxtInstance } from '~/plugins/nuxt-instance';
import { progressHandlerUseCase } from '~/usecases/progress/progress-handler-usecase';
import { campaignHandlerUsecase } from '~/usecases/campaigns/campaigns-alert-usecase';
import { CART_ACTIONS } from '~/constants/cart-actions';
import { openModal } from '~/components/utils/mq-modal-managment';
import { addBagTax } from '~/usecases/checkout/add-bag-tax';

export function makeReactive(obj) {
  // eslint-disable-next-line no-proto
  const proto = obj.__proto__;

  Object.defineProperty(obj, '__proto__', {
    get() {
      return proto;
    },
    // eslint-disable-next-line no-proto
    set(newValue) {
      proto.__proto__ = newValue;
    },
  });
}

const castCollection = (collection) =>
  collection instanceof Array
    ? collection
        .filter(
          (cartProduct) => typeof cartProduct?.product?.id === 'number' || cartProduct?.product?.id instanceof Number,
        )
        .map((cartProduct) => (cartProduct instanceof CartProduct ? cartProduct : new CartProduct(cartProduct)))
    : [];

export function fakeReactive(obj) {
  obj.__ob__ = {
    observeArray: () => {},
    dep: {
      notify: () => {},
    },
  };
}

/**
 * Cart collection
 * @extends {Array<CartProduct>}
 */
export class Cart extends Array {
  /**
   * @property {Map} _cacheCollectionFinder
   */
  _cacheCollectionFinder = new Map();

  /**
   * @property {Map< string, Array<CartProduct> >} _storeCollection
   */
  _storeCollection = new Map();

  /**
   * @property {string} _currentStore
   */
  _currentStore = undefined;

  /**
   * @param {Array<CartProduct>|Array<Object>|CartProduct|Object|null} collection
   * @param {string} currentStore
   * @param {boolean} reactive
   */
  constructor(collection = [], currentStore = undefined, reactive = false) {
    // @ts-ignore
    super(...filter(castCollection(collection), { store: currentStore }));
    this._currentStore = currentStore;

    const storeGroupsCollections = groupBy(castCollection(collection), 'store');

    for (const keyStore in storeGroupsCollections) {
      this._storeCollection.set(keyStore, storeGroupsCollections[keyStore]);
    }

    if (!this._storeCollection.has(currentStore)) {
      this._storeCollection.set(currentStore, []);
    }

    makeReactive(this);
    if (!reactive) {
      fakeReactive(this);
    }
  }

  /**
   * @returns {string}
   */
  getCurrentStore() {
    return this._currentStore;
  }

  /**
   * @param {string} store
   */
  setCurrentStore(store = undefined) {
    this._storeCollection.set(this._currentStore, [...this]);
    if (store) {
      this._currentStore = `${store}`;
      if (!this._storeCollection.has(this._currentStore)) {
        this._storeCollection.set(this._currentStore, []);
      }
      this.set(this._storeCollection.get(this._currentStore));
    }
  }

  getFullCollection() {
    const fullCollection = [];
    this._storeCollection.set(this._currentStore, this);
    this._storeCollection.forEach((collection) => {
      fullCollection.push(...collection);
    });
    return fullCollection;
  }

  clearCollectionStore(store = undefined) {
    if (store) {
      if (!this._storeCollection.has(this._currentStore)) {
        this._storeCollection.set(this._currentStore, []);
      }
      if (store === this._currentStore) {
        this.splice(0, this.length);
      }
    }
  }

  // Default attributes
  defaults() {
    return {
      orderBy: 'name',
    };
  }

  // Route configuration
  routes() {
    return {
      fetch: '/card',
    };
  }

  subTotal() {
    return this.sum((cartProd) => {
      return cartProd.quantityFullPrice * cartProd.fullPrice + cartProd.quantitySpecialPrice * cartProd.specialPrice;
    });
  }

  total() {
    return this.subTotal();
  }

  totalSavings() {
    return this.sum((cartProd) => {
      return cartProd.totalSavings();
    });
  }

  hasDeliveryDiscount() {
    return this.count((prod) => (prod.product.deliveryDiscountAmount ? true : false)).true;
  }

  isEmpty() {
    return !this.count((prod) => (prod.product ? true : false)).true;
  }

  countMerqueo() {
    return this.length;
  }

  getCartModels() {
    return this.map((prod) => {
      return {
        id: prod.product.id,
        quantityFullPrice: prod.quantityFullPrice,
        quantitySpecialPrice: prod.quantitySpecialPrice,
        fullPrice: prod.fullPrice,
        specialPrice: prod.specialPrice,
      };
    });
  }

  totalProductsAdded() {
    return this.sum((cartProd) => {
      return cartProd.quantityFullPrice + cartProd.quantitySpecialPrice;
    });
  }

  totalWithoutDiscounts() {
    return this.subTotal() + this.totalSavings();
  }

  timeSaved(transportTime, pickingTime) {
    return Math.floor(transportTime + this.totalProductsAdded() * pickingTime);
  }

  savingByMarket(marketRatio) {
    return (marketRatio / 100) * this.totalWithoutDiscounts();
  }

  totalProductsPromo() {
    return this.sum((cartProd) => {
      return cartProd.quantitySpecialPrice;
    });
  }

  totalOrderSavings(marketRatio) {
    let savingMarket = this.savingByMarket(marketRatio);
    return this.totalSavings() + savingMarket;
  }

  /**
   * getWeightForCartProduct
   * @param {CartProduct} cartProduct
   */
  getWeightForCartProduct(cartProduct) {
    return (
      (cartProduct.product.weight ? cartProduct.product.weight : 1008) *
      (cartProduct.quantityFullPrice + cartProduct.quantitySpecialPrice)
    );
  }

  /**
   * getVolumeForCartProduct
   * @param {CartProduct} cartProduct
   */
  getVolumeForCartProduct(cartProduct) {
    return (
      (cartProduct.product.volume ? cartProduct.product.volume : 1610) *
      (cartProduct.quantityFullPrice + cartProduct.quantitySpecialPrice)
    );
  }

  /**
   * Get Volumetric
   * @param {Array<CartProduct>} collection
   * @returns {{weight: number, volume: number}}
   */
  getVolumetric(collection = this) {
    return collection.reduce(
      (cumulativeVolumetric, cartProduct) => {
        return {
          weight: cumulativeVolumetric.weight + this.getWeightForCartProduct(cartProduct),
          volume: cumulativeVolumetric.volume + this.getVolumeForCartProduct(cartProduct),
        };
      },
      {
        weight: 0,
        volume: 0,
      },
    );
  }

  /**
   * @param {Array<CartProduct>} additionalCartProducts
   * @returns {boolean}
   */
  checkVolumetric(additionalCartProducts = []) {
    const {
      store: { state, dispatch },
    } = nuxtInstance;
    const additionalVolumetric = this.getVolumetric(additionalCartProducts);
    const cartVolumetric = this.getVolumetric();
    if (
      state.config.config.expressCapacity.volume_capacity > additionalVolumetric.volume + cartVolumetric.volume &&
      state.config.config.expressCapacity.weight_capacity > additionalVolumetric.weight + cartVolumetric.weight
    ) {
      return true;
    }
    return false;
  }

  /**
   * Open Modal Basket Full
   */
  openModalBasketFull() {
    openModal({
      component: () => import('~/components/dialogs/mq-basket-full'),
      props: {},
      group: 'group-modal-basket',
      config: {
        position: {
          value: 'center',
        },
        behaviors: {
          closableContainer: false,
        },
      },
    });
  }

  /**
   * @param {Product} product
   */
  addProduct(product, quantity, trackProductAdd = true) {
    if (!(typeof product.id === 'number' || product.id instanceof Number)) {
      Sentry.captureException(new Error("CAN'T BE ADD PRODUCT WITHOUT ID TYPE NUMBER"), {
        extra: { product, quantity },
      });
      return;
    }
    const {
      store: { getters },
    } = nuxtInstance;
    for (let i = 0; i < quantity; i += 1) {
      const exist = this.findCached(product.id);
      if (exist instanceof CartProduct) {
        exist.addProduct();
        progressHandlerUseCase(this);
        if (getters['home/isExpress']() && !this.checkVolumetric()) {
          exist.removeProduct();
          this.openModalBasketFull();
          return;
        }
        campaignHandlerUsecase(this, product, CART_ACTIONS.ADD_PRODUCT_ACTION);
      } else {
        const newCarProduct = new CartProduct();
        newCarProduct.product = product instanceof Product ? product : new Product(product);
        newCarProduct.screen = product.screen;
        newCarProduct.store = this._currentStore;
        newCarProduct.bannerId = product.bannerId;
        newCarProduct.quantityFullPrice = 0;
        newCarProduct.searchText = product.searchText;
        newCarProduct.brandRoom = product.brandRoom;
        newCarProduct.originalQuantity = product.originalQuantity;
        this.add(newCarProduct);
        newCarProduct.addProduct();
        progressHandlerUseCase(this);
        if (getters['home/isExpress']() && !this.checkVolumetric()) {
          newCarProduct.removeProduct();
          this.openModalBasketFull();
          return;
        }
        campaignHandlerUsecase(this, product, CART_ACTIONS.ADD_PRODUCT_ACTION);
      }
      if (trackProductAdd) {
        this.trackAddProduct(product, quantity);
      }
    }
    addBagTax(this, CART_ACTIONS.ADD_PRODUCT_ACTION);
  }

  /**
   * @param {Product} product
   * @param {Number} quantity
   * @returns {Number}
   */
  removeProduct(product, quantity) {
    const exist = this.findCached(product?.id);
    if (exist instanceof CartProduct) {
      this.trackRemoveProduct(product, quantity);
      exist.removeProduct();
      addBagTax(this, CART_ACTIONS.REMOVE_PRODUCT_ACTION);
      if (exist.getCount() === 0) {
        this.remove(exist);
        return 2;
      }
      progressHandlerUseCase(this);
      campaignHandlerUsecase(this, product, CART_ACTIONS.REMOVE_PRODUCT_ACTION);
      return 1;
    }
    return 0;
  }

  trackAddProduct(product, quantity) {
    const { route: $route } = nuxtInstance;
    const { $events } = Vue.prototype;
    const name = `${product.name} ${product.quantity} ${product.unit}`;
    const price = product.specialPrice ? product.specialPrice : product.price;

    $events.productAdded.track({
      departmentId: product?.department?.id ? product.department.id : 0,
      shelfId: product?.shelf?.id ? product.shelf.id : 0,
      name,
      price,
      quantity,
      bestPriceGuaranteed: product.isBestPrice ? 1 : 0,
      bannerId: product.bannerId,
      position: product.position,
      storeProductId: product.id,
      sponsored: product.sponsored ? 1 : 0,
      screen: 'screen' in $route.query ? $route.query.screen : product.screen,
      storyId: product.story ?? 0,
      textSearched: product.textSearched,
      brandRoomId: product.brandRoom ?? 0,
      attribution: product.screen === 'lastProductsBought' ? 'favoritos' : product.attribution,
      attributionValue: product.attributionValue,
    });
  }

  trackRemoveProduct(product, quantity) {
    const { route: $route } = nuxtInstance;
    const { $events } = Vue.prototype;
    const price = product.specialPrice ? product.specialPrice : product.price;

    const name = `${product.name} ${product.quantity} ${product.unit}`;

    $events.productRemoved.track({
      departmentId: product?.department?.id ? product.department.id : 0,
      shelfId: product?.shelf?.id ? product.shelf.id : 0,
      name,
      price,
      quantity,
      storeProductId: product.id,
      screen: 'screen' in $route.query ? $route.query.screen : product.screen,
      attribution: product.screen === 'lastProductsBought' ? 'favoritos' : product.attribution,
    });
  }

  /**
   * Clear all cart collection
   */
  clear() {
    this._cacheCollectionFinder.clear();
    this.splice(0, this.length);
    this._storeCollection.set(this._currentStore, []);
    this._cacheCollectionFinder = new Map();
  }

  /**
   * @override
   * @param {Object|Function} where
   * @description - back-guard compatibility
   */
  find(where) {
    if (where instanceof Object) {
      return find(this, where);
    } else if (typeof where === 'function') {
      return super.find(where);
    }
  }

  /**
   * @override
   * @param {Number} productId
   */
  findCached(productId) {
    if (this._cacheCollectionFinder.has(productId)) {
      return this._cacheCollectionFinder.get(productId);
    }
    const cartProduct = this.find((cartProduct) => cartProduct.product.id === productId);
    if (cartProduct instanceof CartProduct) {
      this._cacheCollectionFinder.set(productId, cartProduct);
      return cartProduct;
    }
    return null;
  }

  /**
   * @override
   * @param {Object|Function} where
   * @description - back-guard compatibility
   */
  findIndex(where) {
    if (where instanceof Object) {
      return findIndex(this, where);
    } else if (typeof where === 'function') {
      return super.findIndex(where);
    }
  }

  /**
   * @override
   * @param {Array<CartProduct|Object>} cartProducts
   */
  push(...cartProducts) {
    const validCartProducts = cartProducts.filter((cartProduct) => {
      if (typeof cartProduct?.product?.id === 'number' || cartProduct?.product?.id instanceof Number) {
        return true;
      }
      Sentry.captureException(new Error("CAN'T BE PUSH PRODUCTS WITHOUT ID TYPE NUMBER"), {
        extra: { cartProducts },
      });
      return false;
    });
    return super.push(
      ...validCartProducts.map((cartProduct) =>
        cartProduct instanceof CartProduct ? cartProduct : new CartProduct(cartProduct),
      ),
    );
  }

  /**
   * Model that is contained in this collection.
   * @description - back-guard compatibility
   */
  model() {
    return CartProduct;
  }

  /**
   * @property {Array<CartProduct|Object>}
   * @description - back-guard compatibility
   */
  get models() {
    return [...this];
  }

  set models(cartProducts) {
    this.set(cartProducts);
  }

  /**
   * @param {Array<CartProduct|Object>} cartProducts
   */
  set(cartProducts) {
    this.clear();
    this.push(...cartProducts);
  }

  /**
   * @param {CartProduct} cartProduct
   * @description - back-guard compatibility
   */
  add(cartProduct) {
    this._cacheCollectionFinder.set(cartProduct.product.id, cartProduct);
    this.push(cartProduct);
  }

  /**
   * @param {CartProduct} cartProduct
   * @description - back-guard compatibility
   */
  remove(cartProduct) {
    if (this._cacheCollectionFinder.has(cartProduct.product.id)) {
      this._cacheCollectionFinder.delete(cartProduct.product.id);
    }
    this.splice(
      this.findIndex((cartProductItem) => cartProduct.product.id === cartProductItem.product.id),
      1,
    );
    addBagTax(this, CART_ACTIONS.REMOVE_PRODUCT_ACTION);
  }

  createCloneWithStore(store) {
    try {
      return JSON.parse(JSON.stringify(this)).map((cartProduct) => ({ ...cartProduct, store }));
    } catch (e) {}
    return [];
  }

  setCartToAnotherKey(newCartParam) {
    const newCart = `${newCartParam}`;
    const currentCart = `${this._currentStore}`;
    this._storeCollection.set(newCart, this.createCloneWithStore(newCart));
    this._storeCollection.set(currentCart, []);
    this._currentStore = newCart;
    this.set(this._storeCollection.get(newCart));
  }

  /**
   * Sum all returns values
   * @param {string|((value: any) => number)} iteratee
   * @description - back-guard compatibility
   */
  sum(iteratee) {
    return sumBy(this, iteratee);
  }

  /**
   * @param {Object} iteratee
   * @returns {Object}
   * @description - back-guard compatibility
   */
  count(iteratee) {
    return countBy(this, iteratee);
  }

  /**
   * @description Check exist BagTax Product
   * @returns {Boolean}
   */
  hasBagTax() {
    const product = this.find((cartProduct) => cartProduct.product.typeProduct === 'ColombiaBagTax');
    return !!product;
  }

  /**
   * @description Check count BagTax product
   * @returns {*|number}
   */
  countBagTax() {
    const bagTaxProduct = this.find((cartProduct) => cartProduct.product.typeProduct === 'ColombiaBagTax');
    return bagTaxProduct ? bagTaxProduct.quantityFullPrice : 0;
  }

  /**
   * @description Get BagTax product
   * @return {CartProduct}
   */
  getBagTaxProduct() {
    return this.find((cartProduct) => cartProduct.product.typeProduct === 'ColombiaBagTax');
  }
}

export default Cart;
