import { nuxtInstance } from '~/plugins/nuxt-instance';
import { getValidateDniTypeUsecase } from '~/usecases/dni-types/validate-dni-type-usecase';

let timeoutOfValidate;

function timeOutLeft(ms) {
  return new Promise((resolve) => {
    timeoutOfValidate = setTimeout(resolve, ms);
  });
}

export default async (props, value) => {
  let valid = true;
  let val = value;
  let error = null;

  if (props.required) {
    if (val === '' || val === null || val === undefined) {
      valid = false;
      error = nuxtInstance?.app?.i18n?.t('validator_error_required');
    }
  }

  if (props.mqEmail && valid) {
    //TODO: correct regexp
    //eslint-disable-next-line
    const re = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/i;
    if (!re.test(String(val).toLowerCase())) {
      valid = false;
      error = nuxtInstance?.app?.i18n?.t('validator_error_email');
    } else {
      valid = true;
    }
  }

  if (typeof props.password !== 'undefined' && valid) {
    if (props.password !== val) {
      valid = false;
      error = nuxtInstance?.app?.i18n?.t('validator_error_match_passwords');
    }
  }

  if (props.minLength && valid) {
    if (val && val.length < parseInt(props.minLength)) {
      valid = false;
      error = nuxtInstance?.app?.i18n?.t('validator_error_field_min_length', { minLength: props.minLength });
    }
  }

  if (props.number && valid) {
    if (!/^\d*$/.test(val)) {
      valid = false;
      error = nuxtInstance?.app?.i18n?.t('validator_error_field_number');
    }
  }

  if (props.alphabet && valid) {
    //TODO: correct regexp
    //eslint-disable-next-line
    if (!/^[a-zA-ZÁ-ÿ\s\ñ\Ñ]+$/.test(val)) {
      valid = false;
      error = nuxtInstance?.app?.i18n?.t('validator_error_field_letters');
    }
  }

  if (props.alphabetnumeric && valid) {
    //TODO: correct regexp
    //eslint-disable-next-line
    if (!/^[a-zA-z0-9\s\ñ\Ñ]+$/.test(val)) {
      valid = false;
      error = nuxtInstance?.app?.i18n?.t('validator_error_field_alpha_numeric');
    }
  }

  if (props.regex && valid) {
    if (val && val.length > 0) {
      if (!RegExp(props.regex).test(val)) {
        valid = false;
        error = nuxtInstance?.app?.i18n?.t('validator_error_field_invalid_format');
      }
    }
  }

  if (props.hasEmoji && valid) {
    const regexExp =
      /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;
    const hasEmoji = regexExp.test(val);

    if (val && val.length > 0) {
      if (hasEmoji) {
        valid = false;
        error = nuxtInstance?.app?.i18n?.t('validator_error_field_has_emoji');
      }
    }
  }

  if (props.validCPF !== undefined && valid) {
    if (val && val.length > 0) {
      if (!validCPF(val)) {
        valid = false;
        error = nuxtInstance?.app?.i18n?.t('validator_error_field_invalid_CPF');
      }
    }
  }

  if (typeof props.documentType !== 'undefined' && valid) {
    clearTimeout(timeoutOfValidate);
    await timeOutLeft(500);
    const { data, err } = await getValidateDniTypeUsecase(props.documentType, val);
    if (err && !data) {
      valid = false;
      error =
        err.status === '500'
          ? nuxtInstance?.app?.i18n?.t(err.code.toLowerCase())
          : nuxtInstance?.app?.i18n?.t(err.id.toLowerCase());
    }
  }

  return {
    valid,
    value: val,
    error,
  };
};

// For see the logic see https://merqueo.atlassian.net/wiki/spaces/PROD/pages/1535967233/BR+Validaci+n+de+CPF
function validCPF(documentNumber) {
  const matcherArray = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2];
  const documentNumberArray = Array.from(documentNumber);
  const matchArrays = (val, index) => val * documentNumberArray[index];
  const sumMatchedValues = (total, value) => total + value;

  //Validation first digit
  const matcherArrayFirstDigit = matcherArray.slice(1);
  const mathedArraysFirstDigit = matcherArrayFirstDigit.map(matchArrays);
  const totalSumFirstDigit = mathedArraysFirstDigit.reduce(sumMatchedValues);
  const resultModuleFirstDigit = totalSumFirstDigit % 11;
  const firstDigitValidation = resultModuleFirstDigit >= 2 ? 11 - resultModuleFirstDigit : 0;

  //Validation second digit
  const mathedArraysSecondDigit = matcherArray.map(matchArrays);
  const totalSumSecondDigit = mathedArraysSecondDigit.reduce(sumMatchedValues);
  const resultModuleSecondDigit = totalSumSecondDigit % 11;
  const SecondDigitValidation = resultModuleSecondDigit >= 2 ? 11 - resultModuleSecondDigit : 0;

  return firstDigitValidation === +documentNumberArray[9] && SecondDigitValidation === +documentNumberArray[10];
}
