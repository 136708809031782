import { userPolicyValidateEndpoint } from '~/endpoints/policy/user-policy-validate-endpoint';
import { errorTransforms } from '~/transforms/errors/error-transform';

export async function userPolicyValidateUsecase({ country, base }) {
  const { error, response } = await userPolicyValidateEndpoint({ country, base });
  if (error === null && response?.status === 200) {
    return { data: response, error: null };
  } else if (error === null && response?.status === 280) {
    return { data: null, error: response.data.errors[0] };
  } else if (error?.response?.data instanceof Object && error?.response?.data?.errors instanceof Array) {
    return { data: null, error: errorTransforms(error) };
  } else {
    return {};
  }
}
