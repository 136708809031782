import { Store } from "vuex";
import { getBannerDetailsUseCase } from "@/usecases/banners/get-banner-details-usecase";
import { Strategy } from "@/helpers/deeplinks/strategy";
import { IQuery, ICity, getStoreSlug } from "@/helpers/deeplinks/utils/get-store-slug";
import { DEEP_LINKS_NAMES } from '~/helpers/deeplinks/constants/deeplinks';

export const strategyBannerDeepLink = (
  store: Store<any>,
  route: IQuery,
  city: ICity
) =>
  new Strategy(DEEP_LINKS_NAMES.banner, async () => {
    await store.dispatch("events/setTypeDeepLink", "banner");
    const bannerId = Number(route.query.banner_id);
    const zoneId = store.getters["home/getZoneId"];
    const { slug: storeSlug, store: currentStore } = await getStoreSlug(
      store,
      route
    );

    if (
      currentStore?.addressRequired &&
      !store.getters["address/isAddressValid"]
    ) {
      return storeSlug;
    }

    const { error } = await getBannerDetailsUseCase({
      storeId: currentStore.id,
      bannerId,
      zoneId,
    });
    if (error === null) {
      return `/${city?.slug}/${storeSlug}/banners/${bannerId}`;
    }

    return "/error/not-available";
  });
