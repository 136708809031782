import { getBestProducstByDptoEndpoint } from '~/endpoints/products/get-best-products-by-dpto-endpoint';
import { errorTransformsFetch, errorTransforms } from '~/transforms/errors/error-transform';
import { productTransforms } from '~/transforms/products/products-transform';

/**
 *
 * @param {number} storeId
 * @param {number} departmentId,
 */
export async function getBestProducstByDptoUseCase({ storeId, departmentId, zoneId }) {
  const { error, response } = await getBestProducstByDptoEndpoint({ storeId, departmentId, zoneId });
  if (!error && response.status === 200) {
    const data = await response.json();
    const product = productTransforms(data);
    return { data: product, error: null };
  } else if (!error && response.status === 280) {
    return { data: null, error: errorTransformsFetch(await response.json()) };
  } else if (error && error.hasOwnProperty('status')) {
    return { data: null, error: errorTransforms(await error.json()) };
  } else {
    return { data: null, error };
  }
}
