import { Checkout } from '~/endpoints/checkout/checkout-endpoint';
import { CheckoutEndpointParams } from '~/schemes/checkout-scheme-paramas';
import { getCheckoutTransform } from '~/transforms/checkout/checkout-transform';
import { error280Transforms } from '~/transforms/errors/error-transform';

export async function getCheckoutUsecase(params: CheckoutEndpointParams) {
  const { response, error } = await Checkout.getCheckoutEndpoint(new CheckoutEndpointParams(params));

  if (error === null && response.status === 200) {
    const checkoutData = await getCheckoutTransform(response.data);
    checkoutData.status = true;
    return { data: checkoutData, error: null };
  } else if (error === null && response.status === 280) {
    return { data: null, error: error280Transforms(response) };
  } else if (
    error &&
    error.response &&
    error.response.data instanceof Object &&
    error.response.data.errors instanceof Array
  ) {
    return { data: null, error: error.response.data.errors[0] };
  }
  return {};
}

