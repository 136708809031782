import { getProductsByTagUsecase } from '~/usecases/products/history-products-usecase';

// initial state
const state = {
  products: [],
  default_products: [],
  products_widget: [],
  products_page: {
    current: 1,
    total: null,
  },
  tooltipProductIdActive: null,
  videoSelectedInDetail: false,
  productsByCampaign: {},
};

// getters (state) => (id) =>
const getters = {};

// actions
const actions = {
  async getProductByTag({ rootGetters }, tag) {
    const params = { tag };
    params['adq'] = rootGetters['user/isAcquisition'];
    params['zoneId'] = rootGetters['home/getZoneId'];
    params['storeId'] = rootGetters['home/getCurrentStoreId'];

    const data = await getProductsByTagUsecase(params);
    return data;
  },
  hideDiscountTab() {
    const check_products = [...state.products];
    if (
      !check_products.filter(function (product) {
        return product.discountPercentage !== null;
      }).length > 0
    ) {
      // Hide "mayor ahorro" option if no product have this attribute
      let show_sort_option = document.querySelector('.select-sort .mq-select-options li:nth-child(3)');
      show_sort_option.classList.remove('visible');
    }
  },
  setProducts({ commit }, products) {
    commit('SET_PRODUCTS', products);
  },
  setVideoSelected({ commit }, isSelect) {
    commit('SET_VIDEO_SELECTED', isSelect);
  },
  setTooltipId({ commit }, tooltipId) {
    commit('SET_TOOLTIP_ID', tooltipId);
  },
  setProductsByCampaign({ commit }, productsList) {
    commit('SET_PRODUCT_BY_CAMPAIGN', productsList);
  },
};

// mutations
const mutations = {
  SET_PRODUCTS(state, products) {
    state.products = products;
  },
  SET_DEFAULT_PRODUCTS(state) {
    const default_products = [...state.products];
    state.default_products = default_products;
  },
  SET_PRODUCTS_WIDGET(state, products) {
    state.products_widget = products;
  },
  APPEND_PRODUCTS(state, products) {
    state.products = state.products.concat(products);
  },
  ADD_PRODUCTS_PAGE(state, reset = false) {
    if (!reset) state.products_page.current++;
    else state.products_page.current = 1;
  },
  SET_PRODUCTS_PAGE_TOTAL(state, total_pages) {
    state.products_page.total = total_pages;
  },
  SET_VIDEO_SELECTED(state, isSelect) {
    state.videoSelectedInDetail = isSelect;
  },
  SET_TOOLTIP_ID(state, tooltipId) {
    state.tooltipProductIdActive = tooltipId;
  },
  SET_PRODUCT_BY_CAMPAIGN(state, productsData) {
    state.productsByCampaign = productsData;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
