import CartConstructor from '../../models/cart';
import CartProduct from '../../models/cart_product';
import { getPercentFreeDelivery, mustNotifyFreeDelivery } from '~/usecases/delivery/percent-free-delivery';
import { CART_ACTIONS, CART_TYPES } from '~/constants/cart-actions';
import { getSuggestedProductsUsecase } from '~/usecases/products/suggested-products-usecase';
import { handlerValidateCampaignsUseCase } from '~/usecases/campaigns/handler-validate-campaigns-usecase';
import { CAMPAIGNS_MAIN_TYPES } from '~/constants/campaigns_types';

const getDefaultState = () => ({
  products: new CartConstructor([], undefined, true),
  suggested: [],
  widgets: {
    showAlertEmpty: false,
  },
  product_modal: {},
  no_products_modal: [],
  freeDeliveryNotified: false,
  instanceCart: CART_TYPES.DEFAULT,
});

/**
 * @type {ReturnType<typeof getDefaultState>}
 */
const STATE = getDefaultState();

const GETTERS = {
  /**
   * @param {ReturnType<typeof getDefaultState>} state
   * @returns {Cart}
   */
  getCart(state) {
    return state.products;
  },
  /**
   * Get the full cart collection with all stores items
   * @param {ReturnType<typeof getDefaultState>} state
   * @returns {Array<CartProduct>}
   */
  getFullCart(state) {
    return state.products.getFullCollection();
  },
  getPercentFreeDelivery(_state, getters, rootState, rootGetters) {
    const { freeDeliveryDays } = rootState.user.user;
    const marketplaceStore = rootGetters['home/getCurrentStore'];
    let freeDelivery = 0;
    if (marketplaceStore instanceof Object && typeof marketplaceStore.minimumOrderAmountFreeDelivery === 'number') {
      freeDelivery = marketplaceStore.minimumOrderAmountFreeDelivery;
    }
    return getPercentFreeDelivery(freeDelivery, getters.getCart, freeDeliveryDays);
  },
  mustNotifyFreeDelivery(_state, getters, rootState, rootGetters) {
    const marketplaceStore = rootGetters['home/getCurrentStore'];
    let freeDelivery = 0;
    if (marketplaceStore instanceof Object && typeof marketplaceStore.minimumOrderAmountFreeDelivery === 'number') {
      freeDelivery = marketplaceStore.minimumOrderAmountFreeDelivery;
    }
    return mustNotifyFreeDelivery(freeDelivery, getters.getCart);
  },
  getVolumetricPercent(state, _getters, rootState) {
    const cartProducts = state.products;
    const { expressCapacity } = rootState.config.config;

    const cartVolumetric = cartProducts.getVolumetric();
    const weightPercent = (cartVolumetric.weight / expressCapacity.weight_capacity) * 100;
    const volumePercent = (cartVolumetric.volume / expressCapacity.volume_capacity) * 100;

    return Math.round(volumePercent < weightPercent ? weightPercent : volumePercent);
  },
  getProductListIds(state) {
    return state.products.map((cartProduct) => cartProduct.product.id);
  },
  getProductList(state) {
    return state.products.map((cartProduct) => ({
      id: cartProduct.product.id,
      quantity: cartProduct.quantityFullPrice + cartProduct.quantitySpecialPrice,
    }));
  },
  getInstanceCart(state) {
    return state.instanceCart;
  },
  getNoProductsModal(state) {
    return state.no_products_modal ?? null;
  },
};

// actions
const ACTIONS = {
  addProduct({ commit }, product) {
    commit('ADD_PRODUCT', product);
  },
  setNoproductsModal({ commit }, noProductsModal) {
    commit('SET_NO_PRODUCTS_MODAL', noProductsModal);
  },
  async getSuggestedProduct({ commit, rootState, getters, rootGetters }) {
    commit('SET_SUGGESTED_PRODUCT', []);
    if (rootState.ui.widgets.cartWidget) {
      const storeId = rootGetters['home/getCurrentStoreId'];
      const zoneId = rootGetters['home/getZoneId'];
      const products = getters.getCart.getCartModels();

      const params = {
        zoneId,
        cart: products,
      };

      const { data, error } = await getSuggestedProductsUsecase(storeId, params);
      if (!error && data instanceof Array) {
        commit('SET_SUGGESTED_PRODUCT', data);
        return data;
      }
    }
    commit('SET_SUGGESTED_PRODUCT', []);
    return [];
  },

  setCartProductStore({ commit }, cartProductStore = undefined) {
    commit('SET_CURRENT_STORE', cartProductStore);
  },

  setFreeDeliveryNotified({ commit }, value) {
    commit('SET_FREE_DELIVERY_NOTIFIED', value);
  },

  setAllProducts({ commit }, dataCart) {
    commit('SET_ALL_PRODUCTS', dataCart);
  },

  setCartInstance({ commit }, instance = CART_TYPES.DEFAULT) {
    commit('SET_CART_INSTANCE', instance);
  },

  setProductModal({ commit }, product) {
    commit('SET_PRODUCT_MODAL', product);
  },
};

// mutations
const MUTATIONS = {
  /**
   * @param {ReturnType<typeof getDefaultState>} state
   * @param {string} store
   */
  SET_CURRENT_STORE(state, store) {
    const { products: cart } = state;
    cart.setCurrentStore(store);
  },
  /**
   * Agrega productos al carro de compras
   * Incrementa y disminuye productos existentes
   * @param {ReturnType<typeof getDefaultState>} state
   */
  ADD_PRODUCT(state, { product, type = CART_ACTIONS.ADD_PRODUCT_ACTION, quantity = 1, screen = null }) {
    const { products } = state;
    Object.assign(product, { screen });
    if (type === CART_ACTIONS.ADD_PRODUCT_ACTION) {
      products.addProduct(product, quantity);
      handlerValidateCampaignsUseCase(products, CAMPAIGNS_MAIN_TYPES.GENERAL);
    } else if (type === CART_ACTIONS.REMOVE_PRODUCT_ACTION) {
      products.removeProduct(product, quantity);
      handlerValidateCampaignsUseCase(products, CAMPAIGNS_MAIN_TYPES.GENERAL);
    }
  },

  /**
   * Elimita un producto del carrito totalmente.
   */
  DELETE_PRODUCT(state, { product, quantity = 1 }) {
    const { products } = state;
    const exist = products.find({ product: { id: product?.id } });

    if (exist instanceof CartProduct) {
      products.remove(exist, quantity);
      handlerValidateCampaignsUseCase(state.products, CAMPAIGNS_MAIN_TYPES.GENERAL);
    }
  },

  /**
   * Eliminar todos los productos del carrito
   * @param {ReturnType<typeof getDefaultState>} state
   */
  DELETE_ALL_PRODUCT(state) {
    handlerValidateCampaignsUseCase(state.products, CAMPAIGNS_MAIN_TYPES.GENERAL);
    state.products.clear();
  },

  /**
   * Set state de los widgets
   */
  SET_WIDGET(state, name) {
    state.widgets[name] = !state.widgets[name];
  },

  /**
   * Producto sugerido en el carrito.
   */
  SET_SUGGESTED_PRODUCT(state, product) {
    state.suggested = product;
  },

  /**
   * Producto para informacion en modal de special price.
   */
  SET_PRODUCT_MODAL(state, product) {
    state.product_modal = product;
  },

  /**
   * Productos para informacion en modal de cambio de direccion (zona).
   */
  SET_NO_PRODUCTS_MODAL(state, products) {
    state.no_products_modal = products;
  },
  /**
   * Reset a todas las variables del state inicial
   */
  resetState(state) {
    const defaultState = getDefaultState();
    state.products.clear();
    Object.assign(state, defaultState, { products: state.products });
  },

  /**
   * Asigna todo el carrito con los productos que recibe
   * @param {ReturnType<typeof getDefaultState>} state
   * @param {Array<Object>} products
   */
  SET_ALL_PRODUCTS(state, products) {
    state.products.set(products);
  },
  /**
   * Asigna valor a freeDeliveryNotified
   * @param {*} state
   * @param {*} value
   */
  SET_FREE_DELIVERY_NOTIFIED(state, value) {
    state.freeDeliveryNotified = value;
  },

  SET_CART_INSTANCE(state, instance) {
    state.instanceCart = instance;
  },
};

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
};
