import { validateScheme } from '~/decorators/validateScheme';
import { PaymentMethodsParams } from '~/schemes/payment-methods-scheme';
import $axios from '~/services/global-axios';

export class PaymentMethods {
  @validateScheme
  static async getPaymentMethodsEndpoint(
    paymentMethodsParams: PaymentMethodsParams,
  ): Promise<{ response: any; error: any }> {
    const { zoneId } = paymentMethodsParams;
    try {
      const paramZoneId = zoneId ? `&zoneId=${zoneId}` : '';
      const response = await $axios.get(`/3.1/payment-methods?${paramZoneId}`);

      return { response, error: null };
    } catch (error) {
      return { response: null, error };
    }
  }
}
