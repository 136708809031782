import { productServicesBase } from '~/../env.local';

export async function getInfoDeviceHuaweiEndpoint() {
  try {
    const response = await fetch(`${productServicesBase}/huawei-header/v1`);
    return { response, error: null };
  } catch (error) {
    return { response: null, error };
  }
}
export async function getInfoDeviceHuaweiService() {
  const { response } = await getInfoDeviceHuaweiEndpoint();
  if (response?.ok && response.status === 200) {
    const {
      data: { x_requested_with: xRequestedWith = '' },
    } = await response.json();
    return xRequestedWith !== '' && xRequestedWith.toLowerCase().includes('huawei');
  }
  return false;
}

