import { getValidateDniTypeEndpoint } from '~/endpoints/dni-types/validate-dni-type-endpoint';
import { errorTransforms, error280Transforms } from '~/transforms/errors/error-transform';

export async function getValidateDniTypeUsecase(dniTypeId, documentNumber) {
  const { response, err } = await getValidateDniTypeEndpoint(dniTypeId, documentNumber);
  if (err === null && response?.status === 200) {
    return { data: { status: true }, err: null };
  } else if (err === null && response?.status === 280) {
    return { data: null, err: error280Transforms(response) };
  } else if (err?.response?.data?.errors instanceof Array) {
    return { data: null, err: errorTransforms(err) };
  } else {
    return {};
  }
}
