import { Store } from 'vuex';
import { Strategy } from '@/helpers/deeplinks/strategy';
import { IQuery, ICity } from '@/helpers/deeplinks/utils/get-store-slug';
import { getPathShelf } from '@/helpers/deeplinks/utils/get-path-shelf';
import { DEEP_LINKS_NAMES } from '~/helpers/deeplinks/constants/deeplinks';

export const strategyShelfDeepLink = (store: Store<any>, route: IQuery, cityBySlug: ICity) =>
  new Strategy(DEEP_LINKS_NAMES.shelf, async () => {
    const shelfPath = await getPathShelf(store, route);
    return shelfPath ? `/${cityBySlug?.slug}/${shelfPath}` : ``;
  });

