import { nuxtInstance } from '~/plugins/nuxt-instance';
import $axios from '~/services/global-axios';
import { SORT_TYPES } from '~/constants/sort-types';

/**
 *
 * @param {string} hashOrderId - Hashing order id
 */
export async function getSearchProductsEndpoint(
  storeId,
  {
    search,
    page = 1,
    per_page = 50,
    zoneId = '',
    sort_by = nuxtInstance?.app?.i18n?.t(SORT_TYPES.RELEVANCE.PARAM_NAME),
  },
) {
  try {
    const params = {
      q: search,
      page,
      per_page,
      zoneId: zoneId ? zoneId : undefined,
      sort_by,
    };
    const response = await $axios.get(`/3.1/stores/${storeId}/search`, { params });
    return { response, error: null };
  } catch (error) {
    return { response: null, error };
  }
}
