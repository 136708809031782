//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

export default {
  name: 'MqSocialButtons',
  props: {
    theme: {
      type: String,
      default: 'mq-default',
    },
  },
  mounted() {
    this.$store.commit('SET_LOADING', false);
  },
  computed: {
    ...mapState({
      facebook: (state) => state.config.config.social.facebook,
      instagram: (state) => state.config.config.social.instagram,
      twitter: (state) => state.config.config.social.twitter,
    }),
  },
};
