import { Store } from 'vuex';
import { Strategy } from '@/helpers/deeplinks/strategy';
import { IQuery, ICity } from '@/helpers/deeplinks/utils/get-store-slug';
import { getPathProduct } from '@/helpers/deeplinks/utils/get-path-product';
import { DEEP_LINKS_NAMES } from '~/helpers/deeplinks/constants/deeplinks';

export const strategyProductDeepLink = (store: Store<any>, route: IQuery, city: ICity) =>
  new Strategy(DEEP_LINKS_NAMES.product, async () => {
    const path = await getPathProduct(store, route);
    return path ? `/${city?.slug}/${path}` : ``;
  });

