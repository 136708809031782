//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex';

export default {
  name: 'MqAppsLinks',
  computed: {
    ...mapState({
      appStore: (state) => state.config.config.download.appStore,
      googlePlay: (state) => state.config.config.download.googlePlay,
      huaweiGallery: (state) => state.config.config.download.huaweiGallery,
    }),
    ...mapGetters({
      isCountry: 'home/isCountry',
    }),
  },
};
