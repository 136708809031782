export async function redirectLegacyHalls({ store, redirect, params }) {
  const halls = store.getters['home/getHalls'];
  const hall = halls.find((hall) => hall.slug === params.store);
  if (hall instanceof Object) {
    let redirectPath = `/${params.city}/super-ahorro/${hall.slug}`;
    redirectPath = params.department ? `${redirectPath}/${params.department}` : redirectPath;
    redirectPath = params.shelf ? `${redirectPath}/${params.shelf}` : redirectPath;
    return redirect(redirectPath);
  }
}
