import $axios from '~/services/global-axios';

const getMyAddress = async ({ currentPage, perPage }) => {
  try {
    let response = await $axios.get(`/3.0/users/addresses?page=${currentPage}&per_page=${perPage}`);
    return { response: response.data, error: null };
  } catch (error) {
    return { response: null, error };
  }
};
export default getMyAddress;
