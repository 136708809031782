import Cart from '../../models/cart.js';
import CartProduct from '../../models/cart_product';
import Product from '../../models/product.js';
import { CART_ACTIONS } from '~/constants/cart-actions.js';

const getOriginalState = () => {
  return {
    orderProducts: new Cart([], 'edit', true),
    product_modal: {},
    message_modal: {},
    products_changed_modal: [],
    removeCartTemp: [],
    campaignsByOrder: null,
  };
};

const state = getOriginalState();

// getters
const getters = {
  /**
   * @param {ReturnType<typeof getDefaultState>} state
   */
  getCart(state) {
    return state.orderProducts;
  },
  getCartProductsDeleted(state) {
    return state.removeCartTemp;
  },
  getMoreOfOriginalCart(state) {
    const { orderProducts } = state;

    const countMoreOfOriginal = orderProducts.filter((product) => {
      return (
        (product.isOriginalProduct && product.getCount() !== product.originalQuantity) || !product.isOriginalProduct
      );
    });
    return countMoreOfOriginal.length;
  },
  getLessOfOriginalCart(state) {
    const { orderProducts } = state;

    const countLessOfOriginal = orderProducts.filter((product) => {
      return product.isOriginalProduct && product.getCount() < product.originalQuantity;
    });
    return countLessOfOriginal;
  },
  getCartModelsFinal(state) {
    const { orderProducts } = state;
    const orderProductsMap = orderProducts.map((prod) => {
      const operationFinalQuantities = (prod) => {
        const { originalQuantity, quantitySpecialPrice, quantityFullPrice } = prod;
        let finalQuantityFullPrice = quantityFullPrice;
        let finalQuantitySpecialPrice = quantitySpecialPrice;

        for (let i = 1; i <= originalQuantity; i++) {
          if (finalQuantitySpecialPrice > 0) {
            finalQuantitySpecialPrice--;
          } else if (finalQuantityFullPrice > 0) {
            finalQuantityFullPrice--;
          }
        }

        return { finalQuantitySpecialPrice, finalQuantityFullPrice };
      };

      const { finalQuantitySpecialPrice, finalQuantityFullPrice } = operationFinalQuantities(prod);
      return {
        id: prod.idStoreProduct ? prod.idStoreProduct : prod.product.id,
        quantityFullPrice: finalQuantityFullPrice,
        quantitySpecialPrice: finalQuantitySpecialPrice,
        fullPrice: prod.fullPrice,
        specialPrice: prod.specialPrice,
      };
    });
    return orderProductsMap.filter((prod) => {
      return prod.quantityFullPrice + prod.quantitySpecialPrice > 0;
    });
  },
  getRemoveCart(state) {
    const { orderProducts, removeCartTemp } = state;
    const orderProductsRemoved = [...orderProducts, ...removeCartTemp].filter((orderProduct) => {
      const { originalQuantity, quantitySpecialPrice, quantityFullPrice } = orderProduct;
      const remainingQuantity = quantitySpecialPrice + quantityFullPrice;
      return orderProduct.isOriginalProduct && originalQuantity > remainingQuantity;
    });
    const removeOrderProductsSpecials = [];
    const orderProductsMap = orderProductsRemoved.map((cartProduct) => {
      const { originalQuantity, quantitySpecialPrice, quantityFullPrice } = cartProduct;
      const { quantitySpecialPrice: MaxQuantitySpecialPrice } = cartProduct.product;

      const quantityNormalPrice = originalQuantity - MaxQuantitySpecialPrice - quantityFullPrice;
      const quantitySpecialPriceValue =
        originalQuantity > MaxQuantitySpecialPrice
          ? MaxQuantitySpecialPrice - quantitySpecialPrice
          : originalQuantity - quantitySpecialPrice;

      if (cartProduct?.product?.specialPriceOrderId && quantitySpecialPriceValue > 0) {
        removeOrderProductsSpecials.push({
          id: cartProduct.product.specialPriceOrderId.toString(),
          quantity: quantitySpecialPriceValue,
        });
      }
      return {
        id: cartProduct.product.normalPriceOrderId.toString(),
        quantity: quantityNormalPrice > 0 ? quantityNormalPrice : quantitySpecialPriceValue,
      };
    });
    return [...orderProductsMap, ...removeOrderProductsSpecials];
  },
  getCampaignsByOrder(state) {
    return state.campaignsByOrder;
  },
};

const mutations = {
  /**
   * Agrega productos al carrito de edición
   * Incrementa y disminuye productos existentes
   * @param {ReturnType<typeof getDefaultState>} state
   */
  ADD_PRODUCT(
    state,
    { product, type = CART_ACTIONS.ADD_PRODUCT_ACTION, quantity = 1, screen = null, isOriginalProduct = false },
  ) {
    const { orderProducts, removeCartTemp } = state;
    if (type === CART_ACTIONS.ADD_PRODUCT_ACTION) {
      for (let i = 0; i < quantity; i++) {
        const exist = orderProducts.findCached(product.id);
        if (exist instanceof CartProduct) {
          exist.addProduct();
        } else {
          const newCarProduct = new CartProduct();
          newCarProduct.product = product instanceof Product ? product : new Product(product);
          newCarProduct.screen = screen;
          newCarProduct.store = state.currentStore;
          newCarProduct.originalQuantity = isOriginalProduct ? quantity : null;
          newCarProduct.originalQuantityOld = quantity;
          newCarProduct.isOriginalProduct = isOriginalProduct;

          orderProducts.add(newCarProduct);
          newCarProduct.addProduct();
        }
      }
    } else if (type === CART_ACTIONS.REMOVE_PRODUCT_ACTION) {
      const productId = product?.id ?? product?.product?.id;
      const exist = orderProducts.findCached(productId);
      if (exist instanceof CartProduct) {
        exist.removeProduct();
        if (exist.getCount() === 0) {
          if (exist.isOriginalProduct) {
            removeCartTemp.push(exist);
          }
          orderProducts.remove(exist);
        }
      }
    } else if (type === CART_ACTIONS.UNDO_REMOVE_PRODUCT_ACTION) {
      const productId = product?.id ?? product?.product?.id;
      const productExist = removeCartTemp.some(
        (product) => product?.id === productId || product?.product?.id === productId,
      );
      if (productExist && product instanceof CartProduct) {
        state.removeCartTemp = removeCartTemp.filter(
          (product) =>
            (product?.id && product?.id !== productId) || (product?.product?.id && product?.product?.id !== productId),
        );
        state.orderProducts.push(product);
        if (product.isOriginalProduct) {
          const { originalQuantity } = product;
          for (let i = 1; i <= originalQuantity; i++) {
            product.addProduct();
          }
        }
      }
    }
  },

  /**
   * Elimita un producto del carrito totalmente.
   */
  DELETE_PRODUCT(state, { product, alreadyBought = false }) {
    const { orderProducts, removeCartTemp } = state;
    const exist = orderProducts.find({ product: { id: product.id } });

    if (exist instanceof CartProduct) {
      const quantity = exist.getCount();

      if (!alreadyBought) {
        removeCartTemp.push(exist);
      }

      if (exist.isOriginalProduct) {
        for (let i = 0; i < quantity; i++) {
          exist.removeProduct(true);
        }
      }
      orderProducts.remove(exist);
    }
  },

  /**
   * Eliminar todos los productos del carrito
   * @param {ReturnType<typeof getDefaultState>} state
   */
  DELETE_ALL_PRODUCT(state) {
    state.orderProducts.clear();
  },

  /**
   * Producto para informacion en modal de special price.
   */
  SET_PRODUCT_MODAL(state, product) {
    state.product_modal = product;
  },

  /**
   * Message info internal modal
   */
  SET_MESSAGE_MODAL_INFO(state, { title, subtitle }) {
    state.message_modal = { title, subtitle };
  },

  /**
   * Products change price or not available
   */
  SET_PRODUCTS_CHANGED_MODAL(state, products) {
    state.products_changed_modal = products;
  },
  SET_REMOVE_CART_TEMP(state) {
    state.removeCartTemp = [];
  },
  SET_CAMPAIGNS_BY_ORDER(state, value) {
    state.campaignsByOrder = value;
  },
  CLEAR_ALL_DATA_ORDER_EDIT(state) {
    state.orderProducts = new Cart([], 'edit', true);
    state.product_modal = {};
    state.message_modal = {};
    state.products_changed_modal = [];
    state.removeCartTemp = [];
    state.campaignsByOrder = null;
  },
};

const actions = {
  clearAllDataOrderEdit({ commit }) {
    commit('CLEAR_ALL_DATA_ORDER_EDIT');
  },
  setCampaignsByOrder({ commit }, value) {
    commit('SET_CAMPAIGNS_BY_ORDER', value);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
