import { refreshTokenEndpoint } from '~/endpoints/refresh-token/refresh-token-endpoint';
import { errorTransforms } from '~/transforms/errors/error-transform';
import { refreshTokenTransforms } from '~/transforms/user/refresh-token-transform';

export async function refreshTokenService(tokenId) {
  const { response, error } = await refreshTokenEndpoint(tokenId);
  if (!error && response?.status === 200) {
    const { value } = refreshTokenTransforms(response.data);
    return { data: value, error: null };
  }
  return { data: null, error: errorTransforms(error) };
}

