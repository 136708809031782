import { createCampaignsStorageState } from './storages/campaigns';
import { createCartStorageState } from './storages/cart-storage';
import { createCheckoutStorageState } from './storages/checkout-storage';
import { createGlobalStorageState } from './storages/global-storage';

export default ({ store }) => {
  createGlobalStorageState(store);
  createCheckoutStorageState(store);
  createCartStorageState(store);
  createCampaignsStorageState(store);
};
