//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
export default {
  name: 'MqTooltip',
  props: {
    disabled: {
      type: Boolean,
      default: () => false,
    },
    icon: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: '',
    },
    align: {
      type: String,
      default: 'top',
    },
    text: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: 'pink500',
    },
    textColor: {
      type: String,
      default: 'white',
    },
    textWeight: {
      type: Boolean,
      default: true,
    },
    colorIcon: {
      type: String,
      default: '',
    },
    additionalStyle: {
      type: String,
      default: null,
    },
    allowAlignTopOrBottom: {
      type: Boolean,
      default: () => true,
    },
    allowAlignRight: {
      type: Boolean,
      default: () => false,
    },
    isOpenWithClick: {
      type: Boolean,
      default: () => false,
    },
    tooltipId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      alignData: this.align,
      open: false,
      alignDataAdjust: '',
      isCalculateAlignEnd: true,
      manyHover: 0,
    };
  },
  watch: {
    open() {
      !this.isOpenWithClick && this.recalculateAlign();
    },
  },
  mounted() {
    if (!this.disabled) {
      !this.isOpenWithClick && this.$el.addEventListener('mouseover', this.hoverHandler);
      !this.isOpenWithClick && this.recalculateAlign();
      this.$nuxt.$on('close-other-tooltip', () => {
        this.open = false;
      });
    }
  },
  beforeDestroy() {
    this.$el.removeEventListener('mouseover', this.hoverHandler);
  },
  methods: {
    ...mapActions({
      setTootipIdActive: 'product/setTooltipId',
    }),
    hoverHandler() {
      !this.isOpenWithClick && this.$nuxt.$emit('close-other-tooltip');
      this.recalculateAlign();
    },
    recalculateAlign() {
      if (this.allowAlignTopOrBottom && (this.alignData === 'bottom' || this.alignData === 'top')) {
        const innerWidth = window.innerWidth;
        const boundingRect = this.$el.getBoundingClientRect();
        const boundingRectTooltip = this.$el.querySelector('.tooltip').getBoundingClientRect();
        if (innerWidth < boundingRect.x + boundingRectTooltip.width) {
          this.alignData = 'left';
        } else if (boundingRect.x < 0) {
          this.alignData = 'right';
        }
      } else if (this.allowAlignRight && (this.alignData === 'right' || this.alignData === 'top')) {
        const innerWidth = window.innerWidth;
        const boundingRect = this.$el.getBoundingClientRect();
        const boundingRectTooltip = this.$el.querySelector('.tooltip').getBoundingClientRect();
        if (innerWidth - 30 < boundingRect.x + boundingRectTooltip.width) {
          this.alignDataAdjust = 'top adjust-top-left';
        } else {
          this.alignDataAdjust = '';
        }
      }
      this.isCalculateAlignEnd = true;
    },
    openWithClick() {
      this.$nuxt.$emit('close-other-tooltip');
      if (this.isOpenWithClick) {
        this.isCalculateAlignEnd = false;
        this.open = true;
        setTimeout(() => {
          this.recalculateAlign();
        }, 100);
        this.tooltipId && this.setTootipIdActive(this.tooltipId);
      }
    },
    closeWithClick() {
      this.open = false;
    },
    emitHover() {
      this.manyHover = this.manyHover + 1;
      this.$emit('hover', [true, this.manyHover]);
    },
    emitHoverLeave() {
      this.manyHover = 0;
      this.$emit('hover', [false, this.manyHover]);
    },
  },
};
