import { getUserTotalCreditsEndpoint } from '~/endpoints/account/get-user-total-credits-endpoint';
import { totalCreditsTransforms } from '~/transforms/account/credits-transform';
import { errorTransforms, error280Transforms } from '~/transforms/errors/error-transform';

/**
 *
 * @param {number} storeId
 * @param {number} productid,
 */
export async function getUserTotalCreditsService(countryId) {
  const { error, response } = await getUserTotalCreditsEndpoint(countryId);
  if (response && response.status === 200) {
    const data = totalCreditsTransforms(response.data);
    return { data, error: null };
  } else if (response && response.status === 280) {
    return { data: null, error: error280Transforms(error) };
  } else if (error) {
    return { data: null, error };
  } else {
    return {};
  }
}
