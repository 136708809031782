// initial state

import Vue from 'vue';
import Cart from '@/models/cart';
import { PAYMENT_METHODS } from '~/constants/payment-methods';
import { getDeliveryTimesUseCase } from '~/usecases/checkout/delivery-times-usecase';
import { validateCouponsUsecase } from '~/usecases/checkout/validate-coupons-usecase';
import { getPaymentMethodsUsecase } from '~/usecases/checkout/payment-methods-usecase';
import { getValidateTotalsUsecase } from '~/usecases/checkout/validate-totals-usecase';
import { getCheckoutUsecase } from '~/usecases/checkout/checkout-usecase';
import { handlerValidateCampaignsUseCase } from '~/usecases/campaigns/handler-validate-campaigns-usecase';
import { CAMPAIGNS_MAIN_TYPES } from '~/constants/campaigns_types';
import ChangePaymentMethodService from '~/services/checkout/change-payment-method-service.js';

const env = require('../../../env.local.js');

const DEFAULT_VIEW = {
  page: '',
  view: 'delivery-confirm',
  current: true,
};

const getDefaultState = () => {
  return {
    view_checkout: 'delivery-confirm',
    user_credit_cards: [],
    paymentMethods: [],
    documentTypes: [],
    pseBanks: [],
    pseData: {
      pseBank: {
        pseCode: null,
        description: null,
      },
      fullName: null,
      phone: null,
      typePerson: {
        code: null,
        text: null,
      },
      typeDocument: {
        id: null,
        text: null,
      },
      numberDocument: null,
      psePaymentUrl: null,
    },
    payment: {
      type: null,
      method: null,
      methodText: null,
      creditCardId: null,
      enableCVV: false,
      coupon: null,
      useCredits: false,
      installmentsCreditCard: '',
      newCreditCard: {
        name: undefined,
        number: undefined,
        monthExp: undefined,
        yearExp: undefined,
        cvv: undefined,
        document_type: undefined,
        document: undefined,
        address: undefined,
        phone: undefined,
        installments: undefined,
      },
      invoice_company_nit: null,
      invoice_company_name: null,
      invoice_company_type: null,
      orderCreated: false,
      reference: null,
      backForFail: false,
      dniTypeId: null,
    },
    orders: {
      merqueo: {},
      marketplace: {},
    },
    history: [DEFAULT_VIEW],
    totals: {
      deliveryAmount: 0,
      discount: 0,
      subTotal: 0,
      total: 0,
    },
    products_not_available: [],
    message_not_available: null,
    messageNotDeliveryDate: '',
    orderSuccessTemp: {},
    isCheckoutSuccess: false,
    isGiftCampignAdded: false,
    surveyData: {
      id: null,
      question: null,
      answers: [],
    },
    messageErrorCheckout: null,
    cartTemp: null,
    isFreeDelivery: false,
    isRetryPayment: false,
    //campaignPrizeIds: null,
    isAddressChanged: false,
    whoReceives: {},
  };
};

const state = getDefaultState();

// getters
const getters = {
  getCurrentView: function (state) {
    const currentView = state.history.filter((history) => history.current)[0];
    return currentView ? currentView : DEFAULT_VIEW;
  },
  getWhoReceives: (state) => state.whoReceives,
  getBackView: function (state) {
    let histories = state.history;

    let currentHistory = 0;

    histories.forEach((element, index) => {
      if (element.current) {
        currentHistory = index;
      }
    });

    currentHistory = currentHistory > 0 ? currentHistory - 1 : 0;
    return histories[currentHistory];
  },
  getTempCart: function (state) {
    if (state.cartTemp instanceof Array) {
      return new Cart(state.cartTemp, 'temp');
    } else if (state.cartTemp instanceof Object) {
      return new Cart(Object.values(state.cartTemp), 'temp');
    }
    return new Cart([], 'temp');
  },
  getSuccessOrderId: function (state) {
    return 'orders' in state.orderSuccessTemp && 'id' in state.orderSuccessTemp.orders[0]
      ? state.orderSuccessTemp.orders[0].id
      : '';
  },
  getCurrentTotal(state) {
    return state.totals;
  },
  getIsRetryPayment(state) {
    return state.isRetryPayment;
  },
  getCampaignPrizeIds(state) {
    return state.campaignPrizeIds;
  },
  isAddressChangedEvent(state) {
    return state.isAddressChanged;
  },
};

// actions
const actions = {
  setWhoReceives({ commit }, whoReceives) {
    commit('SET_WHO_RECEIVES', whoReceives);
  },
  setPaymentsAttributes({ commit }, paymentsAttributes) {
    Object.entries(paymentsAttributes).forEach(([type, value]) => {
      commit('SET_PAYMENT', { type, value });
    });
  },
  async getDeliveryTimes({ rootState, rootGetters, dispatch }) {
    const storeId = rootGetters['home/getCurrentStoreId'];
    const cart = rootGetters['cart/getCart'].getCartModels();
    const zoneId = rootGetters['home/getZoneId'];
    const slotExpress = rootGetters['home/isExpress']();
    const latitude = rootState.address.address_object ? rootState.address.address_object.latitude : '';
    const longitude = rootState.address.address_object ? rootState.address.address_object.longitude : '';
    const campaigns = rootGetters['campaigns/getCampaignCheckout'];
    const campaignPrizeIds = campaigns.map((item) => item.id);

    if (!cart.length) return {};
    if (!rootState.user.token) {
      return {};
    }

    const responseDelivery = await getDeliveryTimesUseCase({
      storeId,
      zoneId,
      latitude,
      longitude,
      cart,
      slotExpress,
      campaignPrizeIds,
    });
    const { error, data } = responseDelivery;

    if (data) {
      return responseDelivery;
    } else if (error.status === '280') {
      await dispatch('campaigns/setCampaignsParticipating', new Map(), {
        root: true,
      });
      const responseDelivery2 = await getDeliveryTimesUseCase(
        storeId,
        zoneId,
        latitude,
        longitude,
        cart,
        slotExpress,
        campaignPrizeIds,
      );
      return responseDelivery2;
    }
    return responseDelivery;
  },
  async getPaymentMethods({ commit, dispatch, rootGetters }) {
    const zoneId = rootGetters['home/getZoneId'];
    const { data: responsePaymentMethods, error } = await getPaymentMethodsUsecase({ zoneId });

    if (!error && responsePaymentMethods) {
      commit('SET_PAYMENTS_METHODS', responsePaymentMethods.paymentMethods);
      commit('SET_PSE_BANKS', responsePaymentMethods.pseBanks);
      await dispatch('setDocumentsToArray');
    }
  },
  async setDocumentsToArray({ commit, rootState }) {
    const arrayDocuments = [];
    Object.entries(rootState.config.documents).forEach((documentType) => {
      const documentObject = {};
      documentObject.id = documentType[1].attributes.code;
      documentObject.text = documentType[1].attributes.document;
      arrayDocuments.push(documentObject);
    });
    commit('SET_DOCUMENT_TYPES', arrayDocuments);
  },
  setCreditCards({ commit }, creditCardsData) {
    commit('SET_CREDIT_CARDS', creditCardsData);
  },
  async validateCoupon(
    { rootGetters },
    { code, paymentMethod, creditCardId, creditCardInstallments, creditCard, creditCardCvv },
  ) {
    let storeId = rootGetters['home/getCurrentStoreId'];
    let cart = rootGetters['cart/getCart'].getCartModels();

    let params = {
      code: code,
      cart: cart,
      paymentMethod: paymentMethod,
      zoneId: rootGetters['home/getZoneId'],
    };

    if (creditCard) {
      params['creditCard'] = creditCard;
    }

    if (creditCardId) {
      params['creditCard']['id'] = creditCardId;
      params['creditCard']['installments'] = creditCardInstallments;
    }
    if (creditCardCvv) {
      params['creditCard']['cvv'] = creditCardCvv;
    }

    const { data: responseValidateCoupons, error } = await validateCouponsUsecase({ storeId, ...params });
    return { data: responseValidateCoupons, error };
  },
  async changeBackView({ commit, getters }) {
    let backView = getters.getBackView;
    commit('SET_CURRENT_VIEW', backView);
  },
  async validateTotals({ commit, rootState, rootGetters, dispatch }, paramsConfig) {
    const { disableCampaign = false } = paramsConfig ?? {};
    const storeId = rootGetters['home/getCurrentStoreId'];
    const cartTemp = rootGetters['checkout/getTempCart'];
    const zoneId = rootGetters['home/getZoneId'];
    let cart = rootGetters['cart/getCart'].getCartModels();
    let cartCampaign = rootGetters['cart/getCart'];
    let creditCard = null;

    if (cart.length === 0 && cartTemp.length > 0) {
      const mappedTempCart = cartTemp.map((prod) => {
        return {
          id: prod.product.id,
          quantityFullPrice: prod.quantityFullPrice,
          quantitySpecialPrice: prod.quantitySpecialPrice,
          fullPrice: prod.fullPrice,
          specialPrice: prod.specialPrice,
        };
      });
      cart = mappedTempCart;
    }

    if (!rootState.user.token) {
      return;
    }

    if (
      rootState.checkout.payment.method === PAYMENT_METHODS.CREDIT_CARD ||
      rootState.checkout.payment.method === PAYMENT_METHODS.CODENSA
    ) {
      const creditCardData = rootState.checkout.payment;
      if (creditCardData?.creditCardId) {
        creditCard = {
          id: creditCardData.creditCardId,
          installments: creditCardData.installmentsCreditCard,
        };

        if (creditCardData.enableCVV) {
          creditCard.cvv = creditCardData.creditCardCvv;
        }
      } else {
        creditCard = {
          cvv: creditCardData.newCreditCard.cvv,
          holder: {
            address: creditCardData.newCreditCard.address,
            documentNumber: creditCardData.newCreditCard.document,
            documentType: creditCardData.newCreditCard.document_type,
            name: creditCardData.newCreditCard.name,
            phone: creditCardData.newCreditCard.phone,
          },
          installments: creditCardData.newCreditCard.installments,
          month: creditCardData.newCreditCard.monthExp,
          number: creditCardData.newCreditCard.number,
          year: creditCardData.newCreditCard.yearExp,
        };
      }
    }

    const params = {
      cart,
      zoneId,
      code: rootState.checkout.payment.coupon,
      useCredits: rootState.checkout.payment.useCredits,
      deliveryTimes: rootState.checkout.orders,
      paymentMethod: rootState.checkout.payment.method,
      creditCard,
    };

    if (!params.paymentMethod) {
      params.code = null;
    }
    /**
     * TODO filteredCampaigns must be deleted when be implemented new structure of campaigns on checkout
     * TODO campaignPrizeIds must be modified when be implemented new structure of campaigns on checkout,
     *  the idea is received that data form getter that iterate on map structure
     */
    const getCampaignCheckout = rootGetters['campaigns/getCampaignCheckout'];
    const campaignPrizeIds = getCampaignCheckout?.map((campaign) => campaign.id);
    if (campaignPrizeIds?.length && !disableCampaign) {
      params.campaignPrizeIds = campaignPrizeIds;
    }

    const { data: responseTotals, error: errorTotals } = await getValidateTotalsUsecase({ storeId, ...params });

    if (responseTotals && !errorTotals) {
      if (!responseTotals.campaignPrizes && !disableCampaign) {
        params.campaignPrizeIds = null;
        await dispatch('campaigns/setCampaignsParticipating', new Map(), {
          root: true,
        });
      } else if (responseTotals.campaignPrizes) {
        if (
          responseTotals.campaignPrizes.some(
            (campaign) => campaign.campaignType === CAMPAIGNS_MAIN_TYPES.PAYMENT_METHOD,
          )
        ) {
          const typeMethod = await dispatch('checkout/getPaymentData', 'method', { root: true });
          handlerValidateCampaignsUseCase({ cart: cartCampaign, typeMethod }, CAMPAIGNS_MAIN_TYPES.PAYMENT_METHOD);
        }

        if (responseTotals.campaignPrizes.some((campaign) => campaign.campaignType === CAMPAIGNS_MAIN_TYPES.GENERAL)) {
          handlerValidateCampaignsUseCase(cartCampaign, CAMPAIGNS_MAIN_TYPES.GENERAL);
        }
        if (params.campaignPrizeIds) {
          const campaignIds = responseTotals.campaignPrizes.map((el) => +el.campaignId);
          const arrayId = params.campaignPrizeIds.filter((id) => !campaignIds.includes(id));
          if (arrayId.length > 0) {
            await dispatch('campaigns/removeCampaignsParticipating', arrayId, {
              root: true,
            });
          }
        }
      }
      commit('SET_TOTALS', responseTotals);
      return responseTotals;
    } else if (errorTotals) {
      const errorReq = Array.isArray(errorTotals) && errorTotals.length > 0 ? errorTotals[0] : errorTotals;
      if (errorReq.status == '400') {
        const { status = '400', detail = 'No error detail' } = errorReq;
        Vue.prototype.$events.checkoutError.track({
          screen: 'validateTotals',
          message: this.app.i18n.t('errors_message_validate_totals_track', {
            status,
            detail,
          }),
          ticket: rootState.checkout.totals.total,
          delivery: rootState.checkout.totals.deliveryAmount,
          minimumOrder: rootGetters['home/getMinimumOrderAmount'],
        });
      } else if (errorReq.status == '280') {
        const { campaignPrizeIds } = params;
        const listCampaign = errorTotals.filter((el) => el.code === 'CODE_CAMPAIGN_INVALID');
        const idsError = listCampaign.map((el) => {
          let {
            source: {
              data: { campaign_id },
            },
          } = el;
          return +campaign_id;
        });
        if (idsError.length > 0) {
          params.campaignPrizeIds = campaignPrizeIds.filter((el) => {
            return !idsError.includes(el);
          });
        }
        await dispatch('campaigns/removeCampaignsParticipating', idsError, {
          root: true,
        });
        const { data: responseTotals } = await getValidateTotalsUsecase({
          storeId,
          ...params,
        });
        /* Add manage error campaign in this line */
        commit('SET_TOTALS', responseTotals);
        return responseTotals;
      }
    }
  },

  // TODO: Validar uso
  updateProductsNotAvailable({ commit, rootState, rootGetters }) {
    const producsNotAvailables = rootState.checkout.products_not_available;

    rootGetters['cart/getCart'].forEach((cart) => {
      const prodNotAvailable = producsNotAvailables.filter((prod) => prod.id === cart.product.id);

      if (prodNotAvailable.length) {
        const currentTotal = cart.getCount();
        commit('cart/DELETE_PRODUCT', cart, { root: true });
        commit(
          'cart/ADD_PRODUCT',
          {
            product: prodNotAvailable[0],
            type: 'increment',
            quantity: currentTotal,
          },
          { root: true },
        );
      }
    });
  },
  async checkout({ commit, rootState, rootGetters, dispatch }) {
    const storeId = rootGetters['home/getCurrentStoreId'];
    const currentCityId = rootGetters['home/getCityId'];
    const cart = rootGetters['cart/getCart'].getCartModels();
    const currentZoneId = await rootGetters['home/getZoneId'];

    if (!rootState.user.token) {
      return false;
    }

    const addressObject = rootGetters['address/getAddressObject'];

    const labelAddress =
      addressObject.addressType === 'Otro' ? addressObject.customAddressType : addressObject.addressType;

    const paymentCheckout = rootState.checkout.payment;

    const newCreditCard =
      paymentCheckout.type === PAYMENT_METHODS.ONLINE && paymentCheckout.method === PAYMENT_METHODS.CREDIT_CARD
        ? paymentCheckout.newCreditCard
        : getDefaultState().payment.newCreditCard;

    let psePayer = null;

    if (paymentCheckout.method === PAYMENT_METHODS.PSE) {
      const urlReceive =
        location.protocol +
        '//' +
        location.hostname +
        (location.port ? ':' + location.port : '') +
        env.path +
        '/checkout/receive';

      psePayer = {
        name: rootState.checkout.pseData.fullName,
        documentType: rootState.checkout.pseData.typeDocument.id,
        identityNumber: rootState.checkout.pseData.numberDocument,
        bankCode: rootState.checkout.pseData.pseBank.pseCode,
        personType: rootState.checkout.pseData.typePerson.code,
        urlReceive,
      };
    }
    const params = {
      creditCard: {
        id: paymentCheckout.creditCardId,
        number: newCreditCard.number,
        month: newCreditCard.monthExp,
        year: newCreditCard.yearExp,
        type: paymentCheckout.type,
        holder: {
          name: newCreditCard.name,
          documentType: newCreditCard.document_type,
          documentNumber: newCreditCard.document,
          address: newCreditCard.address,
          phone: newCreditCard.phone,
        },
        installments: paymentCheckout.creditCardId
          ? paymentCheckout.installmentsCreditCard
          : newCreditCard.installments,
      },
      address: {
        id: typeof addressObject.id != 'string' ? addressObject.id : null,
        name: addressObject.address,
        label: labelAddress,
        further: addressObject.addressFurther,
        neighborhood: addressObject.neighborhood,
        postalCode: addressObject.postalCode,
        outdoorNumber: addressObject.outdoorNumber,
        latitude: 'latitude' in addressObject ? addressObject.latitude : '',
        longitude: 'longitude' in addressObject ? addressObject.longitude : '',
        route: addressObject?.route ?? '',
      },
      paymentMethod: paymentCheckout.method,
      deliveryTimes: rootState.checkout.orders,
      useCredits: paymentCheckout.useCredits,
      code: paymentCheckout.coupon,
      cart,
      zoneId: currentZoneId,
      cityId: currentCityId,
      businessName: paymentCheckout.invoice_company_name,
      businessIdentityNumber: paymentCheckout.invoice_company_nit,
      businessIdentityType: paymentCheckout.invoice_company_type,
      dniTypeId: paymentCheckout.dniTypeId,
      psePayer,
      whoReceives: rootState.checkout.whoReceives,
    };

    if (paymentCheckout?.newCreditCard?.cvv) {
      params.creditCard.cvv = paymentCheckout.newCreditCard?.cvv;
    } else if (paymentCheckout.enableCVV) {
      params.creditCard.cvv = paymentCheckout.creditCardCvv;
    }

    const campaigns = rootGetters['campaigns/getCampaignCheckout'];
    const campaignPrizeIds = campaigns.map((item) => item.id);
    if (campaignPrizeIds?.length) {
      params.campaignPrizeIds = campaignPrizeIds;
    }
    commit('SET_MESSAGE_ERROR_CHECKOUT', null);

    let { data, error } = await getCheckoutUsecase({ storeId, ...params });

    if (data && !error) {
      await dispatch('campaigns/setCampaigns', null, { root: true });
      // await dispatch('campaigns/setCurrentCampaign', null, { root: true });
      commit('SET_ORDER_SUCCESS_TEMP', data);
      let reference = data.orders[0].reference;
      commit('SET_PAYMENT', {
        type: 'reference',
        value: reference,
      });

      commit('SET_PAYMENT', {
        type: 'orderCreated',
        value: true,
      });
      let totals = {
        deliveryAmount: data.deliveryAmount,
        discount: data.discount,
        subTotal: data.subtotal,
        total: data.total,
        campaignPrizes: data.campaignPrizes,
      };

      commit('SET_TOTALS', totals);

      if (paymentCheckout.method === PAYMENT_METHODS.PSE) {
        commit('SET_CHECKOUT_SUCCESS', false);

        commit('SET_PAYMENT', {
          type: 'backForFail',
          value: true,
        });

        // Vaciamos el carrito de compras (por si se devuelve de PSE o cualquier cosa, no encuentre el carrito)
        commit(
          'cart/DELETE_ALL_PRODUCT',
          {},
          {
            root: true,
          },
        );
      } else {
        commit('SET_CHECKOUT_SUCCESS', true);
      }

      if (data.hasOwnProperty('address') && data.address) {
        await dispatch('address/updateAddressObject', data.address, {
          root: true,
        });
      }

      commit('SET_SURVEY_DATA', {});
      if (data.hasOwnProperty('survey') && data.survey) {
        commit('SET_WIDGET', ['surveyModal', true], { root: true });
        commit('SET_SURVEY_DATA', data.survey);
      }

      // En caso de pago PSE
      if (data.hasOwnProperty('psePaymentUrl') && data.psePaymentUrl) {
        commit('SET_URL_PSE_DATA', data.psePaymentUrl);
      }
      return data;
    } else if (!data && error) {
      // evento: checkout_error
      let data_for_event = {
        screen: 'validateTotals', // {string}   |
        message: null, // {string } ( Debe indicar un nombre de tarjeta )  |
        ticket: rootState.checkout.totals.total,
        delivery: rootState.checkout.totals.deliveryAmount,
        minimumOrder: rootGetters['home/getMinimumOrderAmount'],
      };
      if (error.detail && !error.source?.data?.campaignId) {
        commit('SET_MESSAGE_ERROR_CHECKOUT', error.detail);
        data_for_event.screen = 'CHECKOUT_OTHER_MESSAGE';
        data_for_event.message = error.detail;
        return error;
      } else if (parseInt(error.status, 10) === 280 && error.source?.data?.campaignId) {
        await dispatch('campaigns/removeCampaignsParticipating', [error.source?.data?.campaignId], { root: true });
        return error;
      }

      Vue.prototype.$events.checkoutError.track(data_for_event);
      return false;
    }
  },
  async changePaymentMethod(
    { commit, rootState, rootGetters },
    // eslint-disable-next-line
    city = 'bogota',
  ) {
    let handleReturn;
    const { checkout } = rootState;
    const { method: paymentMethod } = checkout.payment;

    const params = {
      reference: rootState.checkout.payment.reference,
      paymentMethod,
    };

    if (paymentMethod === PAYMENT_METHODS.PSE) {
      params.psePayer = {
        name: rootState.checkout.pseData.fullName,
        documentType: rootState.checkout.pseData.typeDocument.id,
        identityNumber: rootState.checkout.pseData.numberDocument,
        bankCode: rootState.checkout.pseData.pseBank.pseCode,
        personType: rootState.checkout.pseData.typePerson.code,
        urlReceive:
          location.protocol +
          '//' +
          location.hostname +
          (location.port ? ':' + location.port : '') +
          env.path +
          '/checkout/receive',
      };
    } else if (paymentMethod === PAYMENT_METHODS.CREDIT_CARD) {
      const newCreditCard =
        checkout.payment.type === PAYMENT_METHODS.ONLINE && checkout.payment.method === PAYMENT_METHODS.CREDIT_CARD
          ? checkout.payment.newCreditCard
          : getDefaultState().payment.newCreditCard;

      params.creditCard = {
        id: checkout.payment.creditCardId,
        number: newCreditCard.number,
        month: newCreditCard.monthExp,
        year: newCreditCard.yearExp,
        cvv: newCreditCard.cvv,
        holder: {
          name: newCreditCard.number,
          documentType: newCreditCard.document_type,
          documentNumber: newCreditCard.document,
          address: newCreditCard.address,
          phone: newCreditCard.phone,
        },
        installments: checkout.payment.creditCardId
          ? checkout.payment.installmentsCreditCard
          : newCreditCard.installments,
      };

      commit('SET_CHECKOUT_SUCCESS', true);
    }
    if (
      paymentMethod === PAYMENT_METHODS.CASH ||
      paymentMethod === PAYMENT_METHODS.DATAPHONE ||
      paymentMethod === PAYMENT_METHODS.CREDIT_CARD ||
      paymentMethod === PAYMENT_METHODS.DATAPHONE_SODEXO
    ) {
      commit('SET_CHECKOUT_SUCCESS', true);
    } else {
      commit('SET_CHECKOUT_SUCCESS', false);
    }

    commit('SET_MESSAGE_ERROR_CHECKOUT', null);

    const { data: dataPayment, error: errorPayment } = await ChangePaymentMethodService(params);

    if (dataPayment) {
      const { paymentMethod: isPsePaymentMethod } = dataPayment.orders.find((item) => item.paymentMethod);
      if (isPsePaymentMethod !== PAYMENT_METHODS.PSE) {
        delete dataPayment.psePaymentUrl;
      }

      if ('psePaymentUrl' in dataPayment) {
        commit('SET_URL_PSE_DATA', dataPayment.psePaymentUrl);
        setTimeout(() => {
          window.location = dataPayment.psePaymentUrl;
        }, 3000);
      } else {
        rootState.checkout.orderSuccessTemp.orders.map((order) => (order.status = 'Iniciado'));
      }
      dataPayment.status = true;
      handleReturn = dataPayment;
    }
    if (errorPayment) {
      // evento: checkout_error
      const dataForEvent = {
        screen: 'validateTotals',
        message: null,
        ticket: rootState.checkout.totals.total,
        delivery: rootState.checkout.totals.deliveryAmount,
        minimumOrder: rootGetters['home/getMinimumOrderAmount'],
      };
      commit('SET_MESSAGE_ERROR_CHECKOUT', errorPayment.detail);

      dataForEvent.screen = 'CHECKOUT_OTHER_MESSAGE';
      dataForEvent.message = errorPayment.detail;

      Vue.prototype.$events.checkoutError.track(dataForEvent);
      handleReturn = errorPayment.detail;
    }
    return handleReturn;
  },
  async sendSurvey({ rootState }, { surveyId, orderId, answer }) {
    if (!rootState.user.token) {
      return false;
      // this.$axios.setToken(rootState.user.token, "Bearer");
    }

    let params = {
      surveyId: surveyId,
      orderId: orderId,
      answer: answer,
    };

    let data = await this.$axios.$post(`/2.0/surveys`, params);
    return data.data;
  },

  setIsRetryPayment({ commit }, value) {
    commit('SET_IS_RETRY_PAYMENT', value);
  },
  // setCampaignPrizeIds({ commit }, value) {
  //   commit('SET_CAMPAIGN_PRIZE_IDS', value);
  // },

  setTempCartWithProductAlreadyBought({ commit, rootGetters }) {
    let objectCartNew = rootGetters['cart/getCart'];
    commit('SET_CART_TEMP', objectCartNew.createCloneWithStore('temp'));
  },

  setAddressChanged({ commit }, value) {
    commit('SET_ADDRESS_CHANGED', value);
  },
  getPaymentData({ state }, type) {
    return state.payment[type];
  },
};

// mutations
const mutations = {
  SET_WHO_RECEIVES(state, whoReceives) {
    state.whoReceives = whoReceives;
  },
  SET_ADDRESS_CHANGED(state, isAddressChanged) {
    state.isAddressChanged = isAddressChanged;
  },
  SET_VIEW_CHECKOUT(state, view_checkout) {
    state.view_checkout = view_checkout;
  },
  SET_ORDERS(state, { order, type }) {
    state.orders[type] = order;
  },
  SET_HISTORY(state, history) {
    let existHistory = false;

    state.history = state.history.map((value) => {
      if (history.view == value.view) {
        existHistory = true;
        value.current = true;
      } else {
        value.current = false;
      }
      return value;
    });

    if (!existHistory) {
      history['current'] = true;
      state.history.push(history);
    }

    // validacion para eliminar history segun el view actual
    if (history.view == 'pay-select') {
      state.history = state.history.filter((value) => {
        return value.view != 'coupon' && value.view != 'new-card';
      });
    }

    window.scrollTo(0, 0);
  },
  SET_PAYMENT(state, { type, value }) {
    state.payment[type] = value;
  },
  SET_CURRENT_VIEW(state, { view, page }) {
    let history = state.history.map((history) => {
      history['current'] = false;
      if (history.page === page && history.view === view) {
        history['current'] = true;
      }
      return history;
    });

    // validacion para eliminar history segun el view actual
    if (view == 'pay-select') {
      history = history.filter((value) => {
        return value.view != 'coupon' && value.view != 'new-card';
      });
    }

    state.history = history;
  },
  SET_CREDIT_CARDS(state, user_credit_cards) {
    state.user_credit_cards = user_credit_cards;
  },
  SET_PAYMENTS_METHODS(state, paymentMethods) {
    state.paymentMethods = paymentMethods;
  },
  SET_DOCUMENT_TYPES(state, documentTypes) {
    state.documentTypes = documentTypes;
  },
  SET_PSE_BANKS(state, pseBanks) {
    state.pseBanks = pseBanks;
  },
  SET_TOTALS(state, totals) {
    state.totals = totals;
  },
  SET_PRODUCTS_NOT_AVAILABLE(state, products_not_available) {
    state.products_not_available = products_not_available;
  },
  SET_MESSAGE_NOT_AVAILABLE(state, message_not_available) {
    state.message_not_available = message_not_available;
  },
  SET_MESSAGE_NOT_DELIVERY(state, messageNotDeliveryDate) {
    state.messageNotDeliveryDate = messageNotDeliveryDate;
  },
  SET_ORDER_SUCCESS_TEMP(state, orderSuccessTemp) {
    state.orderSuccessTemp = orderSuccessTemp;
  },
  SET_CHECKOUT_SUCCESS(state, isCheckoutSuccess) {
    state.isCheckoutSuccess = isCheckoutSuccess;
  },
  SET_GIFT_CAMPAIGN_ADDED(state, isGiftCampignAdded) {
    state.isGiftCampignAdded = isGiftCampignAdded;
  },
  resetState(state) {
    // temporal
    const cartTemp = state.cartTemp instanceof Array ? [...state.cartTemp] : null;
    const estados = getDefaultState();

    estados.cartTemp = cartTemp;

    Object.assign(state, estados);
  },
  SET_SURVEY_DATA(state, surveyData) {
    state.surveyData = surveyData;
  },
  SET_URL_PSE_DATA(state, psePaymentUrl) {
    state.pseData.psePaymentUrl = psePaymentUrl;
  },
  SET_HISTORY_RETRY(state, history) {
    state.history = history;
  },
  SET_CANCEL_ORDER(state) {
    state.orderSuccessTemp.orders.map((o) => {
      o.status = 'Cancelado';
    });
  },
  SET_RESET_PSE_DATA(state) {
    state.pseData = {
      pseBank: {
        pseCode: null,
        description: null,
      },
      fullName: null,
      phone: null,
      typePerson: {
        code: null,
        text: null,
      },
      typeDocument: {
        id: null,
        text: null,
      },
      numberDocument: null,
      psePaymentUrl: null,
    };
  },
  SET_MESSAGE_ERROR_CHECKOUT(state, messageErrorCheckout) {
    state.messageErrorCheckout = messageErrorCheckout;
  },
  SET_CART_TEMP(state, cart) {
    state.cartTemp = cart;
  },
  SET_FREE_DELIVERY(state, value) {
    state.isFreeDelivery = value;
  },
  SET_IS_RETRY_PAYMENT(state, value) {
    state.isRetryPayment = value;
  },
  // SET_CAMPAIGN_PRIZE_IDS(state, value) {
  //   state.campaignPrizeIds = value;
  // },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
