import { UsermeEndpoint } from '~/endpoints/user-me/userme-endpoint';
import { userTransforms } from '~/transforms/user/user-transform';
import { errorTransforms } from '~/transforms/errors/error-transform';

export async function UserMeService({ freeDeliveryDays }) {
  let params = '';
  if (freeDeliveryDays) {
    params = `?include=freeDeliveryDays`;
  }
  const { response, error } = await UsermeEndpoint(params);
  if (response?.data) {
    const dataUserTransform = userTransforms(response.data);
    return { data: dataUserTransform, error: null };
  }
  return { data: null, error: errorTransforms(error) };
}

export default UserMeService;
