export const DEEP_LINKS_NAMES = {
  store: 'storeDeepLink',
  department: 'departmentDeepLink',
  shelf: 'shelfDeepLink',
  product: 'productDeepLink',
  favorites: 'favoritesDeepLink',
  orders: 'ordersDeepLink',
  story: 'storyDeepLink',
  brandRoom: 'brandRoomDeepLink',
  banner: 'bannerDeepLink',
  searchScheme: 'searchSchemeDeepLink',
  merqueoPrime: 'merqueoPrimeDeepLink',
};

export const DEEP_LINKS_TYPE = {
  store: 'store',
  department: 'department',
  shelf: 'shelf',
  product: 'product',
  favorites: 'favorites',
  orders: 'orders',
  story: 'storeStory',
  brandRoom: 'brandroom',
  searchScheme: 'searchScheme',
  merqueoPrime: 'merqueoPrime',
};

