import { deleteCreditCardsEndpoint } from '~/endpoints/credit-cards/delete-credit-card-enpoint';
import { error280Transforms, errorTransforms } from '~/transforms/errors/error-transform';

export const deleteCreditCardService = async (idCreditCardDelete: number) => {
  const { response, error } = await deleteCreditCardsEndpoint(idCreditCardDelete);
  if (response?.status === 200) {
    return { status: 200, error: null };
  } else if (response?.status === 280 && error === null) {
    const translatedDetail: string =
      response.data.errors[0].code === 'USER_CREDIT_CARD_WITH_ORDER'
        ? 'user_credit_card_with_order'
        : 'user_credit_card_not_found';
    const transformedError: any = error280Transforms(response);
    return { data: null, status: 280, error: { ...transformedError, detail: translatedDetail } };
  }
  return { data: null, error: errorTransforms(error) };
};
