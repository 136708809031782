
import $axios from '~/services/global-axios';

export async function getCampaignsEndpoint(storeId) {
  try {
    const response = await $axios.get(`3.1/stores/${storeId}/campaigns`);
    return { error: null, response };
  } catch (error) {
    return { error, response: null };
  }
}
