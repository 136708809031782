import { camelize } from '~/helpers/camelize';
import normalize from 'json-api-normalize';
import { findElementInMapObject } from '~/helpers/find-element-in-map-object';
import { MAP_PAYMENT_METHODS_KEYS } from '~/constants/payment-methods-map';

export function paymentMethodsTransforms(responseData) {
  const serialized = camelize(
    normalize(responseData).get(['payment_methods', 'pse_banks', 'installments', 'show_installments']),
  );
  serialized['paymentMethods'] = serialized['paymentMethods'].map(paymentMethod => ({
    ...paymentMethod,
    // text: findElementInMapObject(MAP_PAYMENT_METHODS_KEYS, paymentMethod.text),
    methods: [
      ...paymentMethod.methods.map(method => ({
        ...method,
        // text: findElementInMapObject(MAP_PAYMENT_METHODS_KEYS, method.text),
      })),
    ],
  }));
  return serialized;
}
