import Vue from 'vue';
import { initializeApp } from 'firebase/app';
import { getRemoteConfig, fetchAndActivate, getNumber, getValue, getBoolean, getString } from 'firebase/remote-config';

export default async () => {
  // verified IndexedDB support
  if (!IDBIndex || !IDBDatabase) {
    Vue.prototype.$getRemoteConfigParam = () => undefined;
    return null;
  }

  try {
    const env = require('../../env.local');
    const configFirebase = env.configFirebase;

    initializeApp(configFirebase);
    const remoteConfig = getRemoteConfig();

    remoteConfig.settings = {
      fetchTimeoutMillis: 60000,
      // Recomendado por documentación (12 horas)
      minimumFetchIntervalMillis: 3600000,
    };

    Vue.prototype.$getAsyncRemoteConfigParam = async function (parameterKey, typeReturn) {
      try {
        await fetchAndActivate(remoteConfig);
        return Vue.prototype.$getRemoteConfigParam(parameterKey, typeReturn);
      } catch (_e) {
        return null;
      }
    };

    // ParameterKey es el nombre del key en Firebase Remote Config
    Vue.prototype.$getRemoteConfigParam = function (parameterKey, typeReturn = 'string') {
      let response;
      switch (typeReturn) {
        case 'value':
          response = getValue(remoteConfig, parameterKey);
          break;
        case 'number':
          response = getNumber(remoteConfig, parameterKey);
          break;
        case 'boolean':
          response = getBoolean(remoteConfig, parameterKey);
          break;
        default:
          response = getString(remoteConfig, parameterKey);
      }
      return response;
    };
  } catch (e) {
    Vue.prototype.$getAsyncRemoteConfigParam = () => null;
    Vue.prototype.$getRemoteConfigParam = () => null;
    clearDatabaseIndexed();
  }

  function clearDatabaseIndexed() {
    let dbToClear = 'firebase-installations-database';
    let objectToClear = 'firebase-installations-store';
    let db;
    let DBOpenRequest = window.indexedDB.open(dbToClear, 1);

    DBOpenRequest.onsuccess = function () {
      db = DBOpenRequest.result;
      clearData();
    };

    function clearData() {
      // open a read/write db transaction, ready for clearing the data
      let transaction = db.transaction([objectToClear], 'readwrite');
      let objectStore = transaction.objectStore(objectToClear);
      objectStore.clear();
    }
  }
};
