import { Store } from 'vuex';
import { Strategy } from '@/helpers/deeplinks/strategy';
import { getStoreSlug, IQuery, ICity } from '@/helpers/deeplinks/utils/get-store-slug';
import { DEEP_LINKS_NAMES } from '~/helpers/deeplinks/constants/deeplinks';

export const strategyFavoriteDeepLink = (store: Store<any>, route: IQuery, city: ICity) =>
  new Strategy(DEEP_LINKS_NAMES.favorites, async () => {
    const { slug: storeSlug } = await getStoreSlug(store, route);
    return `/${city?.slug}/${storeSlug}/productos-recomendados`;
  });

