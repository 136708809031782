//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

export default {
  name: 'MqInfobipWidget',
  components: {
    MqFormNumberInfobip: () => import('./mq-form-number'),
    MqFormEntryCode: () => import('./mq-form-entry-code'),
    MqNotReceiveCode: () => import('./mq-not-receive-code'),
    MqAnimationsLoad: () => import('./mq-animations-load'),
    MqErrorsInfobip: () => import('./mq-errors-infobip'),
  },
  computed: mapState({
    countries: state => state.home.countries,
    current_view: state => state.phone_validate.current_view,
    animations_info: state => state.phone_validate.animations_info,
    errors_info: state => state.phone_validate.errors_info,
    current_phone: state => state.phone_validate.current_phone,
  }),
  methods: {
    isCurrentView(view) {
      return this.current_view === view;
    },
  },
};
