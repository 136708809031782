export const PRIME_STORAGE = {
  PAYMENT: 'planSelectedPrime',
  SUCCESS: 'primePurchaseFlowFinished',
  PRIME_STATE: 'prime_subscription_state',
  PRIME_CHECKOUT_STORE: 'primeCheckoutStore',
};

export const PRIME_UI = {
  ACTIVATED: 'ACTIVATED',
  ACQUISITION: 'ACQUISITION',
  PLAN_LIST: 'PLAN-LIST',
  TRIAL: 'TRIAL',
  ERROR: 'ERROR',
};

export const PRIME_TYPE = 'prime';

export const PRIME_ERRORS_GENERIC = [
  'CODE_ACTIVATION_PROCESS_ERROR',
  'CODE_USER_WITH_ACTIVE_SERVICE_ERROR',
  'CODE_INVALID_CARD_TYPE_ERROR',
  'CODE_SERVICE_PLAN_NOT_FOUND_ERROR',
  'CODE_PLAN_PERIOD_NOT_VALID_ERROR',
  'CODE_PLAN_PRICE_NOT_VALID_ERROR',
  'CODE_PLAN_BENEFITS_NOT_VALID_ERROR',
  'CODE_PLAN_PROMOTIONAL_PRICE_NOT_VALID_ERROR',
];

export const PRIME_ERROR_PAYMENT = 'CODE_CANCELLATION_PROCESS_ERROR';

export const PRIME_TYPE_OF_TRANSACTIONS = {
  SUBSCRIPTION_ACTIVATED: 'subscription_activated',
  SUBSCRIPTION_CANCELED: 'subscription_canceled',
  SUBSCRIPTION_RENOVATED: 'subscription_renovated',
};

export const PRIME_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

