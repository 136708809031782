import $axios from '~/services/global-axios';

const getAddressCoverageEndpoint = async ({ storeId, params }) => {
  try {
    const response = await $axios.$get(`/3.0/stores/${storeId}/coverage`, { params });
    return { response, error: null };
  } catch (error) {
    return { response: null, error };
  }
};

export default getAddressCoverageEndpoint;
