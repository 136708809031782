const env = require('./../../../env.local');

const getOriginalState = () => {
  return {
    current_view: 'formNumber',
    animations_info: {
      title: '',
      image: '',
    },
    errors_info: {
      title: '',
      image: '',
      button: '',
      type: '',
    },
    current_phone: {
      prefix: '57',
      phone: null,
      verified: false,
    },
    phone_register: '',
    pinId: null,
    typeRequest: '',
    changeUserPhone: false,
    attempts: 0,
    tokenGenerated: '',
    fbId: '',
  };
};

const state = getOriginalState();

const mutations = {
  SET_CURRENT_VIEW(state, value) {
    state.current_view = value;
  },
  SET_CUSTOM_INFO(state, { keyToSet, objectValues }) {
    state[keyToSet] = objectValues;
  },
  SET_CURRENT_PHONE(state, value) {
    state.current_phone.phone = value.phone;
    state.current_phone.prefix = value.prefix;
  },
  SET_PIN_ID(state, value) {
    state.pinId = value;
  },
  SET_ATTEMPTS(state, value) {
    state.attempts = value;
  },
  SET_VERIFIED_PHONE(state, value) {
    const { current_phone } = state;
    state.current_phone = { ...current_phone, verified: value };
  },
  RESET_ATTEMPTS(state) {
    state.attempts = 0;
  },
  SET_TYPE_REQUEST(state, value) {
    state.typeRequest = value;
  },
  SET_CHANGE_USER_PHONE(state, value) {
    state.changeUserPhone = value;
  },
  SET_TOKEN_GENERATED(state, value) {
    state.tokenGenerated = value;
    if (state.type_request !== 'facebook') state.type_request = value;
  },
  SET_PHONE_REGISTER(state, value) {
    state.phone_register = value;
  },
  RESET_STATE_PHONE(state) {
    Object.assign(state, getOriginalState());
  },
  SET_FB_ID(state, value) {
    state.fbId = value;
  },
};

const actions = {
  async sendPin({}, { phonePrefix = '57', phone = null, type = 'sms' }) {
    let dataParams = { phonePrefix, phone, type };

    let data = [];
    try {
      data = await this.$axios.$post(`/3.0/pin/send`, dataParams);
      return data;
    } catch (error) {
      if (error.response.data.errors instanceof Array) {
        const errors = error.response.data.errors[0];
        return errors;
      }
    }
  },

  async reSendPin({}, { pinId = '', type = 'sms' }) {
    const dataParams = { pinId, type };
    let data = [];
    data = await this.$axios.$post(`/3.0/pin/resend`, dataParams);
    return data;
  },

  async sendPinWhatsapp({}, { phone_prefix = '57', phone = null }) {
    let dataParams = { phone_prefix, phone };
    let data = [];
    try {
      data = await this.$axios.$post(`${env.uriGenerateCodeWhatsapp}`, dataParams);
      return data;
    } catch (error) {
      if (error.response.data.errors instanceof Array) {
        const errors = error.response.data.errors[0];
        return errors;
      }
    }
  },

  setCurrentView({ commit }, view) {
    commit('SET_CURRENT_VIEW', view);
  },

  setCustomInfo({ commit }, type) {
    const actionType = typeof type === 'object' ? type.type : type;
    const customErrorMessage = typeof type === 'object' ? type.errorMessage : null;

    let keyToSet = '';
    let objectValues = {};
    switch (actionType) {
      case 'sending_sms':
        keyToSet = 'animations_info';
        objectValues = {
          title: this.app.i18n.t('infobip_title_sending_sms'),
          image: 'loader.json',
        };
        break;
      case 'sms_sent':
        keyToSet = 'animations_info';
        objectValues = {
          title: this.app.i18n.t('infobip_title_sms_sent'),
          image: 'success.json',
        };
        break;
      case 'without_validation':
        keyToSet = 'errors_info';
        objectValues = {
          title: this.app.i18n.t('infobip_message_error_without_validation'),
          image: 'message_not_send.svg',
          button: this.app.i18n.t('infobip_message_button_without_validation'),
          type: 'without_validation',
        };
        break;
      case 'validating_code':
        keyToSet = 'animations_info';
        objectValues = {
          title: this.app.i18n.t('infobip_title_validating_code'),
          image: 'loader.json',
        };
        break;
      case 'validation_success':
        keyToSet = 'animations_info';
        objectValues = {
          title: this.app.i18n.t('infobip_title_validation_success'),
          image: 'success.json',
        };
        break;
      case 'changed_phone_success':
        keyToSet = 'animations_info';
        objectValues = {
          title: this.app.i18n.t('infobip_title_phone_change_success'),
          image: 'success.json',
        };
        break;
      case 'changed_phone_error':
        keyToSet = 'animations_info';
        objectValues = {
          title: customErrorMessage,
          image: 'loader.json',
        };
        break;
      case 'error_code':
        keyToSet = 'errors_info';
        objectValues = {
          title: this.app.i18n.t('infobip_message_error_code'),
          image: 'number_not_valid.svg',
          button: this.app.i18n.t('infobip_message_button_error_code'),
          type: 'error_code',
        };
        break;
      case 'error_code_3':
        keyToSet = 'errors_info';
        objectValues = {
          title: this.app.i18n.t('infobip_message_error_code_3'),
          image: 'warning_user_infobip.svg',
          button: this.app.i18n.t('infobip_message_button_error_code_3'),
          type: 'error_code_3',
        };
        break;
    }

    commit('SET_CUSTOM_INFO', { keyToSet, objectValues });
  },

  setCurrentPhone({ commit }, phone) {
    commit('SET_CURRENT_PHONE', phone);
  },

  setPinId({ commit }, pinId) {
    commit('SET_PIN_ID', pinId);
  },

  async verifyCode({}, { pinId, pin }) {
    let dataParams = { pinId, pin };
    let data = [];
    data = await this.$axios.$post(`/3.0/pin/verify`, dataParams);
    return data;
  },

  addAttempt({ commit, state }) {
    const { attempts } = state;
    commit('SET_ATTEMPTS', attempts + 1);
  },

  setVerifiedPhone({ commit }, value) {
    commit('SET_VERIFIED_PHONE', value);
  },

  resetAttempts({ commit }) {
    commit('RESET_ATTEMPTS');
  },

  setTypeRequest({ commit }, value) {
    commit('SET_TYPE_REQUEST', value);
  },

  setChangeUserPhone({ commit }, value) {
    commit('SET_CHANGE_USER_PHONE', value);
  },

  async verifyCodeWhatsapp({}, { user_pin, uuid }) {
    const dataBody = { user_pin, uuid };
    let dataResponse = [];
    dataResponse = await this.$axios.$post(`${env.uriVerifiedCodeWhatsapp}`, dataBody);
    return dataResponse;
  },

  setTokenGenerated({ commit }, value) {
    commit('SET_TOKEN_GENERATED', value);
  },

  setPhoneRegister({ commit }, value) {
    commit('SET_PHONE_REGISTER', value);
  },

  resetStatePhone({ commit }) {
    commit('RESET_STATE_PHONE');
  },

  setFbId({ commit }, value) {
    commit('SET_FB_ID', value);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
