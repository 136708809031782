import { Model } from 'vue-mc';
import { nuxtInstance } from '~/plugins/nuxt-instance';

/**
 * CartProduct model
 * @class
 * @property {Product} product
 */
class CartProduct extends Model {
  constructor(carProduct = {}) {
    super(carProduct);
  }
  // Default attributes that define the "empty" state.
  defaults() {
    return {
      product: {},
      quantityFullPrice: 0,
      quantitySpecialPrice: 0,
      quantityFullPriceOld: 0,
      quantitySpecialPriceOld: 0,
      fullPrice: 0,
      specialPrice: 0,
      screen: null,
      store: '',
      bannerId: null,
      originalQuantity: null,
      originalQuantityOld: null,
      isOriginalProduct: null,
      idStoreProduct: null,
      textSearched: 'N/A',
    };
  }

  addProduct() {
    // Aplica precio especial para N cantidad
    const {
      store: { getters },
    } = nuxtInstance;
    const isFirstBuy = getters['user/willBeFirstBuy'];
    const availableSpecialPrice =
      (this.product.firstOrderSpecialPrice && isFirstBuy) ||
      (this.product.specialPrice && !this.product.firstOrderSpecialPrice);
    if (
      (this.product.quantitySpecialPrice > this.quantitySpecialPrice && availableSpecialPrice) ||
      this.product.quantitySpecialPrice === 0
    ) {
      this.quantitySpecialPrice++;
    } else {
      this.quantityFullPrice++;
    }
    this.fullPrice = this.product.price ? this.product.price : this.product.unitPrice;
    this.specialPrice = this.product.specialPrice;
    this.idStoreProduct = this.product.idStoreProduct;
  }
  removeProduct() {
    if (
      (this.product.quantitySpecialPrice === this.quantitySpecialPrice || !this.product.specialPrice) &&
      this.quantityFullPrice > 0
    ) {
      this.quantityFullPrice--;
    } else {
      this.quantitySpecialPrice--;
    }
    this.fullPrice = this.product.price ? this.product.price : this.product.unitPrice;
    this.specialPrice = this.product.specialPrice;
  }

  totalPriceProduct() {
    return this.quantitySpecialPrice * this.specialPrice + this.quantityFullPrice * this.fullPrice;
  }

  getCount() {
    return this.quantityFullPrice + this.quantitySpecialPrice;
  }

  totalSavings() {
    if (this.specialPrice > 0) {
      return (this.fullPrice - this.specialPrice) * this.quantitySpecialPrice;
    }
    return 0;
  }

  maxSpecialPrice() {
    if (this.product.quantitySpecialPrice === this.quantitySpecialPrice && this.quantityFullPrice === 0) {
      return true;
    }
    return false;
  }

  /**
   * Can Discount product quantity
   */
  canRemoveProduct() {
    return this.getCount() > this.originalQuantity;
  }

  /**
   * Can Detele product
   */
  canDeleteProduct() {
    return true;
  }

  // Route configuration
  routes() {
    return {
      fetch: '/cart_product/{id}',
      save: '/cart_product',
    };
  }
}

export default CartProduct;
