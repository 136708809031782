import $axios from '~/services/global-axios';
const baseApiRewrite = process.env.baseApi.replace('/api', '');

export async function getChannnelsMainBrandroom({ storeId, brandroomId, warehouseId }) {
  try {
    let response = await $axios.$get(
      `${baseApiRewrite}/featured/api/1.0/stores/${storeId}/channels/brandrooms/${brandroomId}?warehouseId=${warehouseId}`,
      {
        headers: {
          'X-Merqueo-Agent': 'Web',
          'X-Merqueo-Version': '2.0.0',
        },
      },
    );
    return { response, error: null };
  } catch (error) {
    return { response: null, error };
  }
}

export async function getStoriesMainBrandroom({ storeId, channelId, brandroomId, warehouseId }) {
  try {
    let response = await $axios.$get(
      `${baseApiRewrite}/featured/api/1.0/stores/${storeId}/channels/${channelId}/brandrooms/${brandroomId}/main/stories?warehouseId=${warehouseId}`,
      {
        headers: {
          'X-Merqueo-Agent': 'Web',
          'X-Merqueo-Version': '2.0.0',
        },
      },
    );
    return { response, error: null };
  } catch (error) {
    return { response: null, error };
  }
}
