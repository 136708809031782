import { checkFeatureFlag, FEATURE_TOGGLES_KEYS } from '~/feature-flags/feature-toggles';
import { redirectCountry } from '~/usecases/redirect/redirect-country';

export default async function ({ store, route, redirect, error }) {
  /**
   * Redirection that are relationship  with old routes
   */

  if (RegExp('www').test(window.location.href)) {
    window.location.assign(window.location.href.replace('www.', ''));
  }

  if (checkFeatureFlag(FEATURE_TOGGLES_KEYS.BUY_ON_STORE_DISABLED)) {
    const NOT_ENABLE_ROUTES = [
      'checkout',
      'checkout-payment',
      'checkout-campaign',
      'checkout-success',
      'login',
      'sign-up',
      'my-account',
      'my-account-address',
      'my-account-orders',
      'my-account-advertising',
      'my-account-credits',
      'my-account-payment-methods',
      'my-account-your-data',
    ];
    if (NOT_ENABLE_ROUTES.includes(route.name)) {
      redirect('/');
    }
  }

  if (route.query?.viewImage && route.params.product) {
    error({ statusCode: 404, message: 'Page not found' });
  }

  /**
   * Redirection that are relationship  with change of countries or entry first time
   */

  redirectCountry({ store, route, redirect });
}

