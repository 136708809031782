import { checkFeatureFlag, FEATURE_TOGGLES_KEYS } from '~/feature-flags/feature-toggles';
import { schemeDescriptor } from './functions/schemeDescriptor';
import { ISchemeProperty } from './interfaces/schemePropertyInterface';

const PRIMITIVE_LIST = ['string', 'number', 'boolean'];

const CHECK_NULL = (propertyValue: any) => {
  return propertyValue === undefined || propertyValue === null || propertyValue === NaN;
};

const CHECK_UNDEFINED = (propertyValue: any) => {
  return propertyValue === undefined || propertyValue === null;
};

const CHECK_INSTANCE = (propertyValue: any, propertyMetadata: ISchemeProperty) => {
  const propertyPrimitiveType = typeof propertyValue ?? '';
  return (
    propertyPrimitiveType === propertyMetadata.propertyType.name.toLowerCase() ||
    !PRIMITIVE_LIST.find((value) => value === propertyPrimitiveType)
  );
};

export function validateScheme<T>(target: any, propertyKey: string, descriptor: TypedPropertyDescriptor<T>) {
  if (typeof descriptor.value === 'function') {
    const oldDescriptor = descriptor.value!;

    descriptor.value = function () {
      // code for middleware decorator
      const instance = arguments[0];
      const { constructor } = instance.__proto__;
      const customSchemaMap = schemeDescriptor(constructor);
      const customSchema = [...customSchemaMap.values()];
      if (customSchema instanceof Array && checkFeatureFlag(FEATURE_TOGGLES_KEYS.ENABLE_ENDPOINT_VALIDATION_TOGGLE)) {
        const inconsistencies = customSchema.reduce(
          (p, n: ISchemeProperty) => {
            const propertyValue = instance[n.propertyKey];
            const realType = typeof propertyValue;
            if (n.propertyRequired && CHECK_NULL(propertyValue)) {
              p.push(`* La propiedad {${n.propertyKey}} es requerida y se esta enviando como {${propertyValue}}\n`);
            } else if (
              (n.propertyRequired && !CHECK_INSTANCE(propertyValue, n)) ||
              (!n.propertyRequired && !CHECK_UNDEFINED(propertyValue) && !CHECK_INSTANCE(propertyValue, n))
            ) {
              p.push(
                `* La propiedad {${n.propertyKey}} no se esta enviando como tipo {${n.propertyType.name}} si no como {${realType}}\n`,
              );
            }
            return [...p];
          },
          ['\n'],
        );
        if (inconsistencies.length > 1) {
          throw new Error(inconsistencies.join(''));
        }
      }
      // noImplicitThis
      // @ts-ignore: Unreachable code error
      return oldDescriptor.apply(this, arguments);
    } as any as T;
  }
}
