export const PRODUCT_DISCOUNT = {
  DEFAULT: 'discount',
  ADQUISITION: 'discount-adquisition',
  NORMAL: 'discount-normal'
};

export const PRODUCT_TAG_TYPE = {
  DEFAULT: 'top',
  SPONSORED: 'top-featured',
  TRADE: 'top-premium'
};

export const PRODUCT_TAG_LABEL = {
  DEFAULT: 'bottom',
  ADQUISITION: 'bottom-adquisition',
  DELIVERY: 'bottom-delivery',
  NOT_AVAILABLE: 'bottom-not-avaible'
};

export const PRODUCT_TAG_STATUS = {
  NOT_AVAILABLE: 'unavailable'
}