import { getCampaignsUsecase } from '~/usecases/campaigns/get-campaigns-usecase';

export default async function ({ store, route, app }) {
  const { params } = route;
  const { getters, dispatch } = store;

  if (params.store) {
    const storeId = getters['home/getCurrentStoreId'];

    // get halls
    const halls = await dispatch('home/loadHalls');
    // get the first hall
    const hall = halls[0];

    // load parallel resources
    await Promise.all([
      dispatch('home/getBrandRooms'),
      dispatch('home/setDepartmentsWithBestProducts', { hall, storeId, firstLoad: true }),
      getCampaignsUsecase(storeId),
    ]);

    const brandroom = await dispatch('home/getBrandroomBySlug', params.department);
    Object.assign(app, { nuxtDepartmentLayout: brandroom ? 'mq-brandroom-layout' : 'mq-store-layout' });
  }
}

