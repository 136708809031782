// TODO: @AddressFlow: refactorizar
export default async function ({ store, route }) {
  const { commit, getters, state } = store;

  // Si retorna string, no es la 1ra vez que se ingresa
  const isFirstEnterToApp = typeof (sessionStorage.getItem('isFirstEnterToApp') ?? true) === 'boolean';
  const mustAcceptNewPolicy = sessionStorage.getItem('mustAcceptNewPolicy') === 'true';
  const isAddressValid = getters['address/isAddressValid'];
  const user = store.state.user;
  const userIsLogged = 'id' in user.user;
  const isDeepLink = getters['events/isDeeplink'];
  const currentStore = getters['home/getCurrentStore'];

  // Caso cuando no hay dirección persistida

  // Home General
  if (route.name === 'index') {
    if (userIsLogged && !isAddressValid && !mustAcceptNewPolicy) {
      await commit('SET_WIDGET', ['addressWidgetModal', true]);
    } else if (isDeepLink && !isAddressValid) {
      await commit('SET_WIDGET', ['addressWidgetModal', true]);
    }
  }
  // Views inside store
  else if (route.name === 'city-store') {
    if (currentStore && currentStore.addressRequired && !isAddressValid && !isFirstEnterToApp && !mustAcceptNewPolicy) {
      await commit('SET_WIDGET', ['addressWidgetModal', true]);
    } else if (currentStore && currentStore.addressRequired && !isAddressValid) {
      await commit('SET_WIDGET', ['addressWidgetModal', true]);
    } else if (currentStore && !isAddressValid && userIsLogged && !isFirstEnterToApp && !mustAcceptNewPolicy) {
      await commit('SET_WIDGET', ['addressWidgetModal', true]);
    }
  }
}
