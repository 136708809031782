export const DEPARTMENT_TRANFORM_KEYS = [
  'id',
  'slug',
  'href',
  'name',
  'store_id',
  'description',
  'image_icon_url',
  'image_list_app_url',
  'image_header_app_url',
  'image_list_app_url_temp',
];
