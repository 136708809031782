//
//
//
//
//
//
//

export default {
  name: 'MqProductCardTag',
  data() {
    return {
      promotionTagText: '',
    };
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: '',
    },
  },
  mounted() {},
  methods: {},
};
