//
//
//
//
//
//

export default {
  name: 'MqProgress',
  props: {
    theme: {
      type: String,
      default: 'material',
    },
    width: {
      type: Number,
      default: 100,
    },
    color: {
      type: String,
      default: 'green500',
    },
  },
};
