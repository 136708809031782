/**
 * Type definitions
 * @typedef {import('react-query/types').UseQueryOptions} UseQueryOptions
 */

export const tagStylesState = {
  key: 'tagStyles',
  handler: (tag) => {
    return async () => {
      if (tag) {
        try {
          const request = await fetch(tag.urlStyles);
          const data = await request.json();
          return data;
        } catch (error) {
          return {};
        }
      }
    };
  },
  /**
   * @type {UseQueryOptions}
   */
  options: {
    cacheTime: 15000,
    refetchOnWindowFocus: false, // default true
    refetchOnReconnect: false, // default true
    refetchOnMount: false, // default true
  },
};
