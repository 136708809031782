import { Store } from 'vuex';
import { getShelvesByDepartmentUseCase } from '@/usecases/products/shelves-by-department-usecase';
import { getPathDepartment } from '@/helpers/deeplinks/utils/get-path-department';
import { IQuery } from '@/helpers/deeplinks/utils/get-store-slug';

async function getShelves(storeId: number, zoneId: number, departmentId: number) {
  const { data, error } = await getShelvesByDepartmentUseCase(departmentId, storeId, zoneId);
  if (data instanceof Object && error === null) {
    return data;
  }
  return {};
}

export async function getPathShelf(store: Store<any>, { query }: IQuery) {
  const storeId = parseInt(query.store_id);
  const hallId = parseInt(query.department_id);
  const shelfId = parseInt(query.shelf_id);
  const departmentSlug = await getPathDepartment(store, { query });

  const zoneId = store.getters['home/getZoneId'];
  const shelvesList = await getShelves(storeId, zoneId, hallId);
  const shelf = shelvesList.find((shelf: any) => shelf.id === shelfId);
  const shelfSlug = shelf?.slug ?? '';

  return departmentSlug && shelfSlug ? `${departmentSlug}/${shelfSlug}` : departmentSlug;
}

