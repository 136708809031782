import normalize from 'json-api-normalize';
import { camelize } from '~/helpers/camelize';

export function storeTransforms(responseData: any) {
  const storeList = responseData.data;
  const newResponseData = {
    ...responseData,
    data:
      storeList instanceof Array
        ? storeList.map((store: any) => ({
            ...store,
            attributes: {
              ...store.attributes,
              store_type: store.attributes.type,
              slug: store.attributes.is_express ? `${store.attributes.slug}-express` : store.attributes.slug,
            },
          }))
        : storeList,
  };
  return normalize(newResponseData)
    .get([
      'id',
      'title',
      'store_type',
      'slug',
      'zone_id',
      'is_express',
      'description',
      'warehouse_id',
      'delivery_time',
      'merqueo_invoice',
      'logo_url',
      'model',
      'logo_small_url',
      'logo_store_url',
      'address_required',
      'business_status',
      'free_delivery',
      'free_delivery_first_order',
      'promotion_tag',
      'promotion_percentage_amount',
      'minimum_order_amount',
      'show_without_coverage',
      'minimum_order_amount_free_delivery',
      'app_background_color',
      'app_background_secondary_color',
    ])
    .map((store: any) => {
      const modelBaseOnExpress = store.is_express ? 'Tienda' : 'Supermercado';
      const model = store.model ?? modelBaseOnExpress;
      return { ...(camelize(store) as any), model };
    });
}
