import Vue from 'vue';
import { openModal } from '~/components/utils/mq-modal-managment';
import { CART_TYPES } from '~/constants/cart-actions';

export function handlerErrors(error, store) {
  const errorsArray = error.data.errors;
  const status = error.status;

  if (errorsArray instanceof Array && errorsArray.length > 0) {
    const errorsArrayNormalized = getArrayErrorNormalized(errorsArray, store);
    switchErrors(errorsArray[0], store, errorsArrayNormalized, status);
  }
}

function switchErrors(error, store, arrayErrors = null, status) {
  const errorString = error.code;
  let messageNotAvailable = null;
  let isModifyCart = false;
  let productAlreadyBought = false;
  let openModalProductsNotPromo = false;
  const instanceCart = store.getters['cart/getInstanceCart'];

  switch (errorString) {
    case 'PRODUCT_PRICE_CHANGED': {
      store.commit('checkout/SET_PRODUCTS_NOT_AVAILABLE', arrayErrors, {
        root: true,
      });
      messageNotAvailable = store.app.i18n.t('errors_message_price_changed');
      store.dispatch('checkout/updateProductsNotAvailable');
      openModalProductsNotPromo = instanceCart === CART_TYPES.DEFAULT;

      isModifyCart = true;
      break;
    }

    case 'PRODUCT_NOT_VALID': {
      const productsNot = arrayErrors;
      const objectCart = store.getters['cart/getCart'];
      const filterProductsNot = objectCart.models.filter((prod) => {
        const findProduct = productsNot.find((element) => {
          return element.id === prod.product.id;
        });

        if (findProduct) {
          store.commit('cart/DELETE_PRODUCT', prod, { root: true });
          return prod;
        }
      });

      const newProductsNotToStore = filterProductsNot.map((prod) => {
        return prod.product;
      });

      if (newProductsNotToStore.length) {
        store.commit('checkout/SET_PRODUCTS_NOT_AVAILABLE', newProductsNotToStore, {
          root: true,
        });
        messageNotAvailable = store.app.i18n.t('errors_message_product_not_valid');
        openModalProductsNotPromo = true;
      }

      isModifyCart = true;
      break;
    }

    case 'DELIVERY_TIME_CHANGED': {
      const message = store.app.i18n.t('errors_message_delivery_time_changed');
      store.commit('checkout/SET_CURRENT_VIEW', { page: '', view: 'delivery-schedule' });
      store.commit('checkout/SET_MESSAGE_NOT_DELIVERY', message);
      store.$router.push('/checkout');
      break;
    }

    case 'INVALID_COUPON': {
      store.commit('checkout/SET_PAYMENT', {
        type: 'coupon',
        value: null,
      });
      const message = store.app.i18n.t('errors_message_mqToast_invalid_coupon') + error.detail;
      Vue.prototype.$mqToast(message, 'warning', 'error', 10000);
      break;
    }

    case 'INVALID_USER_REFERRED': {
      const message = store.app.i18n.t('errors_message_mqToast_invalid_user_referred') + error.detail;
      Vue.prototype.$mqToast(message, 'warning', 'error', 10000);
      break;
    }

    case 'ALREADY_BOUGHT': {
      const objectCartAlready = store.getters[`${instanceCart}/getCart`];

      const findProduct = [];

      arrayErrors.forEach((productId) => {
        const product = objectCartAlready.find({
          product: { id: productId },
        });
        findProduct.push(product);
      });

      if (findProduct.length) {
        findProduct.forEach((product) => {
          store.commit(
            `${instanceCart}/DELETE_PRODUCT`,
            { product: product.product, alreadyBought: true },
            { root: true },
          );
          product.quantityFullPrice += product.quantitySpecialPrice;
          product.quantitySpecialPrice = null;
          product.specialPrice = null;

          product.product.specialPrice = null;
          product.product.quantitySpecialPrice = null;
          store.commit(
            `${instanceCart}/ADD_PRODUCT`,
            {
              product: product.product,
              type: 'increment',
              quantity: product.quantityFullPrice,
            },
            { root: true },
          );
        });

        store.commit('checkout/SET_PRODUCTS_NOT_AVAILABLE', findProduct, {
          root: true,
        });

        isModifyCart = true;
        productAlreadyBought = true;
      }

      messageNotAvailable = null;
      break;
    }
    case 'INVALID_USE_CREDITS': {
      const message = store.app.i18n.t('errors_invalid_use_credits');
      Vue.prototype.$mqToast(message, 'warning', 'error', 10000);
      break;
    }

    default: {
      if (status >= 500) {
        if (errorString?.includes('INFOBIP')) {
          Vue.prototype.$mqToast(store.app.i18n.t('error_message_validate_number'), 'warning', 'error', 10000);
        } else {
          Vue.prototype.$mqToast(store.app.i18n.t('errors_message_unknown_error'), 'warning', 'error', 10000);
        }
      }
    }
  }
  if (isModifyCart) {
    const objectCartNew = store.getters[`${instanceCart}/getCart`];
    store.commit('checkout/SET_CART_TEMP', objectCartNew.createCloneWithStore('temp'));
  }

  if (openModalProductsNotPromo) {
    store.commit('checkout/SET_MESSAGE_NOT_AVAILABLE', messageNotAvailable, {
      root: true,
    });
    openModal({
      component: () => import('~/components/dialogs/mq-products-not-promo'),
      group: 'group-modal-products-changes',
      config: {
        position: {
          value: 'center',
        },
        behaviors: {
          closableContainer: false,
        },
      },
    });
  }

  if (productAlreadyBought) {
    store.commit('checkout/SET_MESSAGE_NOT_AVAILABLE', null);
    openModal({
      component: () => import('@/components/dialogs/mq-product-already-bought'),
      group: 'group-modal-products-changes',
      config: {
        position: {
          value: 'center',
        },
        behaviors: {
          closableContainer: false,
        },
      },
    });
  }
}

function getProductById(id, store) {
  const productChanged = store.getters['cart/getCart'].models.filter((prod) => prod.product.id === id);
  // eslint-disable-next-line no-prototype-builtins
  if (productChanged[0] && productChanged[0].hasOwnProperty('product')) {
    return productChanged[0].product;
  } else {
    return false;
  }
}

function getArrayErrorNormalized(errorsArray, store) {
  const arrayNormalized = [];
  errorsArray.forEach((item) => {
    if (item.code === 'ALREADY_BOUGHT') {
      arrayNormalized.push(item.source.data.id);
      // eslint-disable-next-line no-prototype-builtins
    } else if (item.source && item.source.data && item.source.data.hasOwnProperty('id')) {
      const productChanged = getProductById(item.source.data.id, store);
      if (productChanged) {
        if (item.source.data.price > 0 || item.source.data.quantity_special_price > 0) {
          productChanged.price = item.source.data.price;
          productChanged.specialPrice = item.source.data.special_price;
          productChanged.quantitySpecialPrice = item.source.data.quantity_special_price;
        }
        arrayNormalized.push(productChanged);
      }
      // eslint-disable-next-line no-prototype-builtins
    } else if (item.source && item.source.hasOwnProperty('parameter')) {
      arrayNormalized.push(item.source.parameter);
    }
  });
  return arrayNormalized;
}

