//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MqTypeProduct',
  props: {
    delivery: { type: String },
    bestPrice: { type: Boolean },
    specialPrice: { type: Number },
    productName: { type: String },
    status: { type: Boolean, default: true },
  },
};
