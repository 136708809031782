import { orderDetailService } from '~/services/orders/order-detail-services';

export async function orderDetailUsecase(storeId) {
  const { data, error } = await orderDetailService(storeId);
  if (data) {
    return { data, error: null };
  } else {
    return { data: null, error };
  }
}
