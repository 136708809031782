import { Event } from '../event';

export default class LeanplumEvent extends Event {
  /**
   * @param {Leanplum} client
   * @param {string} name
   */
  constructor(client, name, store) {
    super();
    this._client = client;
    this._name = name;
    this._store = store;
  }

  /**
   * @todo Should return a promise if needed
   * @param data
   * @returns {Promise<any>}
   */
  track(data) {
    const { getters } = this._store;
    const cityId = { cityId: getters['home/getCityId'] };
    const params = { ...data, ...cityId };
    return new Promise((resolve) => {
      this._client.track(this._name, params, null, null, resolve);
    });
  }
}
