//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex';
export default {
  name: 'MqListCountries',
  props: ['country'],
  computed: {
    ...mapState({}),
    ...mapGetters({
      currentCountry: 'home/getCurrentCountry',
    }),
  },
  methods: {
    selectCountry(country) {
      const { $route, $events } = this;
      const { dispatch, getters } = this.$store;
      const citySelected = getters['home/getCity'];
      const marketplaceStore = getters['home/getCurrentStore'];
      const redirectDomainUrl = country.urlDomain !== this.currentCountry.urlDomain;
      dispatch('home/setHasSelectedCountry', true);
      dispatch('home/showToolTipCountry', true);
      dispatch('home/setCurrentCountry', country);

      const changePath =
        $route.name !== 'index'
          ? `${country.urlDomain}/${citySelected.slug}/${marketplaceStore.slug}`
          : redirectDomainUrl
          ? `${country.urlDomain}?redirect-main=true`
          : `${country.urlDomain}`;

      this.$events.countrySelected.track({
        option: country.name,
      });

      setTimeout(() => {
        window.location = changePath;
      }, 350);
    },
  },
};
