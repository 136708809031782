//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MqVueCarousel',
  props: {
    // La implentacion de esta propiedad fue removida, el skeleton se muestra con el timeOut una vez se monte el componente
    loading: {
      type: Boolean,
      default: false,
    },
    timeOut: {
      type: Number,
      default: 1000,
    },
  },
  data: () => ({
    skeleton: true,
  }),
  mounted() {
    this.renderCarousel();
  },
  methods: {
    renderCarousel() {
      setTimeout(() => {
        this.skeleton = false;
      }, this.timeOut);
    },
  },
};
