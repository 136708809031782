import { updateBasicUserData as updateBasicDataEndpoint } from '~/endpoints/account/update-basic-user-data';
import { updatePhone as updatePhoneEndpoint } from '~/endpoints/account/update-phone';

const updateBasicUserData = async data => {
  const { response, error } = await updateBasicDataEndpoint(data);
  if (error === null && response.status === 200) {
    return responseSuccess(response);
  } else if (isError(error)) {
    return responseError(error);
  } else {
    return {};
  }
};

const updatePhone = async data => {
  const { response, error } = await updatePhoneEndpoint(data);
  if (error === null && response.status === 200) {
    return responseSuccess(response);
  } else if (isError(error)) {
    return responseError(error);
  } else {
    return {};
  }
};

const responseSuccess = response => {
  return { data: response.data, error: null };
};

const responseError = error => {
  return { data: null, error: error.response.data.errors[0] };
};

const isError = error => {
  return (
    error && error.response && error.response.data instanceof Object && error.response.data.errors instanceof Array
  );
};

export { updateBasicUserData, updatePhone };
