import * as Sentry from '@sentry/browser';

// eslint-disable-next-line import/extensions
const env = require('../../env.local.js');

if (!env.ENABLE_MERQUEO_LOCAL_DOMAIN) {
  Sentry.init({
    dsn: env.sentryDNS,
    release: env.sentryTagRelease,
    ignoreErrors: [
      /Error_\d\d/,
      'BLOCK_DEV',
      'ResizeObserver loop limit exceeded',
      'Non-Error promise rejection captured with value',
    ],
  });
}
