import { Store } from 'vuex';
import { Strategy } from '@/helpers/deeplinks/strategy';
import { IQuery, ICity, getStoreSlug } from '@/helpers/deeplinks/utils/get-store-slug';
import { getBrandRoom } from '@/usecases/products/brand-room-usecase';
import { DEEP_LINKS_NAMES } from '~/helpers/deeplinks/constants/deeplinks';

export const strategyBrandRoom = (store: Store<any>, route: IQuery, city: ICity) =>
  new Strategy(DEEP_LINKS_NAMES.brandRoom, async () => {
    const { slug: storeSlug, store: currentStore } = await getStoreSlug(store, route);
    const storeId = currentStore?.id;
    const warehouseId = currentStore?.warehouseId;
    const responseBrandRoom = await getBrandRoom(storeId, warehouseId);
    const brandRoom = responseBrandRoom.find(
      (brand: any) => brand.id && brand.id === parseInt(route.query?.brandroom_id),
    );
    return brandRoom ? `/${city?.slug}/${storeSlug}/${brandRoom.slug}` : ``;
  });

