//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { IMaskDirective } from 'vue-imask';
import validator from '~/plugins/validator';

export default {
  name: 'MqInput',
  props: {
    label: { type: String, default: '' },
    theme: { type: String, default: '' },
    message: { type: Boolean, default: true },
    name: { type: String, required: true },
    value: { type: String, default: null },
    valid: { type: Boolean, default: null },
    disabled: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    onChange: { type: Function, default: () => null },
    onFocus: { type: Function, default: () => null },
    onBlur: { type: Function, default: () => null },
    onClick: { type: Function, default: () => null },
    validate: { type: Object, default: null },
    icon: { type: String, default: '' },
    iconColor: { type: String, default: '' },
    iconRight: { type: Boolean, default: false },
    iconLeft: { type: Boolean, default: false },
    type: { type: String, default: 'text' },
    isInvalid: { type: Boolean, default: null },
    maxLength: { type: Number, default: null },
    minLength: { type: Number, default: null },
    showLabel: { type: Boolean, default: true },
    prefixTesting: { type: String, default: '' },
    autocomplete: { type: Array, default: null },
    showSendEmailLabel: { type: Boolean, default: false },
    inputType: { type: String, default: null },
    autoFocus: { type: Boolean, default: false },
    tabIndex: { type: Number, default: 0 },
    mask: { type: Object, default: null },
    documentType: { type: Number, default: null },
  },
  data() {
    return {
      inputValue: this.value,
      inputValid: this.valid,
      noValid: null,
      inputError: null,
      autocompleteShow: false,
      service: null,
      desktop: false,
      dirty: this.value?.length > 0,
      maskarade: this.mask,
    };
  },
  computed: {
    proxyValue: {
      get() {
        const value = this.onChange ? this.inputValue : this.value;

        this.checkValid(value);

        return value;
      },
      set(value) {
        this.dirty = true;
        this.checkValid(value);
        this.$emit('input', value);
      },
    },
  },
  watch: {
    value(newVal, oldVal) {
      this.inputValue = this.getInputValue(newVal, oldVal);
      this.checkValid(this.inputValue);
    },
    inputValue(newVal, oldVal) {
      this.inputValue = this.getInputValue(newVal, oldVal);
      this.checkValid(this.inputValue);
    },
    autocomplete(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.autocompleteShow = true;
      }
    },
    inputValid(newVal) {
      this.$emit('inputValid', newVal);
    },
  },
  created() {
    if (this.inputValue !== null) {
      this.handleChange(this.inputValue);
    }
  },
  methods: {
    deleteInputContent() {
      if (this.inputValue !== null) {
        this.handleChange('');
      } else {
        this.proxyValue = '';
      }
      this.$emit('autocompleteValue', null);
    },
    onBlurInside(e) {
      setTimeout(() => {
        this.autocompleteShow = false;
      }, 200);
      if (!this.autocompleteShow && this.proxyValue) {
        this.$emit('input', this.proxyValue.trim());
      } else if (!this.autocompleteShow && this.inputValue) {
        this.onChange(this.inputValue.trim());
        this.autocompleteShow = false;
      }
      this.onBlur(e);
    },
    getInputValue(newVal, oldVal) {
      return newVal && this.maxLength && newVal.length > this.maxLength ? oldVal.substr(0, oldVal) : newVal;
    },
    async checkValid(value) {
      this.inputValid = true;
      if (this.validate) {
        this.handleInputInDocType();

        const trimValue = value ? `${value}`.trim() : value;
        const data = await validator(this.validate, trimValue);
        if (this.dirty) {
          this.inputError = data.error;
          this.inputValid = data.valid;
        }
      }
    },
    handleInputInDocType() {
      if ('documentType' in this.validate) {
        this.inputValid = false;
      }
    },

    async handleChange(value) {
      if (this.validate) {
        this.checkValid(value);
        this.inputValue = value;
        if (this.inputValid) {
          this.onChange(this.inputValue, this);
        } else {
          this.onChange(null, this);
        }
      } else {
        this.noValid = value !== '';
        this.inputValue = value !== '' ? value : null;
        this.proxyValue = value !== '' ? value : null;
        if (this.onChange) {
          this.onChange(value, this);
        }
      }

      if (this.$el) {
        this.$el.children[0].focus();
      }
    },
    clickOnAutocomplete(label) {
      this.inputValue = label;
      this.proxyValue = label;
      this.$emit('autocompleteValue', label);
      this.autocompleteShow = false;
    },
    forceValidation(overrideDirty) {
      const { inputValue } = this;
      this.dirty = overrideDirty ?? inputValue?.trim().length > 0;
      this.handleChange(inputValue);
    },
  },
  directives: {
    imask: IMaskDirective,
  },
};
