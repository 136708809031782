export default class Wizard {
  /**
   * Wizard constructor
   * @param {Leanplum} client
   * @param {boolean} devMode
   * @param {string} appId
   * @param {string} appKey
   */
  constructor(client, { devMode, appId, appKey }) {
    this._client = client;
    this._devMode = devMode;
    this._appId = appId;
    this._appKey = appKey;
  }

  /**
   * @param {string} userId
   * @param {Object} userAttributes
   * @return {Promise<bool>}
   */
  start(userId, userAttributes) {
    const setCredentials = this._devMode
      ? this._client.setAppIdForDevelopmentMode
      : this._client.setAppIdForProductionMode;

    setCredentials(this._appId, this._appKey);
    this._client.setVariables(userAttributes);

    return new Promise(fulfill => {
      this._client.startFromCache(userId, userAttributes, fulfill);
    });
  }

  /**
   * @param attributes
   */
  setVariables(attributes) {
    this._client.start(attributes);
  }

  /**
   * @param userId
   */
  setUserId(userId) {
    if (userId) {
      this._client.setUserId(userId.toString());
    }
  }
}
