import { getUserCreditsEndpoint } from '~/endpoints/account/get-user-credits-endpoint';
import { creditsTransforms } from '~/transforms/account/credits-transform';
import { error280Transforms } from '~/transforms/errors/error-transform';

/**
 *
 * @param {number} storeId
 * @param {number} productid,
 */
export async function getUserCreditsService(countryId) {
  const { error, response } = await getUserCreditsEndpoint(countryId);
  if (response && response.status === 200) {
    const data = creditsTransforms(response.data);
    return { data, error: null };
  } else if (response && response.status === 280) {
    return { data: null, error: error280Transforms(error) };
  } else if (error) {
    return { data: null, error };
  }
  return {};
}
