const env = require('./../../../env.local');
import { PAYMENT_METHODS, PAYMENT_METHODS_TEXT } from '~/constants/payment-methods';

const getOriginalState = () => {
  return {
    department: {
      source: null,
    },
    minimumOrderReached: null,
    isDeeplink: false,
    urlDeepLink: '',
    typeDeepLink: '',
    storyDeepLink: {
      storyId: null,
      channelId: null,
    },
    counterEntryDeepLink: 0,
  };
};

const state = getOriginalState();

const actions = {
  //Todo: validar uso
  async trackBigQuery(res, params) {
    let data = await this.$axios.$post(env.trackApi, params);
    return data;
  },

  setIsDeeplink({ commit }, value) {
    commit('SET_IS_DEEPLINK', value);
  },
  setStoryDeepLink({ commit }, value) {
    commit('SET_STORY_DEEPLINK', value);
  },

  incrementCounterEntryDeepLink({ commit, state }) {
    const { counterEntryDeepLink } = state;
    commit('INCREMENT_COUNTER_ENTRY_DEEPLINK', counterEntryDeepLink + 1);
  },

  resetCounterEntryDeepLink({ commit }) {
    commit('INCREMENT_COUNTER_ENTRY_DEEPLINK', 0);
  },
  setUrlDeepLink({ commit }, value) {
    commit('SET_URL_DEEPLINK', value);
  },
  setTypeDeepLink({ commit }, value) {
    commit('SET_TYPE_DEEPLINK', value);
  },
};

const mutations = {
  //Todo: validar uso
  SET_DEPARTMENT_SOURCE(state, source) {
    state.department.source = source;
  },
  //Todo: validar uso
  SET_MINIMUM_ORDER_REACHED(state, source) {
    state.minimumOrderReached = source;
  },
  CLEAN_STATE(state) {
    Object.assign(state, getOriginalState());
  },
  SET_IS_DEEPLINK(state, value) {
    state.isDeeplink = value;
  },
  SET_STORY_DEEPLINK(state, value) {
    state.storyDeepLink = { ...value };
  },
  INCREMENT_COUNTER_ENTRY_DEEPLINK(state, value) {
    state.counterEntryDeepLink = value;
  },
  SET_URL_DEEPLINK(state, value) {
    state.urlDeepLink = value;
  },
  SET_TYPE_DEEPLINK(state, value) {
    state.typeDeepLink = value;
  },
};

const getters = {
  getPaymentMethodMapped: () => paymentMethod => {
    switch (paymentMethod) {
      case PAYMENT_METHODS.CASH:
        return PAYMENT_METHODS_TEXT.CASH;
      case PAYMENT_METHODS.DATAPHONE:
        return PAYMENT_METHODS_TEXT.DATAPHONE;
      case PAYMENT_METHODS.DATAPHONE_SODEXO:
        return PAYMENT_METHODS_TEXT.SODEXO;
      case PAYMENT_METHODS.CREDIT_CARD:
        return PAYMENT_METHODS_TEXT.CREDIT_CARD;
      case PAYMENT_METHODS.PSE:
        return PAYMENT_METHODS_TEXT.PSE;
      default:
        return '';
    }
  },
  isDeeplink: state => {
    return state.isDeeplink ? 1 : 0;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
