import { getSearchProductsEndpoint } from '~/endpoints/products/search-enpoint';
import { productsTransforms } from '~/transforms/products/products-transform';
import { metaTransforms } from '~/transforms/meta/meta-transform';

/**
 *
 * @param {*} storeId
 * @param {*} search
 */
export async function getSearchProductsUsecase(
  storeId,
  { search, page = 1, per_page = 50, zoneId = '', sort_by = 'relevance' },
) {
  const { error, response } = await getSearchProductsEndpoint(storeId, { search, page, per_page, zoneId, sort_by });
  if (error === null && response.status === 200) {
    const products = productsTransforms(response.data);
    const meta = metaTransforms(response.data);
    return { data: { products, meta }, error: null };
  } else if (
    error &&
    error.response &&
    error.response.data instanceof Object &&
    error.response.data.errors instanceof Array
  ) {
    return { data: null, error: error.response.data.errors[0] };
  } else {
    return {};
  }
}
