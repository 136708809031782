//
//
//
//
//

import error404 from '~/components/error/404.vue';
import error500 from '~/components/error/500.vue';
import { HEADER_LINK_MIXIN } from '~/mixins/headersData';

export default {
  name: 'NuxtError',
  mixins: [HEADER_LINK_MIXIN],
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    errorPage() {
      if (this.error.statusCode === 404) {
        return error404;
      }
      // catch everything else
      return error500;
    },
  },
};
