import * as Sentry from '@sentry/browser';
import Cart from '~/models/cart';
import VuexPersistence from 'vuex-persist';
import LocalForage from 'localforage';
import { CART_TYPES } from '~/constants/cart-actions';
import { memorySizeOf } from '~/helpers/memory-size-of';
import { tryParseJSON } from '~/helpers/try-parse-json';

const oldCartStore = window.localStorage.getItem('cart');
const migrationCart = window.localStorage.getItem('migrationCart');
let sentryReportFlag = false;
let oldProducts = false;
let oldCartStoreParsed = null;
if (oldCartStore && !migrationCart && indexedDB) {
  oldCartStoreParsed = JSON.parse(oldCartStore);
  if (oldCartStoreParsed.cart?.products.length > 0) {
    oldProducts = true;
    window.localStorage.setItem('migrationCart', true);
    window.localStorage.removeItem('cart');
  }
}

const cartStore = LocalForage.createInstance({
  name: 'cart',
  driver: typeof indexedDB !== 'undefined' ? LocalForage.INDEXEDDB : LocalForage.LOCALSTORAGE,
});

const regexMutationsPermit = /cart\//;

export function createCartStorageState(store) {
  return new VuexPersistence({
    key: CART_TYPES.DEFAULT,
    modules: [CART_TYPES.DEFAULT],
    reducer: (state) => {
      return {
        cart: {
          products: state.cart.products,
          suggested: state.cart.suggested,
          widgets: state.cart.widgets,
          product_modal: state.cart.product_modal,
          no_products_modal: state.cart.no_products_modal,
          freeDeliveryNotified: state.cart.freeDeliveryNotified,
        },
      };
    },
    filter: (data) => data?.type && regexMutationsPermit.exec(data.type),
    async restoreState(key, storage) {
      const storageState = await storage.getItem(key);
      try {
        const state = oldProducts && !migrationCart && indexedDB ? oldCartStoreParsed : JSON.parse(storageState);
        const products = new Cart(state[key].products, state[key].cartStore, true);
        const result = {
          [key]: {
            ...state[key],
            products,
          },
        };
        return result;
      } catch (_e) {
        return {};
      }
    },
    async saveState(key, state, storage) {
      try {
        const products = state[CART_TYPES.DEFAULT].products.getFullCollection();
        const stateMapped = {
          [CART_TYPES.DEFAULT]: {
            ...state[CART_TYPES.DEFAULT],
            ...{ products, cartStore: state[CART_TYPES.DEFAULT].products.getCurrentStore() },
          },
        };
        const stateMappedString = JSON.stringify(stateMapped);
        await storage.setItem(key, stateMappedString);
      } catch (error) {
        if (!sentryReportFlag) {
          const sizeOfMemoryStateToSave = memorySizeOf(state);
          const sizeOfMemoryStateOnState = memorySizeOf(tryParseJSON(await storage.getItem(key)));
          Sentry.captureException(error, { extra: { sizeOfMemoryStateToSave, sizeOfMemoryStateOnState } });
          sentryReportFlag = true;
        }
      }
    },
    storage: cartStore,
    asyncStorage: true,
  }).plugin(store);
}
