import $axios from '~/services/global-axios';

export async function getCitiesConfigEndpoint(countryId) {
  try {
    const response = await $axios.get(`/3.1/countries/config?countryId=${countryId}`);
    return { error: null, response };
  } catch (error) {
    return { error, response: null };
  }
}
