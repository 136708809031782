import { ISchemeProperty } from '../interfaces/schemePropertyInterface';

export function updateInternalScheme(
  scheme: Map<String, ISchemeProperty>,
  propertyKey: string,
  updateValues: Partial<ISchemeProperty>,
) {
  if (scheme.has(propertyKey)) {
    scheme.set(propertyKey, { ...scheme.get(propertyKey), ...updateValues } as ISchemeProperty);
  } else {
    scheme.set(propertyKey, { ...updateValues } as ISchemeProperty);
  }
}
