import normalize from 'json-api-normalize';
import { camelize } from '~/helpers/camelize';

export const BANNER_TRANSFORM_KEYS = [
  'category',
  'department_id',
  'id',
  'image_web_url',
  'image_app_url',
  'image_web_mobile_url',
  'is_for_first_order',
  'position',
  'shelf_id',
  'store_id',
  'store_product_id',
  'product_slugs',
  'target',
  'title',
  'type',
  'url',
  'video_url',
  'content',
  'content_title',
  'content_subtitle',
  'description',
  'template_video',
  'image_web_desktop_detail_url',
  'image_web_responsive_detail_url',
];

export function getBannersTransform(responseData) {
  return normalize(responseData)
    .get(BANNER_TRANSFORM_KEYS)
    .map((banner) => camelize(banner));
}

export function getBannerTransform(responseData) {
  return camelize(normalize(responseData).get(BANNER_TRANSFORM_KEYS));
}
