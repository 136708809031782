import { camelize } from '~/helpers/camelize';

/**
 * Transform for errors, only return first error
 * @return {Error | {}}
 */
export function errorTransforms(responseError: any) {
  const errors =
    responseError &&
    responseError.response &&
    responseError.response.data instanceof Object &&
    responseError.response.data.errors instanceof Array
      ? responseError.response.data.errors
      : [{}];
  return errors.map((error: any) => camelize(error)).pop();
}

export function errorTransformsFetch(responseError: any | null) {
  const errors = responseError && responseError.errors instanceof Array ? responseError.errors : [{}];
  return errors.map((error: any) => camelize(error)).pop();
}

export function error280Transforms(responseError: any | null) {
  const errors = responseError?.data?.errors ? responseError.data.errors : [{}];
  const errorMap = errors.map((error: any) => camelize(error)).pop();
  return errorMap;
}
