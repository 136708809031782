const relAlternative = 'href alternative';
const PATH_LINK_HEADER_OPTS = {
  '/': [
    {
      rel: relAlternative,
      href: 'https://merqueo.com',
      hreflang: 'es-CO',
    },
    {
      rel: relAlternative,
      href: 'https://merqueo.com.mx',
      hreflang: 'es-MX',
    },
    {
      rel: relAlternative,
      href: 'https://merqueo.com.br',
      hreflang: 'pt-BR',
    },
  ],
  '/login': [
    {
      rel: relAlternative,
      href: 'https://merqueo.com/login',
      hreflang: 'es-CO',
    },
    {
      rel: relAlternative,
      href: 'https://merqueo.com.mx/login',
      hreflang: 'es-MX',
    },
    {
      rel: relAlternative,
      href: 'https://merqueo.com.br/login',
      hreflang: 'pt-BR',
    },
  ],
};

export const FN_LINK_CONSTRUCTOR = (hreflang) => {
  return (
    PATH_LINK_HEADER_OPTS[window.location.pathname] || [
      {
        rel: relAlternative,
        href: window.location.href,
        hreflang,
      },
    ]
  );
};
