import $axios from '~/services/global-axios';

export async function getSuggestedProductsEndpoint(store_id, params) {
  try {
    let response = await $axios.post(`/3.1/stores/${store_id}/products/suggested`, params);
    return { response, error: null };
  } catch (error) {
    return { response: null, error };
  }
}
