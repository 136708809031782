import $axios from '~/services/global-axios';
import { PAYMENT_METHODS } from '~/constants/payment-methods';
import { validateScheme } from '~/decorators/validateScheme';
import { ValidateTotalsParams } from '~/schemes/validate-totals-endpoints-scheme';

export class ValidateTotals {
  @validateScheme
  static async getValidateTotalsEndpoint(
    validateTotalsParams: ValidateTotalsParams,
  ): Promise<{ response: any; error: any }> {
    const { cart, code, zoneId, useCredits, storeId, deliveryTimes, paymentMethod, creditCard, campaignPrizeIds } =
      validateTotalsParams;
    const codeValue = typeof code === 'string' ? code : undefined;
    const deliveryTimesValue = deliveryTimes?.merqueo?.date ? { merqueo: deliveryTimes?.merqueo } : undefined;
    const campaignPrizeIdsValue = campaignPrizeIds && campaignPrizeIds.length > 0 ? campaignPrizeIds : undefined;
    const paramsToSend: any = {
      cart,
      zoneId,
      useCredits,
      code: codeValue,
      deliveryTimes: deliveryTimesValue,
      campaignPrizeIds: campaignPrizeIdsValue,
    };

    if (paymentMethod && typeof paymentMethod === 'string' && paymentMethod !== PAYMENT_METHODS.CREDIT_CARD) {
      paramsToSend.paymentMethod = paymentMethod;
    }

    if (paymentMethod === PAYMENT_METHODS.CREDIT_CARD) {
      if (creditCard) {
        paramsToSend.creditCard = creditCard;
        paramsToSend.paymentMethod = paymentMethod;
      } else {
        delete paramsToSend.code;
      }
    }

    try {
      const response = await $axios.post(`/3.1/stores/${storeId}/carts/totals`, paramsToSend);
      return { response, error: null };
    } catch (error) {
      return { response: null, error };
    }
  }
}
