import { getCountriesEndpoint } from '~/endpoints/countries/get-countries-endpoint';
import { getCountriesTransform } from '~/transforms/countries/countries-transform';
import { errorTransformsFetch } from '~/transforms/errors/error-transform';
import { nuxtInstance } from '~/plugins/nuxt-instance';

export async function getCountriesUsecase() {
  const { response, error } = await getCountriesEndpoint();
  if (response?.ok && response?.status === 200) {
    const data = await response.json();
    const countries = getCountriesTransform(data);
    await nuxtInstance?.store?.dispatch('home/setCountries', countries);
    return { data: countries, error: null };
  } else if (response?.ok && response?.status === 280) {
    const errors = await response.json();
    return { data: null, error: errorTransformsFetch(errors) };
  }
  await nuxtInstance?.store?.dispatch('home/setCountries', process.env.DEFAULT_COUNTRIES);
  return { data: process.env.DEFAULT_COUNTRIES, error: error };
}
