//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MqBreadcrumb',
  props: {
    breadCrumbs: {
      type: Array,
      default: new Array(),
    },
  },
};
