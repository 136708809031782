const listObserver = new Map();

export function manageEventCarousel(el, binding, mode) {
  const {
    value: { cta, options, container, percentage, isLoop = false },
  } = binding;
  const root = container ? el.closest(container) : null;
  const optionsEvent = {
    root,
    ...options,
  };
  if ('IntersectionObserver' in window) {
    if (mode === 'set') {
      const observer = new IntersectionObserver((entries) => {
        const elem = entries[0];
        if (elem.isIntersecting && elem.intersectionRatio >= percentage) {
          if (!isLoop) {
            observer.disconnect();
            if (listObserver.has(el)) {
              listObserver.delete(el);
            }
          }
          cta && cta(elem);
        }
      }, optionsEvent);
      observer.observe(el);
      listObserver.set(el, observer);
    } else {
      if (listObserver.has(el)) {
        const observer = listObserver.get(el);
        observer.disconnect();
        listObserver.delete(el);
      }
    }
  }
}

