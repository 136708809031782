import { camelize } from '~/helpers/camelize';
import normalize from 'json-api-normalize';

export function validateTotalsTransforms(responseData) {
  return camelize(
    normalize(responseData).get([
      'discount',
      'delivery_amount',
      'sub_total',
      'total',
      'total_products',
      'campaignPrizes.amount',
      'campaignPrizes.campaign_id',
      'campaignPrizes.campaign_prize_type',
      'campaignPrizes.campaign_type',
      'campaignPrizes.code',
      'campaignPrizes.message',
    ]),
  );
}
