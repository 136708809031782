import { validateGeneralCampaignsUseCase } from '~/usecases/campaigns/validate-general-campaigns-usecase';
import { validatePaymentMethodCampaignsUseCase } from '~/usecases/campaigns/validate-payment-method-campaigns-usecase';

export async function handlerValidateCampaignsUseCase(options, type) {
  const campaignsType = {
    payment_method: {
      validate: validatePaymentMethodCampaignsUseCase,
    },
    general: {
      validate: validateGeneralCampaignsUseCase,
    },
  };
  const {
    [type]: { validate },
  } = campaignsType;
  await validate(options);
}
