const state = {
    firstTimeInNequi: true,
    userIsInNequi: false,
    token: null,
    user: {
        phone: null,
        firstName: null,
        lastName: null,
        email: null,
        typeDocument: null,
        document: null,
        birthday: null
    },
};

const getters = {
    getUserFromNequi(state) {
        return state.user;
    },
    isFirstTimeInNequi(state) {
        return state.firstTimeInNequi;
    },
    isUserInNequi(state) {
        return state.userIsInNequi;
    },
    getNequiToken(state) {
        return state.token
    }
};

const mutations = {
    SET_FIRST_TIME_DISABLED(state) {
        state.firstTimeInNequi = false;
    },
    SET_USER_IS_IN_NEQUI(state) {
        state.userIsInNequi = true;
    },
    SET_TOKEN_NEQUI(state, token) {
        state.token = token;
    },
    SET_NEQUI_USER(state, user) {
        state.user = user;
    },
};

const actions = {

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
