const state = {
  primeData: null,
  errorPrimeData: null,
  planData: [],
  ui: {
    loader: false,
  },
  subscriptionData: {
    cvv: '',
    creditCard: {
      id: 1,
      cvv: false,
      lastFour: '123',
      cardType: 'VISA',
    },
    billingForm: {
      address: '',
      postalCode: 0,
      identityNumber: '',
      identityType: '',
    },
  },
  userHavePrime: false,
};

const getters = {
  getPrimeStatus(state) {
    return state.primeData;
  },
  getDataPlan(state) {
    return state.planData;
  },
  getSubscriptionData(state) {
    return state.subscriptionData;
  },
  getPrimeErrorStatus(state) {
    return state.errorPrimeData;
  },
  isTrialPlan(state) {
    return state.planData?.hasTrial === true;
  },
  isRenovatedPlan(state) {
    return state.primeData?.isRenovated === true;
  },
};

const mutations = {
  SET_PRIME_STATUS(state, primeData) {
    state.primeData = primeData;
  },
  SET_PRIME_PLAN_DATA(state, planData) {
    state.planData = planData;
  },
  SET_PRIME_STATUS_UI(state, data) {
    const { key, value } = data;
    state.ui[key] = value;
  },
  SET_PRIME_SUBSCRIPTION_DATA(state, data) {
    const { key, value } = data;
    state.subscriptionData[key] = value;
  },
  SET_PRIME_ERROR_DATA(state, data) {
    state.errorPrimeData = data;
  },
  SET_USER_HAVE_PRIME(state, data) {
    state.userHavePrime = data;
  },
  SET_PRIME_PLAN_RENOVATION(state, data){
    state.primeData.isRenovated = data
  }
};

const actions = {
  setPrimeStatus({ commit }, primeData) {
    commit('SET_PRIME_STATUS', primeData);
  },
  setDataPlanPrime({ commit }, planData) {
    commit('SET_PRIME_PLAN_DATA', planData);
  },
  updateStatusUI({ commit }, data) {
    commit('SET_PRIME_STATUS_UI', data);
  },
  setDataSubscriptionPrime({ commit }, data) {
    commit('SET_PRIME_SUBSCRIPTION_DATA', data);
  },
  setErrorPrimeData({ commit }, data) {
    commit('SET_PRIME_ERROR_DATA', data);
  },
  setUserHavePrime({ commit }, data) {
    commit('SET_USER_HAVE_PRIME', data);
  },
  setPrimePlanRenovation({ commit }, data){
    commit('SET_PRIME_PLAN_RENOVATION', data);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

