import Vue from 'vue';
import { storeRedirectUseCase } from '~/usecases/stores/store-redirect-usecase';

export default function ({ store, route }) {
  setTimeout(async () => {
    const cartProducts = store.getters['cart/getCart'];
    const minimumOrderAmount = store.getters['home/getMinimumOrderAmount'];
    switch (route.name) {
      case 'checkout':
      case 'checkout-payment':
      case 'checkout-campaign':
      case 'checkout-success':
        store.dispatch('user/me');

        if (
          cartProducts.total() < minimumOrderAmount &&
          !store.state.checkout.isCheckoutSuccess &&
          !store.state.checkout.payment.orderCreated &&
          store.state.user.token
        ) {
          Vue.prototype.$mqToast(`El monto mínimo de compra es de ${minimumOrderAmount}`, 'close', 'error');

          storeRedirectUseCase();
        }
        break;
      default:
        break;
    }
  }, 1000);
}
