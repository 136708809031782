import getAddressEndpoint from '~/endpoints/address/address-endpoints';
import { errorTransforms } from '~/transforms/errors/error-transform';
import addressTransform from '~/transforms/address/address-transform';

const getAddressService = async ({ storeId, params }) => {
  const { response, error } = await getAddressEndpoint({ storeId, params });
  if (error === null && response) {
    const data = await addressTransform(response);
    return { data, error: null };
  } else {
    return { data: null, error };
  }
};

export default getAddressService;
