import normalize from 'json-api-normalize';
import { MAP_CITIES_KEYS } from '~/constants/cities-i18n-keys';
import { translateFromMapObject } from '~/helpers/find-element-in-map-object';

export function citiesMainAndNeighborhoodTransform(responseData) {
  const mainAndNeighborhoods = normalize(responseData).get([
    'address_type',
    'id',
    'name',
    'is_main',
    'latitude',
    'longitude',
    'code',
    'time_zone',
    'storeCovered.id',
    'storeCovered.city_id',
    'storeCovered.minimum_order_amount',
    'storeCovered.minimum_order_amount_express',
    'storeCovered.name',
    'storeCovered.slug',
    'storeCovered.warehouse_id',
    'storeCovered.zone_id',
    'slug',
    'type',
  ]);
  return mainAndNeighborhoods.map((city) => ({
    ...city,
    city: city.name,
    get cityName() {
      return translateFromMapObject(MAP_CITIES_KEYS, city.slug, city.name);
    },
  }));
}

