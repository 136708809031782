import Vue from 'vue';
import * as env from '~/../env.local';
import { assignHammerEvents } from '~/helpers/hammer-manage-events';
import { manageEventCarousel } from '~/helpers/interceptor-observer-manage';
import manageTooltip from '~/helpers/manage-tooltip';

// import { scrollLockDirective } from './../plugins/scroll-lock';

Vue.directive('nospace', {
  componentUpdated(el) {
    el.value = el.value.replace(/^\s+/g, '');
  },
});

// Vue.directive('scroll-lock', scrollLockDirective);

Vue.directive('testing-name', {
  bind: function (el, binding) {
    if (env.testingNameEnable && binding.value) {
      el.setAttribute('data-test', binding.value);
    }
  },
});

Vue.directive('scroll-focus', {
  bind: function (el) {
    if (el instanceof HTMLElement) {
      el.scrollIntoView();
    }
  },
});

Vue.directive('hammer-event', {
  bind: (el, binding) => {
    assignHammerEvents(el, binding, 'set');
  },
  unbind: (el, binding) => {
    assignHammerEvents(el, binding, 'remove');
  },
});

Vue.directive('focus', {
  inserted: function (el, binding) {
    if (binding.value) {
      el.focus();
    }
  },
});

Vue.directive('view-slide', {
  inserted: (el, binding) => {
    manageEventCarousel(el, binding, 'set');
  },
  unbind: (el, binding) => {
    manageEventCarousel(el, binding, 'remove');
  },
});

Vue.directive('tooltip-text', {
  inserted: (el, binding) => {
    manageTooltip(el, binding, 'set', 'text');
  },
  unbind: (el, binding) => {
    manageTooltip(el, binding, 'remove', 'text');
  },
});

Vue.directive('tooltip-component', {
  inserted: (el, binding) => {
    manageTooltip(el, binding, 'set', 'component');
  },
  unbind: (el, binding) => {
    manageTooltip(el, binding, 'remove', 'component');
  },
});
