/**
 * @param {number} storeId,
 * @param {number} departmentID,
 */
export async function getBestProducstByDptoEndpoint({ storeId, departmentId, zoneId = undefined }) {
  try {
    const params = new URLSearchParams();
    if (zoneId) {
      params.append('zoneId', zoneId);
    }
    const baseUrl = process.env.baseApi;
    const response = await fetch(`${baseUrl}/3.1/stores/${storeId}/department/${departmentId}/products-best?${params.toString()}`);
    if (response.ok) return { response, error: null };
    else {
      return { response: null, error: response };
    }
  } catch (error) {
    return { response: null, error };
  }
}
