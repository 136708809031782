import { nuxtInstance } from '~/plugins/nuxt-instance';

export async function storeRedirectUseCase(typeRedirect = 'marketplace') {
  const {
    store: { getters },
    redirect,
  } = nuxtInstance;
  const marketplaceStore = getters['home/getCurrentStore'];
  const defaultMarketplaceStore = getters['home/getDefaultStore'];
  const city = getters['home/getCity'];
  if (typeRedirect === 'marketplace') {
    if (marketplaceStore instanceof Object && city instanceof Object) {
      return redirect(`/${city.slug}/${marketplaceStore.slug}`);
    } else if (defaultMarketplaceStore instanceof Object) {
      return redirect(`/${city.slug}/${marketplaceStore.slug}`);
    }
  }
  return redirect(``);
}
