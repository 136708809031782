import $axios from '~/services/global-axios';

const post = async (url, data) => {
  try {
    let response = await $axios.post(url, {
      ...data,
    });
    return { response, error: null };
  } catch (error) {
    return { response: null, error };
  }
};
export { post };
