import $axios from '~/services/global-axios';

export async function ChangePaymentMethodEndpoint(params) {
  try {
    const response = await $axios.$put(`/3.1/order/change-payment-method`, params);
    return { response, error: null };
  } catch (error) {
    return { response: null, error };
  }
}
