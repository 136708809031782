import $axios from '~/services/global-axios';

export async function getDeliveryTimes({ zoneId, storeId }) {
  try {
    const response = await $axios.$get(`/3.1/stores/${storeId}/next-all-available-delivery-slot`, {
      params: {
        zoneId,
      },
    });
    return { response, error: null };
  } catch (error) {
    return { response: null, error };
  }
}
