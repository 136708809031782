import { render, staticRenderFns } from "./mq-icon.vue?vue&type=template&id=5d73580e&"
import script from "./mq-icon.vue?vue&type=script&lang=js&"
export * from "./mq-icon.vue?vue&type=script&lang=js&"
import style0 from "./mq-icon.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports