//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex';
import SkFinder from '~/components/skeleton/sk-finder';
import MqProductFinder from '~/components/elements/mq-product-finder';
import SkFinderProduct from '~/components/skeleton/sk-finder-product';
import { getSearchProductsUsecase } from '~/usecases/products/search-usecase';
import { simpleDisableBodyScroll, simpleEnableBodyScroll } from '~/plugins/scroll-lock';

export default {
  name: 'MqFinder',
  components: {
    MqProductFinder,
    SkFinder,
    SkFinderProduct,
  },
  props: {
    theme: { type: String, default: () => '' },
  },
  data() {
    return {
      showForm: false,
      desktop: false,
      finder: null,
      notFound: false,
      removing: false,
      adding: false,
      added: false,
      count: 0,
      finding: false,
      loading: true,
      timeoutID: null,
      products: [],
    };
  },
  computed: {
    ...mapState({
      finderWidget: (state) => state.ui.widgets.finderWidget,
    }),
    ...mapGetters({
      getZoneId: 'home/getZoneId',
      store: 'home/getCurrentStore',
      city: 'home/getCity',
    }),
    cityName() {
      return this.city.cityName;
    },
    storeTitle() {
      return this.store.title;
    },
  },
  watch: {
    finderWidget(val) {
      if (val) {
        simpleEnableBodyScroll();
        setTimeout(() => {
          simpleDisableBodyScroll();
        }, 200);
      } else {
        simpleEnableBodyScroll();
        this.finder = null;
      }
      this.calculateCssProperties();
    },
    $route() {
      simpleEnableBodyScroll();
    },
  },
  created() {
    document.documentElement.style.setProperty('--heightSearch', `100%`);
  },
  mounted() {
    this.$nextTick(() => {
      document.querySelector('#finder-products')?.classList.add('marketplace');
    });
    this.loading = false;
    const $screen = $(window);
    let widthScreen = $screen.width();

    const showForm = () => {
      const resizeScreen = $screen.width() !== widthScreen;
      widthScreen = $screen.width();
      this.desktop = widthScreen >= 720;
      if (this.finderWidget && resizeScreen) this.closeForm();
    };

    showForm();

    $screen.on('resize.formFinder', () => {
      showForm();
    });
    this.calculateCssProperties();
  },
  beforeDestroy() {
    simpleEnableBodyScroll();
    $(window).off('resize.formFinder');
  },
  methods: {
    calculateCssProperties() {
      setTimeout(() => {
        const topElement = document.getElementById('mq-nav-finder');
        if (topElement instanceof HTMLElement) {
          const topRect = topElement.getBoundingClientRect();
          const offset = 8;
          document.documentElement.style.setProperty('--backdropFinderLeft', `${topRect.x}px`);
          document.documentElement.style.setProperty('--backdropFinderTop', `${topRect.y + topRect.height + offset}px`);
          document.documentElement.style.setProperty('--backdropFinderWidth', `${topRect.width}px`);
        }
      }, 100);
    },
    test(e) {
      const find = e.target.value;
      if (find.length >= 2 && !this.finderWidget) {
        this.finderProduct(find);
      }
    },
    openForm() {
      const { dispatch } = this.$store;
      dispatch('LOAD_MODAL_WIDGET', ['finderWidget', true]);
      if (!this.desktop) {
        $('#finder-products-movil').focus();
      }
    },
    closeForm() {
      const { dispatch } = this.$store;
      dispatch('LOAD_MODAL_WIDGET', ['finderWidget', false]);
      this.finder = '';
      $('#finder-products-movil').val('');
    },
    finderProduct(e) {
      if (e.length >= 2) {
        this.$store.commit('SET_WIDGET', ['finderWidget', true]);
        this.finder = e;
        clearTimeout(this.timeoutID);
        this.timeoutID = setTimeout(async () => {
          this.finding = true;
          const storeId = this.store.id;
          const zoneId = this.getZoneId;
          const dataSearch = {
            search: this.finder,
            per_page: 50,
            zoneId,
          };
          const { data, error } = await getSearchProductsUsecase(storeId, dataSearch);
          if (!error) {
            this.products = data.products ? data.products.filter((product) => product.status) : [];
            this.notFound = data.products ? data.products.length === 0 : true;
            this.finding = false;
          } else {
            this.notFound = true;
            this.finding = false;
          }
        }, 300);
      } else if (e.length <= 0) {
        this.finder = null;
        if (this.desktop) {
          this.$store.commit('SET_WIDGET', ['finderWidget', false]);
        }
        this.finding = false;
      }
    },
    finderEnter() {
      if (this.finder !== null) {
        this.$router.push({ path: `/${this.city.slug}/${this.store.slug}/search/${this.finder}` });
        if (this.onSameSearch(this.finder)) {
          setTimeout(() => {
            this.$store.commit('SET_WIDGET', ['finderWidget', false]);
          }, 100);
        } else {
          this.openForm();
        }
      }
    },
    onSameSearch(finderQuery) {
      const {
        $route: {
          name: pathName,
          params: { q },
        },
      } = this;
      return pathName === 'city-store-search-q' && q === finderQuery;
    },
  },
};
