import { productServicesBase } from '~/../env.local';
import { validateScheme } from '~/decorators/validateScheme';
import { generateRequestHeaders } from '~/helpers/fetch-request-headers';
import { AvailableSeparateStoreParams } from '~/schemes/available-separate-store-scheme';

export class SplitStoreFlag {
  @validateScheme
  static async splitStoreFlagEndpoint(availableSeparateStoreParams: AvailableSeparateStoreParams) {
    const { cityId } = availableSeparateStoreParams;
    const requestHeaders = generateRequestHeaders();
    requestHeaders.delete('X-Merqueo-Country');

    try {
      const response = await fetch(`${productServicesBase}/available-city-flag/v1?cityId=${cityId}`, {
        headers: requestHeaders,
      });
      if (response?.ok && response?.status === 200) {
        return { response: await response.json(), error: null };
      }
    } catch (error) {
      return { response: null, error };
    }
    return { response: null, error: {} };
  }
}

