const I18NKEYS = [
  ['Pago en casa', 'payment_method_in_house'],
  ['Pago en línea', 'payment_method_in_line'],
  ['Efectivo', 'payment_method_in_house_cash'],
  ['Datáfono', 'payment_method_in_house_dataphone'],
  ['Terminal', 'payment_method_in_house_dataphone'],
  ['Datáfono - Sodexo', 'payment_method_in_house_dataphone_sodexo'],
  ['Tarjeta de crédito', 'payment_method_in_line_credit_card'],
  ['Débito - PSE', 'payment_method_in_line_pse'],
];

export const MAP_PAYMENT_METHODS_KEYS = new Map(I18NKEYS);
