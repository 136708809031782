const env = require('./../../../env.local');
import addressListUseCase from '~/usecases/address/address-list-usecase';
import getAddressService from '~/services/addresess/address-service';
import getAddressCoverageService from '~/services/addresess/address-coverage-service';
// initial state
const getDefaultState = () => {
  return {
    view_address: 'initial',
    addresses_find: [],
    address: null,
    address_type: null,
    address_object: null,
    zone: null,
    address_temp: {},
    city_temp: {},
    my_addresses: [],
    view_address_queue: [],
    back_view: null,
    add_view: true,
    zone_temp: null,
    zone_id: null,
    zoneExpressId: null,
    temp_no_products_info: null,
    tempProductWithoutAddress: {},
    citySelectedWidget: null,
    addressesWithRoute: [],
    geolocationPermission: null,
    country_temp: {},
    gpsDataTemp: {},
  };
};

const state = getDefaultState();

// getters
const getters = {
  isAddressValid(state, _getters, rootState) {
    return 'slug' in rootState.home.store && 'address' in state.address_object;
  },
  getAddressObject(state) {
    return state.address_object ?? {};
  },
  getAddressTemp(state) {
    return state.address_temp ?? {};
  },
  getAddressType(state) {
    return state.address_type;
  },
  getTempNoProductsInfo(state) {
    return state.temp_no_products_info ?? {};
  },
  getCityTemp(state) {
    return state.city_temp ?? {};
  },
  getCityTempId(state, getters) {
    return getters.getCityTemp.id;
  },
  getMyAddresses(state) {
    return state.my_addresses;
  },
};

// actions
const actions = {
  setAddressObject({ commit }, addressObject) {
    commit('SET_ADDRESS_OBJECT', addressObject);
  },
  setAddress({ commit }, address) {
    commit('SET_ADDRESS', address);
  },
  async validateAddress({ commit, rootGetters }, { address, storeId, cityId }) {
    const params = {
      address: address,
      cityId: cityId,
    };
    const { data, error } = await getAddressService({
      storeId: storeId ?? rootGetters['home/getCurrentStoreId'],
      params,
    });
    if (!error && data instanceof Array) {
      commit('SET_ADDRESSES_FIND', data);
    }
    return { data, error };
  },
  //TODO: Validar uso
  async getMyAddress({ commit, rootState, dispatch }, { currentPage = 1, perPage = 30 }) {
    //TODO: Validar variables
    //eslint-disable-next-line
    let myAddress = null;
    let address = [];
    if (rootState.user.token) {
      myAddress = await addressListUseCase({ currentPage, perPage });
      address = myAddress.data ? myAddress.data.data : [];
    }
    address = await dispatch('validateAddressObject', address);
    commit('SET_MY_ADDRESSES', address);
    let responseTemp = {
      data: {
        data: address,
      },
    };
    return myAddress && myAddress.data ? myAddress : responseTemp;
  },
  async validateAddressObject({ rootState, rootGetters, commit, getters }, addressList) {
    let addressObject = getters['getAddressObject'];
    let store = rootGetters['home/getCity'];
    if (addressObject && !addressObject.id) {
      addressObject.city = {
        id: store.id,
        city: store.city,
        slug: store.slug,
        address_type: store.address_type,
      };
      addressObject.label = this.app.i18n.t('common_message_current_address');
      addressObject.addressFurther = '';
      addressObject.neighborhood = addressObject.neighborhood ? addressObject.neighborhood : '';
    }
    //si hay lista de direcciones cargadas
    if (addressList && addressList.length > 0 && addressObject.id) {
      const validateAddressNotIdExist = !addressList.some((addr) => addr.id === addressObject.id);
      if (validateAddressNotIdExist) {
        addressList.push(addressObject);
      } else if (addressList.some((addr) => addr.address === addressObject.address) && !addressObject.id) {
        const newAddressObject = addressList.find((addr) => addr.address === addressObject.address);
        commit('SET_ADDRESS_OBJECT', newAddressObject);
      }
    }
    //Si no hay lista de direcciones cargadas
    else if (addressObject.city && addressList && addressList.length === 0 && addressObject.address) {
      addressList.push(addressObject);
    }
    return addressList;
  },
  //TODO: Validar uso
  async validateCoverage({ rootGetters }, { city, latitude, longitude }) {
    const storeId = city ?? rootGetters['home/getCurrentStoreId'];
    let params = {
      latitude: latitude,
      longitude: longitude,
    };
    const { data, error } = await getAddressCoverageService({ storeId, params });
    return { data, error };
  },

  // Servicios de direcciones para mi cuenta
  async deleteAddress(res, { addressId }) {
    //TODO: variable local redundante
    let data = await this.$axios.$delete(`/2.0/addresses/${addressId}`);

    return data;
  },

  async updateAddress(res, { params }) {
    let addressId = 'addressId' in params ? params.addressId : null;

    if (addressId) {
      delete params.addressId;
    }

    let data = await this.$axios.$put(`/2.0/addresses/${addressId}`, params);

    return data;
  },
  // TODO: variable local redundante
  async createAddress(res, { params }) {
    let data = await this.$axios.$post(`/2.0/addresses`, params);

    return data;
  },
  resetAddressLogout({ commit }) {
    commit('SET_RESET_ADDRESS_LOGOUT', null);
  },
  updateAddressObject({ commit, getters }, attributes) {
    let addressObject = getters['getAddressObject'];
    Object.assign(addressObject, attributes);
    commit('SET_ADDRESS_OBJECT', addressObject);
  },

  setAddressesWithRoute({ commit }, addresses) {
    commit('SET_ADDRESSES_WITH_ROUTE', addresses);
  },

  setAddressType({ commit }, type) {
    commit('SET_ADDRESS_TYPE', type);
  },
  setProductWithoutAddress({ commit }, tempProductWithoutAddress) {
    commit('SET_PRODUCT_WITHOUT_ADDRESS', tempProductWithoutAddress);
  },
  setGeolocationPermission({ commit }, status) {
    commit('SET_GEOLOCATION_PERMISSION', status);
  },
  setCityTemp({ commit }, cityData) {
    commit('SET_CITY_TEMP', cityData);
  },
  setCountryTemp({ commit }, countryData) {
    commit('SET_COUNTRY_TEMP', countryData);
  },
  setGpsDataTemp({ commit }, gpsData) {
    commit('SET_GPS_DATA_TEMP', gpsData);
  },
  setAddressTemp({ commit }, addressTemp) {
    commit('SET_ADDRESS_TEMP', addressTemp);
  },
  setViewAddress({ commit }, view) {
    commit('SET_VIEW_ADDRESS', view);
  },
  setTempNoProductsInfo({ commit }, tempNoProductsInfo) {
    commit('SET_TEMP_NOT_PRODUCTS_INFO', tempNoProductsInfo);
  },
  removeViewAddressInQueue({ commit }) {
    commit('REMOVE_VIEW_ADDRESS_IN_QUEUE');
  },
};

// mutations
const mutations = {
  //Todo: Validar uso
  SET_VIEW_ADDRESS(state, view_address) {
    state.view_address = view_address;
    if (state.add_view && !state.view_address_queue.some((view) => view === view_address)) {
      state.view_address_queue.push(view_address);
    }
    state.add_view = true;
  },

  SET_ADDRESSES_FIND(state, addresses_find) {
    state.addresses_find = addresses_find;
  },
  //Todo: Validar uso
  //address confirmed
  SET_ADDRESS(state, address) {
    state.address = address;
  },
  //Todo: Validar uso
  // all address object
  SET_ADDRESS_OBJECT(state, addressObject) {
    const addressTypes = ['Casa', 'Apartamento', 'Oficina'];
    const findAddressType = addressTypes.findIndex((type) => type === addressObject.label);
    const addressType =
      findAddressType === -1 &&
      addressObject.id &&
      addressObject.label !== '' &&
      addressObject.label !== undefined &&
      addressObject.label !== null
        ? 'Otro'
        : addressObject.label;
    const customAddressType = addressType === 'Otro' ? addressObject.label : '';
    state.address_object = { ...addressObject, addressType, customAddressType };
  },
  //Todo: Validar uso
  //address selected before confirmed
  SET_ADDRESS_TEMP(state, address) {
    state.address_temp = address;
  },
  //Todo: Validar uso
  //city selected before confirmed
  SET_CITY_TEMP(state, city) {
    delete city.neighborhoods;
    state.city_temp = city;
  },
  //Todo: Validar uso
  SET_LATLNG_IN_ADDRESS(state, { latitude, longitude }) {
    state.address_temp.latitude = latitude;
    state.address_temp.longitude = longitude;
  },

  SET_MY_ADDRESSES(state, my_addresses) {
    state.my_addresses = my_addresses;
  },
  //Todo: Validar uso
  //clean queue views
  SET_VIEW_ADDRESS_IN_QUEUE(state) {
    state.view_address_queue = [];
  },
  //Todo: Validar uso
  REMOVE_VIEW_ADDRESS_IN_QUEUE(state) {
    state.add_view = false;
    if (state.view_address_queue.length > 1) {
      state.view_address_queue.pop();
      state.back_view = state.view_address_queue.slice(-1)[0];
    } else {
      state.view_address_queue.pop();
    }
  },
  //Todo: Validar uso
  SET_ZONE_TEMP(state, zone) {
    state.zone_temp = zone;
  },
  //Todo: Validar uso
  SET_ZONE(state) {
    state.zone = state.zone_temp;
  },
  //Todo: Temporal el !=36
  SET_ZONE_ID(state, zone_id) {
    state.zone_id = zone_id != 36 ? zone_id : null;
  },
  SET_ZONE_EXPRESS_ID(state, zone_express_id) {
    state.zoneExpressId = zone_express_id;
  },
  //Todo: Validar uso
  SET_TEMP_NOT_PRODUCTS_INFO(state, temp_no_products_info) {
    state.temp_no_products_info = temp_no_products_info;
  },

  resetState(state) {
    let estados = getDefaultState();
    Object.assign(state, estados);
  },
  //Todo: Validar uso
  SET_PRODUCT_WITHOUT_ADDRESS(state, tempProductWithoutAddress) {
    state.tempProductWithoutAddress = tempProductWithoutAddress;
  },
  //Todo: Validar uso
  SET_CITY_SELECTED_WIDGET(state, citySelectedWidget) {
    state.citySelectedWidget = citySelectedWidget;
  },
  SET_RESET_ADDRESS_LOGOUT(state, value) {
    state.address_object.id = value;
    state.address_object.label = value;
    state.address_object.neighborhood = value;
    state.address_object.outdoorNumber = value;
    state.address_object.postalCode = value;
    state.address_object.addressFurther = value;
  },
  SET_ADDRESSES_WITH_ROUTE(state, addresses) {
    state.addressesWithRoute = addresses;
  },
  SET_ADDRESS_TYPE(state, type) {
    state.address_type = type;
  },
  SET_VIEW_ADDRESS_IN_QUEUE_RESTART(state) {
    state.view_address_queue =
      state.my_addresses.length > 0 ? ['mydir', 'initial', 'nocoverage'] : ['initial', 'nocoverage'];
  },
  SET_GEOLOCATION_PERMISSION(state, status) {
    state.geolocationPermission = status;
  },
  SET_COUNTRY_TEMP(state, country) {
    state.country_temp = country;
  },
  SET_GPS_DATA_TEMP(state, gpsData) {
    state.gpsDataTemp = gpsData;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
