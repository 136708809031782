import { Event } from '../event';

export class AnalyticEvent extends Event {
  constructor() {
    super();
  }

  /**
   * @param dataVars
   */
  track(dataVars) {
    dataLayer.push({
      event: this.name,
      ...dataVars,
    });
  }
}

