import $axios from '~/services/global-axios';

export async function getProductByIdEndpoint(storeId: number, productId: number) {
  try {
    const response: any = await $axios.get(`/3.0/stores/${storeId}/products/${productId}`);
    return { error: null, response };
  } catch (error) {
    return { error, response: null };
  }
}
