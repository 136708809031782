//
//
//
//
//
//

export default {
  name: 'MqMessageShelf',
  props: ['message'],
};
