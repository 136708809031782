
import axios from 'axios';
import { nuxtInstance } from '~/plugins/nuxt-instance';

export async function userPolicyValidateEndpoint({ country, deviceId, version, base }) {
  const {
    store: { state },
  } = nuxtInstance;
  const token = state.user.token;
  try {
    const response = await axios.post(
      `${base}/user-policy-update/v1`,
      {
        deviceId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'X-Merqueo-Country': country,
          'X-Merqueo-Version': version,
          'X-Merqueo-Agent': 'WEB',
        },
      },
    );
    return { error: null, response };
  } catch (error) {
    return { error, response: null };
  }
}
