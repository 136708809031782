import { getChannnelsMainBrandroom, getStoriesMainBrandroom } from '~/endpoints/stories/main-brandroom-endpoint';
import { errorTransforms, error280Transforms } from '~/transforms/errors/error-transform';
import { channelsTransforms } from '~/transforms/stories/get-channels-transform';
import { storiesTransforms } from '~/transforms/stories/get-stories-transform';

export async function getChannelsMainBrandroomServices({ storeId, brandroomId, warehouseId }) {
  const { response, error } = await getChannnelsMainBrandroom({ storeId, brandroomId, warehouseId });

  if (error === null && response?.data) {
    const data = response.data;
    return { data: channelsTransforms(data), error: null };
  } else if (error === null && response.status === 280) {
    return { data: null, error: error280Transforms(response) };
  } else if (error && error?.response?.status === 429) {
    return { data: null, error: errorTransforms(response) };
  } else {
    return { data: null, error: errorTransforms(error) };
  }
}

export async function getStoriesMainBrandroomServices({ storeId, channelId, brandroomId, warehouseId }) {
  const { response, error } = await getStoriesMainBrandroom({ storeId, channelId, brandroomId, warehouseId });

  if (error === null && response?.data) {
    const data = response.data;
    return { data: storiesTransforms(response), error: null };
  } else if (!error && response.status === 280) {
    return { data: null, error: error280Transforms(response) };
  } else if (error && error?.response?.status === 429) {
    return { data: null, error: errorTransforms(response) };
  } else {
    return { data: null, error: errorTransforms(error) };
  }
}
