import { schemeDescriptor } from './functions/schemeDescriptor';
import { updateInternalScheme } from './functions/updateInternalScheme';

export function required(options?: any) {
  return function (target: any, propertyKey: string) {
    const { constructor } = target;
    const propertyType = Reflect.getMetadata('design:type', target, propertyKey);
    if (propertyType) {
      const scheme = schemeDescriptor(constructor);
      updateInternalScheme(scheme, propertyKey, {
        propertyKey,
        propertyType,
        propertyRequired: true,
      });
    }
  };
}
