import { FN_LINK_CONSTRUCTOR } from '~/constants/headers-options';
import { metaDataIndex } from '~/helpers/meta-index';
export const HEADER_LINK_MIXIN = {
  head() {
    return {
      meta: metaDataIndex(),
      link: this.linkHeadMixin,
    };
  },
  data() {
    return {
      linkHeadMixin: FN_LINK_CONSTRUCTOR(this.$store.state.i18n.locale),
    };
  },
  watch: {
    $route(to, from) {
      this.linkHeadMixin = FN_LINK_CONSTRUCTOR(this.$store.state.i18n.locale);
    },
  },
};
