//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MqMenu',
  props: {
    mqClick: Function,
    openMenu: Boolean,
    menuState: Boolean,
  },
  data() {
    return {
      closeBtn: false,
    };
  },
  watch: {
    menuState(value) {
      this.closeBtn = value;
    },
  },
  mounted() {
    this.closeBtn = false;
  },
  mounted() {
    this.closeBtn = false;
  },
  methods: {
    toogleMenu(e) {
      this.closeBtn = !this.closeBtn;
      this.mqClick(e, this.openMenu);
    },
  },
};
