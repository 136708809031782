const I18NKEYS = [
  ['bogota', 'common_label_city_bogota'],
  ['barranquilla', 'common_label_city_barranquilla'],
  ['cali', 'common_label_city_cali'],
  ['soledad', 'common_label_city_soledad'],
  ['medellin', 'common_label_city_medellin'],
  ['bello', 'common_label_city_bello'],
  ['cajica', 'common_label_city_cajica'],
  ['caldas', 'common_label_city_caldas'],
  ['chia', 'common_label_city_chia'],
  ['copacabana', 'common_label_city_copacabana'],
  ['cota', 'common_label_city_cota'],
  ['el-retiro', 'common_label_city_el_retiro'],
  ['envigado', 'common_label_city_envigado'],
  ['funza', 'common_label_city_funza'],
  ['itagui', 'common_label_city_itagui'],
  ['la-calera', 'common_label_city_la_calera'],
  ['la-ceja', 'common_label_city_la_ceja'],
  ['la-estrella', 'common_label_city_la_estrella'],
  ['madrid', 'common_label_city_madrid'],
  ['mosquera', 'common_label_city_mosquera'],
  ['rionegro', 'common_label_city_rionegro'],
  ['sabaneta', 'common_label_city_sabaneta'],
  ['san-antonio-de-pereira', 'common_label_city_san_antonio_de_pereira'],
  ['sibate', 'common_label_city_sibate'],
  ['soacha', 'common_label_city_soacha'],
  ['zipaquira', 'common_label_city_zipaquira'],
  ['mexico-df', 'common_label_city_mexico_df'],
  ['estado-de-mexico', 'common_label_city_estado_de_mexico'],
  ['sao-paulo', 'common_label_city_sao_paulo'],
];

export const MAP_CITIES_KEYS = new Map(I18NKEYS);
