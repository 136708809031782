import $axios from '~/services/global-axios';

export async function getCountriesEndpoint() {
  try {
    let baseUrl = process.env.baseApi;
    const response = await fetch(`${baseUrl}/3.0/countries`);
    if (response?.ok) {
      return { response, error: null };
    } else {
      const error = new Error(response.status);
      return { response: null, error };
    }
  } catch (error) {
    return { response: null, error };
  }
}
