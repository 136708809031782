import { camelize } from '~/helpers/camelize';
import normalize from 'json-api-normalize';

export function changePaymentMethodTransform(responseData) {
  return camelize(
    normalize(responseData).get([
      'id',
      'delivery_amount',
      'discount',
      'pse_payment_url',
      'subtotal',
      'total',
      'total_products',
      'address.address',
      'address.address_further',
      'address.label',
      'address.latitude',
      'address.longitude',
      'address.neighborhood',
      'address.outdoor_number',
      'address.parts',
      'address.postal_code',
      'address.zone_id',
      'address.id',
      'campaignPrizes.amount',
      'campaignPrizes.campaign_id',
      'campaignPrizes.campaign_prize_type',
      'campaignPrizes.campaign_type',
      'campaignPrizes.code',
      'campaignPrizes.message',
      'campaignPrizes.id',
      'orders.date',
      'orders.delivery_amount',
      'orders.discount',
      'orders.payment_method',
      'orders.real_status',
      'orders.reference',
      'orders.status',
      'orders.subtotal',
      'orders.time',
      'orders.total',
      'orders.total_products',
      'orders.user_business_name',
      'orders.user_identity_number',
      'orders.user_identity_type',
      'orders.id',
    ]),
  );
}
