import { userPolicyValidateEndpoint } from '~/endpoints/policy/user-policy-update-endpoint';
import { errorTransforms } from '~/transforms/errors/error-transform';

export async function userPolicyUpdateUsecase({ country, deviceId, version, base }) {
  const { error, response } = await userPolicyValidateEndpoint({ country, deviceId, version, base });
  if (error === null && response?.status === 200) {
    return { data: response, error: null };
  } else if (error === null && response?.status === 280) {
    return { data: null, error: response.data.errors[0] };
  } else if (
    error &&
    error.response &&
    error.response.data instanceof Object &&
    error.response.data.errors instanceof Array
  ) {
    return { data: null, error: errorTransforms(error) };
  } else {
    return {};
  }
}
