import { productServicesBase } from '~/../env.local';
import { errorTransforms, error280Transforms } from '~/transforms/errors/error-transform';
import { camelize } from '~/helpers/camelize';
import { nuxtInstance } from '~/plugins/nuxt-instance';
import { generateRequestHeaders } from '~/helpers/fetch-request-headers';
import { PRIME_TYPE } from '~/constants/prime-config-components';

function formatPlansData(plansList, hasTrial) {
  const {
    store: { getters },
  } = nuxtInstance;
  return plansList.map((el) => {
    const { period, expirationDate, promotionalPrice, price } = el;
    const dateRenewal = expirationDate ? new Date(expirationDate) : new Date();
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'UTC',
    };
    const keyMonth = period === 1 ? 'common_label_month' : 'common_label_months';
    const titleVal = `${period} ${nuxtInstance?.app?.i18n?.t(keyMonth)}`.toLowerCase();
    const currentLocale = getters['home/getCurrentLocale'];
    const titleFree = nuxtInstance?.app?.i18n?.t('prime_title_free_trial');
    return {
      ...el,
      title: hasTrial ? titleFree : titleVal,
      renewal: new Intl.DateTimeFormat(currentLocale, options).format(dateRenewal).replace(/\//g, '-'),
      priceDiff: promotionalPrice ? price - promotionalPrice : promotionalPrice,
      validity: titleVal,
      term: `${period} ${nuxtInstance?.app?.i18n?.t(keyMonth)}`.toLowerCase(),
    };
  });
}

export async function listServicesPrimeEndpoint() {
  const {
    store: { state },
  } = nuxtInstance;
  const {
    user: { token },
  } = state;
  const requestHeaders = generateRequestHeaders();
  requestHeaders.delete('X-Merqueo-Agent');
  requestHeaders.delete('X-Merqueo-Version');
  requestHeaders.set('Authorization', `Bearer ${token}`);
  try {
    const response = await fetch(`${productServicesBase}/services-list/v1`, {
      headers: requestHeaders,
    });
    return { response, error: null };
  } catch (error) {
    return { response: null, error };
  }
}

export async function listServicesPrimeService() {
  const { response, error } = await listServicesPrimeEndpoint();
  if (response?.ok && response.status === 200) {
    const { data: jsonData } = await response.json();
    const { attributes: data = {} } = jsonData.find((elem) => elem?.attributes?.type === PRIME_TYPE) ?? {};
    return { data: camelize(data), error };
  } else if (response?.ok && response.status === 280) {
    return { data: null, error: error280Transforms(response) };
  }
  return { data: null, error: errorTransforms(error) };
}

export async function listServicesPrimeUseCase() {
  const { data, error } = await listServicesPrimeService();
  if (data?.plans?.length > 0) {
    const { plans, hasTrial } = data;
    let plansConfig = plans;
    if (!hasTrial) {
      if (plans.filter((el) => el.promotionalPrice !== null).length === 1) {
        const firstProm = plans.find((el) => el.promotionalPrice !== null);
        const plansFilterProm = plans.filter((el) => el.promotionalPrice === null);
        plansConfig = [firstProm, ...plansFilterProm];
      } else if (plans.filter((el) => el.promotionalPrice !== null).length > 1) {
        const plansMultipleProm = plans.filter((el) => el.promotionalPrice !== null);
        const plansWithoutProm = plans.filter((el) => el.promotionalPrice === null);
        plansMultipleProm.sort((a, b) => {
          const { price: priceA, promotionalPrice: promotionalPriceA } = a;
          const { price: priceB, promotionalPrice: promotionalPriceB } = b;
          const diffA = +priceA - +promotionalPriceA;
          const diffB = +priceB - +promotionalPriceB;
          return diffB - diffA;
        });
        plansConfig = [...plansMultipleProm, ...plansWithoutProm];
      }
    }
    const planList = formatPlansData(plansConfig, hasTrial);
    return { data: { ...data, plans: planList }, error };
  }
  return { data, error };
}

export async function listPrimeHistoryLogsEndPoint() {
  const {
    store: { state },
  } = nuxtInstance;
  const {
    user: { token },
  } = state;
  const requestHeaders = generateRequestHeaders();
  requestHeaders.delete('X-Merqueo-Version');
  requestHeaders.set('Authorization', `Bearer ${token}`);
  try {
    const response = await fetch(`${productServicesBase}/transaction-logs/v1`, {
      headers: requestHeaders,
    });
    return { response, error: null };
  } catch (error) {
    return { response: null, error };
  }
}

export async function getPrimeHistoryLogsService() {
  const { response, error } = await listPrimeHistoryLogsEndPoint();
  if (response) {
    const { data: jsonData } = await response.json();
    const data = jsonData.map((elem) => camelize(elem?.attributes));
    return { data };
  } else if (response?.ok && response.status === 280) {
    return { data: null, error: error280Transforms(response) };
  }
  return { data: null, error: errorTransforms(error) };
}

