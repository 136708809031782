export class Strategy {
    constructor(private name: string, private handler: Function) {}

    getName() {
        return this.name;
    }

    doAction() {
        return this.handler();
    }
}