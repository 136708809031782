import { getBrandRoomEndPoint } from '~/endpoints/products/brand-room-endpoints';
import { brandRoomTransforms } from '~/transforms/brandroom/brand-room-transform';

export async function getBrandRoom(storeId, wareHouseId) {
  const [error, response] = await getBrandRoomEndPoint(storeId, wareHouseId);
  if (!error && response) {
    const data = await response.json();
    const brandRooms = brandRoomTransforms(data);
    if (brandRooms && brandRooms.length > 0) {
      return brandRooms;
    }
  }
  return [];
}
