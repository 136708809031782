import { getBannersEndpoint, getBannersNewServiceEndpoint } from '~/endpoints/banners/get-banners-endpoint';
import { errorTransformsFetch } from '~/transforms/errors/error-transform';
import { getBannersTransform } from '~/transforms/banners/get-banners-transform';

/**
 * @param {number} storeId
 * @param {URLSearchParams} searchParams
 */
export async function getBannersUseCase({ storeId, zoneId, departmentId, isAcquisition }) {
  const LIMIT_PROCESSING_BANNER = 100;
  const { error, response } = await getBannersEndpoint({ storeId, zoneId, departmentId, isAcquisition });

  if (error === null && response.status === 200) {
    const responseJson = await response.json();
    return { data: getBannersTransform(responseJson).slice(0, LIMIT_PROCESSING_BANNER), error: null };
  }
  return { data: null, error: errorTransformsFetch(error) };
}

export async function getBannersNewServiceUseCase({ storeId, adq, department_id, platform, warehouseId }) {
  const LIMIT_PROCESSING_BANNER = 100;
  const { error, response } = await getBannersNewServiceEndpoint({
    storeId,
    adq,
    department_id,
    platform,
    warehouseId,
  });
  if (error === null && response.status === 200) {
    const data = await response.json();
    return { data: getBannersTransform(data).slice(0, LIMIT_PROCESSING_BANNER), error: null };
  }
  return { data: null, error: errorTransformsFetch(error) };
}
