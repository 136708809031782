import { camelize } from '~/helpers/camelize';
import normalize from 'json-api-normalize';

export function channelsTransforms(responseData) {
  return normalize(responseData)
    .get(['id', 'active_story_id', 'name', 'platforms', 'position', 'status', 'title', 'url_logo', 'type', 'category'])
    .map(channel => {
      return camelize(channel);
    });
}
