//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex';
import { AddCart } from '~/mixins/addProduct';
import { RemoveCart } from '~/mixins/removeProduct';

export default {
  name: 'MqAddCart',
  mixins: [AddCart, RemoveCart],
  props: {
    theme: {
      type: String,
      default: '',
    },
    product: {
      type: Object,
      default: () => {},
    },
    productParent: {
      type: Object,
      default: null,
    },
    bannerId: {
      type: Number,
      default: 0,
    },
    position: {
      type: Number,
      default: 0,
    },
    textSearched: {
      type: String,
      default: 'N/A',
    },
    brandRoom: {
      type: Number,
      default: 0,
    },
    attribution: {
      type: String,
      default: '',
    },
    attributionValue: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      removing: false,
      adding: false,
    };
  },
  computed: {
    quantity() {
      return this.getCartProduct ? this.getCartProduct.getCount() : 0;
    },
    isAdded() {
      return this.getCartProduct ? this.getCartProduct.getCount() > 0 : false;
    },
    getCartProduct() {
      const cart = this.cartProducts;
      return cart.findCached(this.product.id);
    },
    isDisabled() {
      if (!this.getCartProduct.canDeleteProduct() && !this.getCartProduct.canRemoveProduct()) {
        return true;
      }
      return false;
    },
    storyIdComputed() {
      if (this.isFromWidget) {
        return this.storyIdInPlayer;
      }
      return this.$route.query?.story ?? null;
    },
    ...mapState({
      cartProducts() {
        const typeInstanceCart = `${this.cartInstanceType}/getCart`;
        return this.$store.getters[typeInstanceCart];
      },
      ...mapGetters({
        storyIdInPlayer: 'stories/getStoryIdInPlayer',
      }),
    }),
  },
  methods: {
    AddToCartFbPixel(product) {
      window.fbProductFbPixel.price = product.specialPrice ? product.specialPrice : product.price;
      window.fbProductFbPixel.id = [product.id.toString()];
      window.fbProductFbPixel.name = product.name;
    },
  },
};
