//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
	name: 'MqActionProduct',
  components: {  },
	props: {
		product: {
      type: Object,
      default: () => {},
    },
		addProduct: {
      type: Function,
      default: () => null,
    },
		removeProduct: {
      type: Function,
      default: () => null,
    },
		isAdded: {
      type: Boolean,
      default: false,
    },
		quantity: {
      type: Number,
      default: 0,
    },
	},
	data() {
		return {
			limitProductAdd: 99,
			classActive: 'product-active',
			classElement: 'mq-actions-btns',
		}
	},
  methods: {
		showActionProduct() {
			const actionElement = this.$el.querySelector(`.${this.classElement}`);
			if (!actionElement.classList.contains(this.classActive)) {
				const productActive = document.querySelectorAll(`.${this.classActive}`);
				productActive.forEach(element => {
					element.classList.remove(this.classActive)
				});
				actionElement.classList.add(this.classActive);
			}
			if (this.quantity <= 0) {
				actionElement.classList.remove(this.classActive);
			}
		},
  },
}
