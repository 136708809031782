export const COVERAGE_TYPES = {
  CURRENT_STORE_WITH_COVERAGE: 'current_store_with_coverage',
  CURRENT_STORE_CLOSED: 'current_store_closed',
  CURRENT_STORE_NOT_FOUND: 'current_store_not_found',
  STORES_NOT_AVAILABLE: 'STORES_NOT_AVAILABLE',
  CURRENT_STORE_WITHOUT_COVERAGE: 'current_store_without_coverage',
  WITHOUT_STORE_SELECTED: 'without_store_selected',
};

export const MODALS_ACTIONS = {
  ENTER_OTHER_ADDRESS: 'enter-other-address',
  SEE_OTHER_STORES: 'current-store-closed-see-stores',
  LEAVE_COMMENT: 'leave-comment',
  CLOSED_BY_X: 'closed-by-x',
};

