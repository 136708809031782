import { getCitiesConfigEndpoint } from '~/endpoints/config/get-countries-config-endpoint';
import { getValidateDniTypeUsecase } from '~/usecases/dni-types/validate-dni-type-usecase';
// initial state
const state = {
  config: null,
  documents: [],
  isHuaweiHMS: false,
};

// getters
const getters = {
  getConfigMapped:
    () =>
    ({ data: { attributes }, included }) => {
      const configMapped = {
        data: {
          about: attributes.about,
          contactUs: {
            email: attributes.contactUs.email,
            admin_phone: attributes.contactUs.admin_phone,
            phone_code: attributes.contactUs.phone_code,
          },
          discounts: {
            creditsByFilledCode: attributes.referred.referred_credit,
            creditsByRefer: attributes.referred.referred_by_credit,
            freeDelivery: null,
            minimum_order_total_user_credit: attributes.discounts.minimum_order_total_use_credit,
          },
          download: {
            appStore: attributes.download.app_store,
            googlePlay: attributes.download.google_play,
            huaweiGallery: attributes.download.huawei_gallery,
          },
          events: {
            bigQuery: attributes.events.big_query,
          },
          social: {
            facebook: attributes.social.facebook,
            instagram: attributes.social.instagram,
            twitter: attributes.social.twitter,
          },
          expressCapacity: attributes.express_capacity,
          legalBanner: attributes.legal_banner,
          path: attributes.path,
          policy: attributes.policy,
          postal_code_regex: attributes.postal_code_regex,
          products: attributes.products,
          referred: attributes.referred,
          currency: attributes.currency,
          currency_formatter: attributes.currency_formatter,
          included,
          is_invoice_required: attributes.is_invoice_required,
          is_required_identification: attributes.is_required_identification,
          terms: attributes.terms,
          tabs: attributes.tabs,
          prime_activation_flag: attributes.prime_activation_flag,
        },
      };

      return configMapped;
    },
  getContactEmail(state) {
    const { email } = state.config.contactUs;
    return email ?? 'servicioalcliente@merqueo.com';
  },
  getPostalCodeRegex(state) {
    return state.config.postal_code_regex ?? '';
  },
  getStatusHuaweiDevice(state) {
    return state.isHuaweiHMS;
  },
};

// actions
const actions = {
  async getConfig({ commit, state, rootState, getters }) {
    const { currentCountry } = rootState.home;
    const { id } = currentCountry;

    let countryId = id ? id : 1;

    if (!state.config) {
      const { data } = await (await getCitiesConfigEndpoint(countryId)).response;
      let config = getters.getConfigMapped(data);
      commit('SET_DOCUMENT_TYPES', config.data.included);
      commit('SET_CONFIG', config.data);
      return config.data;
    }
    return state.config;
  },
  async getValidateDniType({ commit, state, rootState, getters }, dniTypeId, documentNumber) {
    const { data } = await getValidateDniTypeUsecase(dniTypeId, documentNumber);
    return data;
  },
  async setStatusHuaweiDevice({ commit }, status) {
    commit('SET_HUAWEI_DEVICE_STATUS', status);
  },
};

// mutations
const mutations = {
  SET_CONFIG(state, config) {
    state.config = config;
  },
  SET_DOCUMENT_TYPES(state, documents) {
    state.documents = documents;
  },
  SET_HUAWEI_DEVICE_STATUS(state, status) {
    state.isHuaweiHMS = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
