
import axios from 'axios';
import { nuxtInstance } from '~/plugins/nuxt-instance';

export async function userPolicyValidateEndpoint({ country, base }) {
  const {
    store: { state },
  } = nuxtInstance;
  const token = state.user.token;
  try {
    const response = await axios.get(`${base}/user-policy-validate/v1`, {
      headers: {
        'X-Merqueo-Country': country,
        Authorization: `Bearer ${token}`,
      },
    });
    return { error: null, response };
  } catch (error) {
    return { error, response: null };
  }
}
