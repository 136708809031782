import { validateScheme } from '~/decorators/validateScheme';
import { ValidateCouponsParams } from '~/schemes/validate-coupons-scheme';
import $axios from '~/services/global-axios';

export class ValidateCoupons {
  @validateScheme
  static async getValidateCouponsEndpoint(
    validateCouponsParams: ValidateCouponsParams,
  ): Promise<{ response: any; error: any }> {
    const { storeId } = validateCouponsParams;
    try {
      const response = await $axios.post(`/3.1/stores/${storeId}/promotional-codes`, validateCouponsParams);
      return { response, error: null };
    } catch (error) {
      return { response: null, error };
    }
  }
}
