import { camelize } from '~/helpers/camelize';
import normalize from 'json-api-normalize';
import { DEPARTMENT_TRANFORM_KEYS } from '../departments/department-transform';
import { SHELF_TRANSFORM_KEYS } from '../shelfs/shelf-transform';
import { TAG_TRANSFORM_KEYS, TAG_CATALOGUE_TRANSFORM_KEYS } from '../tags/tag-transform';

const PRODUCT_TRANFORM_KEYS = [
  'id',
  'pum',
  'unit',
  'href',
  'name',
  'slug',
  'price',
  'public_price',
  'volume',
  'weight',
  'quantity',
  'store_id',
  'has_warning',
  'description',
  'image_app_url',
  'special_price',
  'is_best_price',
  'is_marketplace',
  'image_large_url',
  'image_small_url',
  'image_medium_url',
  'discount_percentage',
  'quantity_special_price',
  'delivery_discount_amount',
  'marketplace_delivery_days',
  'sponsored',
  'template_video',
  'first_order_special_price',
  'status',
  'type_product',
];

export const PRODUCT_IMAGES_KEYS = ['image_large_url', 'image_app_url'];

export function productTransforms(responseData) {
  const normalizeProduct = normalize(responseData).get([
    ...PRODUCT_TRANFORM_KEYS,
    ...TAG_TRANSFORM_KEYS.map((key) => `tag.${key}`),
    ...SHELF_TRANSFORM_KEYS.map((key) => `shelf.${key}`),
    ...PRODUCT_IMAGES_KEYS.map((key) => `images.${key}`),
    ...DEPARTMENT_TRANFORM_KEYS.map((key) => `department.${key}`),
    ...TAG_CATALOGUE_TRANSFORM_KEYS.map((key) => `storeProductCatalogTags.${key}`),
  ]);
  return camelize(normalizeProduct);
}

export function productsTransforms(
  responseData,
  overrideProperties = ['slugs.city', 'slugs.shelf', 'slugs.product', 'slugs.department'],
) {
  return normalize(responseData)
    .get([
      ...PRODUCT_TRANFORM_KEYS,
      ...TAG_TRANSFORM_KEYS.map((key) => `tag.${key}`),
      ...SHELF_TRANSFORM_KEYS.map((key) => `shelf.${key}`),
      ...DEPARTMENT_TRANFORM_KEYS.map((key) => `department.${key}`),
      ...TAG_CATALOGUE_TRANSFORM_KEYS.map((key) => `storeProductCatalogTags.${key}`),
      ...overrideProperties,
    ])
    .map((product) => {
      return camelize(product);
    });
}

export function productsTransformsUpdate(
  responseData,
  department,
  shelf,
  overrideProperties = ['slugs.city', 'slugs.shelf', 'slugs.product', 'slugs.department'],
) {
  return normalize(responseData)
    .get([
      ...PRODUCT_TRANFORM_KEYS,
      ...TAG_TRANSFORM_KEYS.map((key) => `tag.${key}`),
      ...SHELF_TRANSFORM_KEYS.map((key) => `shelf.${key}`),
      ...DEPARTMENT_TRANFORM_KEYS.map((key) => `department.${key}`),
      ...TAG_CATALOGUE_TRANSFORM_KEYS.map((key) => `storeProductCatalogTags.${key}`),
      ...overrideProperties,
    ])
    .map((product) => {
      return camelize({ ...product, shelf: { ...shelf }, department: { ...department } });
    });
}
export function orderProductTransform(responseData) {
  return normalize({
    data: responseData,
  }).get([
    'id',
    'totalPrice',
    'unitPrice',
    'quantityCartOriginal',
    'quantityCart',
    'status',
    'idStoreProduct',
    'detailProduct',
  ]);
}
export function storeProductTransform(responseData) {
  return normalize({
    data: responseData,
  }).get([
    'id',
    'slug',
    'specialPrice',
    'price',
    'publicPrice',
    'storeId',
    'discountPercentage',
    'quantitySpecialPrice',
    'deliveryDiscountAmount',
    'hasWarning',
    'sponsored',
    'pum',
    'volume',
    'weight',
  ]);
}
